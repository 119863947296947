import React from 'react';
import style from './style.module.css';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DELAY } from 'const';
import { FESpinner } from 'components';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

const Layout = ({ open }: { open: boolean }) => {
  const [animationIn, setAnimationIn] = React.useState<boolean>(true);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (open === false) {
      setAnimationIn(false);
    }
  }, [open]);

  return (
    <>
      <CSSTransition
        in={animationIn}
        timeout={ANIMATION_DELAY}
        mountOnEnter
        unmountOnExit
        classNames={containerAnimation}
        nodeRef={containerRef}
      >
        <div ref={containerRef} className={style.container}>
          <FESpinner size='large' />
        </div>
      </CSSTransition>
    </>
  );
};

export default Layout;
