import FeatSDKV1 from 'api/version1';
import FeatSDKV3 from 'api/version3';
import { LastOrder } from 'domains/cart';
import { GiftRepurschase } from 'domains/giftRepurschases';
import { Order } from 'domains/profile';
import { createEffect } from 'effector';
import { AuthClient } from 'api/base';
import { FELocalStorageUtil } from 'utils';
import { Banner } from './entities';

export const getBannersByCatalogIdFx = createEffect(
  async ({ catalogId }: { catalogId: string }) => {
    let banners: Banner[];

    banners = await Promise.all(
      (
        await FeatSDKV1.banners.getBannersByCatalogId({ catalogId })
      ).items.map(async (banner) => {
        if (banner.link?.type === 'STORIES') {
          try {
            return {
              ...banner,
              story: await FeatSDKV1.stories
                .getStoryByid({
                  id: banner.link.value,
                })
                .then(async (story) => {
                  return {
                    ...story,
                    stories: await Promise.all(
                      story.stories.map(async (story) => {
                        if (
                          story.button &&
                          story.button.link?.type === 'STORIES'
                        ) {
                          try {
                            return {
                              ...story,
                              story: await FeatSDKV1.stories.getStoryByid({
                                id: story.button.link.value,
                              }),
                            };
                          } catch (error) {
                            return { ...story, story: null };
                          }
                        } else return { ...story, story: null };
                      }),
                    ),
                  };
                }),
            };
          } catch (error) {
            return { ...banner, story: null };
          }
        } else return { ...banner, story: null };
      }),
    );
    return {
      banners,
    };
  },
);

export const initBannersFx = createEffect(
  async ({ catalogId, cityId }: { catalogId: string; cityId: string }) => {
    let banners: Banner[];

    let lastOrder: LastOrder | null = null;
    let giftRepurchases: GiftRepurschase[];
    let needToFetchLastOrder = false;

    let orders: Order[] = [];

    banners = await Promise.all(
      (
        await FeatSDKV1.banners.getBannersByCatalogId({ catalogId })
      ).items.map(async (banner) => {
        if (banner.link?.type === 'STORIES' && banner.link.value) {
          try {
            return {
              ...banner,
              story: await FeatSDKV1.stories
                .getStoryByid({
                  id: banner.link.value,
                })
                .then(async (story) => {
                  return {
                    ...story,
                    stories: await Promise.all(
                      story.stories.map(async (story) => {
                        if (
                          story.button &&
                          story.button.link?.type === 'STORIES' &&
                          story.button.link.value
                        ) {
                          try {
                            return {
                              ...story,
                              story: await FeatSDKV1.stories.getStoryByid({
                                id: story.button.link.value,
                              }),
                            };
                          } catch (error) {
                            return { ...story, story: null };
                          }
                        } else {
                          return { ...story, story: null };
                        }
                      }),
                    ),
                  };
                }),
            };
          } catch (error) {
            return { ...banner, story: null };
          }
        } else {
          return { ...banner, story: null };
        }
      }),
    );

    if (FELocalStorageUtil.getLastOrderId.lastOrderId) {
      const { lastOrderId } = FELocalStorageUtil.getLastOrderId;
      const lastOrderInList = orders.length ? orders[0] : null;
      if (lastOrderInList === null || lastOrderInList.id === lastOrderId) {
        if (lastOrderInList) {
          if (
            lastOrderInList.status !== 'DONE' &&
            lastOrderInList.status !== 'CANCELLED'
          ) {
            lastOrder = {
              order: {
                ...lastOrderInList,
                paid: false,
              },
              changed: false,
            };
            needToFetchLastOrder = true;
          } else FELocalStorageUtil.deleteLastOrder();
        } else {
          try {
            const order = await FeatSDKV3.orders.getOrderById({
              id: lastOrderId,
            });
            if (order.status !== 'DONE' && order.status !== 'CANCELLED') {
              lastOrder = {
                order: {
                  ...order,
                  paid: false,
                },
                changed: false,
              };
              needToFetchLastOrder = true;
            } else FELocalStorageUtil.deleteLastOrder();
          } catch (error) {}
        }
      } else {
        if (
          lastOrderInList.status === 'CANCELLED' ||
          lastOrderInList.status === 'DONE'
        )
          FELocalStorageUtil.deleteLastOrder();
        else {
          FELocalStorageUtil.setLastOrderId({
            lastOrderId: lastOrderInList.id,
          });
          lastOrder = {
            order: {
              ...lastOrderInList,
              paid: false,
            },
            changed: false,
          };
          needToFetchLastOrder = true;
        }
      }
    }

    try {
      if (AuthClient.isAuth)
        giftRepurchases = await FeatSDKV1.giftRepurchases.getGiftRepurchases({
          cityId,
        });
      else
        giftRepurchases =
          await FeatSDKV1.giftRepurchases.notSecureGetGiftRepurchases({
            cityId,
          });
    } catch (error) {
      giftRepurchases = [];
    }

    return {
      orders,
      lastOrder,
      needToFetchLastOrder,
      banners,
      giftRepurchases,
    };
  },
);
