import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import {
  GetRestaurantByIdRequestV1,
  GetRestaurantStopListRequestV1,
  GetRestaurantTimeIntervalsRequestV1,
  GetRestaurantsByCityIdRequestV1,
} from '.';

import {
  GetRestaurantByIdResponseV1,
  GetRestaurantStopListResponseV1,
  GetRestaurantTimeIntervalsResponseV1,
  GetRestaurantsByCityIdResponseV1,
} from '.';

const restaurantsRepositoryV1 = {
  getRestaurantsByCityId: (
    { cityId }: GetRestaurantsByCityIdRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetRestaurantsByCityIdResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/restaurants?cityId=${cityId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetRestaurantsByCityIdResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetRestaurantsByCityIdResponseV1.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getRestaurantById: (
    { id }: GetRestaurantByIdRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetRestaurantByIdResponseV1.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/restaurants/${id}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetRestaurantByIdResponseV1.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetRestaurantByIdResponseV1.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getRestaurantTimeIntervals: (
    { id }: GetRestaurantTimeIntervalsRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetRestaurantTimeIntervalsResponseV1>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/restaurants/${id}/time-intervals`;
          let result: AxiosResponse<
            BaseAPIResponse<GetRestaurantTimeIntervalsResponseV1>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetRestaurantTimeIntervalsResponseV1>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getRestaurantStopList: (
    { id }: GetRestaurantStopListRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetRestaurantStopListResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/places/restaurants/${id}/stop-list`;
        let result: AxiosResponse<
          BaseAPIResponse<GetRestaurantStopListResponseV1>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetRestaurantStopListResponseV1>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default restaurantsRepositoryV1;
