import React from 'react';
import FECodeFieldProps from './props';
import clsx from 'clsx';
import style from './style.module.css';
import { KEY_CODE } from 'const';

const FECodeField = ({
  value,
  fields = 4,
  onChange,
  onInputComplete,
  hasError = false,
  errorText,
}: FECodeFieldProps) => {
  const [values, setValues] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (value) {
      const values = value.split('');
      if (values.length < fields) {
        return setValues(
          new Array(fields)
            .fill('')
            .map((char, index) => (values[index] ? values[index] : char)),
        );
      } else if (values.length > fields) {
        return setValues(values.slice(0, fields));
      } else {
        return setValues(values);
      }
    } else setValues(new Array(fields).fill(''));
  }, [value, fields]);

  const triggerChange = (values: string[]) => {
    const val = values.join('');
    onChange && onChange(val);
    if (onInputComplete && val.length >= fields) {
      onInputComplete(val);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const nextTarget =
      e.target.parentElement?.nextElementSibling?.children.item(
        0,
      ) as EventTarget & HTMLInputElement;
    // const prevTarget =
    //   e.target.parentElement?.previousElementSibling?.children.item(
    //     0,
    //   ) as EventTarget & HTMLInputElement;
    // const lastTarget =
    //   e.target.parentElement?.parentElement?.lastElementChild?.children.item(
    //     0,
    //   ) as EventTarget & HTMLInputElement;

    const eventTarget = e.target;
    const value = eventTarget.value;

    if (value.length > 1) {
      const newValues = [...values];
      const valueArray = value.split('');
      for (let i = index, x = 0; i < fields; ++i, x++) {
        if (valueArray[x] !== undefined) newValues[i] = valueArray[x];
      }
      setValues(newValues);
      triggerChange(newValues);
    } else {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
      if (nextTarget) {
        nextTarget.focus();
        nextTarget.select();
      }
      triggerChange(newValues);
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (!['e', 'E', '+', '-'].includes(e.key)) {
      const prevTarget =
        e.currentTarget.parentElement?.previousElementSibling?.children.item(
          0,
        ) as EventTarget & HTMLInputElement;
      const nextTarget =
        e.currentTarget.parentElement?.nextElementSibling?.children.item(
          0,
        ) as EventTarget & HTMLInputElement;
      const prevIndex = index - 1;
      switch (e.code) {
        case KEY_CODE.backspace:
          e.preventDefault();
          const vals = [...values];
          if (values[index]) {
            vals[index] = '';
            setValues(vals);
            triggerChange(vals);
          } else if (prevTarget) {
            vals[prevIndex] = '';
            prevTarget.focus();
            setValues(vals);
            triggerChange(vals);
          }
          break;
        case KEY_CODE.left:
          e.preventDefault();
          if (prevTarget) {
            prevTarget.focus();
          }
          break;
        case KEY_CODE.right:
          e.preventDefault();
          if (nextTarget) {
            nextTarget.focus();
          }
          break;
        case KEY_CODE.up:
        case KEY_CODE.down:
          e.preventDefault();
          break;
        default:
          break;
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <div className={style.root}>
      <div className={style.content}>
        {values.map((digit, index) => (
          <div key={index} className={style.inputContainer}>
            <input
              className={clsx(style.input, 'title')}
              type='number'
              value={digit}
              inputMode='numeric'
              autoComplete='one-time-code'
              pattern='\d{1}'
              maxLength={fields}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
        ))}
      </div>
      {hasError && errorText && (
        <p className={clsx('caption-1', style.errorText)}>{errorText}</p>
      )}
    </div>
  );
};

export default FECodeField;
export type { FECodeFieldProps };
