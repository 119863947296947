import { CurrencySymbol } from 'models';
import React from 'react';
import { FERadioButton } from 'ui-kit/components';
import { ConstructorBase } from '../../../../../../model';
import style from './style.module.css';

const BaseItem = ({
  active,
  onClick,
  name,
  price,
  currency,
}: {
  active: boolean;
  onClick: () => void;
  name: string;
  price: number;
  currency: CurrencySymbol;
}) => {
  return (
    <div className={style.base} onClick={onClick}>
      <div className={style.info}>
        <FERadioButton active={active} />
        <p className='subhead'>{name}</p>
      </div>
      <p className='subhead'>{`${price} ${currency}`}</p>
    </div>
  );
};

const BasePicker = ({
  currentBase,
  bases,
  onChangeBase,
  currency,
}: {
  currentBase?: ConstructorBase;
  bases: ConstructorBase[];
  onChangeBase: (base: ConstructorBase) => void;
  currency: CurrencySymbol;
}) => {
  return (
    <div className={style.root}>
      {bases.map((base) => (
        <BaseItem
          currency={currency}
          onClick={() => onChangeBase(base)}
          key={base.id}
          active={base.id === currentBase?.id}
          name={base.name}
          price={base.price}
        />
      ))}
    </div>
  );
};

export default BasePicker;
