import { FEImageComponent, FEQuantity } from 'components';
import { ModifierProps } from './props';
import style from './style.module.css';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import clsx from 'clsx';
import { CurrencySymbol } from 'models';

const Modifier = ({
  quantity,
  name,
  id,
  onChangeQuantity,
  price,
  imageId,
  disabled,
  max,
  currency,
    isReachedGroupMax = false
}: ModifierProps & { currency: CurrencySymbol } & {isReachedGroupMax?: boolean}) => {
  return (
    <div className={style.root}>
      <div className={clsx(style.content, disabled && style.content__disabled)}>
        <FEImageComponent
          className={style.image}
          image={{ imageId: imageId || '' }}
          altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
        />
        <div className={style.data}>
          <p className={clsx('subhead')}>{name}</p>
          <p className='subhead'>{`${price} ${currency}`}</p>
        </div>
        <div className={style.active}>
          <FEQuantity
            disabled={disabled}
            value={quantity}
            disabledMax={isReachedGroupMax}
            max={max}
            min={0}
            onChange={(val) => onChangeQuantity(id, val)}
          />
        </div>
      </div>
    </div>
  );
};

export default Modifier;
