import clsx from 'clsx';
import React from 'react';
import { SelectorGroupPickerProps } from './props';
import { BottomSheetContent, Modifier, ModifierCard } from './components';
import style from './style.module.css';
import { MOBILE_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import { FEBottomSheet, FEImageComponent } from 'components';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const SelectorGroupPicker = ({
  currentModifiers,
  baseModifiers,
  onPickModifiers,
  name,
  iconId,
  min,
  max,
  hasError = false,
  currency,
}: SelectorGroupPickerProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleToggleModifier = (id: string, active: boolean) => {
    if (max !== 1) {
      if (active) {
        if (max && currentModifiers.length < max) {
          const newCurrentModifiers = [
            ...currentModifiers,
            baseModifiers.find((baseModifier) => baseModifier.id === id)!,
          ];
          onPickModifiers(newCurrentModifiers);
        }
      } else {
        if (min < currentModifiers.length) {
          const newCurrentModifiers = currentModifiers.filter(
            (currentModifier) => currentModifier.id !== id,
          );
          onPickModifiers(newCurrentModifiers);
        }
      }
    } else {
      if (active) {
        const modifier = baseModifiers.find(({ id: mdId }) => id === mdId)!;
        onPickModifiers([modifier]);
      } else {
        min < currentModifiers.length && onPickModifiers([]);
      }
    }
  };

  const modifiers = React.useMemo(() => {
    const currentModifiersObject = Object.fromEntries(
      currentModifiers.map((currentModifier) => [
        currentModifier.id,
        currentModifier,
      ]),
    );

    return baseModifiers.map((modifier) => {
      if (currentModifiersObject[modifier.id]) {
        return {
          ...modifier,
          active: true,
        };
      }
      return {
        ...modifier,
        active: false,
      };
    });
  }, [currentModifiers, baseModifiers]);

  return !isDesktop ? (
    <>
      {currentModifiers.length === 0 ? (
        <div
          className={clsx(style.root, hasError && style.root__error)}
          onClick={() => setOpen(true)}
        >
          <div
            className={clsx(style.content, hasError && style.content__error)}
          >
            {iconId && (
              <FEImageComponent
                className={clsx('icon-24')}
                image={{ imageId: iconId }}
              />
            )}
            <p className='subhead'> {t('catalog.dish.modal.choose')}</p>
            <button
              className={clsx('button-chevronDown', style.chevronButton)}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={style.changeButton__container}>
            <button
              className={clsx('headline', style.changeButton)}
              onClick={() => setOpen(true)}
            >
              {t('common.change')}
            </button>
          </div>
          <div className={style.modifiers}>
            {currentModifiers.map((currentModifier, i) => (
              <ModifierCard
                key={currentModifier.id}
                name={currentModifier.info.name}
                imageId={currentModifier.info.imageId}
              />
            ))}
          </div>
        </>
      )}
      <FEBottomSheet
        open={open}
        onClose={() => setOpen(false)}
        render={({ onClose }) => (
          <BottomSheetContent
            currency={currency}
            max={max}
            min={min}
            currentModifiers={currentModifiers}
            baseModifiers={baseModifiers}
            // modifiers={modifiers}
            onClose={onClose}
            name={name}
            onSubmit={(modifiers) => {
              onPickModifiers(modifiers.filter((modifier) => modifier.active));
            }}
          />
        )}
      />
    </>
  ) : (
    <div
      className={clsx(
        style.root,
        hasError && style.root__error,
        open && style.root__open,
      )}
    >
      <div
        className={clsx(
          style.header,
          open && style.header__open,
          hasError && style.header__error,
        )}
        onClick={() => setOpen(!open)}
      >
        <p className={clsx('subhead', style.name)}>
          {t('catalog.dish.modal.choose')}
        </p>
        <button className={clsx(style.chevronButton, 'button-chevronDown')} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {modifiers
          .filter((md) => (!open ? md.active : md))
          .map((modifier) => (
            <Modifier
              type={max > 1 ? 'checkbox' : 'radio'}
              currency={currency}
              key={modifier.id}
              disabled={
                ((!modifier.active && currentModifiers.length === max) ||
                  (modifier.active && currentModifiers.length === min)) &&
                max !== 1
              }
              active={modifier.active}
              name={modifier.info.name}
              id={modifier.id}
              onToggle={handleToggleModifier}
              price={modifier.price}
              imageId={modifier.info.imageId}
            />
          ))}
      </div>
    </div>
  );
};

export default SelectorGroupPicker;
