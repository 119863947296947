import ordersRepositoryV3 from './orders';
import catalogRepositoryV3 from './catalog';
import constructorsRepositoryV3 from './constructors';
import dishesRepositoryV3 from './dishes';
import productsRepositoryV3 from './products';

export * from './orders';
export * from './catalog';
export * from './constructors';
export * from './dishes';
export * from './products';

const FeatSDKV3 = {
  orders: ordersRepositoryV3,
  catalog: catalogRepositoryV3,
  constructors: constructorsRepositoryV3,
  dishes: dishesRepositoryV3,
  products: productsRepositoryV3,
};

export default FeatSDKV3;
