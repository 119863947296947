import clsx from 'clsx';
import { Restaurant } from 'domains/cartography';
import { StatusInfo, WorkStatus, Location } from 'models';
import React from 'react';
import { getDistanceTo } from 'utils';
import style from './style.module.css';
import { t } from 'i18next';

const getRestaurantStatus = (
  status: WorkStatus,
  statusInfo: StatusInfo,
  openAt: string,
  closeAt: string,
): { title: string; disabled: boolean; showRestaurantInfo: boolean } => {
  if (status === 'CLOSE') {
    return {
      title: t('cartography.restaurant.statuses.CLOSE'),
      disabled: true,
      showRestaurantInfo: true,
    };
  } else {
    if (statusInfo === 'OPEN') {
      return {
        title: t('cartography.restaurant.statuses.OPEN', {
          from: openAt,
          to: closeAt,
        }),
        disabled: false,
        showRestaurantInfo: true,
      };
    } else if (statusInfo === 'ONLY_OFFLINE') {
      return {
        title: t('cartography.restaurant.statuses.ONLY_OFFLINE'),
        disabled: true,
        showRestaurantInfo: true,
      };
    } else {
      return {
        title: t('cartography.restaurant.statuses.AWAITING_OPEN'),
        disabled: true,
        showRestaurantInfo: false,
      };
    }
  }
};

type Props = {
  restaurant: Restaurant;
  userGeolocation: Location | null;
  onClick: () => void;
};

const RestaurantsListItem = ({
  restaurant,
  userGeolocation,
  onClick,
}: Props) => {
  const [distanceWidth, setDistanceWidth] = React.useState<number>(0);
  const distanceRef = React.useRef<HTMLParagraphElement>(null);

  React.useLayoutEffect(() => {
    if (distanceRef.current) {
      setDistanceWidth(distanceRef.current.getBoundingClientRect().width);
    }
  }, [userGeolocation, restaurant.address.location]);

  const { title, disabled } = getRestaurantStatus(
    restaurant.status,
    restaurant.statusInfo,
    restaurant.openAt,
    restaurant.closeAt,
  );

  return (
    <div className={style.root} onClick={onClick}>
      <div className={style.container}>
        <div className={style.info}>
          <p
            className={clsx('headline')}
            style={{
              paddingRight: `calc(20px + ${distanceWidth}px)`,
            }}
          >
            {restaurant.name}
          </p>
          {userGeolocation && restaurant.address.location && (
            <p ref={distanceRef} className={clsx('text-1', style.distanceTo)}>
              {getDistanceTo(
                userGeolocation,
                restaurant.address.location,
                true,
              )}
            </p>
          )}
        </div>
        <p className={clsx('text-2', style.address)}>
          {restaurant.address.address}
        </p>
        <p
          className={clsx(
            'text-2',
            !disabled ? style.worktime : style.worktime__closed,
          )}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

export default RestaurantsListItem;
