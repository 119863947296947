/* CONSTANTS BLOCK */
const UTM_COOKIE_LIFETIME_DAYS = 90;
const UTM_COOKIE_NAME = 'utm_params_admitad';
const CROSSDOMAIN_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  // If you need to transfer other params between domains, add them below, e.g.:
  'tagtag_uid',
];
/* END OF CONSTANTS BLOCK */

export const initAdmitadCrossdomainScript = () => {
  function getUtmParams() {
    const pageUrl = new URL(window.location.href);
    let utmCookieValue = '';
    for (let i = 0; i < CROSSDOMAIN_PARAMS.length; i++) {
      const param = CROSSDOMAIN_PARAMS[i];
      const value = pageUrl.searchParams.get(param);

      if (value === null) continue;

      utmCookieValue += param + '=' + value + '&';
    }
    return utmCookieValue.slice(0, -1);
  }

  function getCookie(name: string) {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)',
      ),
    );
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  function setCookie(name: string, value: string, lifetimeDays: number) {
    const periodMillis = lifetimeDays * 24 * 60 * 60 * 1000;
    const expiresDate = new Date(new Date().getTime() + periodMillis);
    const cookieString =
      name + '=' + value + '; path=/; expires=' + expiresDate.toUTCString();
    document.cookie = cookieString;
    document.cookie = cookieString + '; domain=.' + window.location.host;
  }

  function addParamsToLinks(paramsString: string) {
    const links = document.getElementsByTagName('a');

    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      if (link.host === window.location.host || link.host === '') {
        continue;
      }
      try {
        const linkUrl = new URL(link.href);
        if (linkUrl.search) {
          linkUrl.search += '&' + paramsString;
        } else {
          linkUrl.search = '?' + paramsString;
        }
        link.href = linkUrl.toString();
      } catch (err) {}
    }
  }

  const utmParams = getUtmParams() || getCookie(UTM_COOKIE_NAME);
  if (utmParams) {
    setCookie(UTM_COOKIE_NAME, utmParams, UTM_COOKIE_LIFETIME_DAYS);
    addParamsToLinks(utmParams);
  }
};
