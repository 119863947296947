import { Order } from 'domains/profile';
import style from './style.module.css';
import { Order as OrderComponent } from './components';
import clsx from 'clsx';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';
import { FESpinner } from 'ui-kit/components';

const Content = ({
  orders,
  onClose,
  isPendingOrders,
  onRepeatOrder,
  currency,
  pendingList,
}: {
  orders: Order[];
  onRepeatOrder: ({ order }: { order: Order }) => void;
  onClose: () => void;
  isPendingOrders: boolean;
  currency: CurrencySymbol;
  pendingList: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!!orders.length ? (
        <div className={style.content}>
          {orders.map((order) => (
            <OrderComponent
              currency={currency}
              onRepeatOrder={onRepeatOrder}
              order={order}
              key={order.id}
            />
          ))}
          {pendingList && (
            <div className={style.pendingContainer}>
              <FESpinner size='small' />
            </div>
          )}
        </div>
      ) : (
        <div className={style.emptyContentContainer}>
          <p className={clsx('text-2', style.emptyMessage)}>
            {t('orders.noOrdersMessage')}
          </p>
        </div>
      )}
    </>
  );
};

export default Content;
