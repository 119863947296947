import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetCitiesResponseV1, GetCitiesSmallResponseV1 } from '.';

const citiesRepositoryV1 = {
  getCities: (signal?: AbortSignal) =>
    new Promise<GetCitiesResponseV1.ListResponse>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/places/cities`;
        let result: AxiosResponse<
          BaseAPIResponse<GetCitiesResponseV1.ListResponse>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetCitiesResponseV1.ListResponse>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  getCitiesSmall: (signal?: AbortSignal) =>
    new Promise<GetCitiesSmallResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/cities/small`;
          let result: AxiosResponse<
            BaseAPIResponse<GetCitiesSmallResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetCitiesSmallResponseV1.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default citiesRepositoryV1;
