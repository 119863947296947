import React, {useState} from 'react';
import {FEButton} from 'components';
import {Modifier as ModifierModel} from './model';
import style from './style.module.css';
import _ from 'lodash';
import {Modifier} from './components';
import {DishSelectorGroupModifier} from '../../../../../../../../models';
import {CurrencySymbol} from 'models';
import {useTranslation} from 'react-i18next';
import {log} from "@craco/craco/dist/lib/logger";

const BottomSheetContent = ({
                                name,
                                onClose,
                                onSubmit,
                                baseModifiers,
                                currentModifiers,
                                currency,
                                max,
                                min,
                            }: {
    name: string;
    onClose: () => void;
    onSubmit: (modifiers: ModifierModel[]) => void;
    baseModifiers: DishSelectorGroupModifier[];
    currentModifiers: DishSelectorGroupModifier[];
    max: number;
    min: number;
    currency: CurrencySymbol;
}) => {
    const {t} = useTranslation();
    const [newModifiers, setNewModifiers] = React.useState<ModifierModel[]>([]);
    const [modifiersAddOrder, setModifiersAddOrder] = useState<string[]>(
        currentModifiers.length ? currentModifiers.map(mod => mod.id) : []
    )
    const modifiers = React.useMemo(() => {
        const currentModifiersObject = Object.fromEntries(
            currentModifiers.map((currentModifier) => [
                currentModifier.id,
                currentModifier,
            ]),
        );

        return baseModifiers.map((modifier) => {
            if (currentModifiersObject[modifier.id]) {
                return {
                    ...modifier,
                    active: true,
                };
            }
            return {
                ...modifier,
                active: false,
            };
        });
    }, [currentModifiers, baseModifiers]);

    React.useEffect(() => {
        setNewModifiers(modifiers);
    }, [modifiers]);

    const isSubmitDisabled = React.useMemo(() => {
        const currentModifiersObject = Object.fromEntries(
            currentModifiers.map((currentModifier) => [
                currentModifier.id,
                currentModifier,
            ]),
        );

        return _.isEqual(
            baseModifiers.map((modifier) => {
                if (currentModifiersObject[modifier.id]) {
                    return {
                        ...modifier,
                        active: true,
                    };
                }
                return {
                    ...modifier,
                    active: false,
                };
            }),
            newModifiers,
        );
    }, [baseModifiers, currentModifiers, newModifiers]);

    const hasActiveModifiers = React.useMemo(
        () => newModifiers.filter((modifier) => modifier.active).length !== 0,
        [newModifiers],
    );

    const price = React.useMemo(() => {
        if (newModifiers.filter((modifier) => modifier.active).length === 0) {
            return 0;
        } else {
            return newModifiers
                .filter((modifier) => modifier.active)
                .reduce((accumulator, modifier) => accumulator + modifier.price, 0);
        }
    }, [newModifiers]);



    const handleToggleModifier = (id: string, active: boolean) => {
        const newCurrentModifiers = newModifiers.map((modifier) => {
            if (modifier.id === id && modifiersAddOrder.length !== max) {
                modifier.active = active;
                if (active) {
                    setModifiersAddOrder((a) => {
                        a.push(id)
                        return a
                    })
                } else {
                    setModifiersAddOrder((a) => {
                        a = a.filter((item) => item !== id)
                        return a
                    })
                }
            } else if (modifiersAddOrder.length >= max) {
                if (modifier.id === modifiersAddOrder[0] && active) {
                    modifier.active = false;
                    setModifiersAddOrder((a) => {
                        a = a.filter((item) => item !== modifiersAddOrder[0])
                        return a
                    })
                }
                if (modifier.id === id) {
                    modifier.active = active;
                    if (active) {
                        setModifiersAddOrder((a) => {
                            a.push(id)
                            return a
                        })
                    } else {
                        setModifiersAddOrder((a) => {
                            a = a.filter((item) => item !== id)
                            return a
                        })
                    }
                }
            }

            return modifier;
        });
        setNewModifiers(newCurrentModifiers);
    };

    return (
        <div className={style.root}>
            <div className={style.header}>
                <button className='button-arrowBack' onClick={onClose}/>
                <p className='title'>{name}</p>
            </div>
            <div className={style.container}>
                <div className={style.content}>
                    {newModifiers.map((modifier, i) => (
                        <div key={modifier.id} className={style.modifier}>
                            <Modifier
                                currency={currency}
                                disabled={
                                    (!modifier.active &&
                                        newModifiers.filter((modifier) => modifier.active)
                                            .length === max) ||
                                    (modifier.active &&
                                        newModifiers.filter((modifier) => modifier.active)
                                            .length === min)
                                }
                                active={modifier.active}
                                name={modifier.info.name}
                                id={modifier.id}
                                onToggle={handleToggleModifier}
                                price={modifier.price}
                                imageId={modifier.info.imageId}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className={style.submit}>
                <FEButton
                    disabled={isSubmitDisabled}
                    onClick={() => {
                        onSubmit(newModifiers);
                        onClose();
                    }}
                >
                    {isSubmitDisabled || !hasActiveModifiers ? (
                        t('common.next')
                    ) : (
                        <div className={style.submitButtonContent}>
                            <p className='headline'>{t('catalog.dish.modal.addGroupText')}</p>
                            <p className='headline'>{`+ ${price} ${currency}`}</p>
                        </div>
                    )}
                </FEButton>
            </div>
        </div>
    );
};

export default BottomSheetContent;
