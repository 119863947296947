import clsx from 'clsx';
import { CountryCodeModel } from 'domains/cartography';
import useMergedRef from 'hooks/useMergedRef';
import { IMG_IC_24_CHEVRON_DOWN } from 'images';
import { forwardRef, useCallback, useRef, useState } from 'react';
import { useClickOutside } from 'ui-kit/hooks';
import { FEPhoneFieldProps } from '.';
import { getFlagLink } from './model';
import style from './style.module.css';

type InputProps = Omit<
  FEPhoneFieldProps,
  'tabIndex' | 'onValid' | 'disabled' | 'hasError' | 'onChange'
> & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  hasError: boolean;
  onChangeCountry: (country: CountryCodeModel) => void;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      countries,
      currentCountry,
      disabled,
      hasError,
      onChange,
      onChangeCountry,
    },
    ref,
  ) => {
    const [direction, setDirection] = useState<'top' | 'bottom'>('bottom');
    const [codeOpen, setCodeOpen] = useState<boolean>(false);
    const innerRef = useRef<HTMLInputElement>(null);
    const codeModalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, innerRef);

    useClickOutside(codeModalRef, () => setCodeOpen(false));

    const handleRootLoad = useCallback((node: HTMLDivElement) => {
      if (node) {
        const { top } = node.getBoundingClientRect();
        if (window.innerHeight - top - 52 > 200) setDirection('top');
      }
    }, []);

    return (
      <>
        <div
          ref={handleRootLoad}
          className={style.phoneField}
          has-error={hasError ? 'true' : 'false'}
          is-disabled={disabled ? 'true' : 'false'}
        >
          <div
            className={style.codeContainer}
            has-error={hasError ? 'true' : 'false'}
            is-disabled={disabled ? 'true' : 'false'}
            onClick={(e) => {
              e.stopPropagation();
              !disabled && setCodeOpen(true);
            }}
          >
            <div className={style.codeContent}>
              {currentCountry && (
                <div className={style.code}>
                  <img
                    className={style.flagIcon}
                    src={getFlagLink(currentCountry.iso)}
                    alt={currentCountry.iso}
                  />
                  <img
                    className='icon-24'
                    src={IMG_IC_24_CHEVRON_DOWN}
                    alt=''
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className={style.textFieldContainer}
            has-error={hasError ? 'true' : 'false'}
            onClick={() =>
              innerRef.current && !disabled && innerRef.current.focus()
            }
          >
            <input
              className={clsx('text-1', style.input)}
              type='tel'
              ref={mergedRef}
              disabled={disabled}
              value={value ?? ''}
              onChange={onChange}
            />
          </div>
          {codeOpen && (
            <div
              ref={codeModalRef}
              className={clsx(
                style.codeModalRoot,
                direction === 'top'
                  ? style.codeModalRoot__top
                  : style.codeModalRoot__bottom,
              )}
            >
              {countries.map((country) => {
                const { iso, name, phonePrefix } = country;
                return (
                  <div
                    onClick={() => {
                      setCodeOpen(false);
                      country !== currentCountry && onChangeCountry(country);
                    }}
                    className={style.codeListItem}
                    key={name}
                  >
                    <div className={style.codeListItemContainer}>
                      <div className={style.codeListItemContent}>
                        <div className={style.codeData}>
                          <img
                            className={style.codeFlag}
                            src={getFlagLink(iso)}
                            alt={iso}
                          />
                          <p>{country.name}</p>
                        </div>
                        <p>{phonePrefix}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  },
);
