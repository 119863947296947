import React from 'react';
import { useMediaQuery } from 'ui-kit/hooks';
import FEHeaderProps from './props';
import { IMG_PLACEHOLDER_LOGO, IMG_IC_24_BONUS } from 'images';
import style from './style.module.css';
import { FEBar } from 'components';
import { Confirmation, Menu } from './components';
import { MOBILE_MENU_WIDTH } from 'const';
import { useTranslation } from 'react-i18next';

const FEHeader = ({
  orderType,
  address,
  restaurant,
  bonusAccount,
  user,
  onAddressBarClick,
  onBonusBarClick,
  onLogoClick,
  onCloseConfirmation,
  onDeclineConfirmation,
  isOpenConfirmation,
  isPendingUser,
  features,
}: FEHeaderProps) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_MENU_WIDTH}px)`);
  const balanceRef = React.useRef<HTMLDivElement>(null);
  const logoRef = React.useRef<HTMLImageElement>(null);
  const [balanceWidth, setBalanceWidth] = React.useState<number>(0);
  const [logoWidth, setLogoWidth] = React.useState<number>(0);

  React.useLayoutEffect(() => {
    if (balanceRef.current) {
      setBalanceWidth(balanceRef.current.getBoundingClientRect().width);
    }
  }, [isDesktop, bonusAccount]);

  React.useLayoutEffect(() => {
    if (logoRef.current) {
      setLogoWidth(logoRef.current.getBoundingClientRect().width);
    }
  }, [isDesktop, bonusAccount]);

  return (
    <>
      <div className={style.root}>
        {isDesktop ? (
          <>
            <div className={style.header}>
              <div className={style.container}>
                <div className={style.appData}>
                  <img
                    ref={logoRef}
                    className={style.logo}
                    src={IMG_PLACEHOLDER_LOGO}
                    alt=''
                    onClick={onLogoClick}
                  />
                  <div
                    className={style.addressContainer}
                    style={{
                      width: `calc(100% - ${logoWidth}px)`,
                    }}
                  >
                    <FEBar
                      onClick={onAddressBarClick}
                      label={
                        orderType === 'COURIER'
                          ? t('cartography.header.deliveryLabel')
                          : t('cartography.header.pickupLabel')
                      }
                      value={orderType === 'COURIER' ? address : restaurant}
                      placeholder={t('cartography.header.placeholder')}
                    />
                    <Confirmation
                      value={orderType === 'COURIER' ? address : restaurant}
                      open={isOpenConfirmation}
                      onClose={onCloseConfirmation}
                      onDeclineConfirmation={onDeclineConfirmation}
                    />
                  </div>
                </div>
                <div className={style.userData}>
                  {bonusAccount && features.includes('LOYALTY_PROGRAM') && (
                    <div className={style.balanceContainer}>
                      <FEBar
                        label={t('bonus.header.label')}
                        value={bonusAccount.balance}
                        valueIcon={IMG_IC_24_BONUS}
                        onClick={onBonusBarClick}
                      />
                    </div>
                  )}
                  <Menu
                    features={features}
                    user={user}
                    bonusAccount={bonusAccount}
                    isPendingUser={isPendingUser}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.header}>
              <div className={style.container}>
                <div className={style.menuSide}>
                  <img
                    className={style.logo}
                    src={IMG_PLACEHOLDER_LOGO}
                    alt=''
                    onClick={onLogoClick}
                  />
                  <Menu
                    features={features}
                    user={user}
                    bonusAccount={bonusAccount}
                    isPendingUser={isPendingUser}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {!isDesktop && (
        <div className={style.dataSide}>
          <div
            className={style.addressContainer}
            style={{
              width: `calc(100% - ${balanceWidth}px)`,
            }}
          >
            <FEBar
              onClick={onAddressBarClick}
              label={
                orderType === 'COURIER'
                  ? t('cartography.header.deliveryLabel')
                  : t('cartography.header.pickupLabel')
              }
              value={orderType === 'COURIER' ? address : restaurant}
              placeholder={t('cartography.header.placeholder')}
            />
            <Confirmation
              value={orderType === 'COURIER' ? address : restaurant}
              open={isOpenConfirmation}
              onClose={onCloseConfirmation}
              onDeclineConfirmation={onDeclineConfirmation}
            />
          </div>
          {bonusAccount !== null && features.includes('LOYALTY_PROGRAM') && (
            <div ref={balanceRef} className={style.balanceContainer}>
              <FEBar
                label={t('bonus.header.label')}
                value={bonusAccount.balance}
                valueIcon={IMG_IC_24_BONUS}
                onClick={onBonusBarClick}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FEHeader;
export type { FEHeaderProps };
