import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import {
  OrdersValidateRequestV3,
  GetAllOrdersRequestV3,
  GetOrderByIdRequestV3,
  OrdersCreateRequestV3,
  OrdersAvaliablePaymentTypesRequestV3,
  GetOrderLongPollRequestV3,
} from '.';

import {
  OrdersAvaliablePaymentTypesResponseV3,
  OrdersValidateResponseV3,
  OrdersCreateResponseV3,
  GetAllOrdersResponseV3,
  GetOrderByIdResponseV3,
  GetOrderLongPollResponseV3,
} from '.';

const ordersRepositoryV3 = {
  getAllOrders: (
    { query, limit, offset, sortName, sortDirection }: GetAllOrdersRequestV3,
    signal?: AbortSignal,
  ) =>
    new Promise<GetAllOrdersResponseV3.ListResponse>(
      async (resolve, reject) => {
        try {
          let url =
            `${BASE_URL}/api/v3/orders?` +
            `${query ? `query=${query}` : ''}` +
            `${limit ? `&limit=${limit}` : ''}` +
            `${offset ? `&offset=${offset}` : ''}` +
            `${query ? `&query=${query}` : ''}` +
            `${sortDirection ? `&sortDirection=${sortDirection}` : ''}` +
            `${sortName ? `&sortName=${sortName}` : ''}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetAllOrdersResponseV3.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetAllOrdersResponseV3.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getOrderById: ({ id }: GetOrderByIdRequestV3, signal?: AbortSignal) =>
    new Promise<GetOrderByIdResponseV3.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v3/orders/${id}`;
        let result: AxiosResponse<
          BaseAPIResponse<GetOrderByIdResponseV3.Response>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetOrderByIdResponseV3.Response>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  ordersValidate: (
    request: OrdersValidateRequestV3.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersValidateResponseV3.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v3/orders/validate`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersValidateResponseV3.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersValidateResponseV3.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  ordersCreate: (
    request: OrdersCreateRequestV3.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersCreateResponseV3.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v3/orders/create`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersCreateResponseV3.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersCreateResponseV3.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  ordersAvaliablePaymentTypes: (
    request: OrdersAvaliablePaymentTypesRequestV3.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersAvaliablePaymentTypesResponseV3>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/api/v3/orders/available-payment-types`;
          let result: AxiosResponse<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV3>
          > = await BaseAPIRepository.post<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV3>
          >(url, request, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  notSecureOrdersValidate: (
    request: OrdersValidateRequestV3.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersValidateResponseV3.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v3/orders/validate`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersValidateResponseV3.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersValidateResponseV3.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  notSecureOrdersCreate: (
    request: OrdersCreateRequestV3.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersCreateResponseV3.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v3/orders/create`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersCreateResponseV3.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersCreateResponseV3.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  notSecureOrdersAvaliablePaymentTypes: (
    request: OrdersAvaliablePaymentTypesRequestV3.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersAvaliablePaymentTypesResponseV3>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v3/orders/available-payment-types`;
          let result: AxiosResponse<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV3>
          > = await BaseAPIRepository.post<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV3>
          >(url, request, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getOrderLongPoll: (
    { id, timeout }: GetOrderLongPollRequestV3,
    signal?: AbortSignal,
  ) =>
    new Promise<GetOrderLongPollResponseV3.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v3/orders/long-polling/${id}/change?timeout=${timeout}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetOrderLongPollResponseV3.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetOrderLongPollResponseV3.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default ordersRepositoryV3;
