import { $images } from './stores';
import { addImage, deleteImage } from './events';

$images.on(addImage, (images, { imageId, value }) =>
  images.set(imageId, value),
);

$images.on(deleteImage, (images, { imageId }) => {
  images.delete(imageId);
});
