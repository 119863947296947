import { t } from 'i18next';
import { StatusInfo, WorkStatus } from 'models';

export const getRestaurantStatus = (
  status: WorkStatus,
  statusInfo: StatusInfo,
  openAt: string,
  closeAt: string,
): { title: string; disabled: boolean; showRestaurantInfo: boolean } => {
  if (status === 'CLOSE') {
    return {
      title: t('cartography.restaurant.statuses.CLOSE'),
      disabled: true,
      showRestaurantInfo: true,
    };
  } else {
    if (statusInfo === 'OPEN') {
      return {
        title: t('cartography.restaurant.statuses.OPEN', {
          from: openAt,
          to: closeAt,
        }),
        disabled: false,
        showRestaurantInfo: true,
      };
    } else if (statusInfo === 'ONLY_OFFLINE') {
      return {
        title: t('cartography.restaurant.statuses.ONLY_OFFLINE'),
        disabled: true,
        showRestaurantInfo: true,
      };
    } else {
      return {
        title: t('cartography.restaurant.statuses.AWAITING_OPEN'),
        disabled: true,
        showRestaurantInfo: false,
      };
    }
  }
};
