import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetRestaurantsTimeIntervalsResponseV2 } from '.';

const restaurantsRepositoryV2 = {
  getTimeIntervals: (restaurantId: string, signal?: AbortSignal) =>
    new Promise<GetRestaurantsTimeIntervalsResponseV2>(
      async (resolve, reject) => {
        try {
          const url = `${BASE_URL}/not-secure/api/v2/places/restaurants/${restaurantId}/time-intervals`;

          let result: AxiosResponse<
            BaseAPIResponse<GetRestaurantsTimeIntervalsResponseV2>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetRestaurantsTimeIntervalsResponseV2>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default restaurantsRepositoryV2;
