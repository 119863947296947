import clsx from 'clsx';
import React from 'react';
import FESegmentedControlProps from './props';
import style from './style.module.css';

function FESegmentedControl<T extends string>({
  items,
  value,
  onChange,
  modifiers
}: FESegmentedControlProps<T>) {
  return (
      <>
        <div className={style.root}>
          {Object.keys(items).map((key, index) => (
              <div
                  className={clsx(style.segment, key === value && style.active)}
                  key={key}
                  onClick={(e) => {
                    onChange(key as T);
                  }}
              >
                <p className='subhead'>{items[key as T]}</p>
              </div>
          ))}

        </div>
        <p>{modifiers && modifiers.find((mod) => mod.id === value)?.info.description}</p>
      </>
  );
}

export default FESegmentedControl;
export type {FESegmentedControlProps};
