import { createForm } from 'effector-forms';
import { createStore } from 'effector';
import { Address, CurrencySymbol, Location, OrderType } from 'models';
import { pending } from 'patronum';
import { FEValidationUtils } from 'utils';
import {
  AddressForm,
  City,
  Restaurant,
  AddressInList,
  DeliveryZone,
  DeliveryZoneStopLists,
  TimeIntervals,
  DeliveryZoneAndMapLayerZone,
  Country,
} from './entities';
import {
  fetchCitiesFx,
  fetchRestaurantsFx,
  fetchDeliveryZoneByGeolocationFx,
  fetchAddressesByRequestFx,
  getRestaurantDepsFx,
} from './effects';
import { createGate } from 'effector-react';
import { GetOrdersCheckCityByIdResponseV2 } from 'api/version2';

//stores & forms
export const $address = createStore<Address.ReceivingAddressV2 | null>(null);
export const $addressesList = createStore<AddressInList[]>([]);
export const $noDeliveryPlaceholder = createStore<string | null>(null);

export const $showPlaceholder = createStore(false);

export const $restaurant = createStore<Restaurant | null>(null);
export const $restaurantsList = createStore<Restaurant[]>([]);
export const $noRestaurantsInCityError = createStore<boolean>(false);
export const $hasRestaurantsError = createStore<boolean>(false);

export const $type = createStore<OrderType>('COURIER');

export const $deliveryZonesAndMapLayerZones = createStore<
  DeliveryZoneAndMapLayerZone[]
>([]);

export const $visibleDeliveryZone = createStore<DeliveryZone | null>(null);
export const $deliveryZone = createStore<DeliveryZone | null>(null);
export const $deliveryZoneStopLists = createStore<DeliveryZoneStopLists | null>(
  null,
);
export const $deliveryZoneError = createStore<string | null>(null);

export const $timeIntervals = createStore<TimeIntervals | null>(null);

export const $userGeolocation = createStore<Location | null>(null);

export const $country = createStore<Country | null>(null);
export const $countriesList = createStore<Country[]>([]);

export const $city = createStore<City | null>(null);
export const $citiesList = createStore<City[]>([]);
export const $allCitiesList = createStore<City[]>([]);

export const $currency = createStore<CurrencySymbol>('₽');

export const addressForm = createForm<AddressForm>({
  fields: {
    address: {
      init: null,
      rules: [FEValidationUtils.required()],
    },
    isPrivate: {
      init: false,
    },
    apartment: {
      init: null,
    },
    housing: {
      init: null,
    },
    entrance: {
      init: null,
    },
    floor: {
      init: null,
    },
    isFinal: {
      init: null,
      rules: [FEValidationUtils.true()],
    },
    finalAddress: {
      init: null,
      rules: [FEValidationUtils.required()],
    },
  },
  validateOn: ['submit'],
});

export const $isPendingCartography = pending({
  effects: [
    fetchCitiesFx,
    fetchRestaurantsFx,
    fetchDeliveryZoneByGeolocationFx,
    getRestaurantDepsFx,
  ],
});

export const $isPendingCities = pending({
  effects: [fetchCitiesFx],
});

export const $isPendingAddress = fetchAddressesByRequestFx.pending;
export const $isPendingRestaurants = fetchRestaurantsFx.pending;

export const $showAddressConfirmation = createStore(false);

export const cartographyGate = createGate();

export const $cityInformationBanner =
  createStore<GetOrdersCheckCityByIdResponseV2 | null>(null);
