import FeatSDKV1 from 'api/version1';
import FeatSDKV3 from 'api/version3';
import { City } from 'domains/cartography';
import { createEffect } from 'effector';

export const fetchCatalogFx = createEffect(
  async ({ catalogId, cityId }: { catalogId: string; cityId: string }) => {
    const catalogProducts = await FeatSDKV3.catalog.getCatalogProducts({
      catalogId,
      cityId,
      request: { limit: 999, offset: 0 },
    });
    const catalogCategories = await FeatSDKV1.catalog.getCatalogCategories({
      catalogId,
    });

    return {
      catalogProducts,
      catalogCategories,
      catalogId,
    };
  },
);

export const fetchCatalogByCityFx = createEffect(async (city: City) => {
  const cityId = city.id;
  const defaultRestaurantId = city.defaultRestaurantId;
  const { catalogId } = await FeatSDKV1.restaurants.getRestaurantById({
    id: defaultRestaurantId,
  });
  const catalogProducts = await FeatSDKV3.catalog.getCatalogProducts({
    catalogId,
    cityId,
    request: { limit: 999, offset: 0 },
  });
  const catalogCategories = await FeatSDKV1.catalog.getCatalogCategories({
    catalogId,
  });

  return {
    catalogId,
    catalogProducts,
    catalogCategories,
  };
});
