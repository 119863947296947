import clsx from 'clsx';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import { FEImageComponent } from 'ui-kit/components';
import style from './style.module.css';

const ModifierCard = ({
  name,
  imageId,
}: {
  name: string;
  imageId?: string;
}) => {
  return (
    <div className={style.root}>
      <FEImageComponent
        className={style.image}
        image={{ imageId: imageId || '' }}
        altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
      />
      <div className={style.data}>
        <p className={clsx('caption-1', style.name)}>{name}</p>
      </div>
    </div>
  );
};

export default ModifierCard;
