import { Language } from 'models';
import moment from 'moment';

export default class FEDateUtils {
  static addUtcOffset(moment: moment.Moment): moment.Moment {
    const utcOffset = new Date().getTimezoneOffset();
    return moment.utc(true).utcOffset(-utcOffset);
  }
  static substractUtcOffset(moment: moment.Moment): moment.Moment {
    const utcOffset = new Date().getTimezoneOffset();
    return moment.utc(true).utcOffset(utcOffset);
  }

  static isSame = (
    firstDate: Date | string,
    secondDate: Date | string,
    key?: 'year' | 'month' | 'day',
  ) => moment(firstDate).isSame(secondDate, key);

  static isToday = (date: Date | string): boolean =>
    moment(date).isSame(moment(), 'day');

  static toLocalISOString = (date: Date | string) => {
    const normalizedDate: Date = date instanceof Date ? date : new Date(date);

    let tzo = -normalizedDate.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num: number) {
        return (num < 10 ? '0' : '') + num;
      };

    return (
      normalizedDate.getFullYear() +
      '-' +
      pad(normalizedDate.getMonth() + 1) +
      '-' +
      pad(normalizedDate.getDate()) +
      'T' +
      pad(normalizedDate.getHours()) +
      ':' +
      pad(normalizedDate.getMinutes()) +
      ':' +
      pad(normalizedDate.getSeconds()) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' +
      pad(Math.abs(tzo) % 60)
    );
  };

  static toDWMString = (date: Date | string, language: Language) => {
    const normalizedDate: Date = date instanceof Date ? date : new Date(date);

    return normalizedDate.toLocaleString(language, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    });
  };
}
