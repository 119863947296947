import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetDeliveryZoneTimeIntervalsResponseV2 } from '.';

const deliveryZoneRepositoryV2 = {
  getTimeIntervals: (deliveryZoneId: string, signal?: AbortSignal) =>
    new Promise<GetDeliveryZoneTimeIntervalsResponseV2>(
      async (resolve, reject) => {
        try {
          const url = `${BASE_URL}/not-secure/api/v2/places/delivery-zone/${deliveryZoneId}/time-intervals`;

          let result: AxiosResponse<
            BaseAPIResponse<GetDeliveryZoneTimeIntervalsResponseV2>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetDeliveryZoneTimeIntervalsResponseV2>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default deliveryZoneRepositoryV2;
