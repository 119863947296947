import { initBannersFx } from 'domains/banners';
import { createOrderFx } from 'domains/cart';
import { $city, changeCity } from 'domains/cartography';
import { getUserProfileFx } from 'domains/profile';
import { sample } from 'effector';
import { getGiftRepurchasesFx } from './effects';
import { $giftRepurchases } from './stores';

sample({
  clock: initBannersFx.doneData,
  fn: ({ giftRepurchases }) => giftRepurchases,
  target: $giftRepurchases,
});

sample({
  clock: getGiftRepurchasesFx.doneData,
  fn: (result) => result,
  target: $giftRepurchases,
});
sample({
  clock: getGiftRepurchasesFx.failData,
  fn: () => [],
  target: $giftRepurchases,
});

sample({
  clock: changeCity,
  filter: (city) => !!city,
  fn: (city) => ({ cityId: city!.id }),
  target: getGiftRepurchasesFx,
});
sample({
  clock: getUserProfileFx.doneData,
  source: $city,
  filter: (city) => !!city,
  fn: (city) => ({ cityId: city!.id }),
  target: getGiftRepurchasesFx,
});
sample({
  clock: createOrderFx.doneData,
  source: $city,
  filter: (city) => !!city,
  fn: (city) => ({ cityId: city!.id }),
  target: getGiftRepurchasesFx,
});
sample({
  clock: changeCity,
  filter: (city) => !city,
  fn: () => [],
  target: $giftRepurchases,
});
