import React from 'react';
import { IMG_IC_24_CHECK } from 'images';
import style from '../styles/listItem.style.module.css';
import clsx from 'clsx';

export type ListItemProps = {
  active: boolean;
  onClick: () => void;
  value: string;
  image: string;
};

const ListItem = ({ active, onClick, value, image }: ListItemProps) => {
  return (
    <div
      onClick={(e) => {
        onClick();
        e.preventDefault();
        e.stopPropagation();
      }}
      className={style.item}
    >
      <div className={style.content}>
        <img className='icon-24' src={image} alt='' />
        <p className='text-1'>{value}</p>
        {active && (
          <img
            className={clsx('icon-24', style.activeImage)}
            alt=''
            src={IMG_IC_24_CHECK}
          />
        )}
      </div>
    </div>
  );
};

export default ListItem;
