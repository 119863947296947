import { DESKTOP_WIDTH } from 'const';
import {
  FEScrollableTitledBottomSheet,
  FEScrollableTitledDialog,
} from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import style from './style.module.css';
import { Content } from './components';
import { useUnit } from 'effector-react';
import { $isPendingPromotions, $promotions } from 'domains/promotion';
import { useTranslation } from 'react-i18next';

const PromotionsPage = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${DESKTOP_WIDTH}px)`);

  const [promotions, isPendingPromotions] = useUnit([
    $promotions,
    $isPendingPromotions,
  ]);

  return isDesktop ? (
    <FEScrollableTitledDialog
      title={t('promotions.title')}
      containerClassName={style.container}
      open={open}
      onClose={onClose}
      render={({ onClose }) => (
        <Content {...{ promotions, isPending: isPendingPromotions, onClose }} />
      )}
    />
  ) : (
    <FEScrollableTitledBottomSheet
      title={t('promotions.title')}
      containerClassName={style.container}
      open={open}
      onClose={onClose}
      render={({ onClose }) => (
        <Content {...{ promotions, isPending: isPendingPromotions, onClose }} />
      )}
    />
  );
};

export default PromotionsPage;
