import {
  IMG_MODAL_BIRTHDAY,
  IMG_MODAL_ERROR,
  IMG_MODAL_HELP,
  IMG_MODAL_RESTAURANT_CLOSED,
  IMG_MODAL_SETTINGS,
  IMG_MODAL_SORRY,
  IMG_MODAL_MAP,
  IMG_MODAL_SHOPPING_CART,
  IMG_MODAL_TIME,
  IMG_MODAL_CHECKMARK,
} from 'images';

export const GET_IMAGE = {
  birthday: IMG_MODAL_BIRTHDAY,
  checkmark: IMG_MODAL_CHECKMARK,
  error: IMG_MODAL_ERROR,
  help: IMG_MODAL_HELP,
  restaurantClosed: IMG_MODAL_RESTAURANT_CLOSED,
  settings: IMG_MODAL_SETTINGS,
  sorry: IMG_MODAL_SORRY,
  map: IMG_MODAL_MAP,
  shoppingCart: IMG_MODAL_SHOPPING_CART,
  time: IMG_MODAL_TIME,
};
