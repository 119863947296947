import { FETitledBottomSheet, FETitledDialog } from 'ui-kit/components';
import { useMediaQuery } from 'ui-kit/hooks';
import Layoyt from './layout';
import { FECountryPickerProps } from './props';
import { useTranslation } from 'react-i18next';

const FECountryPicker = ({
  open,
  closable,
  onClose,
  ...rest
}: FECountryPickerProps & { open: boolean; closable: boolean }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${560}px)`);

  return isDesktop ? (
    <FETitledDialog
      onClose={onClose}
      showCloseButton={closable}
      closeOnOverlayClick={closable}
      title={t('cartography.country.picker.title')}
      open={open}
      render={({ onClose, onCloseModal }) => (
        <Layoyt onClose={onClose} onCloseModal={onCloseModal} {...rest} />
      )}
    />
  ) : (
    <FETitledBottomSheet
      onClose={onClose}
      showCloseButton={closable}
      closeOnOverlayClick={closable}
      title={t('cartography.country.picker.title')}
      open={open}
      render={({ onClose, onCloseModal }) => (
        <Layoyt onClose={onClose} onCloseModal={onCloseModal} {...rest} />
      )}
    />
  );
};

export default FECountryPicker;
export { type FECountryPickerProps };
