import { GetAllOrdersResponseV3 } from 'api/version3';
import clsx from 'clsx';
import { CurrencySymbol, ProductType } from 'models';
import React from 'react';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import style from './style.module.css';
import { FEImageComponent } from 'ui-kit/components';

const Product = ({
  product: productFromProps,
  type,
  currency,
}: {
  product: GetAllOrdersResponseV3.Dish | GetAllOrdersResponseV3.Constructor;
  type: ProductType;
  currency: CurrencySymbol;
}) => {
  const { price, quantity, product, base } = productFromProps;

  const productDescription = React.useMemo(() => {
    if (type === 'CONSTRUCTOR') {
      const {
        base: { name, selectorGroups, addonGroups },
      } = productFromProps as GetAllOrdersResponseV3.Constructor;

      return `${name}${
        selectorGroups.length !== 0 &&
        selectorGroups.some(
          (selectorGroup) => selectorGroup.modifiers.length !== 0,
        )
          ? `, ${selectorGroups
              .map((selectorGroup) =>
                selectorGroup.modifiers
                  .map((modifier) => modifier.info.name)
                  .join(', '),
              )
              .join(', ')}`
          : ''
      } ${
        addonGroups.length !== 0 &&
        addonGroups.some((addonGroup) => addonGroup.modifiers.length !== 0)
          ? `, ${addonGroups
              .map((addonGroup) =>
                addonGroup.modifiers
                  .map(
                    (modifier) =>
                      modifier.info.name + ` × ${modifier.quantity}`,
                  )
                  .join(', '),
              )
              .join(', ')}`
          : ''
      }`;
    } else {
      const {
        base: { name, switchGroups, selectorGroups, addonGroups },
      } = productFromProps as GetAllOrdersResponseV3.Dish;

      return `${name}${
        switchGroups.length !== 0
          ? `, ${switchGroups
              .map((switchGroup) =>
                switchGroup.modifiers
                  .map((modifier) => modifier.info.name)
                  .join(', '),
              )
              .join(', ')}`
          : ''
      } ${
        selectorGroups.length !== 0 &&
        selectorGroups.some(
          (selectorGroup) => selectorGroup.modifiers.length !== 0,
        )
          ? `, ${selectorGroups
              .map((selectorGroup) =>
                selectorGroup.modifiers
                  .map((modifier) => modifier.info.name)
                  .join(', '),
              )
              .join(', ')}`
          : ''
      } ${
        addonGroups.length !== 0 &&
        addonGroups.some((addonGroup) => addonGroup.modifiers.length !== 0)
          ? `, ${addonGroups
              .map((addonGroup) =>
                addonGroup.modifiers
                  .map(
                    (modifier) =>
                      modifier.info.name + ` × ${modifier.quantity}`,
                  )
                  .join(', '),
              )
              .join(', ')}`
          : ''
      }`;
    }
  }, [productFromProps, type]);

  return (
    <div>
      <div className={style.container}>
        <FEImageComponent
          className={style.image}
          image={{ imageId: base.imageId }}
          altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
        />
        <div className={style.data}>
          <p className='subhead'>{`${product.name} × ${quantity}`}</p>
          <p className={clsx('caption-1')}>{productDescription}</p>
          <p className='subhead'>{`${Math.trunc(price.total)} ${currency}`}</p>
        </div>
      </div>
    </div>
  );
};

export default Product;
