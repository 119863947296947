import React from 'react';
import style from './style.module.css';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';
import FeatSDKV1 from 'api/version1';
import { onImageError } from 'utils';
import FESpinner from '../FESpinner';

const FEObserveredImage = ({
  className,
  loaderContainerClassName,
  imageId,
  altImage,
  onError,
  spinnerSize = 'small',
}: {
  className?: string;
  loaderContainerClassName?: string;
  imageId: string;
  altImage: string;
  onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  spinnerSize?: 'small' | 'large' | 'medium';
}) => {
  const [url, setUrl] = React.useState<string>();
  const [isPending, setisPending] = React.useState<boolean>(true);
  const [isInView, setIsInView] = React.useState<boolean>(false);
  const { ref, inView } = useInView();
  const src = (imageId: string) => FeatSDKV1.images.getImage(imageId);

  React.useEffect(() => {
    if (inView === true) setIsInView(true);
  }, [inView]);

  React.useEffect(() => {
    if (isInView) {
      setisPending(true);
      new Promise((resolve, reject) =>
        fetch(src(imageId)).then((response) => {
          if (response.ok)
            return response
              .blob()
              .then((image) => resolve(URL.createObjectURL(image)));
          return reject();
        }),
      )
        .then((urls) => {
          setUrl(urls as string);
          setisPending(false);
        })
        .catch(() => {
          setisPending(false);
          setUrl('');
        });
    }
  }, [imageId, isInView]);

  return (
    <div ref={ref} className={clsx(style.root, className)}>
      <div
        className={clsx(
          style.loaderContainer,
          isPending === false && style.loaderContainer__loaded,
          loaderContainerClassName,
        )}
      >
        <FESpinner size={spinnerSize} />
      </div>

      <img
        className={clsx(style.image, className)}
        src={url}
        onContextMenu={(e) => e.preventDefault()}
        onError={(e) => {
          onImageError(e, altImage);
          onError && onError(e);
        }}
        alt=''
      />
    </div>
  );
};

export default FEObserveredImage;
