import { initCatalogFx } from 'domains/application';
import { changeRestaurant, getDeliveryZoneFx } from 'domains/cartography';
import { sample } from 'effector';
import { getLegalsDocumentsFx } from './effects';
import { $legalsDocuments } from './stores';

sample({
  clock: getLegalsDocumentsFx.doneData,
  fn: (response) => response,
  target: $legalsDocuments,
});

sample({
  clock: initCatalogFx.doneData,
  filter: ({ deliveryZone, currentRestaurant }) =>
    !!deliveryZone || !!currentRestaurant,
  fn: ({ deliveryZone, currentRestaurant }) => {
    let placeId: string;
    if (deliveryZone) placeId = deliveryZone.id;
    else placeId = currentRestaurant!.id;
    return { placeId };
  },
  target: getLegalsDocumentsFx,
});

sample({
  clock: changeRestaurant,
  filter: (restaurant) => !!restaurant,
  fn: (restaurant) => ({ placeId: restaurant!.id }),
  target: getLegalsDocumentsFx,
});
sample({
  clock: getDeliveryZoneFx.doneData,
  fn: ({ deliveryZone }) => ({ placeId: deliveryZone.id }),
  target: getLegalsDocumentsFx,
});
