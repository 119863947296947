import React from 'react';
import { useClickOutside } from 'ui-kit/hooks';
import { ContentProps } from './props';
import clsx from 'clsx';

import {
  FEButton,
  FEImageComponent,
  FEQuantity,
  FESegmentedControl,
} from 'components';
import _ from 'lodash';
import style from './style.module.css';
import { IMG_PLACEHOLDER_CARD_PICTURE_MOBILE } from 'images';
import { AddonGroupPicker, SelectorGroupPicker } from './components';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';
import { GET_MEASURE } from 'const';

const Content = ({
  isImageSuccess,
  setIsImageSuccess,
  base,
  bases,
  description,
  baseSize,
  setSwitchGroups,
  baseTitle,
  switchGroups,
  baseSwitchGroups,
  addonGroups,
  selectorGroups,
  setSelectorGroups,
  setBase,
  baseAddonGroups,
  setAddonGroups,
  name,
  hasNutritionFacts,
  nutritionFacts,
  price,
  baseSelectorGroups,
  quantity,
  setQuantity,
  onClose,
  handleAddDishToCart,
  currency,
}: ContentProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const [showNutritionFacts, setShowNutritionFacts] =
    React.useState<boolean>(false);
  const nutritionFactsRef = React.useRef<HTMLButtonElement>(null);
  useClickOutside(nutritionFactsRef, () => setShowNutritionFacts(false));

  const submitDisabled = selectorGroups.some(
    (selectorGroup) => selectorGroup.min > selectorGroup.modifiers.length,
  );
  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.imageContainer}>
          <FEImageComponent
            className={clsx(style.image, isImageSuccess && style.successImage)}
            image={{ imageId: base?.imageId || '' }}
            altImage={IMG_PLACEHOLDER_CARD_PICTURE_MOBILE}
            onError={() => setIsImageSuccess(false)}
          />
        </div>
        <div className={style.productDataContainer}>
          <div className={style.productData}>
            <div className={style.productDataContent}>
              <div className={style.header}>
                <div className={style.headerContent}>
                  <p className={clsx('title', style.name)}>{name}</p>
                  {hasNutritionFacts && (
                    <button
                      ref={nutritionFactsRef}
                      className={clsx(
                        'button-icon-24',
                        style.nutritionFactsButton,
                      )}
                      onClick={() => setShowNutritionFacts(!showNutritionFacts)}
                    />
                  )}
                  {showNutritionFacts && (
                    <div
                      className={style.nutrritionFactsContainer}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className={style.nutrritionFactsContent}>
                        <p
                          className={clsx(
                            'headline',
                            style.nutritionFactsTitle,
                          )}
                        >
                          {t('catalog.nutritionFacts.title')}
                        </p>
                        <div className={style.nutritionFact}>
                          <p className='text-2'>
                            {t('catalog.nutritionFacts.protein')}
                          </p>
                          <p className='text-2'>{`${nutritionFacts.protein} ${t(
                            GET_MEASURE.GRAM,
                          )}`}</p>
                        </div>
                        <div className={style.nutritionFact}>
                          <p className='text-2'>
                            {t('catalog.nutritionFacts.fat')}
                          </p>
                          <p className='text-2'>{`${nutritionFacts.fat} ${t(
                            GET_MEASURE.GRAM,
                          )}`}</p>
                        </div>
                        <div className={style.nutritionFact}>
                          <p className='text-2'>
                            {t('catalog.nutritionFacts.carbohydrate')}
                          </p>
                          <p className='text-2'>{`${
                            nutritionFacts.carbohydrate
                          } ${t(GET_MEASURE.GRAM)}`}</p>
                        </div>
                        <div className={style.nutritionFact}>
                          <p className='text-2'>
                            {t('catalog.nutritionFacts.calories')}
                          </p>
                          <p className='text-2'>{`${
                            nutritionFacts.calories
                          } ${t(GET_MEASURE.KILOCALORIES)}`}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {description && (
                <div className={style.description}>
                  <p className={clsx('text-2')}>{description}</p>
                </div>
              )}
              {baseSize && (
                <div className={style.size}>
                  <p className={'text-2'}>{baseSize}</p>
                </div>
              )}
              {bases.length > 1 && base && (
                <div className={style.modifier}>
                  <p className='headline'>{baseTitle}</p>
                  <FESegmentedControl
                    value={base.id}
                    items={_.fromPairs(_.map(bases, (n) => [n.id, n.name]))}
                    onChange={(id) =>
                      setBase(bases.find((base) => base.id === id)!)
                    }
                  />
                </div>
              )}
              {switchGroups.length !== 0 &&
                baseSwitchGroups.map((baseSwitchGroup, index) => (
                  <div className={style.modifier} key={baseSwitchGroup.id}>
                    <p className='headline'>{baseSwitchGroup.info.name}</p>
                    <FESegmentedControl
                      value={switchGroups[index]?.modifiers[0]?.id || ''}
                      modifiers={baseSwitchGroup.modifiers}
                      onChange={(id) =>
                        setSwitchGroups(
                          switchGroups.map((switchGroup, switchGroupIndex) => {
                            if (switchGroupIndex === index) {
                              switchGroup.modifiers = [
                                ...baseSwitchGroups[index].modifiers.filter(
                                  (modifier) => modifier.id === id,
                                ),
                              ];
                            }
                            return switchGroup;
                          }),
                        )
                      }
                      items={_.fromPairs(
                        _.map(baseSwitchGroup.modifiers, (n) => [
                          n.id,
                          n.info.name,
                        ]),
                      )}
                    />
                  </div>
                ))}
              {baseSelectorGroups.map((baseSelectorGroup, index) => (
                <React.Fragment key={baseSelectorGroup.id}>
                  {baseSelectorGroup.modifiers.length !== 0 && (
                    <div className={style.modifier}>
                      <p
                        style={{ paddingRight: '80px' }}
                        className={clsx('headline')}
                      >
                        {baseSelectorGroup.info.name}
                      </p>
                      <div>
                        <SelectorGroupPicker
                          currency={currency}
                          hasError={
                            selectorGroups[index]
                              ? baseSelectorGroup.min >
                                selectorGroups[index]?.modifiers.length
                              : false
                          }
                          currentModifiers={
                            selectorGroups[index]?.modifiers || []
                          }
                          baseModifiers={baseSelectorGroup.modifiers}
                          onPickModifiers={(modifiers) => {
                            setSelectorGroups(
                              selectorGroups.map((selectorGroup, i) => {
                                if (i === index) {
                                  return {
                                    ...selectorGroup,
                                    modifiers,
                                  };
                                }
                                return selectorGroup;
                              }),
                            );
                          }}
                          name={baseSelectorGroup.info.name}
                          min={baseSelectorGroup.min}
                          max={baseSelectorGroup.max}
                          iconId={baseSelectorGroup.info.iconId}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
              {baseAddonGroups.map((baseAddonGroup, index) => (
                <React.Fragment key={baseAddonGroup.id}>
                  {baseAddonGroup.modifiers.length !== 0 && (
                    <div className={style.modifier}>
                      <p
                        style={{ paddingRight: '80px' }}
                        className={clsx('headline')}
                      >
                        {baseAddonGroup.info.name}
                      </p>
                      <div>
                        <AddonGroupPicker
                          currency={currency}
                          currentModifiers={addonGroups[index]?.modifiers || []}
                          baseModifiers={baseAddonGroup.modifiers}
                          onPickModifiers={(modifiers) => {
                            const newAddonGroups = [
                              // ...addonGroups.filter(
                              //   (addonGroup) =>
                              //     addonGroup.id !== baseAddonGroup.id,
                              // ),
                              // {
                              //   ...baseAddonGroup,
                              //   modifiers,
                              // },
                              ...addonGroups,
                            ];
                            newAddonGroups[index].modifiers = modifiers;
                            setAddonGroups(newAddonGroups);
                          }}
                          name={baseAddonGroup.info.name}
                          max={baseAddonGroup.max}
                          iconId={baseAddonGroup.info.iconId}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className={style.submitContainer}>
            <div className={style.submitContent}>
              <FEQuantity
                value={quantity}
                onChange={(val) => setQuantity(val)}
                fill='none'
                min={1}
              />
              <FEButton
                disabled={submitDisabled}
                onClick={() => {
                  handleAddDishToCart();
                  onClose();
                }}
              >
                <div className={style.submitButtonContent}>
                  <p className='subhead'>
                    {t('catalog.dish.modal.addToCartText')}
                  </p>
                  <p className='subhead'>{`${price} ${currency}`}</p>
                </div>
              </FEButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
