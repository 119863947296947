import React from 'react';
import {
  IMG_IC_16_CHECK_ONCOLOR_SECONDARY,
  IMG_IC_16_GIFT_ONCOLOR_SECONDARY,
} from 'images';
import style from './style.module.css';
import clsx from 'clsx';

const ProgressBar = ({
  current,
  max,
  type,
}: {
  max: number;
  current: number;
  type: 'primary' | 'secondary';
}) => {
  const progressWidth = (100 * current) / max;
  const dotsArr = (() => {
    const arr: number[] = [];
    for (let i = 1; i < max; i++) {
      arr.push(i);
    }
    return arr;
  })();

  return (
    <span
      className={clsx(
        style.background,
        type === 'primary' && style.primary,
        type === 'secondary' && style.secondary,
      )}
    >
      <span
        style={{ transform: `translateX(-${100 - progressWidth}%)` }}
        className={style.propgress}
      />
      {dotsArr.length && (
        <>
          {dotsArr.map((v) => (
            <span
              style={{
                left: `calc(${(100 * v) / max}% - 10px)`,
              }}
              className={style.dot}
              key={v}
            />
          ))}
        </>
      )}
      {
        <span
          style={{
            left: `calc(${progressWidth}% - 20px)`,
          }}
          className={style.indicator}
        >
          <img
            className={style.indicatorIcon}
            src={
              current < max
                ? IMG_IC_16_CHECK_ONCOLOR_SECONDARY
                : IMG_IC_16_GIFT_ONCOLOR_SECONDARY
            }
            alt=''
          />
        </span>
      }
    </span>
  );
};

export default ProgressBar;
