import { useMount } from 'hooks';
import React from 'react';
import style from './style.module.css';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DELAY } from 'const';
import clsx from 'clsx';
import Portal from '../Portal';

type CopyButtonProps = {
  toCopy: string;
};

const TIMOUT_DELAY = 2500;

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

const CopyButton = ({ toCopy }: CopyButtonProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { mounted } = useMount({ isOpened: open });
  const [animationIn, setAnimationIn] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setOpen(false);
    setAnimationIn(false);
  };

  React.useEffect(() => {
    if (mounted) setAnimationIn(true);
  }, [mounted]);

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (mounted) {
      timeout = setTimeout(() => handleClose(), TIMOUT_DELAY);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [mounted]);

  const handleCopy = (toCopy: string) => {
    navigator.clipboard.writeText(toCopy).then(() => {
      setOpen(true);
    });
  };

  return (
    <>
      <button
        className={clsx('button-icon-24', style.button)}
        onClick={() => handleCopy(toCopy)}
      />
      {mounted && (
        <Portal>
          <div className={style.dialog} role='dialog'>
            <CSSTransition
              in={animationIn}
              timeout={ANIMATION_DELAY}
              mountOnEnter
              unmountOnExit
              classNames={containerAnimation}
              nodeRef={containerRef}
            >
              <div className={style.container} ref={containerRef}>
                <div className={style.content}>
                  <p className={clsx('text-2', style.message)}>
                    Скопировано :)
                  </p>
                  <button
                    className={clsx('button-icon-24', style.closeButton)}
                    onClick={handleClose}
                  />
                </div>
              </div>
            </CSSTransition>
          </div>
        </Portal>
      )}
    </>
  );
};

export default CopyButton;
