import FeatSDKV1 from 'api/version1';
import { $images, addImage } from 'domains/images';
import { useUnit } from 'effector-react';
import React from 'react';

export const useImages = ({
  imageId,
  needToFetch,
  fetchFromThirdPartyOrigin,
}: {
  imageId: string;
  needToFetch: boolean;
  fetchFromThirdPartyOrigin: boolean;
}) => {
  const [url, setUrl] = React.useState<string>();
  const [isPending, setisPending] = React.useState<boolean>(true);

  const src = (imageId: string) => FeatSDKV1.images.getImage(imageId);

  const [images, onAddImage] = useUnit([$images, addImage]);

  React.useEffect(() => {
    if (needToFetch === false) {
      setUrl(imageId);
      setisPending(false);
    } else if (fetchFromThirdPartyOrigin) {
      if (images.has(imageId)) {
        setUrl(images.get(imageId));
        setisPending(false);
      } else {
        new Promise((resolve, reject) =>
          fetch(imageId).then((response) => {
            if (response.ok)
              return response
                .blob()
                .then((image) => resolve(URL.createObjectURL(image)));
            return reject();
          }),
        )
          .then((urls) => {
            setUrl(urls as string);
            setisPending(false);
            onAddImage({ imageId, value: urls as string });
          })
          .catch(() => {
            setisPending(false);
            setUrl('');
          });
      }
    } else {
      if (images.has(imageId)) {
        setUrl(images.get(imageId));
        setisPending(false);
      } else {
        new Promise((resolve, reject) =>
          fetch(src(imageId)).then((response) => {
            if (response.ok)
              return response
                .blob()
                .then((image) => resolve(URL.createObjectURL(image)));
            return reject();
          }),
        )
          .then((urls) => {
            setUrl(urls as string);
            setisPending(false);
            onAddImage({ imageId, value: urls as string });
          })
          .catch(() => {
            setisPending(false);
            setUrl('');
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageId, images, needToFetch, fetchFromThirdPartyOrigin]);

  return { url, isPending };
};
