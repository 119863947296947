import { sample } from 'effector';
import { getFeaturesByPlaceIdFx } from './effects';
import { $features } from './stores';

sample({
  clock: getFeaturesByPlaceIdFx.doneData,
  fn: (payload) => payload.items,
  target: $features,
});

sample({
  clock: getFeaturesByPlaceIdFx.failData,
  fn: () => [],
  target: $features,
});
