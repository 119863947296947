import { createEvent } from 'effector';
import { Address, OrderType } from 'models';
import { debounce } from 'patronum';
import {
  AddressInList,
  Restaurant,
  City,
  TimeIntervals,
  Country,
} from './entities';
import { addressForm } from './stores';

export const changeAddress = createEvent<Address.ReceivingAddressV2 | null>();
export const setAddressesList = createEvent<AddressInList[]>();
export const changeAddressRequest = debounce({
  source: addressForm.fields.address.changed,
  timeout: 500,
});

export const changeRestaurant = createEvent<Restaurant | null>();

export const changeType = createEvent<Exclude<OrderType, 'IN_PLACE'>>();

export const setTimeIntervals = createEvent<TimeIntervals | null>();

export const changeUserGeolocation = createEvent<Location | null>();

export const changeCountry = createEvent<Country | null>();
export const setCountriesList = createEvent<Country[]>();

export const changeCity = createEvent<City | null>();
export const setCitiesList = createEvent<City[]>();

export const closeAddressConfirmation = createEvent();

export const submitRestaurant = createEvent<{
  currentRestaurant: Restaurant;
}>();
export const submitAddress = createEvent<{
  currentAddress: Address.ReceivingAddressV2;
}>();

export const closeCityInformationBanner = createEvent();
