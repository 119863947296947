import React from 'react';
import { Banner as BannerModel } from 'domains/banners';
import style from './style.module.css';
import { FEImageComponent, FEStoriesSlider } from 'ui-kit/components';
import { useNavigate } from 'react-router-dom';
// import cyrillicToTranslit from 'cyrillic-to-translit-js';
import { usePrepareLink } from 'hooks/router';
import { IMG_PLACEHOLDER_PERCENT } from 'images';

const Banner = ({
  banner,
  products,
}: {
  banner: BannerModel;
  products: {
    [k: string]: {
      productId: string;
      productName: string;
      categoryId: string;
      categoryName: string;
    };
  };
}) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState<boolean>(false);

  const { imageId, link, story } = banner;

  const currentProductFromLink = link ? products[link.value] : null;

  const productPathname = currentProductFromLink?.productName || '';

  const productLink = usePrepareLink({
    to: `/${productPathname}`,
  });

  const handleClick = (
    type: 'HTTP' | 'STORIES' | 'DISH' | 'PROFILE',
    value: string,
  ) => {
    switch (type) {
      case 'DISH':
        return !!currentProductFromLink && navigate(productLink);
      case 'HTTP':
        return Boolean(value) && window.open(value, '_blank');
      case 'STORIES':
        return story !== null && setOpen(true);
      default:
        break;
    }
  };

  return (
    <>
      <FEImageComponent
        processingComponent={
          <img
            alt=''
            className={style.processingImage}
            src={IMG_PLACEHOLDER_PERCENT}
          />
        }
        loaderContainerClassName={style.loader}
        onClick={() => link && handleClick(link.type, link.value)}
        className={style.image}
        image={{ imageId }}
        spinnerSize='medium'
      />
      {link?.type === 'STORIES' && story != null && (
        <FEStoriesSlider
          products={products}
          open={open}
          onClose={() => setOpen(false)}
          currentStory={story}
        />
      )}
    </>
  );
};

export default Banner;
