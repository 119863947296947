import { MOBILE_WIDTH } from 'const';
import React from 'react';
import { useMediaQuery } from 'ui-kit/hooks';
import FEPromocodeProps from './props';
import FETextField from '../FETextField';
import FEButton from '../FEButton';
import clsx from 'clsx';
import { FETitledBottomSheet } from '..';
import mobile from './styles/mobile.style.module.css';
import desktop from './styles/desktop.style.module.css';
import modal from './styles/modal.style.module.css';
import { OrdersValidateResponseV4 } from 'api/version4';
import { TimeIntervals } from 'domains/cartography';
import { useTranslation } from 'react-i18next';

export default React.forwardRef<HTMLInputElement, FEPromocodeProps>(
  (
    {
      // promocode: currentPromocode,
      // promotionProductName,
      // success,
      promoCode,
      onSubmit,
      onClear,
      mobileWidth = MOBILE_WIDTH,
      // promotionName,
      isPending,
      promotion,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [targetPromocode, setTargetPromocode] = React.useState<string | null>(
      null,
    );
    const [open, setOpen] = React.useState<boolean>(false);

    const isDesktop = useMediaQuery(`(min-width: ${mobileWidth}px)`);

    const isSameCurrentTarget =
      promoCode === targetPromocode && Boolean(promoCode);
    // const isSameCurrentTarget =
    //   currentPromocode === targetPromocode && Boolean(currentPromocode);

    const promotionProductName =
      promotion?.promotionProduct?.product.product.name;

    const isSuccess = React.useMemo(() => {
      if (promotion) {
        if (Boolean(promoCode)) return promotion.success;
        return true;
      }
      return true;
    }, [promotion, promoCode]);
    const isFailed = React.useMemo(() => {
      if (promotion) {
        if (Boolean(promoCode)) return !promotion.success;
        return false;
      }
      return false;
    }, [promotion, promoCode]);
    // const isSuccess = success === true;
    // const isFailed = success === false;

    React.useLayoutEffect(() => {
      setTargetPromocode(promoCode);
    }, [promoCode]);
    // React.useLayoutEffect(
    //   () => setTargetPromocode(currentPromocode ?? ''),
    //   [currentPromocode],
    // );

    return isDesktop ? (
      <>
        <div className={desktop.root}>
          <div className={desktop.container}>
            <div className={desktop.inputContainer}>
              <div className={desktop.input}>
                <FETextField
                  ref={ref}
                  label={t('cart.main.promocode.title')}
                  disabled={isPending}
                  hasError={
                    isPending === false && isFailed
                    // isPending === false && success !== null && currentPromocode
                    //   ? !isSuccess
                    //   : undefined
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    if (Boolean(value)) {
                      setTargetPromocode(value);
                    } else {
                      setTargetPromocode(value);
                      onClear();
                    }
                  }}
                  value={targetPromocode ?? ''}
                />
              </div>
              <div className={desktop.submit}>
                {isSameCurrentTarget ? (
                  <FEButton
                    onClick={onClear}
                    type='secondary'
                    size='large'
                    showLoader={isPending}
                    disabled={isPending}
                  >
                    {t('common.reset')}
                  </FEButton>
                ) : (
                  <FEButton
                    showLoader={isPending}
                    disabled={isPending}
                    size='large'
                    onClick={() =>
                      Boolean(targetPromocode) && onSubmit(targetPromocode)
                    }
                  >
                    {t('common.apply')}
                  </FEButton>
                )}
              </div>
            </div>
            {isSuccess &&
              !isPending &&
              Boolean(promoCode || promotion?.name) && (
                <div className={desktop.messageContainer}>
                  <p className={clsx('subhead', desktop.message)}>
                    {t('cart.main.promocode.promocodeAppliedMessage')} <br />
                    {promotion?.name} <br />
                    {Boolean(promotionProductName) &&
                      t('cart.main.promocode.addedToCartMessage', {
                        productName: promotionProductName,
                      })}
                  </p>
                </div>
              )}
            {isFailed && isPending === false && (
              <div className={desktop.messageContainer}>
                <p className={clsx('subhead', desktop.messageFailed)}>
                  {t('cart.main.promocode.incorrectPromocodeMessage')}
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    ) : (
      <>
        <div className={mobile.root}>
          <div className={mobile.container}>
            <div
              className={mobile.content}
              onClick={(e) => {
                setOpen(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <p className='text-1'>
                {Boolean(promoCode)
                  ? promoCode
                  : t('cart.main.promocode.placeholder')}
              </p>
            </div>
            {Boolean(promoCode) ? (
              <button
                disabled={isPending}
                onClick={(e) => {
                  onClear();
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className={clsx(
                  'button-icon-24',
                  mobile.button,
                  mobile.clearButton,
                )}
              />
            ) : (
              <button
                onClick={() => setOpen(true)}
                className={clsx(
                  'button-icon-24',
                  mobile.button,
                  mobile.exposeButton,
                )}
              />
            )}
          </div>
          {isSuccess && (Boolean(promoCode) || Boolean(promotion?.name)) && (
            <div className={mobile.messageContainer}>
              <p className={clsx('subhead', mobile.message)}>
                {t('cart.main.promocode.promocodeAppliedMessage')} <br />
                {promotion?.name} <br />
                {Boolean(promotionProductName) &&
                  t('cart.main.promocode.addedToCartMessage', {
                    productName: promotionProductName,
                  })}
              </p>
            </div>
          )}
          {isFailed && (
            <div className={mobile.messageContainer}>
              <p className={clsx('subhead', mobile.messageFailed)}>
                {t('cart.main.promocode.incorrectPromocodeMessage')}
              </p>
            </div>
          )}
        </div>
        <FETitledBottomSheet
          open={open}
          onClose={() => {
            if (!isSameCurrentTarget) {
              setTargetPromocode(promoCode);
            }
            setOpen(false);
          }}
          title={t('cart.main.promocode.title')}
          render={({ onClose }) => (
            <div className={modal.root}>
              <div className={modal.content}>
                <div className={modal.data}>
                  <FETextField
                    disabled={isPending}
                    ref={ref}
                    label={t('cart.main.promocode.title')}
                    hasError={
                      isPending === false &&
                      promotion?.success !== undefined &&
                      Boolean(promoCode)
                        ? !isSuccess
                        : undefined
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (Boolean(value)) {
                        setTargetPromocode(value);
                      } else {
                        setTargetPromocode(value);
                        onClear();
                      }
                    }}
                    value={targetPromocode ?? ''}
                  />
                  {isSuccess &&
                    (Boolean(promoCode) || Boolean(promotion?.name)) && (
                      <div className={modal.messageContainer}>
                        <p className={clsx('subhead', modal.message)}>
                          {t('cart.main.promocode.promocodeAppliedMessage')}{' '}
                          <br />
                          {promotion?.name} <br />
                          {Boolean(promotionProductName) &&
                            t('cart.main.promocode.addedToCartMessage', {
                              productName: promotionProductName,
                            })}
                        </p>
                      </div>
                    )}
                  {isFailed && isPending === false && Boolean(promoCode) && (
                    <div className={modal.messageContainer}>
                      <p className={clsx('subhead', modal.messageFailed)}>
                        {t('cart.main.promocode.incorrectPromocodeMessage')}
                      </p>
                    </div>
                  )}
                </div>
                <div className={modal.submit}>
                  <FEButton
                    showLoader={isPending}
                    disabled={isPending}
                    size='large'
                    onClick={() =>
                      Boolean(targetPromocode) &&
                      onSubmit(targetPromocode).then(
                        (payload) => {
                          if (
                            (
                              payload as {
                                response: OrdersValidateResponseV4.Response;
                                promoCode: null | string;
                                timeIntervals: TimeIntervals;
                                timeTo: string | null;
                                urgent: boolean | null;
                              }
                            ).response.promotion.success === true
                          )
                            onClose();
                        },
                        () => {},
                      )
                    }
                  >
                    {t('common.apply')}
                  </FEButton>
                </div>
              </div>
            </div>
          )}
        />
      </>
    );
  },
);

export type { FEPromocodeProps };
