import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetPromotionsRequestV1, GetPromotionByIdRequestV1 } from '.';
import { GetPromotionsResponseV1, GetPromotionByIdResponseV1 } from '.';

const promotionsRepositoryV1 = {
  getPromotions: ({ cityId }: GetPromotionsRequestV1, signal?: AbortSignal) =>
    new Promise<GetPromotionsResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/promotions?cityId=${cityId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetPromotionsResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetPromotionsResponseV1.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getPromotionById: ({ id }: GetPromotionByIdRequestV1, signal?: AbortSignal) =>
    new Promise<GetPromotionByIdResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/promotions/${id}`;
        let result: AxiosResponse<BaseAPIResponse<GetPromotionByIdResponseV1>> =
          await BaseAPIRepository.get<
            BaseAPIResponse<GetPromotionByIdResponseV1>
          >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default promotionsRepositoryV1;
