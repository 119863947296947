import {
  FEBanner,
  FEButton,
  FEMultilineField,
  FESearchPlaceholder,
} from 'components';
import { RestaurantsLayoutProps } from '../props';
import style from './style.module.css';
import { RestaurantsListItem } from '../../components';
import { getDistanceTo } from 'utils';
import { Restaurant } from './components';
import { useTranslation } from 'react-i18next';

type MobileRestaurantsLayoutProps = {
  state: 'INFO' | 'MAP';
  onChangeState: (state: 'INFO' | 'MAP') => void;
};

const MobileRestaurantsLayout = ({
  restaurants,
  searchValue,
  onChangeSearchValue,
  onClearSearchValue,
  currentRestaurant,
  onPickRestaurant,
  onCloseRestaurant,
  hasError,
  noRestaurantsError,
  state,
  onChangeState,
  fetchRestaurants,
  userGeolocation,
}: RestaurantsLayoutProps & MobileRestaurantsLayoutProps) => {
  const { t } = useTranslation();
  return currentRestaurant ? (
    <Restaurant
      {...{
        restaurant: currentRestaurant,
        state,
        onChangeState,
        onCloseRestaurant,
        distanceTo: userGeolocation
          ? getDistanceTo(userGeolocation, currentRestaurant.address.location)
          : null,
      }}
    />
  ) : (
    <div className={style.root}>
      {!noRestaurantsError && (
        <div className={style.searchField}>
          <FEMultilineField
            placeholder={t('cartography.restaurant.form.fieldPlaceholder')}
            hasSelectionEffect={false}
            value={searchValue}
            onChange={onChangeSearchValue}
            onClear={onClearSearchValue}
          />
        </div>
      )}
      {hasError ? (
        <>
          <div className={style.errorConntainer}>
            <FEBanner
              text={t('cartography.restaurant.form.loadErrorPlaceholder')}
            />
            <div className={style.reload}>
              <FEButton onClick={() => fetchRestaurants()}>
                {t('common.refresh')}
              </FEButton>
            </div>
          </div>
        </>
      ) : noRestaurantsError ? (
        <div className={style.placeholderContainer}>
          <FESearchPlaceholder
            title={t('searchPlaceholderComponent.title')}
            message={t('cartography.restaurant.form.noRestaurantsPlaceholder')}
          />
        </div>
      ) : (
        <div className={style.restaurantsList}>
          {restaurants.length === 0 && searchValue ? (
            <div className={style.placeholderContainer}>
              <FESearchPlaceholder
                title={t('searchPlaceholderComponent.title')}
                message={t('cartography.restaurant.form.searchPlaceholder')}
              />
            </div>
          ) : (
            restaurants
              .sort((restA, restB) => {
                if (userGeolocation) {
                  return (
                    (getDistanceTo(
                      userGeolocation,
                      restA.address.location,
                      false,
                    ) as number) -
                    (getDistanceTo(
                      userGeolocation,
                      restB.address.location,
                      false,
                    ) as number)
                  );
                } else return 0;
              })
              .map((restaurant) => (
                <RestaurantsListItem
                  key={restaurant.id}
                  restaurant={restaurant}
                  userGeolocation={userGeolocation}
                  onClick={() => onPickRestaurant(restaurant)}
                />
              ))
          )}
        </div>
      )}
    </div>
  );
};

export default MobileRestaurantsLayout;
