import React, { useMemo } from 'react';
import { DishBase, DishSelectorGroup, DishSwitchGroup } from './models';
import { DishCardProps } from './props';
import { IMG_PLACEHOLDER_CARD_PICTURE_MOBILE } from 'images';
import style from './style.module.css';
import clsx from 'clsx';
import { GET_MEASURE } from 'const';
import {
  FEButton,
  FEObserveredImage,
  FEObserveredVideo,
  FEQuantity,
} from 'components';
import { DishCardModal } from './components';
import { useMediaQuery } from 'ui-kit/hooks';
import { DishToCartModel } from 'domains/cart/entities';
import { useUnit } from 'effector-react';
import { addDishToCartFx } from 'domains/cart';
import { useMount } from 'hooks';
import { usePrepareLink } from 'hooks/router';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';
import { $catalogCategories } from 'domains/catalog';

const DishCard = ({
  dish,
  available,
  quantityInCart,
  changeQuantityInCart,
  stopLists,
  validationPayload,
  className,
  videoClassName,
  currency,
}: DishCardProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [base, setBase] = React.useState<DishBase>();
  const [switchGroups, setSwitchGroups] = React.useState<DishSwitchGroup[]>([]);
  const [selectorGroups, setSelectorGroups] = React.useState<
    DishSelectorGroup[]
  >([]);
  const [quantity] = React.useState<number>(1);

  const categories = useUnit($catalogCategories);

  const {
    imageId,
    videoId,
    displayType,
    name,
    bases,
    defaultBaseId,
    linkValue,
  } = dish;

  const match = useMatch('/:name');

  const navigate = useNavigate();
  const location = useLocation();

  const filteredBases = React.useMemo(
    () =>
      stopLists
        ? bases.filter((base) => !stopLists.bases.includes(base.id))
        : bases,
    [bases, stopLists],
  );
  React.useEffect(() => {
    setBase(
      filteredBases.find((base) => base.id === defaultBaseId) ??
        filteredBases[0],
    );
  }, [defaultBaseId, filteredBases]);

  const filteredBaseSwitchGroups = React.useMemo(() => {
    if (base) {
      if (stopLists)
        return base.switchGroups
          .filter(
            (switchGroup) =>
              !Boolean(stopLists.modifierGroups.includes(switchGroup.id)),
          )
          .map((switchGroup) => ({
            ...switchGroup,
            modifiers: switchGroup.modifiers.filter(
              (modifier) => !Boolean(stopLists.modifiers.includes(modifier.id)),
            ),
          }));
      return base.switchGroups;
    } else return [];
  }, [base, stopLists]);

  const openOnAddToCart = useMemo(
    () =>
      filteredBases.length > 1 ||
      filteredBases.some(
        ({ addonGroups, selectorGroups, switchGroups }) =>
          addonGroups.some(({ modifiers }) => modifiers.length > 1) ||
          selectorGroups.some(({ modifiers }) => modifiers.length > 1) ||
          switchGroups.some(({ modifiers }) => modifiers.length > 1),
      ),
    [filteredBases],
  );

  React.useEffect(
    () =>
      setSwitchGroups(
        filteredBaseSwitchGroups.map((filteredSwitchGroup) => ({
          ...filteredSwitchGroup,
          modifiers: filteredSwitchGroup.modifiers.find(
            (modifier) => modifier.id === filteredSwitchGroup.defaultModifierId,
          )
            ? filteredSwitchGroup.modifiers.filter(
                (modifier) =>
                  modifier.id === filteredSwitchGroup.defaultModifierId,
              )
            : [filteredSwitchGroup.modifiers[0]],
        })),
      ),
    [filteredBaseSwitchGroups],
  );
  const filteredBaseSelectorGroups = React.useMemo(() => {
    if (base) {
      if (stopLists)
        return base.selectorGroups
          .filter(
            (selectorGroup) =>
              !Boolean(stopLists.modifierGroups.includes(selectorGroup.id)),
          )
          .map((selectorGroup) => ({
            ...selectorGroup,
            modifiers: selectorGroup.modifiers.filter(
              (modifier) => !Boolean(stopLists.modifiers.includes(modifier.id)),
            ),
          }));
      return base.selectorGroups;
    } else return [];
  }, [base, stopLists]);

  React.useEffect(
    () =>
      setSelectorGroups(
        filteredBaseSelectorGroups.map((filteredSelectorGroup) => ({
          ...filteredSelectorGroup,
          modifiers: filteredSelectorGroup.modifiers.find(
            (modifier) => modifier.byDefault,
          )
            ? [
                ...filteredSelectorGroup.modifiers.filter(
                  (modifier) => modifier.byDefault,
                ),
              ]
            : [filteredSelectorGroup.modifiers[0]],
        })),
      ),
    [filteredBaseSelectorGroups],
  );

  const productPathname =
    // linkValue.counter === 1
    // ?
    linkValue.value;
  // : `${linkValue.value}${linkValue.counter}`
  const productLink = usePrepareLink({
    to: `/${productPathname}`,
  });
  const { mounted } = useMount({
    isOpened: match?.params.name === productPathname,
  });

  const [isViewSuccess, setIsViewSuccess] = React.useState<boolean>(true);

  const isDesktop = useMediaQuery(`(min-width: ${600}px)`);

  const onAddDishToCart = useUnit(addDishToCartFx);

  const handleAddToCart = () => {
    setShowLoader(true);
    const dishToCart: DishToCartModel = {
      categoryId: dish.categoryId,
      price: base?.price ?? 0,
      name: dish.name,
      quantity: quantity,
      productId: dish.id,
      base: {
        id: base!.id,
        addonGroups: [],
        selectorGroups: selectorGroups.map((selectorGroup) => ({
          id: selectorGroup.id,
          modifiers: selectorGroup.modifiers.map((modifier) => ({
            id: modifier.id,
          })),
        })),
        switchGroups: switchGroups.map((switchGroup) => ({
          id: switchGroup.id,
          modifiers: switchGroup.modifiers.map((modifier) => ({
            id: modifier.id,
          })),
        })),
      },
    };

    onAddDishToCart({ dish: dishToCart, validationPayload, categories }).then(
      () => setShowLoader(false),
      () => setShowLoader(false),
    );
  };

  const baseSize = React.useMemo(() => {
    if (base) {
      const quantity = base.quantity;
      const size = base.size;

      if (quantity && size)
        return `${quantity} ${t(GET_MEASURE['PIECE'])} / ${size.value} ${t(
          GET_MEASURE[size.measure],
        )}`;

      if (quantity) return `${quantity} ${t(GET_MEASURE['PIECE'])}`;

      if (size) return `${size.value} ${t(GET_MEASURE[size.measure])}`;
    } else {
      const base = bases.find((base) => base.id === defaultBaseId) ?? bases[0];
      const quantity = base.quantity;
      const size = base.size;

      if (quantity && size)
        return `${quantity} ${t(GET_MEASURE['PIECE'])} / ${size.value} ${t(
          GET_MEASURE[size.measure],
        )}`;

      if (quantity) return `${quantity} ${t(GET_MEASURE['PIECE'])}`;

      if (size) return `${size.value} ${t(GET_MEASURE[size.measure])}`;
    }
  }, [base, bases, defaultBaseId, t]);

  const price = React.useMemo(() => {
    if (base) {
      const switchPrice = switchGroups.reduce(
        (accumulator, group) => accumulator + group.modifiers[0]?.price || 0,
        0,
      );
      const selectorPrice = selectorGroups.reduce(
        (accumulator, group) =>
          accumulator +
          group.modifiers.reduce(
            (accumulator, modifier) => accumulator + modifier.price,
            0,
          ),
        0,
      );
      const price = (base.price + switchPrice + selectorPrice) * quantity;
      return price;
    } else return 0;
  }, [base, selectorGroups, switchGroups, quantity]);

  const showMobileVideoContent = React.useMemo(
    () => !isDesktop && isViewSuccess && displayType === 'PROMOTION',
    [isDesktop, isViewSuccess, displayType],
  );

  return (
    <>
      <div
        className={clsx(
          style.root,
          className,
          displayType === 'PROMOTION' && isViewSuccess && videoClassName,
        )}
        onClick={() => available && navigate(productLink)}
      >
        {displayType === 'STANDARD' ? (
          <FEObserveredImage
            loaderContainerClassName={style.loader}
            className={clsx(style.image, !isViewSuccess && style.placeholder)}
            imageId={imageId}
            altImage={IMG_PLACEHOLDER_CARD_PICTURE_MOBILE}
            onError={() => setIsViewSuccess(false)}
            spinnerSize='medium'
          />
        ) : (
          <FEObserveredVideo
            videoId={videoId || ''}
            loaderContainerClassName={style.loader}
            containerClassName={clsx(style.videoContainer)}
            imageClassName={clsx(
              style.image,
              !isViewSuccess && style.placeholder,
            )}
            videoClassName={clsx(style.video)}
            imageId={imageId}
            altImage={IMG_PLACEHOLDER_CARD_PICTURE_MOBILE}
            onError={() => setIsViewSuccess(false)}
            spinnerSize='medium'
          />
        )}
        {!showMobileVideoContent && (
          <div className={style.data}>
            <p className={clsx('subhead')}>{name}</p>
            {baseSize && (
              <p className={clsx('caption-1', style.size)}>{baseSize}</p>
            )}
          </div>
        )}
        <div
          className={clsx(
            style.submit,
            showMobileVideoContent && style.submit__video,
          )}
        >
          {isDesktop && <p className='subhead'>{available && `${price} ${currency}`}</p>}
          <div className={clsx(style.submitButton)}>
            {quantityInCart === null ? (
              <FEButton
                showLoader={showLoader}
                onClick={(e) => {
                  e.stopPropagation();
                  openOnAddToCart ? navigate(productLink) : handleAddToCart();
                }}
                disabled={!available || showLoader}
                size='medium'
              >
                {available
                  ? isDesktop
                    ? t('catalog.dish.card.addToCartText')
                    : `${price} ${currency}`
                  : t('catalog.dish.card.unavailableLabel')}
              </FEButton>
            ) : (
              <FEQuantity
                valueColor={showMobileVideoContent ? 'onColor' : 'primary'}
                size='large'
                value={quantityInCart}
                onChange={changeQuantityInCart}
              />
            )}
          </div>
        </div>
      </div>

      {mounted && (
        <DishCardModal
          currency={currency}
          changeShowLoader={setShowLoader}
          validationPayload={validationPayload}
          stopLists={stopLists}
          image={imageId || ''}
          open={match?.params.name === productPathname}
          onClose={() => {
            location.key === 'default'
              ? navigate('/', { replace: true })
              : navigate(-1);
          }}
          dish={dish}
        />
      )}
    </>
  );
};

export default DishCard;
