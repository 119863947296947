import React from 'react';
import { MOBILE_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import { OrderMinimumProps } from './props';
import { FETitledDialog, FETitledBottomSheet, FEButton } from 'components';
import style from './style.module.css';
import clsx from 'clsx';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const Content = ({
  onClose,
  currency,
  price,
}: {
  price?: number;
  currency: CurrencySymbol;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <p className={clsx('text-1', style.message)}>
        {t('cartography.address.orderMinimum.labels.message', {
          price,
          currency,
        })}
      </p>
      <FEButton onClick={onClose} type='secondary'>
        {t('common.ok')}
      </FEButton>
    </div>
  );
};

const OrderMinimum = ({
  open,
  price,
  onClose,
  currency,
}: OrderMinimumProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  return isDesktop ? (
    <FETitledDialog
      showCloseButton={false}
      onClose={onClose}
      open={open}
      title={t('cartography.address.orderMinimum.title')}
      render={({ onClose }) => (
        <Content price={price} currency={currency} onClose={onClose} />
      )}
    />
  ) : (
    <FETitledBottomSheet
      showCloseButton={false}
      onClose={onClose}
      open={open}
      title={t('cartography.address.orderMinimum.title')}
      render={({ onClose }) => (
        <Content price={price} currency={currency} onClose={onClose} />
      )}
    />
  );
};

export default OrderMinimum;
