import BaseAPIRepository, {
  BaseAPIResponse,
  BaseFilterRequest,
} from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { NotificationReadRequestV1 } from '.';
import { GetNotificationsResponseV1 } from '.';

const notificationsRepositoryV1 = {
  read: (request: NotificationReadRequestV1, signal?: AbortSignal) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/notifications/read`;
        let result: AxiosResponse<BaseAPIResponse<string>> =
          await BaseAPIRepository.post<BaseAPIResponse<string>>(url, request, {
            signal,
          });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  getNotifications: (request?: BaseFilterRequest, signal?: AbortSignal) =>
    new Promise<GetNotificationsResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url: string;
          if (request) {
            const { query, limit, offset, sortName, sortDirection } = request;
            url =
              `${BASE_URL}/api/v1/notifications?` +
              `${query ? `query=${query}` : ''}` +
              `${limit ? `&limit=${limit}` : ''}` +
              `${offset ? `&offset=${offset}` : ''}` +
              `${query ? `&query=${query}` : ''}` +
              `${sortDirection ? `&sortDirection=${sortDirection}` : ''}` +
              `${sortName ? `&sortName=${sortName}` : ''}`;
          } else url = `${BASE_URL}/api/v1/notifications`;

          let result: AxiosResponse<
            BaseAPIResponse<GetNotificationsResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetNotificationsResponseV1.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default notificationsRepositoryV1;
