import { GetBonusAccountTransactionsResponseV1 } from 'api/version1';
import clsx from 'clsx';
import { GET_ENUMS, GET_PARAMS } from 'const';
import { usePrepareLink } from 'hooks/router';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';

const Transaction = ({
  transaction,
}: {
  transaction: GetBonusAccountTransactionsResponseV1.ResponseItem;
}) => {
  const { t } = useTranslation();
  const GET_TRANSACTION_TITLE = {
    REFILL: t('bonus.page.transaction.transactionTypes.REFIL'),
    WITHDRAW: t('bonus.page.transaction.transactionTypes.WITHDRAW'),
  };
  const GET_TRANSACTION_SIGN = {
    REFILL: '+',
    WITHDRAW: '-',
  };

  const navigate = useNavigate();
  const { orderId, orderNumber, type, amount } = transaction;

  const orderLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.orders,
    },
    pushToQuery: {
      [GET_PARAMS.orderId]: orderId,
    },
  });

  return (
    <div className={style.root}>
      <div className={style.header}>
        <p>{`№${orderNumber}`}</p>
        <button
          className={clsx('subhead', style.goToOrderButton)}
          onClick={() => navigate(orderLink, { replace: true })}
        >
          {t('bonus.page.transaction.goToOrderLabel')}
        </button>
      </div>
      <div className={style.transactionData}>
        <p className='text-1'>
          {GET_TRANSACTION_TITLE[type as 'REFILL' | 'WITHDRAW']}
        </p>
        <div className={style.transactionAmount}>
          <p
            className={clsx(
              'headline',
              type === 'WITHDRAW' && style.withdrawTransaction,
            )}
          >
            {GET_TRANSACTION_SIGN[type as 'REFILL' | 'WITHDRAW']}
            {amount}
          </p>
          <p className={clsx('icon-24', style.bonusImage)} />
        </div>
      </div>
    </div>
  );
};

export default Transaction;
