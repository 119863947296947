import { BaseErrorResponse } from 'api/base';
import { t } from 'i18next';

const FERequestErrorsHandler = (err: any, reject: (reason: any) => void) => {
  const error = err as BaseErrorResponse.BaseErrorResponse;

  if (error.code === 'ERR_NETWORK') {
    const rejectedError: BaseErrorResponse.ErrorResponse = {
      errors: [
        {
          presentationData: {
            title: t('requestErrorsHandler.ERR_NETWORK.title'),
            message: t('requestErrorsHandler.ERR_NETWORK.message'),
          },
        },
      ],
    };
    return reject(rejectedError);
  } else if (error.message.includes('timeout')) {
    const rejectedError: BaseErrorResponse.ErrorResponse = {
      errors: [
        {
          presentationData: {
            title: t('requestErrorsHandler.timeout.title'),
            message: t('requestErrorsHandler.timeout.message'),
          },
        },
      ],
    };
    return reject(rejectedError);
  } else {
    reject(error.response?.data);
  }
};

export default FERequestErrorsHandler;
