import clsx from 'clsx';
import React from 'react';
import FEMiniCardProps from './props';
import style from './style.module.css';

const FEMiniCard = ({
  active,
  onClick,
  children,
  disabled,
}: FEMiniCardProps) => (
  <div
    className={clsx(
      style.root,
      active && style.active,
      disabled && style.disabled,
    )}
    onClick={(e) => {
      !disabled && onClick();
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {children}
  </div>
);

export default FEMiniCard;
export type { FEMiniCardProps };
