import { OrdersValidateResponseV4 } from 'api/version4';
import React from 'react';
import {
  FEImageComponent,
  FETitledBottomSheet,
  FETitledDialog,
} from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import style from './style.module.css';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const Container = ({
  open,
  onClose,
  productsList,
  onPickProduct,
}: {
  open: boolean;
  onClose: () => void;
  productsList: OrdersValidateResponseV4.PromotionProduct[] | undefined;
  onPickProduct: (v: OrdersValidateResponseV4.PromotionProduct) => void;
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${600}px)`);

  const Content = React.useMemo(
    () =>
      ({
        onClose,
        productsList,
        onPickProduct,
      }: {
        onClose: () => void;
        productsList: OrdersValidateResponseV4.PromotionProduct[] | undefined;
        onPickProduct: (v: OrdersValidateResponseV4.PromotionProduct) => void;
      }) =>
        (
          <div className={style.content}>
            <>
              {productsList?.map((product, i) => (
                <div className={style.root} key={product.orderItemId}>
                  <div className={style.container}>
                    <FEImageComponent
                      className={style.image}
                      image={{ imageId: product.product.product.imageId || '' }}
                      altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
                    />
                    <div className={style.data}>
                      <p className='subhead'>{product.product.product.name}</p>
                    </div>
                    <div className={style.event}>
                      <button
                        className={clsx('subhead', style.eventButton)}
                        onClick={() => {
                          onPickProduct(product);
                          onClose();
                        }}
                      >
                        {t('common.choose')}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
        ),
    [t],
  );

  return isDesktop ? (
    <FETitledDialog
      open={open}
      onClose={onClose}
      title={t('cart.main.promotion.label')}
      render={({ onClose }) => (
        <Content {...{ onClose, productsList, onPickProduct }} />
      )}
    />
  ) : (
    <FETitledBottomSheet
      open={open}
      onClose={onClose}
      title={t('cart.main.promotion.label')}
      render={({ onClose }) => (
        <Content {...{ onClose, productsList, onPickProduct }} />
      )}
    />
  );
};

export default Container;
