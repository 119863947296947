import style from './style.module.css';
import { ListItem } from '..';
import { MOBILE_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import { FESearchPlaceholder } from 'components';
import { ListProps } from './props';
import { getDistanceTo } from 'utils';
import { useTranslation } from 'react-i18next';

const List = ({
  searchFieldHeight,
  onChangeRestaurant,
  userGeolocation,
  restaurantsList,
  fromCart,
}: ListProps) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  return (
    <div
      className={style.root}
      style={
        isDesktop
          ? {
              height: `calc(600px - ${searchFieldHeight}px - 64px - 60px - 60px + 24px)`,
              maxHeight: `calc(85svh - ${searchFieldHeight}px - 64px - 60px - 60px + 24px)`,
            }
          : {
              height: `calc(85svh - ${searchFieldHeight}px - 60px - 60px - 60px + 24px)`,
            }
      }
    >
      {restaurantsList.length === 0 ? (
        <div className={style.placeholderContainer}>
          <FESearchPlaceholder
            title={t('searchPlaceholderComponent.title')}
            message={t('cartography.restaurant.form.searchPlaceholder')}
          />
        </div>
      ) : (
        restaurantsList
          .sort((restA, restB) => {
            if (userGeolocation) {
              return (
                (getDistanceTo(
                  userGeolocation,
                  restA.address.location,
                  false,
                ) as number) -
                (getDistanceTo(
                  userGeolocation,
                  restB.address.location,
                  false,
                ) as number)
              );
            } else return 0;
          })
          .map((restaurant) => (
            <ListItem
              fromCart={fromCart}
              key={restaurant.id}
              userGeolocation={userGeolocation}
              restaurant={restaurant}
              onChangeRestaurant={onChangeRestaurant}
            />
          ))
      )}
    </div>
  );
};

export default List;
