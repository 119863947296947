import clsx from 'clsx';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import { CurrencySymbol } from 'models';
import { FEImageComponent } from 'ui-kit/components';
import style from './style.module.css';

const Modifier = ({
  active,
  name,
  id,
  onToggle,
  price,
  imageId,
  disabled,
  currency,
}: {
  active: boolean;
  name: string;
  id: string;
  onToggle: (id: string, active: boolean) => void;
  price: number;
  imageId?: string;
  disabled: boolean;
  currency: CurrencySymbol;
}) => {
  return (
    <div
      className={clsx(
        style.root,
        active && style.root__active,
        disabled && style.root__disabled,
      )}
      onClick={() => onToggle(id, !active)}
    >
      <div
        className={clsx(
          style.imageContainer,
          active && style.imageContainer__active,
        )}
      >
        <FEImageComponent
          className={style.image}
          image={{ imageId: imageId || '' }}
          altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
        />
      </div>
      <div className={clsx(style.data, active && style.data__active)}>
        <p className='subhead'>{name}</p>
        <p className='subhead'>{`${price} ${currency}`}</p>
      </div>
    </div>
  );
};

export default Modifier;
