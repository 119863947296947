import React from "react";
import FETabViewProps from "./props";
import clsx from "clsx";
import style from "./style.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper";

const FETabView = ({
                     tabList,
                     tabIndex,
                     onChange,
                     tabClassName
                   }: FETabViewProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const activeTabRef = React.useRef<HTMLDivElement>(null);
  const swiperRef = React.useRef<HTMLDivElement>(null);
  const [indicatorLeftPosition, setIndicatorLeftPosition] = React.useState(0);
  const [indicatorWidth, setIndicatorWidth] = React.useState(0);

  React.useEffect(() => {
    if (containerRef.current && activeTabRef.current) {
      const { left: containerLeftPosition } =
        containerRef.current.getBoundingClientRect();
      const { left: activeTabLeftPosition, width: activeTabWidth } =
        activeTabRef.current.getBoundingClientRect();

      const indicatorPosition = activeTabLeftPosition - containerLeftPosition;
      setIndicatorWidth(activeTabWidth);
      setIndicatorLeftPosition(indicatorPosition);
    }
  }, [tabIndex]);

  React.useEffect(() => {
    if (swiperRef.current && tabIndex >= 5) {
      swiperRef.current.scrollTo({
        left: 100,
        behavior: 'smooth'
      });
    }
  }, [tabIndex]);

  return (
    <div className={style.tabContainer} ref={containerRef}>
      <Swiper
        spaceBetween={12}
        mousewheel={true}
        modules={[Mousewheel]}
        direction="horizontal"
        slidesPerView={5}
        //@ts-ignore
        ref={swiperRef}
        style={{minWidth: '100%'}}
      >
        {tabList.map((tab, index) => (
          <SwiperSlide

          >
            <div
              ref={index === tabIndex ? activeTabRef : undefined}
              className={clsx(style.tab, tabClassName, index === tabIndex && style.activeTabBorder)}
              key={index}
              onClick={(e) => {
                if (tab.disabled !== true) {
                  onChange({ index, id: tab.id });
                }
              }}
            >
              <p
                className={clsx(
                  "caption-2",
                  index !== tabIndex ? style.unactiveTab : style.activeTab,
                  tab.disabled && style.disabledTab
                )}
              >
                {tab.title.length > 9 ? tab.title.slice(0, 9) + "..." : tab.title}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/*<div*/}
      {/*  className={style.indicator}*/}
      {/*  style={{ left: indicatorLeftPosition, width: indicatorWidth }}*/}
      {/*/>*/}
    </div>
  );
};

export default FETabView;
export type { FETabViewProps };
