import { createStore } from 'effector';
import { Banner } from './entities';
import { pending } from 'patronum';
import { getBannersByCatalogIdFx, initBannersFx } from './effects';
import { getGiftRepurchasesFx } from 'domains/giftRepurschases/effects';

export const $banners = createStore<Banner[]>([]);

export const $isPendingBanners = pending({
  effects: [initBannersFx, getBannersByCatalogIdFx, getGiftRepurchasesFx],
});
