import { AxiosResponse } from 'axios';
import {
  GetSitesMapByCountryIdRequestV1,
  GetSitesMapByCountryIdResponseV1,
} from './model';
import { FERequestErrorsHandler } from 'utils';
import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { BASE_URL } from 'const';

const sitesMapRepositoryV1 = {
  getSitesMapByCountryId: ({ countryId }: GetSitesMapByCountryIdRequestV1) => {
    return new Promise<GetSitesMapByCountryIdResponseV1>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/sites-map/by-country?countryId=${countryId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetSitesMapByCountryIdResponseV1>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetSitesMapByCountryIdResponseV1>
          >(url);

          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    );
  },
};

export default sitesMapRepositoryV1;
