import clsx from 'clsx';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import FEButton from '../../FEButton';
import { swiperDefaults } from '../model';
import style from '../style.module.css';
import { useTranslation } from 'react-i18next';

const Picker = ({
  submit,
  onClose,
  years,
  months,
  days,
  initialYearIndex,
  initialMonthIndex,
  initialDayIndex,
  changeYear,
  changeMonth,
  changeDay,
}: {
  onClose: () => void;
  years: string[];
  months: string[];
  days: string[];
  initialYearIndex: number;
  initialMonthIndex: number;
  initialDayIndex: number;
  changeYear: (index: number) => void;
  changeMonth: (index: number) => void;
  changeDay: (index: number) => void;
  submit: () => void;
}) => {
  const { t } = useTranslation();
  const [dayIndex, setDayIndex] = React.useState<number>(initialDayIndex);
  const [monthIndex, setMonthIndex] = React.useState<number>(initialMonthIndex);
  const [yearIndex, setYearIndex] = React.useState<number>(initialYearIndex);

  return (
    <div className={style.root}>
      <div className={style.picker}>
        <div className={style.days}>
          <Swiper
            {...swiperDefaults}
            initialSlide={initialDayIndex}
            onSlideChange={(e) => {
              changeDay(e.activeIndex);
              setDayIndex(e.activeIndex);
            }}
          >
            {days.map((day, i) => (
              <SwiperSlide key={i}>
                <div className={style.item}>
                  <p
                    className={clsx('text-1', i !== dayIndex && style.inactive)}
                  >
                    {day}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={style.months}>
          <Swiper
            {...swiperDefaults}
            initialSlide={initialMonthIndex}
            onSlideChange={(e) => {
              changeMonth(e.activeIndex);
              setMonthIndex(e.activeIndex);
            }}
          >
            {months.map((month, i) => (
              <SwiperSlide key={i}>
                <div className={style.item}>
                  <p
                    className={clsx(
                      'text-1',
                      i !== monthIndex && style.inactive,
                    )}
                  >
                    {month}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={style.years}>
          <Swiper
            {...swiperDefaults}
            initialSlide={initialYearIndex}
            onSlideChange={(e) => {
              changeYear(e.activeIndex);
              setYearIndex(e.activeIndex);
            }}
          >
            {years.map((year, i) => (
              <SwiperSlide key={i}>
                <div className={style.item}>
                  <p
                    className={clsx(
                      'text-1',
                      i !== yearIndex && style.inactive,
                    )}
                  >
                    {year}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={style.submit}>
        <FEButton
          onClick={() => {
            submit();
            onClose();
          }}
        >
          {t('common.apply')}
        </FEButton>
      </div>
    </div>
  );
};

export default Picker;
