import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { OrdersValidateRequestV4 } from '.';

import { OrdersValidateResponseV4 } from '.';

const ordersRepositoryV4 = {
  ordersValidate: (
    request: OrdersValidateRequestV4.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersValidateResponseV4.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v4/orders/validate`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersValidateResponseV4.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersValidateResponseV4.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  notSecureOrdersValidate: (
    request: OrdersValidateRequestV4.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersValidateResponseV4.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v4/orders/validate`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersValidateResponseV4.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersValidateResponseV4.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default ordersRepositoryV4;
