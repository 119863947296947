import React from 'react';
import { FEDialog } from '..';
import FEScrollableTitledDialogProps from './props';
import style from './style.module.css';
import clsx from 'clsx';

const FEScrollableTitledDialog = ({
  open,
  onClose,
  closeOnOverlayClick,
  showCloseButton,
  title,
  render,
  onArrowBackClick,
  closeOnArrowBackClick = false,
  containerClassName,
}: FEScrollableTitledDialogProps) => {
  return (
    <FEDialog
      {...{ open, onClose, closeOnOverlayClick, showCloseButton }}
      render={({ onClose, onCloseModal }) => (
        <div className={clsx(containerClassName)}>
          <div className={style.header}>
            {(onArrowBackClick || closeOnArrowBackClick) && (
              <button
                tabIndex={0}
                className='button-arrowBack'
                onClick={(e) => {
                  closeOnArrowBackClick && onClose();
                  onArrowBackClick && onArrowBackClick();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
            <h3 className='title'>{title}</h3>
          </div>
          {render({ onClose: () => onClose && onClose(), onCloseModal })}
        </div>
      )}
    />
  );
};

export default FEScrollableTitledDialog;
export type { FEScrollableTitledDialogProps };
