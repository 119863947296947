import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { FEButton } from 'components';
import { ANIMATION_DELAY } from 'const';
import style from './style.module.css';
import { useClickOutside } from 'ui-kit/hooks';
import { useTranslation } from 'react-i18next';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

const Layout = ({
  onClose,
  value,
  onDeclineConfirmation,
}: {
  onClose: () => void;
  value: string | null;
  onDeclineConfirmation: () => void;
}) => {
  const { t } = useTranslation();
  const [animationIn, setAnimationIn] = React.useState<boolean>(false);
  const layoutRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => setAnimationIn(true), []);

  function handleClose() {
    if (onClose) {
      onClose();
      setAnimationIn(false);
    }
  }

  useClickOutside(layoutRef, () => handleClose());

  return (
    <div className={style.root} ref={layoutRef}>
      <CSSTransition
        in={animationIn}
        timeout={ANIMATION_DELAY}
        mountOnEnter
        unmountOnExit
        classNames={containerAnimation}
        nodeRef={containerRef}
      >
        <div className={style.container} ref={containerRef}>
          <div className={style.data}>
            <p className='text-2'>{t('cartography.confirmation.label')}</p>
            <p className='headline'>{value}</p>
          </div>
          <div className={style.submit}>
            <FEButton onClick={handleClose}>{t('common.correct1')}</FEButton>
            <FEButton
              onClick={() => {
                onDeclineConfirmation();
                handleClose();
              }}
              type='secondary'
            >
              {t('common.change')}
            </FEButton>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Layout;
