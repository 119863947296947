import { Promotion as PromotionType, PromotionProduct } from 'domains/cart';
import { IMG_PLACEHOLDER_GIFT, IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import style from './style.module.css';
import React from 'react';
import clsx from 'clsx';
import { OrdersValidateResponseV4 } from 'api/version4';
import { Container } from './components';
import { FEImageComponent } from 'ui-kit/components';
import { useTranslation } from 'react-i18next';

const Promotion = ({
  promotion,
  promotionProduct,
  onPickProduct,
}: {
  promotion: PromotionType;
  promotionProduct: PromotionProduct | null;
  onPickProduct: (v: OrdersValidateResponseV4.PromotionProduct) => void;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);

  const productsList = promotion.products;
  const productInPromotion = promotion.promotionProduct;
  const hasSeveralProducts = productsList && productsList.length > 1;

  const productDescription = React.useMemo(() => {
    if (promotionProduct) {
      const {
        product: {
          base: { switchGroups, selectorGroups, addonGroups },
          product: { name },
        },
      } = promotionProduct;

      return `${name}${
        switchGroups.length !== 0
          ? `, ${switchGroups
              .map((switchGroup) =>
                switchGroup.modifiers
                  .map((modifier) => modifier.info.name)
                  .join(', '),
              )
              .join(', ')}`
          : ''
      } ${
        selectorGroups.length !== 0 &&
        selectorGroups.some(
          (selectorGroup) => selectorGroup.modifiers.length !== 0,
        )
          ? `, ${selectorGroups
              .map((selectorGroup) =>
                selectorGroup.modifiers
                  .map((modifier) => modifier.info.name)
                  .join(', '),
              )
              .join(', ')}`
          : ''
      } ${
        addonGroups.length !== 0 &&
        addonGroups.some((addonGroup) => addonGroup.modifiers.length !== 0)
          ? `, ${addonGroups
              .map((addonGroup) =>
                addonGroup.modifiers
                  .map(
                    (modifier) =>
                      modifier.info.name + ` × ${modifier.quantity}`,
                  )
                  .join(', '),
              )
              .join(', ')}`
          : ''
      }`;
    } else return '';
  }, [promotionProduct]);

  return (
    <>
      <Container
        onClose={() => setOpen(false)}
        open={open}
        productsList={productsList}
        onPickProduct={onPickProduct}
      />
      {hasSeveralProducts && productInPromotion ? (
        <>
          <div className={style.root}>
            <div className={style.container}>
              <FEImageComponent
                image={{
                  imageId: promotionProduct?.product.product.imageId || '',
                }}
                altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
                className={style.image}
              />
              <div className={style.data}>
                <p className='subhead'>
                  {promotionProduct?.product.product.name}
                </p>
                <p className={clsx('caption-1')}>{productDescription}</p>
                <p className='subhead'>
                  {t('cart.main.promotion.productLabel')}
                </p>
              </div>
              <div className={style.event}>
                <button
                  className={clsx('subhead', style.eventButton)}
                  onClick={() => setOpen(true)}
                >
                  {t('common.change')}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : hasSeveralProducts && productInPromotion === undefined ? (
        <>
          <div className={style.root}>
            <div className={style.container}>
              <img className={style.image} src={IMG_PLACEHOLDER_GIFT} alt='' />
              <div className={style.data}>
                <p className='subhead'>{t('cart.main.promotion.label')}</p>
              </div>
              <div className={style.event}>
                <button
                  className={clsx('subhead', style.eventButton)}
                  onClick={() => setOpen(true)}
                >
                  {t('common.choose')}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : hasSeveralProducts === false && productInPromotion ? (
        <>
          <div className={style.root}>
            <div className={style.container}>
              <FEImageComponent
                image={{
                  imageId: promotionProduct?.product.product.imageId || '',
                }}
                altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
                className={style.image}
              />
              <div className={style.data}>
                <p className='subhead'>
                  {promotionProduct?.product.product.name}
                </p>
                <p className={clsx('caption-1')}>{productDescription}</p>
                <p className='subhead'>
                  {t('cart.main.promotion.productLabel')}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Promotion;
