import { Location } from 'models';
import { t } from 'i18next';

const getDistanceTo = (
  from: Location,
  to: Location,
  normalize: boolean = true,
) => {
  const { latitude: lat1, longitude: lon1 } = from;
  const { latitude: lat2, longitude: lon2 } = to;

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };

  const earthRadius = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c * 1000;
  if (normalize) {
    const roundedDistance = Math.round(distance);
    if (roundedDistance.toString().length >= 4) {
      const distanceInKm = roundedDistance / 1000;
      const fixedDistanceInKm = distanceInKm.toFixed(1);
      return `${fixedDistanceInKm} ${t('distances.km')}`;
    } else {
      return `${roundedDistance} ${t('distances.m')}`;
    }
  } else return distance;
};

export default getDistanceTo;
