import { GetCountriesResponseV1 } from 'api/version1';

export const normalizeMask = (mask: string) =>
  mask
    .replace(/[[\]]/g, '')
    .split('')
    .map((char) => {
      if (/[1-9]/.test(char)) return `\\${char}`;
      return char;
    })
    .join('')
    .replace(/[0]/g, '9');

export const getFlagLink = (iso: string) =>
  `https://purecatamphetamine.github.io/country-flag-icons/3x2/${iso}.svg`;

export type Country = GetCountriesResponseV1.ResponseItem;
