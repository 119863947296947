import { useMount } from 'hooks';
import React from 'react';
import FEPortal from '../FEPortal';
import { Layout } from './components';
import { FEStoriesSliderProps } from './props';

const FEStoriesSlider = (props: FEStoriesSliderProps) => {
  const { mounted } = useMount({ isOpened: props.open });

  if (!mounted) return null;

  return (
    <FEPortal>
      <Layout {...props} />
    </FEPortal>
  );
};

export default FEStoriesSlider;
export type { FEStoriesSliderProps };
