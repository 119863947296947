import { FEBottomSheet } from '..';
import FETitledBottomSheetProps from './props';
import style from './style.module.css';

const FETitledBottomSheet = ({
  onClose,
  open,
  render,
  title,
  showCloseButton = true,
  closeOnOverlayClick = true,
  onArrowBackClick,
  closeOnArrowBackClick = false,
}: FETitledBottomSheetProps) => (
  <FEBottomSheet
    onClose={onClose}
    open={open}
    closeOnOverlayClick={closeOnOverlayClick}
    render={({ onClose, onCloseModal }) => (
      <>
        <div className={style.header}>
          <div className={style.side}>
            {(onArrowBackClick || closeOnArrowBackClick) && (
              <button
                tabIndex={0}
                className='button-arrowBack'
                onClick={(e) => {
                  closeOnArrowBackClick && onClose();
                  onArrowBackClick && onArrowBackClick();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
            <h3 className='title'>{title}</h3>
          </div>
          {showCloseButton && (
            <button
              tabIndex={0}
              className='closeButton-mobile'
              onClick={(e) => {
                onClose();
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
        </div>
        <>{render({ onClose, onCloseModal })}</>
      </>
    )}
  />
);

export default FETitledBottomSheet;
export type { FETitledBottomSheetProps };
