import { OrderType, PaymentTypeInList } from 'models';
import { FETitledBottomSheet } from '../..';
import {
  GET_COURIER_PAYMENT_TYPE,
  GET_PAYMENT_TYPE,
  GET_PAYMENT_TYPE_IMAGE,
} from 'const';
import style from '../styles/list.style.module.css';
import ListItem from './ListItem';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export type ListProps<T> = {
  open: boolean;
  isDesktop: boolean;
  paymentTypes: T[];
  value: T | null;
  onChange: (paymentType: T) => void;
  onClose: () => void;
  orderType: OrderType;
  disabled: boolean;
};

const Content = <T = PaymentTypeInList,>({
  paymentTypes,
  value,
  onChange,
  onClose,
  orderType,
  disabled,
}: {
  disabled: boolean;
  paymentTypes: T[];
  value: T | null;
  onChange: (paymentType: T) => void;
  onClose: () => void;
  orderType: OrderType;
}) => {
  const { t } = useTranslation();
  return (
    <div className={clsx(style.listContent, disabled && style.disabled)}>
      {paymentTypes.map((paymentType) => {
        if (
          GET_PAYMENT_TYPE_IMAGE[paymentType as PaymentTypeInList] &&
          GET_COURIER_PAYMENT_TYPE[paymentType as PaymentTypeInList] &&
          GET_PAYMENT_TYPE[paymentType as PaymentTypeInList]
        ) {
          return (
            <ListItem
              key={paymentType as string}
              image={GET_PAYMENT_TYPE_IMAGE[paymentType as PaymentTypeInList]}
              onClick={() => {
                onChange(paymentType);
                onClose();
              }}
              value={
                orderType === 'COURIER'
                  ? t(
                      GET_COURIER_PAYMENT_TYPE[
                        paymentType as PaymentTypeInList
                      ],
                    )
                  : t(GET_PAYMENT_TYPE[paymentType as PaymentTypeInList])
              }
              active={paymentType === value}
            />
          );
        }

        return null;
      })}
    </div>
  );
};

const List = <T = PaymentTypeInList,>({
  open,
  onChange,
  paymentTypes,
  isDesktop,
  onClose,
  value,
  orderType,
  disabled,
}: ListProps<T>) => {
  const { t } = useTranslation();
  return isDesktop ? (
    <>
      {open && (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={clsx(style.list, disabled && style.disabled)}
        >
          <Content<T>
            {...{ paymentTypes, disabled, value, onChange, onClose, orderType }}
          />
        </div>
      )}
    </>
  ) : (
    <FETitledBottomSheet
      open={open}
      onClose={onClose}
      title={t('cart.ordering.components.paymentTypes.title')}
      render={() => (
        <Content<T>
          {...{ paymentTypes, disabled, value, onChange, onClose, orderType }}
        />
      )}
    />
  );
};

export default List;
