import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import {
  GetDeliveryZoneByIdRequestV1,
  GetDeliveryZoneByGeolocationRequestV1,
  GetDeliveryZoneStopListRequestV1,
  GetDeliveryZoneTimeIntervalsRequestV1,
  GetDeliveryZoneAndMapLayerZoneByCityIdRequestV1,
} from '.';
import {
  GetDeliveryZoneByIdResponseV1,
  GetDeliveryZoneByGeolocationResponseV1,
  GetDeliveryZoneStopListResponseV1,
  GetDeliveryZoneTimeIntervalsResponseV1,
  GetDeliveryZoneAndMapLayerZoneByCityIdResponseV1,
} from '.';

const deliveryZonesRepositoryV1 = {
  getDeliveryZoneById: (
    { id }: GetDeliveryZoneByIdRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetDeliveryZoneByIdResponseV1.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/delivery-zone/${id}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetDeliveryZoneByIdResponseV1.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetDeliveryZoneByIdResponseV1.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getDeliveryZoneByGeolocation: (
    {
      cityId,
      geolocation: { latitude, longitude },
    }: GetDeliveryZoneByGeolocationRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetDeliveryZoneByGeolocationResponseV1.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/delivery-zone?latitude=${latitude}&longitude=${longitude}&cityId=${cityId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetDeliveryZoneByGeolocationResponseV1.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetDeliveryZoneByGeolocationResponseV1.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
  getDeliveryZoneStopList: (
    { id }: GetDeliveryZoneStopListRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetDeliveryZoneStopListResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/places/delivery-zone/${id}/stop-list`;
        let result: AxiosResponse<
          BaseAPIResponse<GetDeliveryZoneStopListResponseV1>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetDeliveryZoneStopListResponseV1>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  getDeliveryZoneTimeIntervals: (
    { id }: GetDeliveryZoneTimeIntervalsRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetDeliveryZoneTimeIntervalsResponseV1>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/delivery-zone/${id}/time-intervals`;
          let result: AxiosResponse<
            BaseAPIResponse<GetDeliveryZoneTimeIntervalsResponseV1>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetDeliveryZoneTimeIntervalsResponseV1>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
  getDeliveryZoneAndMapLayerZoneByCityId: (
    { cityId }: GetDeliveryZoneAndMapLayerZoneByCityIdRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetDeliveryZoneAndMapLayerZoneByCityIdResponseV1.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/delivery-zone/by-city/${cityId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetDeliveryZoneAndMapLayerZoneByCityIdResponseV1.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetDeliveryZoneAndMapLayerZoneByCityIdResponseV1.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default deliveryZonesRepositoryV1;
