import clsx from 'clsx';
import React from 'react';
import { IMG_PLACEHOLDER_PERCENT } from 'images';
import style from './style.module.css';

const SkeletonBanners = ({
  bannersCount = 10,
  showLastOrderBanner = true,
}: {
  bannersCount?: number;
  showLastOrderBanner?: boolean;
}) => {
  return (
    <div className={style.skeletonBannersList}>
      {showLastOrderBanner && (
        <div
          className={clsx(
            style.skeletonBanner__lastOrder,
            style.skeletonBanner,
          )}
        />
      )}
      {new Array(bannersCount).fill(0).map((_, i) => (
        <div
          key={i}
          className={clsx(style.skeletonBanner__story, style.skeletonBanner)}
        >
          <img
            alt=''
            className={style.processingImage}
            src={IMG_PLACEHOLDER_PERCENT}
          />
        </div>
      ))}
    </div>
  );
};

export default SkeletonBanners;
