import { Address, CurrencySymbol, OrderType } from 'models';
import { useNavigate } from 'react-router-dom';
import {
  FEButton,
  FEDeliveryTimePicker,
  FEOpeningBar,
  FEPromocode,
  FESegmentedControl,
} from 'components';
import { IMG_IC_24_MARKER } from 'images';
import { Cutlery, Delivery, Product, Promotion } from './components';
import { CartProps } from './props';
import style from './style.module.css';
import clsx from 'clsx';
import moment from 'moment';
import { FEDateUtils, FELocalStorageUtil } from 'utils';
import { DeliveryZone } from 'domains/cartography';
import { GetRestaurantByIdResponseV1 } from 'api/version1';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { $language } from 'domains/language';
import { GET_ENUMS, GET_PARAMS } from 'const';
import { useCallback, useMemo } from 'react';
import { CatalogCategory } from 'domains/catalog';

const Cart = ({
  onClose,
  hasCriticalCartError,
  dishes,
  constructors,
  cutleries,
  price,
  warning,
  isValidating,
  timeTo,
  urgent,
  promoCode,
  onChangePromoCode,
  stage,
  deliveryZone,
  cartographyLink,
  promotion,
  promotionProduct,
  problems,
  type,
  timeIntervals,
  address,
  restaurant,
  onChangeStage,
  onChangeConstructorQuantity,
  onChangeCutleryQuantity,
  onChangeDishQuantity,
  onDeleteConstructor,
  onDeleteCutlery,
  onDeleteDish,
  onChangeTime,
  onChangePromotionProduct,
  isDesktop,
  title,
  longDeliveryWarning,
  currency,
  onClearCart,
  categories,
}: CartProps & { currency: CurrencySymbol; categories: CatalogCategory[] }) => {
  const { t } = useTranslation();
  const language = useUnit($language);
  const navigate = useNavigate();

  const getCartographyTypeLink = useCallback((type: OrderType) => {
    const pushToQuery = {
      [GET_PARAMS.page]: GET_ENUMS.page.cart,
    };
    const query = {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
      type: type,
    };
    const newQuery = new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
      newQuery.set(key, value);
    });
    Object.entries(pushToQuery).forEach(([key, value]) => {
      const currentValue = newQuery.get(key);
      const splittedValue = currentValue ? currentValue.split(',') : [];
      splittedValue.push(value);

      newQuery.set(key, splittedValue.join(','));
    });

    return newQuery.toString() ? `?${newQuery.toString()}` : '';
  }, []);

  const getSchedule = ({
    address,
    deliveryZone,
    restaurant,
  }: {
    address: Address.ReceivingAddressV2 | null;
    deliveryZone: DeliveryZone | null;
    restaurant: GetRestaurantByIdResponseV1.Response | null;
  }) => {
    if (address && deliveryZone) {
      return {
        openAt: deliveryZone.openAt,
        closeAt: deliveryZone.closeAt,
      };
    }
    if (restaurant) {
      return {
        openAt: restaurant.openAt,
        closeAt: restaurant.closeAt,
      };
    }
    return null;
  };

  const disabledCreateOrder =
    dishes.some((dish) => Boolean(dish.problem?.level === 'ERROR')) ||
    constructors.some((constructor) =>
      Boolean(constructor.problem?.level === 'ERROR'),
    ) ||
    (Boolean(problems) &&
      problems
        .filter((problem) => {
          const reason = problem.reason;
          return (
            reason !== 'NO_CUSTOMER' &&
            reason !== 'DELIVERY_CAN_BE_LONGER' &&
            reason !== 'DELIVERY_CAN_BE_CHEAPER' &&
            reason !== 'DELIVERY_CAN_BE_FREE'
          );
        })
        .some((problem) => problem.level === 'ERROR')) ||
    isValidating;

  const productsQuantity = useMemo(
    () =>
      dishes.reduce((prev, dish) => prev + dish.product.quantity, 0) +
      constructors.reduce(
        (prev, constructor) => prev + constructor.product.quantity,
        0,
      ),
    [constructors, dishes],
  );

  const categoriesObj = useMemo(
    () =>
      Object.fromEntries(categories.map((category) => [category.id, category])),
    [categories],
  );

  return (
    <div className={style.cart}>
      <div className={style.cartContent}>
        {isDesktop ? (
          <div className={style.header}>
            <h3 className='title'>{title}</h3>
          </div>
        ) : (
          <div className={style.header}>
            <h3 className='title'>{title}</h3>

            <button
              tabIndex={0}
              className='closeButton-mobile'
              onClick={(e) => {
                onClose();
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        )}
        {warning && (
          <div className={style.warningContainer}>
            <div className={style.warningContent}>
              <p className={clsx('subhead', style.warningMessage)}>{warning}</p>
            </div>
          </div>
        )}
        <div className={style.pickers}>
          <div className={style.nodeContainer}>
            <FESegmentedControl
              items={{
                COURIER: t('cart.main.segmentedControl.delivery'),
                GO_TO_PLACE: t('cart.main.segmentedControl.pickup'),
              }}
              onChange={(v) => {
                if (v !== type) {
                  const link = getCartographyTypeLink(v);
                  navigate(link);
                }
              }}
              value={type as Exclude<OrderType, 'IN_PLACE'>}
            />
          </div>
          <div className={style.nodeContainer}>
            <FEOpeningBar
              image={IMG_IC_24_MARKER}
              label={
                type === 'COURIER'
                  ? t('cart.main.cartographyBar.deliveryLabel')
                  : t('cart.main.cartographyBar.pickupLabel')
              }
              onClick={() => navigate(cartographyLink)}
              value={
                type === 'COURIER'
                  ? address?.address || ''
                  : restaurant?.name || ''
              }
            />
          </div>
          <div className={style.nodeContainer}>
            <FEDeliveryTimePicker
              language={language}
              schedule={getSchedule({ address, deliveryZone, restaurant })}
              urgent={urgent}
              timeIntervals={
                timeIntervals?.timeVariants.map((timeInterval) =>
                  FEDateUtils.addUtcOffset(moment(timeInterval)).format(
                    'YYYY-MM-DDTHH:mm:ss.SSS',
                  ),
                ) || []
              }
              deliveryTimeError={timeIntervals?.deliveryTimeErrorInMinutes || 0}
              timeTo={
                timeTo
                  ? FEDateUtils.addUtcOffset(moment(timeTo)).format(
                      'YYYY-MM-DDTHH:mm:ss.SSS',
                    )
                  : null
              }
              onChange={({ timeTo, urgent }) =>
                onChangeTime({
                  timeTo: timeTo
                    ? FEDateUtils.substractUtcOffset(moment(timeTo)).format(
                        'YYYY-MM-DDTHH:mm:ss.SSS',
                      )
                    : null,
                  urgent,
                })
              }
            />
          </div>
          {longDeliveryWarning && (
            <div className={style.nodeContainer}>
              <div className={style.longDeliveryWarningContainer}>
                <p className='text-1'>{longDeliveryWarning}</p>
              </div>
            </div>
          )}
        </div>
        <div className={style.mobileDividerContainer}>
          <div className={style.mobileDivider} />
        </div>
        <div className={style.clearContainer}>
          <div className={style.clearContent}>
            <p className='headline'>
              {t('cart.main.clearCart.message', {
                quantity: productsQuantity,
                price: Math.trunc(price?.total || 0),
                currency,
              })}
            </p>
            <FEButton
              className={style.clearButton}
              size='medium'
              type='secondary'
              onClick={() => {
                FELocalStorageUtil.clearCartData();
                onClearCart();
              }}
            >
              {t('common.clear')}
            </FEButton>
          </div>
        </div>
        <div className={style.productsList}>
          <>
            {dishes.map((dish, index) => (
              <Product
                currency={currency}
                key={dish.orderItemId}
                product={dish}
                type='DISH'
                onChangeQuantity={(val) =>
                  onChangeDishQuantity({ index, quantity: val })
                }
                onDelete={() => {
                  onDeleteDish({ index });

                  //яндекс метрика
                  //@ts-ignore
                  window.dataLayer.push({ ecommerce: null });
                  //@ts-ignore
                  window.dataLayer.push({
                    event: 'remove_from_cart',
                    ecommerce: {
                      items: [
                        {
                          item_name: dish.product.product.name,
                          item_id: dish.product.product.id,
                          price: dish.product.price.total ?? 0,
                          item_category:
                            categoriesObj[dish.product.product.categoryId].name,
                          quantity: 1,
                        },
                      ],
                    },
                  });
                }}
                isValidating={isValidating}
              />
            ))}
            {constructors.map((constructor, index) => (
              <Product
                currency={currency}
                key={constructor.orderItemId}
                product={constructor}
                type='CONSTRUCTOR'
                onChangeQuantity={(val) =>
                  onChangeConstructorQuantity({ index, quantity: val })
                }
                onDelete={() => {
                  onDeleteConstructor({ index });

                  //яндекс метрика
                  //@ts-ignore
                  window.dataLayer.push({ ecommerce: null });
                  //@ts-ignore
                  window.dataLayer.push({
                    event: 'remove_from_cart',
                    ecommerce: {
                      items: [
                        {
                          item_name: constructor.product.product.name,
                          item_id: constructor.product.product.id,
                          price: constructor.product.price.total ?? 0,
                          item_category:
                            categoriesObj[
                              constructor.product.product.categoryId
                            ].name,
                          quantity: 1,
                        },
                      ],
                    },
                  });
                }}
                isValidating={isValidating}
              />
            ))}
            {cutleries.map((cutlery, index) => (
              <Cutlery
                currency={currency}
                isValidating={isValidating}
                cutlery={cutlery}
                onDelete={() => onDeleteCutlery({ index })}
                onChangeQuantity={(val) =>
                  onChangeCutleryQuantity({ index, quantity: val })
                }
                key={cutlery.orderItemId}
              />
            ))}
            {promotion && (
              <Promotion
                promotion={promotion}
                promotionProduct={promotionProduct}
                onPickProduct={(product) =>
                  onChangePromotionProduct({ promotionProduct: product })
                }
              />
            )}
            {Boolean(price?.delivery) && (
              <Delivery currency={currency} price={price!.delivery} />
            )}
          </>
        </div>
        <div className={clsx(style.nodeContainer, style.promocodeContainer)}>
          <FEPromocode
            promoCode={promoCode}
            promotion={promotion}
            isPending={isValidating}
            onClear={() => onChangePromoCode(null)}
            onSubmit={(promocode) => onChangePromoCode(promocode)}
          />
        </div>
        <div className={style.submit}>
          <FEButton
            onClick={() => onChangeStage('ORDERING')}
            showLoader={isValidating}
            disabled={disabledCreateOrder || hasCriticalCartError}
          >
            <div className={style.submitContent}>
              <p className='headline'>{t('cart.main.submit')}</p>
              {price && (
                <p className='headline'>
                  {Boolean(price.discount) && (
                    <s
                      className={clsx('headline', style.fullPrice)}
                    >{`${price.cart} ${currency} `}</s>
                  )}
                  {` ${Math.trunc(price.total)} ${currency}`}
                </p>
              )}
            </div>
          </FEButton>
        </div>
      </div>
    </div>
  );
};

export default Cart;
