import React from 'react';
import FEBirthdayPickerProps from '../props';
import moment from 'moment';
import { SwiperProps } from 'swiper/react';
import 'moment/locale/ru';
import { useTranslation } from 'react-i18next';
import { Language } from 'models';

export const swiperDefaults: Partial<SwiperProps> = {
  slidesPerView: 5,
  freeMode: true,
  direction: 'vertical',
  slideToClickedSlide: true,
  centeredSlides: true,
  initialSlide: 0,
};

const getInitials = (language: Language) => {
  let years: string[] = [];
  let months: string[] = [];
  let days: string[] = [];
  for (let i = 0; i <= 100; i++) {
    years = [...years, moment().subtract(i, 'year').format('YYYY')];
  }
  for (let i = 0; i <= moment().month(); i++) {
    months = [
      ...months,
      moment().set('month', i).locale(language).format('MMMM'),
    ];
  }
  for (let i = 1; i <= moment().date(); i++) {
    days = [...days, moment().date(i).format('DD')];
  }
  years.reverse();

  return {
    years,
    months,
    days,
  };
};

const getInitialsWithValue = (val: string, language: Language) => {
  let years: string[] = [];
  let months: string[] = [];
  let days: string[] = [];
  const currentDate = moment(val, 'YYYY-MM-DD');
  const currentYear = currentDate.format('YYYY');
  const currentMonth = currentDate.format('MMMM');
  const isCurrentYear = currentYear === moment().format('YYYY');
  const isCurrentMonth = currentMonth === moment().format('MMMM');
  for (let i = 0; i <= 100; i++) {
    years = [...years, moment().subtract(i, 'year').format('YYYY')];
  }
  for (let i = 0; i <= (isCurrentYear ? moment().month() : 11); i++) {
    months = [
      ...months,
      moment().set('month', i).locale(language).format('MMMM'),
    ];
  }
  for (
    let i = 1;
    i <=
    (isCurrentYear && isCurrentMonth
      ? moment().date()
      : currentDate.daysInMonth());
    i++
  ) {
    days = [...days, moment().date(i).format('DD')];
  }
  years.reverse();

  return {
    years,
    months,
    days,
    yearIndex: years.findIndex((year) => year === currentYear),
    monthIndex: months.findIndex(
      (month) => month === currentDate.locale(language).format('MMMM'),
    ),
    dayIndex: days.findIndex(
      (day) => day === currentDate.locale(language).format('DD'),
    ),
  };
};

export const useBirthdayPicker = ({
  onChange,
  value,
  language = 'ru',
}: FEBirthdayPickerProps) => {
  const { t } = useTranslation();
  const [displayedValue, setDisplayedValue] = React.useState<string>(
    t('editProfile.form.birthday.placeholder'),
  );
  const [years, setYears] = React.useState<string[]>([]);
  const [months, setMonths] = React.useState<string[]>([]);
  const [days, setDays] = React.useState<string[]>([]);
  const [initialYearIndex, setInitialYearIndex] = React.useState<number>(0);
  const [initialMonthIndex, setInitialMonthIndex] = React.useState<number>(0);
  const [initialDayIndex, setInitialDayIndex] = React.useState<number>(0);
  const [currentYear, setCurrentYear] = React.useState<string>();
  const [currentMonth, setCurrentMonth] = React.useState<string>();
  const [currentDay, setCurrentDay] = React.useState<string>();
  const changeYear = (index: number) => {
    const year = years[index];
    let months: string[] = [];
    const isCurrentYear = year === moment().format('YYYY');
    for (let i = 0; i <= (isCurrentYear ? moment().month() : 11); i++) {
      months = [
        ...months,
        moment().set('month', i).locale(language).format('MMMM'),
      ];
    }
    setCurrentYear(year);
    setMonths(months);
  };
  const changeMonth = (index: number) => {
    const month = months[index];
    const isCurrentYear = currentYear === moment().format('YYYY');
    const isCurrentMonth = month === moment().format('MMMM');
    const daysInMonth =
      isCurrentYear && isCurrentMonth
        ? moment().date()
        : moment(`${currentYear}-${month}`, 'YYYY-MMMM').daysInMonth();
    let days: string[] = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days = [...days, moment().date(i).format('DD')];
    }
    setCurrentMonth(month);
    setDays(days);
  };
  const changeDay = (index: number) => setCurrentDay(days[index]);
  React.useLayoutEffect(() => {
    if (value) {
      const { years, months, days, yearIndex, monthIndex, dayIndex } =
        getInitialsWithValue(value, language);
      setYears(years);
      setMonths(months);
      setDays(days);
      setInitialYearIndex(yearIndex);
      setInitialMonthIndex(monthIndex);
      setInitialDayIndex(dayIndex);
      setDisplayedValue(
        moment(
          `${years[yearIndex]}-${months[monthIndex]}-${days[dayIndex]}`,
          'YYYY-MMMM-DD',
        ).format('DD.MM.YYYY'),
      );
      setCurrentYear(years[yearIndex]);
      setCurrentMonth(months[monthIndex]);
      setCurrentDay(days[dayIndex]);
    } else {
      const { years, months, days } = getInitials(language);
      setYears(years);
      setMonths(months);
      setDays(days);
      setInitialYearIndex(0);
      setInitialMonthIndex(0);
      setInitialDayIndex(0);
      setCurrentYear(years[0]);
      setCurrentMonth(months[0]);
      setCurrentDay(days[0]);
    }
  }, [value, language]);
  const submit = () => {
    if (currentYear && currentMonth && currentDay) {
      const date = moment(
        `${currentYear}-${currentMonth}-${currentDay}`,
        'YYYY-MMMM-DD',
      );
      onChange(date.format('YYYY-MM-DD'));
      if (value) {
        setDisplayedValue(moment(value).format('DD.MM.YYYY'));
      } else setDisplayedValue(date.format('DD.MM.YYYY'));
    }
  };
  return {
    years,
    months,
    days,
    initialYearIndex,
    initialMonthIndex,
    initialDayIndex,
    changeYear,
    changeMonth,
    changeDay,
    submit,
    displayedValue,
  };
};
