import { Restaurant as RestaurantModel } from 'domains/cartography';
import { FEButton } from 'components';
import style from './style.module.css';
import { getRestaurantStatus } from '../../../../../../model';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  restaurant: RestaurantModel;
  distanceTo: string | number | null;
};

const Restaurant = ({ restaurant, onClose, distanceTo }: Props) => {
  const { t } = useTranslation();
  const { openAt, closeAt, name, status, statusInfo, address } = restaurant;

  const { title, disabled } = getRestaurantStatus(
    status,
    statusInfo,
    openAt,
    closeAt,
  );

  return (
    <div className={style.root}>
      <div className={style.content}>
        <p className={clsx('headline', style.name)}>{name}</p>
        <div className={style.header}>
          <div className={style.headerContent}>
            <div className={style.info}>
              <p
                className={clsx(
                  'headline',
                  !disabled ? style.worktime : style.worktime__Closed,
                )}
              >
                {title}
              </p>
              {distanceTo && (
                <p className={clsx('text-1', style.distanceTo)}>{distanceTo}</p>
              )}
            </div>
            <p className={clsx('text-2', style.address)}>{address.address}</p>
          </div>
        </div>
        <div className={style.schedule}>
          <p className={clsx('headline', style.scheduleTitle)}>
            {t('cartography.restaurant.restaurantCard.scheduleLabel')}
          </p>
          <div className={style.scheduleItem}>
            <p>{t('cartography.restaurant.restaurantCard.restaurantLabel')}</p>
            <p>
              {t('restaurants.restaurant.dailyScheduleLabel', {
                from: openAt,
                to: closeAt,
              })}
            </p>
          </div>
          <div className={style.scheduleItem}>
            <p>{t('restaurants.restaurant.takingOrdersLabel')}</p>
            <p>
              {t('restaurants.restaurant.dailyScheduleLabel', {
                from: openAt,
                to: closeAt,
              })}
            </p>
          </div>
        </div>
      </div>
      <div className={style.close}>
        <FEButton
          onClick={onClose}
          type='secondary'
          children={t(
            'cartography.restaurant.restaurantCard.buttonsLabel.backToTheList',
          )}
        />
      </div>
    </div>
  );
};

export default Restaurant;
