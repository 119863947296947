import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { ProductsSearchRequestV2 } from '.';
import { ProductsSearchResponseV2 } from '.';

const productsRepositoryV2 = {
  search: (request: ProductsSearchRequestV2, signal?: AbortSignal) =>
    new Promise<ProductsSearchResponseV2.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v2/products/search`;
        let result: AxiosResponse<
          BaseAPIResponse<ProductsSearchResponseV2.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<ProductsSearchResponseV2.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default productsRepositoryV2;
