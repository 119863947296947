import React, {useCallback, useState} from 'react';
import { GET_MEASURE } from 'const';
import { FEDialog, FETitledBottomSheet } from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import { DesktopContent, MobileContent } from './components';
import {
  ConstructorBase,
  ConstructorCurrentAddonGroup,
  ConstructorSelectorGroup,
} from '../../model';
import { ConstructorCardProps } from '../../props';
import { ConstructorToCartModel } from 'domains/cart';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';
import { CatalogCategory } from 'domains/catalog';

const Constructor = ({
  product,
  validationPayload,
  onAddConstructorToCart,
  stopLists,
  open,
  onClose,
  currency,
  categories,
}: Omit<ConstructorCardProps, 'className'> & {
  open: boolean;
  onClose: () => void;
  currency: CurrencySymbol;
  categories: CatalogCategory[];
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const { name, baseTitle, bases, defaultBaseId, description } = product;

  const [final, setFinal] = React.useState<boolean>(false);

  const [quantity, setQuantity] = React.useState<number>(1);

  const [addonGroupModsSum, setAddonGroupModsSum] = useState()

  const [base, setBase] = React.useState<ConstructorBase>();
  const [selectorGroups, setSelectorGroups] = React.useState<
    ConstructorSelectorGroup[]
  >([]);
  const [addonGroups, setAddonGroups] = React.useState<
    ConstructorCurrentAddonGroup[]
  >([]);
  const filteredBases = React.useMemo(
    () =>
      stopLists
        ? bases.filter((base) => !Boolean(stopLists.bases.includes(base.id)))
        : bases,
    [bases, stopLists],
  );
  React.useEffect(() => {
    const defaultBase = filteredBases.find(
      (filteredBase) => filteredBase.id === defaultBaseId,
    );
    if (defaultBase) setBase(defaultBase);
    else setBase(filteredBases[0]);
  }, [defaultBaseId, filteredBases]);

  const filteredBaseSelectorGroups = React.useMemo(() => {
    if (base) {
      if (stopLists)
        return base.selectorGroups
          .filter(
            (selectorGroup) =>
              !Boolean(stopLists.modifierGroups.includes(selectorGroup.id)),
          )
          .map((selectorGroup) => ({
            ...selectorGroup,
            modifiers: selectorGroup.modifiers.filter(
              (modifier) => !Boolean(stopLists.modifiers.includes(modifier.id)),
            ),
          }));
      return base.selectorGroups;
    } else return [];
  }, [base, stopLists]);

  React.useEffect(
    () =>
      setSelectorGroups(
        filteredBaseSelectorGroups.map((filteredSelectorGroup) => ({
          ...filteredSelectorGroup,
          modifiers: [],
        })),
      ),
    [filteredBaseSelectorGroups],
  );

  const filteredBaseAddonGroups = React.useMemo(() => {
    if (base) {
      if (stopLists)
        return base.addonGroups
          .filter(
            (addonGroup) =>
              !Boolean(stopLists.modifierGroups.includes(addonGroup.id)),
          )
          .map((addonGroup) => ({
            ...addonGroup,
            modifiers: addonGroup.modifiers.filter(
              (modifier) => !Boolean(stopLists.modifiers.includes(modifier.id)),
            ),
          }));
      return base.addonGroups;
    } else return [];
  }, [base, stopLists]);

  React.useEffect(
    () =>
      setAddonGroups(
        filteredBaseAddonGroups.map((filteredAddonGroup) => ({
          ...filteredAddonGroup,
          modifiers: [],
        })),
      ),
    [filteredBaseAddonGroups],
  );

  const baseSize = React.useMemo(() => {
    if (base) {
      const size = base.size;

      if (quantity && size)
        return `${quantity} ${t(GET_MEASURE['PIECE'])} / ${size.value} ${t(
          GET_MEASURE[size.measure],
        )}`;

      if (quantity) return `${quantity} ${t(GET_MEASURE['PIECE'])}`;

      if (size) return `${size.value} ${t(GET_MEASURE[size.measure])}`;
    }
  }, [base, quantity, t]);

  const price = React.useMemo(() => {
    if (base) {
      const selectorPrice = selectorGroups.reduce(
        (accumulator, group) =>
          accumulator +
          group.modifiers.reduce(
            (accumulator, modifier) => accumulator + modifier.price,
            0,
          ),
        0,
      );
      const addonPrice = addonGroups.reduce(
        (accumulator, group) =>
          accumulator +
          group.modifiers.reduce(
            (accumulator, modifier) =>
              accumulator + modifier.price * modifier.quantity,
            0,
          ),
        0,
      );
      const price = (base.price + selectorPrice + addonPrice) * quantity;
      return price;
    } else return 0;
  }, [base, addonGroups, selectorGroups, quantity]);

  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  const [activeTabId, setActiveTabId] = React.useState<number>(0);
  const [tabList, setTabList] = React.useState<
    { title: string; disabled: boolean; id: number; type: "ADDON" | "SELECTOR" | "BASE";  originalId: string; }[]
  >([]);

    const sortTabs = () => {
      //@ts-expect-error
        let resArr: { title: string; disabled: boolean; id: number; type: "ADDON" | "SELECTOR" | "BASE";  originalId: string; }[] = [

            ...filteredBaseAddonGroups.map((addonGroup, i) => ({
                title: addonGroup.info.name,
                disabled: true,
                id: i,
                originalId: addonGroup.id,
                type: "ADDON"
            })),
          ...filteredBaseSelectorGroups.map((selectorGroup, i) => ({
            title: selectorGroup.info.name,
            disabled: true,
            id: i,
            originalId: selectorGroup.id,
            type: "SELECTOR"
          })),
        ]
        let order:any = {};

        base?.groupSort.forEach(function (a, i) { order[a] = i; });

        resArr.sort(function (a:any, b:any) {
            return order[a.originalId] - order[b.originalId];
        });

        return resArr
    }

  React.useEffect(() => {

    const sorted = sortTabs()

    setTabList([
      {
        title: baseTitle,
        disabled: false,
        id: 0,
        type: "BASE",
        originalId: base?.id || '0'
      },
      ...sorted
    ]);

  }, [baseTitle, filteredBaseSelectorGroups, filteredBaseAddonGroups]);

  const onResetConstructor = useCallback(() => {
    setActiveTabIndex(0);
    setActiveTabId(0);

    const defaultBase = filteredBases.find(
      (filteredBase) => filteredBase.id === defaultBaseId,
    );
    if (defaultBase) setBase(defaultBase);
    else setBase(filteredBases[0]);

    setTabList([
      {
        title: baseTitle,
        disabled: false,
        id: 0,
        type: "BASE",
        originalId: base?.id || '0'

      },
      ...sortTabs()
    ]);

    setAddonGroups(
      filteredBaseAddonGroups.map((filteredAddonGroup) => ({
        ...filteredAddonGroup,
        modifiers: [],
      })),
    );

    setSelectorGroups(
      filteredBaseSelectorGroups.map((filteredSelectorGroup) => ({
        ...filteredSelectorGroup,
        modifiers: [],
      })),
    );
  }, [
    baseTitle,
    defaultBaseId,
    filteredBaseAddonGroups,
    filteredBaseSelectorGroups,
    filteredBases,
  ]);

  const addToCart = () => {
    const constructorToCart: ConstructorToCartModel = {
      name: product.name,
      categoryId: product.categoryId,
      price: base?.price ?? 0,
      quantity: quantity,
      productId: product.id,
      base: {
        id: base!.id,
        addonGroups: addonGroups
          .filter((addonGroup) => Boolean(addonGroup.modifiers.length))
          .map((addonGroup) => ({
            id: addonGroup.id,
            modifiers: addonGroup.modifiers.map((modifier) => ({
              id: modifier.id,
              quantity: modifier.quantity,
            })),
          })),
        selectorGroups: selectorGroups.map((selectorGroup) => ({
          id: selectorGroup.id,
          modifiers: selectorGroup.modifiers.map((modifier) => ({
            id: modifier.id,
          })),
        })),
      },
    };

    onAddConstructorToCart({
      //@ts-expect-error
      constructor: constructorToCart,
      validationPayload,
      categories,
    });
  };

  const onkeydown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
        onResetConstructor();
      }
    },
    [onClose, onResetConstructor],
  );

  React.useEffect(() => {
    if (open && isDesktop) {
      window.addEventListener('keydown', onkeydown);
    }
    return () => {
      window.removeEventListener('keydown', onkeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isDesktop]);

  return (
    <>
      {isDesktop ? (
        <FEDialog
          open={open}
          onClose={() => onClose()}
          render={({ onClose }) => (
            <DesktopContent
              {...{
                currency,
                onClose,
                quantity,
                final,
                changeFinal: (v) => setFinal(v),
                changeQuantity: setQuantity,
                baseAddonGroups: filteredBaseAddonGroups,
                addonGroups,
                changeAddonGroups: setAddonGroups,
                baseSelectorGroups: filteredBaseSelectorGroups,
                selectorGroups,
                changeSelectorGroups: setSelectorGroups,
                bases,
                base,
                changeBase: setBase,
                baseSize,
                baseTitle,
                price,
                name,
                activeTabIndex,
                description,
                changeActiveTabIndex: (index) => {
                  setActiveTabIndex(index);
                  const newTabList = [...tabList].map((tab, i) => {
                    if (i === index)
                      return {
                        ...tab,
                        disabled: false,
                      };
                    return tab;
                  });
                  setTabList(newTabList);
                },
                activeTabId,
                changeActiveTabId: setActiveTabId,
                tabList,
                addToCart,
                onResetConstructor,
              }}
            />
          )}
        />
      ) : (
        <FETitledBottomSheet
          title={name}
          open={open}
          onClose={() => onClose()}
          render={({ onClose }) => (
            <MobileContent
              {...{
                currency,
                onClose,
                quantity,
                final,
                changeFinal: (v) => setFinal(v),
                changeQuantity: setQuantity,
                baseAddonGroups: filteredBaseAddonGroups,
                addonGroups,
                changeAddonGroups: setAddonGroups,
                baseSelectorGroups: filteredBaseSelectorGroups,
                selectorGroups,
                changeSelectorGroups: setSelectorGroups,
                bases,
                description,
                base,
                changeBase: setBase,
                baseSize,
                baseTitle,
                price,
                name,
                activeTabIndex,
                changeActiveTabIndex: (index) => {
                  setActiveTabIndex(index);
                  const newTabList = [...tabList].map((tab, i) => {
                    if (i === index)
                      return {
                        ...tab,
                        disabled: false,
                      };
                    return tab;
                  });
                  setTabList(newTabList);
                },
                activeTabId,
                changeActiveTabId: setActiveTabId,
                tabList,
                addToCart,
                onResetConstructor,
              }}
            />
          )}
        />
      )}
    </>
  );
};

export default Constructor;
