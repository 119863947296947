import clsx from 'clsx';
import React from 'react';
import { FERadioButtonProps } from './props';
import style from './style.module.css';

const FERadioButton = ({ active }: FERadioButtonProps) => {
  return (
    <div className={style.root}>
      <div className={clsx(style.radio, active && style.radio__active)}>
        <div className={clsx(style.dot, active && style.dot__active)} />
      </div>
    </div>
  );
};

export default FERadioButton;
export type { FERadioButtonProps };
