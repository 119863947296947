import React from 'react';
import { FEDialog } from '..';
import FETitledDialogProps from './props';
import style from './style.module.css';

const FETitledDialog = ({
  open,
  onClose,
  closeOnOverlayClick,
  showCloseButton,
  title,
  render,
  onArrowBackClick,
  closeOnArrowBackClick = false,
}: FETitledDialogProps) => {
  return (
    <FEDialog
      {...{ open, onClose, closeOnOverlayClick, showCloseButton }}
      render={({ onClose, onCloseModal }) => (
        <>
          <div className={style.header}>
            {(onArrowBackClick || closeOnArrowBackClick) && (
              <button
                tabIndex={0}
                className='button-arrowBack'
                onClick={(e) => {
                  closeOnArrowBackClick && onClose();
                  onArrowBackClick && onArrowBackClick();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
            <h3 className='title'>{title}</h3>
          </div>
          {render({ onClose: () => onClose && onClose(), onCloseModal })}
        </>
      )}
    />
  );
};

export default FETitledDialog;
export type { FETitledDialogProps };
