import { LayoutProps } from './props';
import style from './style.module.css';
import { FEOpeningBar, FESegmentedControl, FESpinner } from 'components';
import { AddressLayout, RestaurantLayout } from '..';
import { OrderType } from 'models';
import { FECityPicker, FECountryPicker } from 'ui-kit/layouts';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Layout = ({
  country,
  onCloseModal,
  city,
  userGeolocation,
  onClose,
  onOpenCountryLayout,
  onOpenCityLayout,
  onCloseCountryLayout,
  onCloseCityLayout,
  isOpenCityLayout,
  isOpenCountryLayout,
  countriesList,
  citiesList,
  onChangeCity,
  onChangeCountry,
  type,
  onChangeType,
  isPendingCities,
  isPendingCountries,
  fromCart,
}: LayoutProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  return (
    <>
      <div
        className={style.root}
        hidden={isOpenCityLayout || isOpenCountryLayout}
      >
        {isPendingCities || isPendingCountries ? (
          <div className={style.cartographyLoader}>
            <FESpinner size='large' />
          </div>
        ) : (
          <>
            <div className={style.segmentedControl}>
              <FESegmentedControl<Exclude<OrderType, 'IN_PLACE'>>
                items={{
                  COURIER: t('cartography.segmentedControl.delivery'),
                  GO_TO_PLACE: t('cartography.segmentedControl.pickup'),
                }}
                value={type as Exclude<OrderType, 'IN_PLACE'>}
                onChange={onChangeType}
              />
            </div>
            <div className={style.city}>
              <FEOpeningBar
                onClick={() => onOpenCountryLayout()}
                label={t('cartography.country.bar.label')}
                value={
                  country?.name || t('cartography.country.bar.placeholder')
                }
              />
            </div>
            <div className={style.city}>
              <FEOpeningBar
                onClick={() => onOpenCityLayout()}
                label={t('cartography.city.bar.label')}
                value={city?.name || t('cartography.city.bar.placeholder')}
              />
            </div>
            {type === 'COURIER' ? (
              <>
                <AddressLayout
                  fromCart={fromCart}
                  onCloseModal={onCloseModal}
                  onClose={onClose}
                />
              </>
            ) : (
              <>
                <RestaurantLayout
                  fromCart={fromCart}
                  onCloseModal={onCloseModal}
                  onClose={onClose}
                  currentCity={city}
                  userGeolocation={userGeolocation}
                  changeType={onChangeType}
                />
              </>
            )}
          </>
        )}
      </div>
      <FECountryPicker
        onChangeCountry={onChangeCountry}
        isPendingCounties={isPendingCountries}
        closable={!!country}
        counties={countriesList}
        currentCountry={country}
        onClose={onCloseCountryLayout}
        open={isOpenCountryLayout}
      />
      <FECityPicker
        onGoBack={onOpenCountryLayout}
        separate={
          isOpenCityLayout
            ? new URLSearchParams(search).get('separate') !== 'false'
            : null
        }
        isPendingData={isPendingCities}
        closable={!!city}
        open={isOpenCityLayout}
        currentCity={city}
        data={citiesList}
        onClose={!!city ? onCloseCityLayout : undefined}
        onPickCity={onChangeCity}
      />
    </>
  );
};

export default Layout;
