import { Hash, Path, useLocation } from 'react-router-dom';

export type usePrepareLinkProps = {
  query?: {
    [key: string]: string;
  };
  pushToQuery?: {
    [key: string]: string;
  };
  keepOldQuery?: boolean;
  to?: string;
  hash?: Hash;
};

const usePrepareLink = ({
  to,
  query = {},
  hash,
  pushToQuery = {},
  keepOldQuery = false,
}: usePrepareLinkProps): Partial<Path> => {
  const location = useLocation();

  const pathname = to || location.pathname;

  const newQuery = keepOldQuery
    ? new URLSearchParams(location.search)
    : new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    newQuery.set(key, value);
  });

  Object.entries(pushToQuery).forEach(([key, value]) => {
    const currentValue = newQuery.get(key);
    const splittedValue = currentValue ? currentValue.split(',') : [];
    splittedValue.push(value);

    //@ts-ignore
    newQuery.set(key, splittedValue);
  });

  return {
    pathname: pathname.replace(/\/\//g, '/'),
    search: newQuery.toString() ? `?${newQuery.toString()}` : '',
    hash,
  };
};

export default usePrepareLink;
