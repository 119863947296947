import { MOBILE_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import { FETitledBottomSheet } from '../..';
import { FETitledDialog } from '../..';
import Picker from './Picker';

const Container = ({
  open,
  title,
  days,
  timeIntervals,
  initialDayIndex,
  initialTimeIntervalIndex,
  changeDay,
  changeTimeInterval,
  submit,
  onClose,
}: {
  open: boolean;
  title: string;
  days: string[];
  timeIntervals: string[];
  initialDayIndex: number;
  initialTimeIntervalIndex: number;
  changeDay: (index: number) => void;
  changeTimeInterval: (index: number) => void;
  submit: () => void;
  onClose: () => void;
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  return isDesktop ? (
    <FETitledDialog
      title={title}
      open={open}
      onClose={onClose}
      render={({ onClose }) => (
        <Picker
          {...{
            days,
            timeIntervals,
            initialDayIndex,
            initialTimeIntervalIndex,
            changeDay,
            changeTimeInterval,
            submit,
            onClose,
          }}
        />
      )}
    />
  ) : (
    <FETitledBottomSheet
      open={open}
      title={title}
      onClose={onClose}
      render={({ onClose }) => (
        <Picker
          {...{
            days,
            timeIntervals,
            initialDayIndex,
            initialTimeIntervalIndex,
            changeDay,
            changeTimeInterval,
            submit,
            onClose,
          }}
        />
      )}
    />
  );
};

export default Container;
