import { AxiosResponse } from 'axios';
import {
  GetLegalsDocumentsRequestV1,
  GetLegalsDocumentsResponseV1,
} from './model';
import { FERequestErrorsHandler } from 'utils';
import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { BASE_URL } from 'const';

const legalsDocumentsRepositoryV1 = {
  getLegalsDocuments: ({ placeId }: GetLegalsDocumentsRequestV1) => {
    return new Promise<GetLegalsDocumentsResponseV1.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/${placeId}/legals/documents`;
          let result: AxiosResponse<
            BaseAPIResponse<GetLegalsDocumentsResponseV1.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetLegalsDocumentsResponseV1.Response>
          >(url);

          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    );
  },
};

export default legalsDocumentsRepositoryV1;
