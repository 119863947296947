import React from 'react';
import { useMount } from 'hooks';
import { Layout } from './components';

const Modal = ({
  open,
  onClose,
  value,
  onDeclineConfirmation,
}: {
  open: boolean;
  onClose: () => void;
  value: string | null;
  onDeclineConfirmation: () => void;
}) => {
  const { mounted } = useMount({ isOpened: open });

  if (!mounted) return null;
  return <Layout {...{ onClose, open, value, onDeclineConfirmation }} />;
};

export default Modal;
