import { MOBILE_WIDTH } from 'const';
import { PromotionModel } from 'domains/promotion';
import { memo } from 'react';
import { FEDialog, FEBottomSheet } from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import { Layout } from './layout';

const PromotionModal = memo(({ onClose, open, promotion }: Props) => {
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  return isDesktop ? (
    <FEDialog
      {...{ open, onClose }}
      render={({ onClose }) => (
        <Layout {...{ ...promotion, onClose, isDesktop }} />
      )}
    />
  ) : (
    <FEBottomSheet
      {...{ open, onClose }}
      render={({ onClose }) => (
        <Layout {...{ ...promotion, onClose, isDesktop }} />
      )}
    />
  );
});

type Props = {
  open: boolean;
  onClose: () => void;
  promotion: PromotionModel;
};

export default PromotionModal;
