import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetBannerByIdResponseV1, GetBannersByCatalogIdResponseV1 } from '.';

const bannersRepositoryV1 = {
  getBannersByCatalogId: ({ catalogId }: { catalogId: string }) =>
    new Promise<GetBannersByCatalogIdResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/banners/catalog/${catalogId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetBannersByCatalogIdResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetBannersByCatalogIdResponseV1.ListResponse>
          >(url);
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
  getBannerById: ({ id }: { id: string }) =>
    new Promise<GetBannerByIdResponseV1.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/banners/${id}'`;
        let result: AxiosResponse<
          BaseAPIResponse<GetBannerByIdResponseV1.Response>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetBannerByIdResponseV1.Response>
        >(url);
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default bannersRepositoryV1;
