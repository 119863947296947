import React from 'react';
import {
  FEAddressSearchField,
  FEBanner,
  FEButton,
  FESwitch,
  FETextField,
} from 'components';
import {
  $addressesList,
  $showPlaceholder,
  $currency,
  $deliveryZoneError,
  $isPendingAddress,
  $noDeliveryPlaceholder,
  $visibleDeliveryZone,
  addressForm,
} from 'domains/cartography';
import { fetchDeliveryZoneByGeolocationFx } from 'domains/cartography';
import { submitAddress } from 'domains/cartography';
import style from './style.module.css';
import { useField } from 'effector-forms';
import { useUnit } from 'effector-react';
import { useEvent } from 'hooks';
import { AddressLayoutProps } from './props';
import { DeliveryPriceConditions, OrderMinimum } from './components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const AddressLayout = ({
  onClose,
  onCloseModal,
  fromCart,
}: AddressLayoutProps) => {
  const { t } = useTranslation();
  const [showPriceConditions, setShowPriceConditions] = React.useState(false);
  const [showOrderMinimum, setShowOrderMinimum] = React.useState(false);
  const navigate = useNavigate();

  const currency = useUnit($currency);
  const onSubmitAddress = useUnit(submitAddress);

  const [addressesList, showPlaceholder, noDeliveryPlaceholder] = useUnit([
    $addressesList,
    $showPlaceholder,
    $noDeliveryPlaceholder,
  ]);

  const [deliveryZone, deliveryZoneError, fetchDeliveryZone] = useUnit([
    $visibleDeliveryZone,
    $deliveryZoneError,
    fetchDeliveryZoneByGeolocationFx,
  ]);

  const {
    value: address,
    onChange: onChangeAddress,
    set: setAddressValue,
  } = useField(addressForm.fields.address);
  const { value: apartment, onChange: onChangeApartemnt } = useField(
    addressForm.fields.apartment,
  );
  const { value: entrance, onChange: onChangeEntrance } = useField(
    addressForm.fields.entrance,
  );
  const { value: floor, onChange: onChangeFloor } = useField(
    addressForm.fields.floor,
  );
  const { value: housing, onChange: onChangeHousing } = useField(
    addressForm.fields.housing,
  );
  const { value: isPrivate, onChange: onChangeIsPrivate } = useField(
    addressForm.fields.isPrivate,
  );
  const { value: isFinal, onChange: onChangeIsFinal } = useField(
    addressForm.fields.isFinal,
  );
  const { value: finalAddress, onChange: onChangeFinalAddress } = useField(
    addressForm.fields.finalAddress,
  );

  const hasFinalError = React.useMemo(() => {
    if (finalAddress !== null) {
      return !finalAddress.final;
    } else {
      return !!address && !isFinal;
    }
  }, [isFinal, address, finalAddress]);

  const handleClickSubmit = useEvent(() => {
    if (finalAddress) {
      const { address, details, street, house, location, cityId } =
        finalAddress;
      onSubmitAddress({
        currentAddress: {
          address,
          street,
          house,
          cityId,
          location,
          details,
          apartment: isPrivate ? null : apartment,
          entrance: isPrivate ? null : entrance,
          housing: isPrivate ? null : housing,
          floor: isPrivate ? null : floor,
        },
      });
      onCloseModal();
      if (fromCart) navigate(-1);
      else navigate('/', { replace: true });
    }
  });

  const { conditions, maxDeliveryPrice, minDeliveryPrice, orderTotalMinimum } =
    React.useMemo(() => {
      const deliveryPriceConditions =
        deliveryZone?.conditions.deliveryPriceConditions;
      const conditions = deliveryPriceConditions?.map((condition) => ({
        label:
          condition.deliveryPrice === 0
            ? t(
                'cartography.address.deliveryPriceConditions.labels.orderFrom',
                { value: condition.orderTotalMinimum, currency },
              )
            : t('cartography.address.deliveryPriceConditions.labels.orderTo', {
                value: condition.orderTotalMinimum,
                currency,
              }),
        price:
          condition.deliveryPrice === 0
            ? t('cartography.address.deliveryPriceConditions.labels.free')
            : `${condition.deliveryPrice} ${currency}`,
      }));

      const minDeliveryPrice = deliveryPriceConditions
        ? Math.min(...deliveryPriceConditions.map((item) => item.deliveryPrice))
        : null;
      const maxDeliveryPrice = deliveryPriceConditions
        ? Math.max(...deliveryPriceConditions.map((item) => item.deliveryPrice))
        : null;
      const orderTotalMinimum = deliveryPriceConditions
        ? Math.min(
            ...deliveryPriceConditions?.map((item) => item.orderTotalMinimum),
          )
        : null;

      return {
        conditions,
        minDeliveryPrice,
        maxDeliveryPrice,
        orderTotalMinimum,
      };
    }, [deliveryZone, currency, t]);

  // const addressPriceConditions = React.useMemo(() => {
  //   if (deliveryZone) {
  //     const {
  //       deliveryPrice,
  //       orderTotalMinimum,
  //       maxDeliveryPriceNumber,
  //       minOrderPriceNumber,
  //       freeDeliveryOrderPriceNumber,
  //     } = getZoneConditions(deliveryZone);
  //     return {
  //       deliveryPrice,
  //       orderTotalMinimum,
  //       maxDeliveryPriceNumber,
  //       minOrderPriceNumber,
  //       freeDeliveryOrderPriceNumber,
  //     };
  //   } else return undefined;
  // }, [deliveryZone]);

  return (
    <>
      <div className={style.fields}>
        <FEAddressSearchField
          showPlaceholder={showPlaceholder}
          disabled={!!noDeliveryPlaceholder}
          hasError={hasFinalError}
          value={address}
          onChange={({ address, final }) => {
            onChangeAddress(address);
            onChangeIsFinal(final);
            if (!final) onChangeFinalAddress(null);
          }}
          data={addressesList}
          onChangeAddress={(address) => {
            onChangeFinalAddress(address);
            fetchDeliveryZone({
              geolocation: address.location,
              cityId: address.cityId,
            });
          }}
          onClear={() => {
            setAddressValue('');
            onChangeFinalAddress(null);
            onChangeApartemnt(null);
            onChangeEntrance(null);
            onChangeFloor(null);
            onChangeHousing(null);
          }}
          isPending={useUnit($isPendingAddress)}
        />
        {!noDeliveryPlaceholder && hasFinalError && (
          <FEBanner text={t('cartography.address.form.bannerMessage')} />
        )}
        {noDeliveryPlaceholder && <FEBanner text={t(noDeliveryPlaceholder)} />}
        {!noDeliveryPlaceholder && deliveryZoneError && isFinal && (
          <FEBanner text={deliveryZoneError} />
        )}
        {deliveryZone &&
          !!deliveryZone.conditions.deliveryPriceConditions.length &&
          finalAddress && (
            <div className={style.conditionsContainer}>
              <FEButton
                onClick={() => setShowPriceConditions(true)}
                type='secondary'
                size='medium'
              >
                {minDeliveryPrice === maxDeliveryPrice
                  ? t(
                      'cartography.address.deliveryPriceConditions.labels.buttonLabel2',
                      {
                        minPrice: minDeliveryPrice,
                        currency,
                      },
                    )
                  : t(
                      'cartography.address.deliveryPriceConditions.labels.buttonLabel',
                      {
                        minPrice: minDeliveryPrice,
                        maxPrice: maxDeliveryPrice,
                        currency,
                      },
                    )}
              </FEButton>
              <FEButton
                onClick={() => setShowOrderMinimum(true)}
                type='secondary'
                size='medium'
              >
                {t('cartography.address.orderMinimum.labels.buttonLabel', {
                  value: orderTotalMinimum,
                  currency,
                })}
              </FEButton>
              <FEButton onClick={() => {}} type='secondary' size='medium'>
                {t('cartography.address.receivingDelay.labels.buttonLabel', {
                  delay: deliveryZone.orderReceivingDelay,
                })}
              </FEButton>
            </div>
          )}
        <div className={style.homeType}>
          <p className='text-1'>
            {t('cartography.address.form.privateHouseLabel')}
          </p>
          <FESwitch value={isPrivate} onChange={onChangeIsPrivate} />
        </div>
        {!isPrivate && (
          <div className={style.homeValues}>
            <div className={style.homeValueContainer}>
              <FETextField
                disabled={!!noDeliveryPlaceholder}
                label={t('cartography.address.form.apartment')}
                hasError={!apartment && isFinal === true}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val) onChangeApartemnt(e.target.value);
                  else onChangeApartemnt(null);
                }}
                value={apartment}
              />
            </div>
            <div className={style.homeValueContainer}>
              <FETextField
                disabled={!!noDeliveryPlaceholder}
                label={t('cartography.address.form.entrance')}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val) onChangeEntrance(e.target.value);
                  else onChangeEntrance(null);
                }}
                value={entrance}
              />
            </div>
            <div className={style.homeValueContainer}>
              <FETextField
                disabled={!!noDeliveryPlaceholder}
                label={t('cartography.address.form.floor')}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val) onChangeFloor(e.target.value);
                  else onChangeFloor(null);
                }}
                value={floor}
              />
            </div>
            <div className={style.homeValueContainer}>
              <FETextField
                disabled={!!noDeliveryPlaceholder}
                label={t('cartography.address.form.housing')}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val) onChangeHousing(e.target.value);
                  else onChangeHousing(null);
                }}
                value={housing}
              />
            </div>
          </div>
        )}
      </div>
      <div className={style.submit}>
        <FEButton
          disabled={
            !isFinal ||
            (isPrivate ? false : !Boolean(apartment)) ||
            !!deliveryZoneError ||
            !!noDeliveryPlaceholder
          }
          onClick={handleClickSubmit}
        >
          {t('common.correct2')}
        </FEButton>
      </div>
      <OrderMinimum
        currency={currency}
        open={showOrderMinimum}
        onClose={() => setShowOrderMinimum(false)}
        price={orderTotalMinimum || 0}
      />
      <DeliveryPriceConditions
        open={showPriceConditions}
        onClose={() => setShowPriceConditions(false)}
        conditions={conditions}
      />
    </>
  );
};

export default AddressLayout;
