import { PromotionModel } from 'domains/promotion';
import { useTranslation } from 'react-i18next';
import style from './style.module.css';
import {
  IMG_IC_24_ARROW_BACK,
  IMG_IC_24_CLOSE,
  IMG_IC_24_TIME_DELIVERY,
  IMG_PLACEHOLDER_PERCENT,
} from 'images';
import clsx from 'clsx';
import { FEImageComponent } from 'ui-kit/components';
import moment from 'moment';

export const Layout = ({
  onClose,
  isDesktop,
  name,
  imageId,
  endAt,
  description,
}: PromotionModel & { onClose: () => void; isDesktop: boolean }) => {
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      {!isDesktop && (
        <div className={style.header}>
          {/*<button*/}
          {/*  className={clsx('button-icon-24', style.arrowButton)}*/}
          {/*  onClick={onClose}*/}
          {/*>*/}
          {/*  <img*/}
          {/*    className={style.buttonIcon}*/}
          {/*    src={IMG_IC_24_ARROW_BACK}*/}
          {/*    alt=''*/}
          {/*  />*/}
          {/*</button>*/}
          <p className='title'>{t('promotions.title')}</p>
          <button
            className={clsx('button-icon-24', style.closeButton)}
            onClick={onClose}
          >
            <img className={style.buttonIcon} src={IMG_IC_24_CLOSE} alt='' />
          </button>
        </div>
      )}
      {/* TODO: модифицировать плейсхолдер */}
      <FEImageComponent
        className={style.image}
        spinnerSize='medium'
        altImage={IMG_PLACEHOLDER_PERCENT}
        image={{
          imageId,
          needToFetch: true,
          fetchFromThirdPartyOrigin: false,
        }}
      />
      <div className={style.data}>
        <p className='title'>{name}</p>
        {!!endAt && (
          <div className={style.endAt}>
            <img src={IMG_IC_24_TIME_DELIVERY} alt='' />
            <p className='text-2'>
              {t('promotions.promotionDateTo', {
                to: moment(endAt).format('DD.MM.YYYY HH:mm'),
              })}
            </p>
          </div>
        )}
        <p className={clsx('text-2', style.description)}>{description}</p>
      </div>
    </div>
  );
};
