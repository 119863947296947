import React from 'react';
import FEBannerProps from './props';
import style from './style.module.css';

const FEBanner = ({ text }: FEBannerProps) => (
  <div className={style.root}>
    <p className='text-2'>{text}</p>
  </div>
);

export default FEBanner;
export type { FEBannerProps };
