import { useMount } from 'hooks';
import React, { useEffect } from 'react';
import FEPortal from '../FEPortal';
import Layout from './components/Layout';
import FENotificationCardProps from './props';

const FENotificationCard = (props: FENotificationCardProps) => {
  const { mounted } = useMount({ isOpened: props.open });

  useEffect(() => {
    if (mounted && props.onOpen) {
      props.onOpen();
    }
  }, [mounted, props]);

  if (!mounted) return null;

  return (
    <FEPortal>
      <Layout {...props} />
    </FEPortal>
  );
};

export default FENotificationCard;
export type { FENotificationCardProps };
