import { useState, useEffect, useMemo } from 'react';

import { ANIMATION_DELAY, GET_PARAMS } from 'const';
import { useGetParameter } from '.';

let timeout: any;

const parseStringifiedValue = (value: string | null) =>
  value ? value.split(',') : [];

const useGetModalState = (
  paramsIdentificator: keyof typeof GET_PARAMS = 'page',
) => {
  const modalsNames = useGetParameter(GET_PARAMS[paramsIdentificator]);
  const [mountedModals, setMountedModals] = useState(
    parseStringifiedValue(modalsNames),
  );

  useEffect(() => {
    if (modalsNames) {
      timeout && clearTimeout(timeout);
      setMountedModals(modalsNames.split(','));
    } else {
      timeout = setTimeout(() => {
        setMountedModals([]);
      }, ANIMATION_DELAY);
    }
  }, [modalsNames]);

  useEffect(() => {
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  const modals = useMemo(
    () => parseStringifiedValue(modalsNames),
    [modalsNames],
  );

  return {
    modals,
    mountedModals,
  };
};

export default useGetModalState;
