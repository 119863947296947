import { useMount } from 'hooks';
import React from 'react';
import FEPortal from '../FEPortal';
import Layout from './components/Layout';
import FEBottomSheetProps from './props';

const FEBottomSheet = (props: FEBottomSheetProps) => {
  const { mounted } = useMount({ isOpened: props.open });

  if (!mounted) return null;

  return (
    <FEPortal>
      <Layout {...props} />
    </FEPortal>
  );
};

export default FEBottomSheet;
export type { FEBottomSheetProps };
