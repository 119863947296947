import { ConstructorInCartModel, DishInCartModel } from 'domains/cart';
import { City, Country, Restaurant } from 'domains/cartography';
// import Cookies from 'js-cookie';
import { Address, OrderType } from 'models';

export type ProductsInCart = {
  dishes: DishInCartModel[];
  constructors: ConstructorInCartModel[];
};

export class FELocalStorageUtil {
  static get showCookies() {
    return !localStorage.getItem('cookiesPolicyAccepted');
  }
  static setShowCookies(show: boolean) {
    if (show) localStorage.removeItem('cookiesPolicyAccepted');
    else localStorage.setItem('cookiesPolicyAccepted', 'true');
  }

  static setCartography({
    type,
    address,
    restaurant,
    country,
    city,
  }: {
    type: OrderType;
    address: Address.ReceivingAddressV2 | null;
    restaurant: Restaurant | null;
    country: Country;
    city: City;
  }) {
    localStorage.setItem('type', type);

    const cookiesCity = JSON.stringify(city);
    localStorage.setItem('city', cookiesCity);

    const cookiesCountry = JSON.stringify(country);
    localStorage.setItem('country', cookiesCountry);

    if (address !== null) {
      localStorage.removeItem('restaurant');

      const cookiesAddress = JSON.stringify(address);
      localStorage.setItem('address', cookiesAddress);
    } else {
      localStorage.removeItem('address');

      if (restaurant !== null) {
        const cookiesRestaurant = JSON.stringify(restaurant);
        localStorage.setItem('restaurant', cookiesRestaurant);
      }
    }
  }

  static get getCartography(): {
    type: OrderType | null;
    address: Address.ReceivingAddressV2 | null;
    restaurant: Restaurant | null;
    country: Country | null;
    city: City | null;
  } {
    const cookiesAddress = localStorage.getItem('address');
    const cookiesRestaurant = localStorage.getItem('restaurant');
    const cookiesCity = localStorage.getItem('city');
    const cookiesCountry = localStorage.getItem('country');

    const type = (localStorage.getItem('type') as OrderType | null) || null;
    const address: Address.ReceivingAddressV2 | null = cookiesAddress
      ? JSON.parse(cookiesAddress)
      : null;
    const restaurant: Restaurant | null = cookiesRestaurant
      ? JSON.parse(cookiesRestaurant)
      : null;
    const city: City | null = cookiesCity ? JSON.parse(cookiesCity) : null;
    const country: Country | null = cookiesCountry
      ? JSON.parse(cookiesCountry)
      : null;

    return {
      city,
      country,
      type,
      address,
      restaurant,
    };
  }

  static deleteLastOrder() {
    // Cookies.remove('lastOrderId');
    localStorage.removeItem('lastOrderId');
  }
  static setLastOrderId({ lastOrderId }: { lastOrderId: string }) {
    localStorage.setItem('lastOrderId', lastOrderId);
    // Cookies.set('lastOrderId', lastOrderId);
  }
  static get getLastOrderId() {
    return { lastOrderId: localStorage.getItem('lastOrderId') || null };
    // return { lastOrderId: Cookies.get('lastOrderId') || null };
  }

  static setCartData(products: ProductsInCart) {
    localStorage.setItem('cartData', JSON.stringify(products));
  }
  static get getCartData(): ProductsInCart | null {
    const localStorageProducts = localStorage.getItem('cartData');

    const cartData: ProductsInCart | null = localStorageProducts
      ? (JSON.parse(localStorageProducts) as ProductsInCart)
      : null;

    return cartData;
  }
  static clearCartData() {
    localStorage.removeItem('cartData');
  }
}
