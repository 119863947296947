import { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

export const AdmitadScript = memo(
  ({
    price,
    items,
    orderNumber,
  }: {
    price: string | null;
    items:
      | {
          Product: {
            productID: string;
            category: '1';
            price: string;
            priceCurrency: 'RUB' | 'AED';
          };
          orderQuantity: number;
          additionalType: 'sale';
        }[]
      | null;
    orderNumber: string | null;
  }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      //@ts-ignore
      window.admitadTempVars = {
        price,
        items,
        orderNumber,
      };
      setShow(true);
      return () => {
        //@ts-ignore
        delete window.admitadTempVars;
        setShow(false);
      };
    }, [price, items, orderNumber]);

    return show ? (
      <Helmet>
        <script type='text/javascript'>{`
              ADMITAD = window.ADMITAD || {};
              ADMITAD.Invoice = ADMITAD.Invoice || {};
              if (!getSourceCookie(cookie_name)) {
                ADMITAD.Invoice.broker = 'na';
              } else if (getSourceCookie(cookie_name) != deduplication_cookie_value) {
                ADMITAD.Invoice.broker = getSourceCookie(cookie_name);
              } else {
                ADMITAD.Invoice.broker = 'adm';
              }
              ADMITAD.Invoice.category = '1';
              var orderedItem = window.admitadTempVars.items;
              ADMITAD.Invoice.referencesOrder = ADMITAD.Invoice.referencesOrder || [];
              ADMITAD.Invoice.referencesOrder.push({
                orderNumber: window.admitadTempVars.orderNumber,
                orderedItem: orderedItem,
              });
              ADMITAD.Tracking.processPositions();
          `}</script>
      </Helmet>
    ) : (
      <></>
    );
  },
);
