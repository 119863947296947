import React from 'react';
import { FEOpeningBar } from '..';
import { isInSchedule, useTimePicker } from './model';
import FEDeliveryTimePickerProps from './props';
import { IMG_IC_24_TIME_DELIVERY } from 'images';
import Container from './components/Container';
import { useTranslation } from 'react-i18next';

const FEDeliveryTimePicker = (props: FEDeliveryTimePickerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);

  const {
    displayedValue,
    days,
    timeIntervals,
    initialDayIndex,
    initialTimeIntervalIndex,
    changeDay,
    changeTimeInterval,
    submit,
  } = useTimePicker({ ...props, open });

  return (
    <>
      <FEOpeningBar
        image={IMG_IC_24_TIME_DELIVERY}
        label={t('cart.main.deliveryTimePicker.title')}
        onClick={() => setOpen(true)}
        value={displayedValue}
      />
      <Container
        {...{
          days,
          timeIntervals,
          initialDayIndex,
          initialTimeIntervalIndex,
          changeDay,
          changeTimeInterval,
          submit,
        }}
        open={open}
        title={t('cart.main.deliveryTimePicker.title')}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default FEDeliveryTimePicker;
export type { FEDeliveryTimePickerProps };
export { isInSchedule };
