import React, {useRef} from 'react';
import {
  FEBottomSheet,
  FEButton,
  FEImageComponent,
  FEModifierCard,
  FEQuantity,
  FETabView,
} from 'components';
import { ContentProps } from '../../../../contentProps';
import style from './style.module.css';
import {
  AddonGroupInfo,
  BaseInfo,
  BasePicker,
  SelectorGroupInfo,
} from './components';
import { IMG_PLACEHOLDER_BIG_PICTURE } from 'images';
import { ConstructorCurrentAddonGroupModifier } from 'pages/catalog/components/constructorCard/model';
import { GetCatalogProductsResponseV3 } from 'api/version3';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const MobileContent = ({
  onClose: onCloseFromProps,

  quantity,
  changeQuantity,

  final,
  changeFinal,

  baseAddonGroups,
  addonGroups,
  changeAddonGroups,

  baseSelectorGroups,
  selectorGroups,
  changeSelectorGroups,

  bases,
  base,
  changeBase,

  baseTitle,
  price,

  activeTabIndex,
  changeActiveTabIndex,
  changeActiveTabId,
  tabList,

  addToCart,
  onResetConstructor,
  currency,
  description,
  name,
}: ContentProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const [openInfo, setOpenInfo] = React.useState<boolean>(false);
  const addonGroupSum = useRef(0)
  const activeTabId = tabList[activeTabIndex].id

  React.useEffect(() => {
    if (final) setOpenInfo(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNextDisabled = React.useMemo(() => {
    if (activeTabIndex === 0) return base === undefined;
    if (activeTabIndex > 0 && tabList[activeTabIndex].type === "SELECTOR") {
      const currentSelectorGroup = selectorGroups[activeTabId];
      return (
        currentSelectorGroup.modifiers.length < currentSelectorGroup.min ||
        currentSelectorGroup.modifiers.length > currentSelectorGroup.max
      );
    }
    if (tabList[activeTabIndex].type === "ADDON") {
      const currentAddonGroup = addonGroups[activeTabId];
      return currentAddonGroup.modifiers.length > currentAddonGroup.max;
    }
  }, [
    activeTabId,
    activeTabIndex,
    addonGroups,
    base,
    baseSelectorGroups.length,
    selectorGroups,
  ]);

  const onNextClick = () => {
    final !== true &&
      activeTabIndex < tabList.length - 1 &&
      changeActiveTabIndex(activeTabIndex + 1);
    if (final) {
      setOpenInfo(true);
    } else if (activeTabIndex === 0) changeActiveTabId(0);
    else if (
      activeTabIndex > 0 &&
        tabList[activeTabIndex].type === "SELECTOR"
    ) {

        changeActiveTabId(activeTabId + 1);

    } else if (tabList[activeTabIndex].type === "ADDON"
    )
      changeActiveTabId(activeTabId + 1);
    else {
      setOpenInfo(true);
    }
  };

  React.useEffect(() => {
    if (activeTabIndex === tabList.length - 1) {
      changeFinal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex, tabList.length]);


  return (
    <>
      <div className={style.root}>
        <div className={style.tabList}>
          <div className={style.tabListContainer}>
            <FETabView
              tabList={tabList}
              tabIndex={activeTabIndex}
              onChange={({ index, id }) => {
                changeActiveTabIndex(index);
                changeActiveTabId(id as number);
              }}
            />
          </div>
        </div>
        <div className={style.constructorList}>
          {activeTabIndex === 0 && (
            <>
              <div className={style.listTitle}>
                <p className='headline'>{description}</p>
              </div>
              <BasePicker
                currency={currency}
                currentBase={base}
                bases={bases}
                onChangeBase={(base) => {
                  changeBase(base);
                  changeFinal(false);
                }}
              />
            </>
          )}
          {activeTabIndex > 0 &&
              tabList[activeTabIndex].type === "SELECTOR" && (
              <>
                <div className={style.listTitle}>
                  <p className='headline'>
                    {baseSelectorGroups[activeTabId].info.description}
                  </p>
                </div>
                <div className={style.constructorListContent}>
                  {(() => {
                    const modifiersListObject = Object.fromEntries(
                      selectorGroups[activeTabId].modifiers.map((modifier) => [
                        modifier.id,
                        modifier,
                      ]),
                    );

                    return baseSelectorGroups[activeTabId].modifiers.map(
                      (baseSelectorGroupModifier) => {
                        const modifiersList =
                          selectorGroups[activeTabId].modifiers;
                        const modifierInList:
                          | GetCatalogProductsResponseV3.ConstructorSelectorGroupModifier
                          | undefined =
                          modifiersListObject[baseSelectorGroupModifier.id] ||
                          undefined;

                        return (
                          <div
                            className={style.listItem}
                            key={baseSelectorGroupModifier.id}
                          >
                            <FEModifierCard
                              currency={currency}
                              disabled={
                                !modifierInList &&
                                baseSelectorGroups[activeTabId].max > 1
                                  ? modifiersList.length ===
                                    baseSelectorGroups[activeTabId].max
                                  : false
                              }
                              active={Boolean(modifierInList)}
                              onClick={() => {
                                if (modifierInList) {
                                  const newModifiers = modifiersList.filter(
                                    (modifier) =>
                                      modifier.id !==
                                      baseSelectorGroupModifier.id,
                                  );
                                  changeSelectorGroups(
                                    selectorGroups.map((selectorGroup) => {
                                      if (
                                        selectorGroup.id ===
                                        baseSelectorGroups[activeTabId].id
                                      ) {
                                        return {
                                          ...selectorGroup,
                                          modifiers: newModifiers,
                                        };
                                      } else return selectorGroup;
                                    }),
                                  );
                                } else {
                                  if (baseSelectorGroups[activeTabId].max > 1) {
                                    const newModifiers = [
                                      ...modifiersList,
                                      baseSelectorGroupModifier,
                                    ];
                                    changeSelectorGroups(
                                      selectorGroups.map((selectorGroup) => {
                                        if (
                                          selectorGroup.id ===
                                          baseSelectorGroups[activeTabId].id
                                        ) {
                                          return {
                                            ...selectorGroup,
                                            modifiers: newModifiers,
                                          };
                                        } else return selectorGroup;
                                      }),
                                    );
                                  } else {
                                    const newModifiers = [
                                      baseSelectorGroupModifier,
                                    ];
                                    changeSelectorGroups(
                                      selectorGroups.map((selectorGroup) => {
                                        if (
                                          selectorGroup.id ===
                                          baseSelectorGroups[activeTabId].id
                                        ) {
                                          return {
                                            ...selectorGroup,
                                            modifiers: newModifiers,
                                          };
                                        } else return selectorGroup;
                                      }),
                                    );
                                  }
                                }
                              }}
                              imageId={baseSelectorGroupModifier.info.imageId}
                              name={baseSelectorGroupModifier.info.name}
                              description={
                                baseSelectorGroupModifier.info.description
                              }
                              price={
                                baseSelectorGroupModifier.price || undefined
                              }
                            />
                          </div>
                        );
                      },
                    );
                  })()}
                </div>
              </>
            )}
          {tabList[activeTabIndex].type === "ADDON" && (
            <>
              <div className={style.listTitle}>
                <p className='headline'>
                  {baseAddonGroups[activeTabId].info.description}
                </p>
              </div>
              <div className={style.constructorListContent}>
                {(() => {
                  const modifiersListObject = Object.fromEntries(
                    addonGroups[activeTabId].modifiers.map((modifiers) => [
                      modifiers.id,
                      modifiers,
                    ]),
                  );
                  const modifiersList = addonGroups[activeTabId].modifiers;
                  return baseAddonGroups[activeTabId].modifiers.map(
                    (baseAddonGroupModifier) => {
                      const modifierInList:
                        | ConstructorCurrentAddonGroupModifier
                        | undefined =
                        modifiersListObject[baseAddonGroupModifier.id] ||
                        undefined;


                      return (
                        <div
                          className={style.listItem}
                          key={baseAddonGroupModifier.id}
                        >
                          <FEModifierCard
                            currency={currency}
                            disabled={
                              !modifierInList &&
                              baseAddonGroups[activeTabId].max > 1
                                ? addonGroups[activeTabId].modifiers.reduce(function (acc, item) {
                                    return  acc + item.quantity
                                  }, 0) ===
                                  baseAddonGroups[activeTabId].max
                                : false
                            }
                            active={Boolean(modifierInList)}
                            onClick={() => {
                              if (modifierInList) {
                                const newModifiers = modifiersList.filter(
                                  (modifier) =>
                                    modifier.id !== baseAddonGroupModifier.id,
                                );
                                changeAddonGroups(
                                  addonGroups.map((addonGroup) => {
                                    if (
                                      addonGroup.id ===
                                      baseAddonGroups[activeTabId].id
                                    ) {
                                      return {
                                        ...addonGroup,
                                        modifiers: newModifiers,
                                      };
                                    } else return addonGroup;
                                  }),
                                );
                              } else {
                                if (baseAddonGroups[activeTabId].max > 1) {
                                  const newModifiers = [
                                    ...modifiersList,
                                    {
                                      ...baseAddonGroupModifier,
                                      quantity: 1,
                                    },
                                  ];
                                  changeAddonGroups(
                                    addonGroups.map((addonGroup) => {
                                      if (
                                        addonGroup.id ===
                                        baseAddonGroups[activeTabId].id
                                      ) {
                                        return {
                                          ...addonGroup,
                                          modifiers: newModifiers,
                                        };
                                      } else return addonGroup;
                                    }),
                                  );
                                } else {
                                  const newModifiers = [
                                    {
                                      ...baseAddonGroupModifier,
                                      quantity: 1,
                                    },
                                  ];
                                  changeAddonGroups(
                                    addonGroups.map((addonGroup) => {
                                      if (
                                        addonGroup.id ===
                                        baseAddonGroups[activeTabId].id
                                      ) {
                                        return {
                                          ...addonGroup,
                                          modifiers: newModifiers,
                                        };
                                      } else return addonGroup;
                                    }),
                                  );
                                }
                              }
                            }}
                            imageId={baseAddonGroupModifier.info.imageId}
                            name={baseAddonGroupModifier.info.name}
                            description={
                              baseAddonGroupModifier.info.description
                            }
                            price={baseAddonGroupModifier.price || undefined}
                          />
                        </div>
                      );
                    },
                  );
                })()}
              </div>
            </>
          )}
        </div>
        <div className={style.nextButton}>
          <FEButton disabled={isNextDisabled} onClick={onNextClick}>
            {t('common.next')}
          </FEButton>
        </div>
      </div>

      <FEBottomSheet
        open={openInfo}
        onClose={() => setOpenInfo(false)}
        render={({ onClose }) => (
          <div className={style.infoContainer}>
            <div className={style.infoContent}>
              <div className={style.imageContainer}>
                <FEImageComponent
                  className={style.image}
                  image={{ imageId: base?.imageId || '' }}
                  altImage={IMG_PLACEHOLDER_BIG_PICTURE}
                />
              </div>
              {/* <div className={style.infoList}> */}
              <div className={style.infoHeader}>
                <p className='title'>{name}</p>
              </div>
              <BaseInfo
                currency={currency}
                base={base}
                title={baseTitle}
                onClickChange={() => {
                  changeActiveTabIndex(0);
                  changeActiveTabId(0);
                  onClose();
                }}
              />

              {
                tabList.map((tab, index) => {
                      if (tab.type === "SELECTOR") {
                        const selector = selectorGroups.find(sel => sel.id === tab.originalId) || selectorGroups[0]
                        return (
                            <SelectorGroupInfo
                                currency={currency}
                                key={selector?.id}
                                selectorGroup={selector}
                                onClickChange={() => {
                                  changeActiveTabIndex(index);
                                  changeActiveTabId(index);
                                  onClose();
                                }}
                            />
                        )
                      } else if(tab.type === "ADDON") {
                        const addonGroup = addonGroups.find(addon => addon.id === tab.originalId) || addonGroups[0]
                        return  (
                            <AddonGroupInfo
                                currency={currency}
                                currentSum={addonGroup.modifiers.reduce(function (acc, item) {
                                  return  acc + item.quantity
                                }, 0)}
                                key={addonGroup.id}
                                addonGroup={addonGroup}
                                onClickChange={() => {
                                  changeActiveTabIndex(index);
                                  changeActiveTabId(index);
                                  onClose();
                                }}
                                onChangeModifierQuantity={({ modifierId, quantity }) => {
                                  if (quantity === 0)
                                    changeAddonGroups(
                                        addonGroups.map((addonGroupInList) => {
                                          if (addonGroupInList.id === addonGroup.id) {
                                            return {
                                              ...addonGroupInList,
                                              modifiers: addonGroupInList.modifiers.filter(
                                                  (modifier) => modifier.id !== modifierId,
                                              ),
                                            };
                                          }
                                          return addonGroupInList;
                                        }),
                                    );
                                  else
                                    changeAddonGroups(
                                        addonGroups.map((addonGroupInList) => {
                                          if (addonGroupInList.id === addonGroup.id) {
                                            return {
                                              ...addonGroupInList,
                                              modifiers: addonGroupInList.modifiers.map(
                                                  (modifier) => {
                                                    if (modifier.id === modifierId)
                                                      return { ...modifier, quantity };
                                                    return modifier;
                                                  },
                                              ),
                                            };
                                          }
                                          return addonGroupInList;
                                        }),
                                    );
                                }}
                            />
                        )

                      }
                    }
                )
              }

              {/*{selectorGroups.map((selectorGroup, i) => (*/}
              {/*  <SelectorGroupInfo*/}
              {/*    currency={currency}*/}
              {/*    key={selectorGroup.id}*/}
              {/*    selectorGroup={selectorGroup}*/}
              {/*    onClickChange={() => {*/}
              {/*      changeActiveTabIndex(1 + i);*/}
              {/*      changeActiveTabId(i);*/}
              {/*      onClose();*/}
              {/*    }}*/}
              {/*  />*/}
              {/*))}*/}
              {/*{addonGroups.map((addonGroup, i) => (*/}
              {/*  <AddonGroupInfo*/}
              {/*    currency={currency}*/}
              {/*    currentSum={addonGroup.modifiers.reduce(function (acc, item) {*/}
              {/*      return  acc + item.quantity*/}
              {/*    }, 0)}*/}
              {/*    key={addonGroup.id}*/}
              {/*    addonGroup={addonGroup}*/}
              {/*    onClickChange={() => {*/}
              {/*      changeActiveTabIndex(selectorGroups.length + 1 + i);*/}
              {/*      changeActiveTabId(i);*/}
              {/*      onClose();*/}
              {/*    }}*/}
              {/*    onChangeModifierQuantity={({ modifierId, quantity }) => {*/}
              {/*      if (quantity === 0)*/}
              {/*        changeAddonGroups(*/}
              {/*          addonGroups.map((addonGroupInList) => {*/}
              {/*            if (addonGroupInList.id === addonGroup.id) {*/}
              {/*              return {*/}
              {/*                ...addonGroupInList,*/}
              {/*                modifiers: addonGroupInList.modifiers.filter(*/}
              {/*                  (modifier) => modifier.id !== modifierId,*/}
              {/*                ),*/}
              {/*              };*/}
              {/*            }*/}
              {/*            return addonGroupInList;*/}
              {/*          }),*/}
              {/*        );*/}
              {/*      else*/}
              {/*        changeAddonGroups(*/}
              {/*          addonGroups.map((addonGroupInList) => {*/}
              {/*            if (addonGroupInList.id === addonGroup.id) {*/}
              {/*              return {*/}
              {/*                ...addonGroupInList,*/}
              {/*                modifiers: addonGroupInList.modifiers.map(*/}
              {/*                  (modifier) => {*/}
              {/*                    if (modifier.id === modifierId)*/}
              {/*                      return { ...modifier, quantity };*/}
              {/*                    return modifier;*/}
              {/*                  },*/}
              {/*                ),*/}
              {/*              };*/}
              {/*            }*/}
              {/*            return addonGroupInList;*/}
              {/*          }),*/}
              {/*        );*/}
              {/*    }}*/}
              {/*  />*/}
              {/*))}*/}
              {/* </div> */}
            </div>
            <div className={style.infoAddToCart}>
              <div className={style.infoAddToCartContainer}>
                <FEQuantity
                  min={1}
                  fill='none'
                  value={quantity}
                  onChange={changeQuantity}
                />
                <FEButton
                  className={style.addToCartButton}
                  disabled={isNextDisabled}
                  onClick={() => {
                    addToCart();
                    onClose();
                    onCloseFromProps();
                  }}
                >
                  <div className={style.addToCartButtonContent}>
                    <p>{t('catalog.constructor.modal.addToCartText')}</p>
                    <p>{`${price} ${currency}`}</p>
                  </div>
                </FEButton>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
};

export default MobileContent;
