import React from 'react';
import { FEStoriesSliderProps } from '../../props';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { ANIMATION_DELAY } from 'const';
import style from './style.module.css';
import { FEOverlay, FESpinner } from 'components';
import FeatSDKV1 from 'api/version1';
import { usePrepareLink } from 'hooks/router';
import { useNavigate } from 'react-router-dom';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

const Layout = ({
  currentStory,
  storyDelay = 7000,
  onClose,
  products,
}: FEStoriesSliderProps) => {
  const delay = storyDelay - 1000;

  const navigate = useNavigate();
  const { stories } = currentStory;

  const [urls, setUrls] = React.useState<string[]>([]);
  const [isLoadingImages, setIsLoadingImages] = React.useState<boolean>(true);
  const src = (imageId: string) => FeatSDKV1.images.getImage(imageId);

  React.useEffect(() => {
    setIsLoadingImages(true);
    Promise.all(
      stories.map((story) =>
        fetch(src(story.imageId))
          .then((response) => response.blob())
          .then((image) => URL.createObjectURL(image)),
      ),
    ).then((urls) => {
      setUrls(urls);
      setIsLoadingImages(false);
    });
  }, [stories]);

  const [animationIn, setAnimationIn] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => setAnimationIn(true), []);

  function handleClose() {
    if (onClose) {
      onClose();
      setAnimationIn(false);
    }
  }

  let mousedownId = React.useRef<any>();
  const [pause, setPause] = React.useState<boolean>(false);
  const [activeWidth, setActiveWidth] = React.useState<number>(0);

  const [storyIndex, setStoryIndex] = React.useState<number>(0);
  const [timerIndex, setTimerIndex] = React.useState<number>(0);
  const [time, setTime] = React.useState<number>(0);

  const toggleState = (action: 'play' | 'pause') => {
    setPause(action === 'pause');
  };

  const next = () => {
    if (stories.length > 1 && storyIndex !== stories.length - 1) {
      setStoryIndex(storyIndex + 1);
      setTimerIndex(timerIndex + 1);
      setTime(0);
      setActiveWidth(0);
    } else handleClose();
  };

  const prev = () => {
    if (storyIndex > 0) {
      setStoryIndex(storyIndex - 1);
      setTimerIndex(timerIndex - 1);
      setTime(0);
      setActiveWidth(0);
    }
  };

  const mouseUp =
    (type: 'prev' | 'next') => (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault();
      mousedownId.current && clearTimeout(mousedownId.current);
      if (pause) {
        toggleState('play');
      } else {
        type === 'prev' ? prev() : next();
      }
    };

  const debouncePause = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    mousedownId.current = setTimeout(() => {
      toggleState('pause');
    }, 200);
  };

  React.useEffect(() => {
    if (isLoadingImages === false) {
      const timer = setInterval(() => {
        if (!pause) {
          setTime(time + 10);
          setActiveWidth((time * 100) / delay);
          if (time >= delay) {
            setTime(0);
            setActiveWidth(0);
            storyIndex < stories.length - 1 && setStoryIndex(storyIndex + 1);
            setTimerIndex(timerIndex + 1);
          }
        }
      }, 10);
      if (timerIndex >= stories.length) handleClose();

      return () => clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pause, stories, storyIndex, timerIndex, time, isLoadingImages]);

  const currentProductFromLink = React.useMemo(
    () =>
      stories[storyIndex].button?.link?.value
        ? products[stories[storyIndex].button!.link!.value]
        : null,
    [products, stories, storyIndex],
  );

  const productPathname = currentProductFromLink?.productName || '';

  const productLink = usePrepareLink({
    to: `/${productPathname}`,
  });

  return (
    <>
      <div className={style.dialog} role='dialog'>
        <FEOverlay onClick={handleClose} animationIn={animationIn} />
        <CSSTransition
          in={animationIn}
          timeout={ANIMATION_DELAY}
          mountOnEnter
          unmountOnExit
          classNames={containerAnimation}
          nodeRef={containerRef}
        >
          <div
            className={style.container}
            onSelect={() => false}
            ref={containerRef}
          >
            <div className={style.content}>
              {Boolean(urls.length) && (
                <img
                  onSelect={() => false}
                  onContextMenu={(e) => e.preventDefault()}
                  className={style.image}
                  src={urls[storyIndex]}
                  alt=''
                />
              )}
              <div
                onContextMenu={(e) => e.preventDefault()}
                className={style.skipContainer}
              >
                <div
                  className={clsx(style.skipItem, style.skipItem__prev)}
                  onContextMenu={(e) => e.preventDefault()}
                  onTouchStart={debouncePause}
                  onTouchEnd={mouseUp('prev')}
                  onMouseDown={debouncePause}
                  onMouseUp={mouseUp('prev')}
                >
                  {stories.length > 1 && (
                    <button
                      className={clsx('button-icon-24', style.skipButton__prev)}
                    />
                  )}
                </div>
                <div
                  className={clsx(style.skipItem, style.skipItem__next)}
                  onContextMenu={(e) => e.preventDefault()}
                  onTouchStart={debouncePause}
                  onTouchEnd={mouseUp('next')}
                  onMouseDown={debouncePause}
                  onMouseUp={mouseUp('next')}
                >
                  {stories.length > 1 && (
                    <button
                      className={clsx('button-icon-24', style.skipButton__next)}
                    />
                  )}
                </div>
              </div>
              <button
                onClick={handleClose}
                className={clsx('closeButton-desktop', style.closeButton)}
              />
              <div className={style.timeLineContainer}>
                {stories.map((_, i) => (
                  <div className={style.timeLine} key={_.id}>
                    <div
                      style={{
                        width: i === timerIndex ? `${activeWidth}%` : undefined,
                      }}
                      className={clsx(
                        style.timeLineIndicator,
                        isLoadingImages === false &&
                          i < timerIndex &&
                          style.timeLineIndicator__full,
                      )}
                    />
                  </div>
                ))}
              </div>
              {(() => {
                const { button } = stories[storyIndex];
                if (!button) return null;

                const { colorScheme, text, link } = button;

                const handleClick = (
                  type: 'HTTP' | 'STORIES' | 'DISH' | 'PROFILE',
                  value: string,
                ) => {
                  switch (type) {
                    case 'DISH':
                      return (
                        Boolean(currentProductFromLink) && navigate(productLink)
                      );
                    case 'HTTP':
                      return Boolean(value) && window.open(value, '_blank');
                    // case 'STORIES':
                    //   return (
                    //     stories[storyIndex].story !== null && setOpenStory(true)
                    //   );
                    default:
                      break;
                  }
                };

                return (
                  <div className={style.buttonContainer}>
                    <button
                      className={style.linkButton}
                      style={{
                        color: `${colorScheme.textColor}`,
                        background: `${colorScheme.backgroundColor}`,
                      }}
                      onClick={() => {
                        link && handleClick(link.type, link.value);
                        stories[storyIndex].story === null && handleClose();
                      }}
                    >
                      {text}
                    </button>
                  </div>
                );
              })()}
              <div
                className={clsx(
                  style.spinnerContainer,
                  !isLoadingImages && style.spinnerContainer_loading,
                )}
              >
                <FESpinner size={'medium'} />
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
      {/* {stories[storyIndex].story !== null && openStory && (
        <FEStoriesSlider
          products={products}
          open={openStory}
          onClose={() => handleClose()}
          currentStory={{
            ...stories[storyIndex].story!,
            stories: stories[storyIndex].story!.stories.map((story) => ({
              ...story,
              story: null,
            })),
          }}
        />
      )} */}
    </>
  );
};

export default Layout;
