import {
  GoogleMap,
  MarkerClustererProps,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';
import clsx from 'clsx';
import { Restaurant } from 'domains/cartography';
import { Marker } from '../../../../components';
import { Language } from 'models';
import { IMG_IC_48_MARKER_RESTAURANT_CLUSTER1 } from 'images';
import { FEMapPlaceholder } from 'ui-kit/components';

const CLUSTER_OPTIONS: MarkerClustererProps['options'] = {
  styles: [
    {
      url: `${IMG_IC_48_MARKER_RESTAURANT_CLUSTER1}`,
      height: 48,
      width: 48,
      fontFamily: 'Inter',
      textColor: '#FFF',
      textSize: 20,
      fontWeight: '700',
    },
  ],
};

export type MapProps = {
  restaurants: Restaurant[];
  currentRestaurant: Restaurant | null;
  onRestaurantClick: (
    e: google.maps.MapMouseEvent,
    restaurant: Restaurant,
  ) => void;
  onLoad: (map: google.maps.Map) => void;
  className: string;
  center: {
    lat: number;
    lng: number;
  };
  language?: Language;
};

const Map = ({
  className,
  restaurants,
  onRestaurantClick,
  onLoad,
  center,
  language = 'ru',
  currentRestaurant,
}: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN ?? '',
    language: language,
  });

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      mapContainerClassName={clsx(className)}
      center={center}
      zoom={10}
      options={{
        disableDefaultUI: true,
        minZoom: 10,
      }}
    >
      <MarkerClusterer averageCenter options={CLUSTER_OPTIONS}>
        {(clusterer) => (
          <>
            {restaurants.map((restaurant) => (
              <Marker
                clusterer={clusterer}
                selected={restaurant.id === currentRestaurant?.id}
                onClick={(e) => onRestaurantClick(e, restaurant)}
                position={{
                  lat: restaurant.address.location.latitude,
                  lng: restaurant.address.location.longitude,
                }}
                key={restaurant.id}
              />
            ))}
          </>
        )}
      </MarkerClusterer>
    </GoogleMap>
  ) : (
    <>
      <FEMapPlaceholder className={className} />
    </>
  );
};

export default Map;
