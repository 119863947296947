import { GiftRepurschase } from 'domains/giftRepurschases';
import React from 'react';
import { FEImageComponent } from 'components';
import { IMG_PLACEHOLDER_PERCENT } from 'images';
import style from './style.module.css';
import { GiftRepurchaseModal, ProgressBar } from './components';
import clsx from 'clsx';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const GiftRepurchaseBanner = ({
  giftRepurchase,
  products,
  currency,
}: {
  giftRepurchase: GiftRepurschase;
  currency: CurrencySymbol;
  products: {
    [k: string]: {
      productId: string;
      productName: string;
      categoryId: string;
      categoryName: string;
    };
  };
}) => {
  const { t } = useTranslation();
  const { catalogImageId, maxLevel, currentLevel } = giftRepurchase;
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <>
      <div className={style.root}>
        <FEImageComponent
          processingComponent={
            <img
              alt=''
              className={style.processingImage}
              src={IMG_PLACEHOLDER_PERCENT}
            />
          }
          loaderContainerClassName={style.loader}
          onClick={() => setOpen(true)}
          className={style.image}
          image={{ imageId: catalogImageId }}
          spinnerSize='medium'
        />
        <div className={style.progressContainer}>
          <p className={clsx('caption-2', style.progressCaption)}>
            {t('banners.giftRepurchase.progressLabel')}
          </p>
          <ProgressBar
            type='primary'
            max={maxLevel}
            current={currentLevel || 0}
          />
        </div>
      </div>
      <GiftRepurchaseModal
        {...{ open, giftRepurchase, products, currency }}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default GiftRepurchaseBanner;
