import { MOBILE_WIDTH } from 'const';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FEButton,
  FECodeField,
  FENotificationCard,
  FEPhoneField,
  FETitledBottomSheet,
  FETitledDialog,
} from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import { useUnit } from 'effector-react';
import { getSmsCodeFx, confirmCodefx, getUserProfileFx } from 'domains/profile';
import { $isPendingSmsCode, $isPendingUserAccount } from 'domains/profile';
import useCountDown from 'hooks/useCountDown';
import codeStyle from './styles/code.styles.module.css';
import phoneStyle from './styles/phone.styles.module.css';
import clsx from 'clsx';
import { BaseErrorResponse } from 'api/base';
import {
  $countriesList,
  $country,
  CountryCodeModel,
} from 'domains/cartography';
import { useTranslation } from 'react-i18next';
import {
  AuthConfirmRequestV1,
  AuthConfirmResponseV1,
  AuthStartRequestV1,
  GetCustomerResponseV1,
} from 'api/version1';
import { FEHeadersCookiesUtil } from 'utils';

type ContentType = {
  onClose: () => void;
  phone: string;
  code: string;
  isValidPhone: boolean;
  isPendingSmsCode: boolean;
  isPendingUserAccount: boolean;
  disabledGetAnother: boolean;
  count: number;
  hasCodeError: boolean;
  stage: 'PHONE' | 'CODE';
  country: CountryCodeModel | null;
  countries: CountryCodeModel[];
  validPhone: boolean;
  changeValidPhone: (v: boolean) => void;
  showErrors: boolean;
  changeShowErrors: (v: boolean) => void;
  changePhone: (v: string) => void;
  changeStage: (stage: 'PHONE' | 'CODE') => void;
  changeCode: (v: string) => void;
  changeHasCodeError: (v: boolean) => void;
  changeHasError: (v: boolean) => void;
  changeDisableGetAnother: (v: boolean) => void;
  resetCounter: () => void;
  getSmsCode: (payload: AuthStartRequestV1) => Promise<string>;
  confirmCode: (
    payload: AuthConfirmRequestV1 & {
      mask: string;
    },
  ) => Promise<AuthConfirmResponseV1>;
  getUserProfile: (
    payload: void | AbortSignal | undefined,
  ) => Promise<GetCustomerResponseV1>;
  changeCountry: (v: CountryCodeModel) => void;
  errorMessage: string
  setErrorMessage: (a: string) => void
};

const Content = ({
  onClose,
  code,
  phone,
  isPendingSmsCode,
  isPendingUserAccount,
  disabledGetAnother,
  count,
  stage,
  hasCodeError,
  countries,
  country,
  validPhone,
  changeValidPhone,
  showErrors,
  changeShowErrors,
  changePhone,
  changeStage,
  changeCode,
  changeHasCodeError,
  changeHasError,
  changeDisableGetAnother,
  resetCounter,
  getSmsCode,
  confirmCode,
  getUserProfile,
  changeCountry,
  errorMessage,
  setErrorMessage
}: ContentType) => {
  const { t } = useTranslation();

  const onkeydown = React.useCallback(
      (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          changeShowErrors(true);
          if (validPhone) {
            getSmsCode({ phone: phone }).then(
                () => changeStage('CODE'),
                (reason) => {
                  changeHasError(true)
                  setErrorMessage(reason.errors[0].
                      presentationData.message
                  )
                },
            );
          }
        }
      },
      [changeShowErrors, validPhone, getSmsCode, phone, changeStage, changeHasError],
  );

  React.useEffect(() => {

    window.addEventListener('keydown', onkeydown);

    return () => {
      window.removeEventListener('keydown', onkeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, validPhone]);


  return stage === 'PHONE' ? (
    <>
      <div className={phoneStyle.root}>
        <p className={clsx('text-2', phoneStyle.info)}>
          {t('loginIn.message1')}
        </p>
        <div className={phoneStyle.input}>
          <FEPhoneField
            onChangeCountry={changeCountry}
            onValid={(valid) => changeValidPhone(valid)}
            currentCountry={country}
            countries={countries}
            hasError={!validPhone && showErrors}
            value={phone}
            onChange={(value) => changePhone(value)}
          />
        </div>
        <div className={phoneStyle.submit}>
          <FEButton
            showLoader={isPendingSmsCode}
            disabled={isPendingSmsCode}
            onClick={() => {
              changeShowErrors(true);
              if (validPhone) {
                getSmsCode({ phone: phone }).then(
                  () => changeStage('CODE'),
                  (reason) => {
                    changeHasError(true)
                    setErrorMessage(reason.errors[0].
                        presentationData.message
                    )
                  },
                );
              }
            }}
          >
            {t('loginIn.confirmButton')}
          </FEButton>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={codeStyle.root}>
        <p className={clsx('text-2', codeStyle.info)}>
          {t('loginIn.message2', { phone })}
        </p>
        <div className={codeStyle.code}>
          <FECodeField
            errorText={t('loginIn.invalidCode')}
            hasError={hasCodeError}
            value={code}
            onChange={(code) => changeCode(code)}
            onInputComplete={(code) => {
              confirmCode({
                code: code,
                phone: phone,
                mask: country!.phoneMask,
              }).then(
                () => {
                  FEHeadersCookiesUtil.setCountryCode(country);
                  // Яндекс метрика
                  //@ts-ignore
                  window.dataLayer.push({ ecommerce: null });
                  //@ts-ignore
                  window.dataLayer.push({
                    event: 'new_reg',
                  });
                  getUserProfile().then(
                    () => {
                      onClose();
                    },
                    // () => changeHasCodeError(true),
                  );
                },
                (error: BaseErrorResponse.ErrorResponse) => {
                  if (error.errors && error.errors[0]) {
                    if (
                      error?.errors[0]?.reason ===
                      'CONFIRMATION_CODE_IS_INCORRECT'
                    ) {
                      changeHasCodeError(true);
                    }
                  } else changeHasError(true);
                },
              );
            }}
          />
        </div>
        <div className={codeStyle.timer}>
          <p className={clsx('caption-1', codeStyle.timerMessage)}>
            {t('loginIn.caption', { count })}
          </p>
        </div>
        <div className={codeStyle.submit}>
          <FEButton
            disabled={disabledGetAnother || isPendingUserAccount}
            showLoader={isPendingUserAccount}
            onClick={() => {
              changeCode('');
              changeHasCodeError(false);
              changeDisableGetAnother(true);
              resetCounter();
              getSmsCode({ phone: phone }).then(
                () => {},
                () => changeHasError(true),
              );
            }}
          >
            {t('loginIn.newCodeButton')}
          </FEButton>
          <FEButton
            disabled={isPendingUserAccount}
            showLoader={isPendingUserAccount}
            onClick={() => {
              changeStage('PHONE');
              changeCode('');
              changeHasCodeError(false);
            }}
            type='secondary'
          >
            {t('common.back')}
          </FEButton>
        </div>
      </div>
    </>
  );
};

const AuthPage = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);
  const [stage, setStage] = React.useState<'PHONE' | 'CODE'>('PHONE');
  const [phone, setPhone] = React.useState<string>('');
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [hasCodeError, setHasCodeError] = React.useState<boolean>(false);
  const [code, setCode] = React.useState<string>('');
  const [disabledGetAnother, setDisabledGetAnother] =
    React.useState<boolean>(true);
  const { count, resetCounter } = useCountDown(60, () =>
    setDisabledGetAnother(false),
  );
  const [showError, setShowError] = React.useState(false);
  const [validPhone, setValidPhone] = React.useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [country, countries] = useUnit([$country, $countriesList]);
  const [getSmsCode, confirmCode, getUserProfile] = useUnit([
    getSmsCodeFx,
    confirmCodefx,
    getUserProfileFx,
  ]);
  const [isPendingSmsCode, isPendingUserAccount] = useUnit([
    $isPendingSmsCode,
    $isPendingUserAccount,
  ]);

  const [currentAuthCountry, setCurrentAuthCountry] =
    useState<CountryCodeModel | null>(null);

  useEffect(() => {
    setCurrentAuthCountry(country?.code || null);
  }, [country]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => resetCounter(), [stage]);

  return (
    <>
      {isDesktop ? (
        <>
          <FETitledDialog
            title={
              stage === 'PHONE' ? t('loginIn.title1') : t('loginIn.title2')
            }
            open={open}
            onClose={() => navigate(-1)}
            render={({ onClose }) => (
              <Content
                validPhone={validPhone}
                changeValidPhone={setValidPhone}
                showErrors={showError}
                changeShowErrors={setShowError}
                country={currentAuthCountry}
                countries={countries.map((country) => country.code)}
                hasCodeError={hasCodeError}
                stage={stage}
                count={count}
                disabledGetAnother={disabledGetAnother}
                isPendingUserAccount={isPendingUserAccount}
                onClose={onClose}
                phone={phone}
                code={code}
                isPendingSmsCode={isPendingSmsCode}
                isValidPhone={true}
                changeCode={setCode}
                changeDisableGetAnother={setDisabledGetAnother}
                changeHasCodeError={setHasCodeError}
                changeHasError={setHasError}
                changePhone={setPhone}
                changeStage={setStage}
                changeCountry={setCurrentAuthCountry}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                {...{ resetCounter, getSmsCode, confirmCode, getUserProfile }}
              />
            )}
          />
        </>
      ) : (
        <>
          <FETitledBottomSheet
            title={
              stage === 'PHONE' ? t('loginIn.title1') : t('loginIn.title2')
            }
            open={open}
            onClose={() => navigate(-1)}
            render={({ onClose }) => (
              <Content
                validPhone={validPhone}
                changeValidPhone={setValidPhone}
                showErrors={showError}
                changeShowErrors={setShowError}
                country={currentAuthCountry}
                countries={countries.map((country) => country.code)}
                hasCodeError={hasCodeError}
                stage={stage}
                count={count}
                disabledGetAnother={disabledGetAnother}
                isPendingUserAccount={isPendingUserAccount}
                onClose={onClose}
                phone={phone}
                code={code}
                isPendingSmsCode={isPendingSmsCode}
                isValidPhone={true}
                changeCode={setCode}
                changeDisableGetAnother={setDisabledGetAnother}
                changeHasCodeError={setHasCodeError}
                changeHasError={setHasError}
                changePhone={setPhone}
                changeStage={setStage}
                changeCountry={setCurrentAuthCountry}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                {...{ resetCounter, getSmsCode, confirmCode, getUserProfile }}
              />
            )}
          />
        </>
      )}
      <FENotificationCard
        description={errorMessage ? errorMessage : t('cart.validationErrors.message')}
        icon='error'
        title={t('cart.validationErrors.title')}
        open={hasError}
        primaryButtonProps={{
          text: t('common.ok'),
          onClick: () => {},
          closeOnClick: true,
        }}
        onClose={() => {
          setHasError(false);
          // navigate(-1);
        }}
      />
    </>
  );
};

export default AuthPage;
