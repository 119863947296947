import { useMergedRef } from 'hooks';
import React from 'react';
import FESliderProps from './props';
import style from './style.module.css';
import useSlider from './useSlider';

export default React.forwardRef<HTMLInputElement, FESliderProps>(
  (
    {
      min = 0,
      max,
      value,
      defaultValue = 0,
      step = 1,
      onChange,
      disabled = false,
    },
    ref,
  ) => {
    const rangeRef = React.useRef<HTMLInputElement>(null);
    const thumbRef = React.useRef<HTMLDivElement>(null);

    const { position, progressWidth } = useSlider({
      max,
      value,
      rangeRef,
      thumbRef,
    });

    return (
      <div className={style.root}>
        <div
          className={style.cover}
          style={{
            width: `${progressWidth}px`,
          }}
        />
        <div
          className={style.thumb}
          ref={thumbRef}
          style={{
            left: `${position}px`,
          }}
        />
        <input
          className={style.input}
          ref={useMergedRef(ref, rangeRef)}
          type='range'
          max={max}
          min={min}
          value={value}
          defaultValue={defaultValue}
          step={step}
          onChange={(e) => !disabled && onChange(Number(e.target.value))}
        />
      </div>
    );
  },
);

export type { FESliderProps };
