import React from 'react';
const useSlider = ({
  value,
  max,
  rangeRef,
  thumbRef,
}: {
  value: number;
  max: number;
  rangeRef: React.RefObject<HTMLInputElement>;
  thumbRef: React.RefObject<HTMLDivElement>;
}) => {
  const [position, setPosition] = React.useState<number>(0);
  const [progressWidth, setProgressWidth] = React.useState<number>(0);

  React.useLayoutEffect(() => {
    const range = rangeRef.current;
    const thumb = thumbRef.current;
    if (range && thumb) {
      const rangeWidth = range.getBoundingClientRect().width;
      const thumbWidth = thumb.getBoundingClientRect().width;
      const centerProgressBar =
        thumbWidth + (rangeWidth / max) * value - (thumbWidth / max) * value;
      setPosition(centerProgressBar - thumbWidth);
      setProgressWidth(centerProgressBar);
    }
  }, [max, rangeRef, thumbRef, value]);

  return {
    position,
    progressWidth,
  };
};

export default useSlider;
