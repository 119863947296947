import { initCatalogFx, initCodeFx } from './effects';
import {
  getCityDeliveryZoneFx,
  getDeliveryZoneFx,
  getRestaurantDepsFx,
} from 'domains/cartography';
import { fetchCatalogByCityFx, fetchCatalogFx } from 'domains/catalog';
import { createStore } from 'effector';
import { createGate } from 'effector-react';
import { pending } from 'patronum';
import { getFeaturesByPlaceIdFx } from 'domains/features';
import { getSitesMapByCountryIdFx } from 'domains/sitesMap';

export const applicationGate = createGate();

export const $applicationStarting = pending({
  effects: [initCodeFx, initCatalogFx],
});

export const $isPendingCatalogDeps = pending({
  effects: [
    getSitesMapByCountryIdFx,
    fetchCatalogFx,
    getRestaurantDepsFx,
    fetchCatalogByCityFx,
    getDeliveryZoneFx,
    getFeaturesByPlaceIdFx,
    getCityDeliveryZoneFx,
  ],
});

export const $showStartPage = createStore(false);
