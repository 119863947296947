import clsx from 'clsx';
import React from 'react';
import { FEImageComponent, FEQuantity } from 'components';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import { CutleryProps } from './props';
import style from './style.module.css';
import { CurrencySymbol } from 'models';

const Cutlery = ({
  cutlery,
  onChangeQuantity,
  isValidating,
  onDelete,
  currency,
}: CutleryProps & { currency: CurrencySymbol }) => {
  const {
    complectation: {
      price,
      complectation: { imageId, name },
    },
    quantity,
  } = cutlery;

  return (
    <div className={style.root}>
      <div className={style.container}>
        <FEImageComponent
          image={{ imageId }}
          altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
          className={style.image}
        />
        <div className={style.data}>
          <p className='subhead'>{name}</p>
          <p className='subhead'>
            {`${Math.trunc(price.total)} ${currency}`}{' '}
            {Boolean(price.discount) && (
              <s className={clsx('caption-1', style.fullPrice)}>{`${
                price.price * quantity
              } ${currency}`}</s>
            )}
          </p>
        </div>
        <div className={style.event}>
          <FEQuantity
            onChange={(val) =>
              val > 0 ? onChangeQuantity(val) : onDelete(cutlery)
            }
            disabled={isValidating}
            value={quantity}
            min={0}
            max={100}
          />
        </div>
      </div>
    </div>
  );
};

export default Cutlery;
