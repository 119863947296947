import clsx from 'clsx';
import { GET_PARAMS } from 'const';
import { CurrencySymbol, OrderStatus, OrderType } from 'models';
import moment from 'moment';
import { FEButton, FEImageComponent } from 'components';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import style from './style.module.css';
import { Order as OrderModel } from 'domains/profile';
import { OrderDetails } from './components';
import { useNavigate } from 'react-router-dom';
import { useLinks, usePrepareLink } from 'hooks/router';
import { FEDateUtils } from 'utils';
import { useTranslation } from 'react-i18next';

const formatTimeInterval = (timeInterval: moment.Moment, timeError: number) => {
  if (timeError === 0) return timeInterval.format('HH:mm');

  const first = timeInterval.subtract(timeError, 'minutes').format('HH:mm');
  const second = timeInterval.add(timeError * 2, 'minutes').format('HH:mm');
  return `${first} - ${second}`;
};

export const getTime = (
  dateTime: string,
  timeError: number,
  status: OrderStatus,
  toUTC?: boolean,
) => {
  const momentedDateTime = moment(dateTime);
  if (toUTC) FEDateUtils.addUtcOffset(momentedDateTime);
  if (!Boolean(momentedDateTime.isSame(moment(), 'day'))) {
    return `${momentedDateTime.format('DD.MM.YYYY')}, ${formatTimeInterval(
      momentedDateTime,
      timeError,
    )}`;
  } else {
    return `${
      status !== 'CANCELLED' && status !== 'DONE'
        ? 'Сегодня'
        : momentedDateTime.format('DD.MM.YYYY')
    }, ${formatTimeInterval(momentedDateTime, timeError)}`;
  }
};

const Order = ({
  order,
  onRepeatOrder,
  currency,
}: {
  order: OrderModel;
  onRepeatOrder: ({ order }: { order: OrderModel }) => void;
  currency: CurrencySymbol;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { ordersLink } = useLinks();

  const orderLink = usePrepareLink({
    query: {
      [GET_PARAMS.orderId]: order.id,
    },
    keepOldQuery: true,
  });

  const statuses: Record<
    Exclude<OrderType, 'IN_PLACE'>,
    Record<OrderStatus, string>
  > = {
    COURIER: {
      PROCESSING: t('orders.statuses.PROCESSING'),
      CANCELLED: t('orders.statuses.CANCELLED'),
      COOKING: t('orders.statuses.COOKING'),
      DONE: t('orders.statuses.DONE'),
      IN_PROGRESS: t('orders.statuses.IN_PROGRESS'),
      ON_WAY: t('orders.statuses.ON_WAY'),
      WAIT: t('orders.statuses.WAIT_COURIER'),
    },
    GO_TO_PLACE: {
      PROCESSING: t('orders.statuses.PROCESSING'),
      CANCELLED: t('orders.statuses.CANCELLED'),
      COOKING: t('orders.statuses.COOKING'),
      DONE: t('orders.statuses.DONE'),
      IN_PROGRESS: t('orders.statuses.IN_PROGRESS'),
      ON_WAY: '',
      WAIT: t('orders.statuses.WAIT_GO_TO_PLACE'),
    },
  };

  const getStatus = (
    type: OrderType,
    status: OrderStatus,
    dateTimeTo: string,
  ) => {
    if (status !== 'CANCELLED' && moment(dateTimeTo).isAfter(moment(), 'day')) {
      return t('orders.statuses.PREORDER');
    }
    return statuses[type as Exclude<OrderType, 'IN_PLACE'>][status];
  };

  const getColor: Record<OrderStatus, string> = {
    PROCESSING: style.orange,
    CANCELLED: style.red,
    COOKING: style.orange,
    DONE: style.green,
    IN_PROGRESS: style.orange,
    ON_WAY: style.orange,
    WAIT: style.orange,
  };
  return (
    <>
      <div className={style.order}>
        <div className={style.header}>
          <p className='headline'>
            {t('orders.orderNumberLabel', { orderNumber: order.number })}
          </p>
          <p className='headline'>
            {Boolean(order.price.discount) && (
              <s className={clsx('caption-1', style.fullPrice)}>
                {`${order.price.cart + order.price.discount} ${currency} `}
              </s>
            )}
            {`${order.price.total} ${currency}`}
          </p>
        </div>
        <div className={style.data}>
          <div className={style.dataItem}>
            <p className={clsx('caption-1')}>{t('orders.statusLabel')}</p>
            <p className={clsx(getColor[order.status])}>
              {order.status === 'DONE' || order.status === 'CANCELLED'
                ? statuses[order.type as Exclude<OrderType, 'IN_PLACE'>][
                    order.status
                  ]
                : getStatus(
                    order.type,
                    order.status,
                    order.receiptInformation.dateTimeTo,
                  )}
            </p>
          </div>
          <div className={style.dataItem}>
            <p className={clsx('caption-1')}>
              {order.type === 'COURIER'
                ? order.status === 'DONE'
                  ? t('orders.statusesLabels.courier.DONE')
                  : order.status === 'CANCELLED'
                  ? t('orders.statusesLabels.CANCELLED')
                  : t('orders.statusesLabels.courier.rest')
                : order.status === 'DONE'
                ? t('orders.statusesLabels.goToPlace.DONE')
                : order.status === 'CANCELLED'
                ? t('orders.statusesLabels.CANCELLED')
                : t('orders.statusesLabels.goToPlace.rest')}
            </p>
            <p>
              {order.status === 'CANCELLED'
                ? getTime(order.createDatetime, 0, order.status, true)
                : order.status === 'DONE'
                ? getTime(order.closeDatetime, 0, order.status, true)
                : order.receiptInformation.urgent
                ? getTime(
                    order.receiptInformation.dateTimeTo,
                    order.receiptInformation.deliveryTimeErrorInMinutes,
                    order.status,
                    true,
                  )
                : getTime(
                    order.receiptInformation.dateTimeTo,
                    order.receiptInformation.deliveryTimeErrorInMinutes,
                    order.status,
                    true,
                  )}
            </p>
          </div>
          <div className={style.dataItem}>
            <p className={clsx('caption-1')}>
              {order.type === 'COURIER'
                ? t('orders.captionCourier')
                : t('orders.captionGoToPlace')}
            </p>
            <p>
              {order.type === 'COURIER'
                ? order.receiptInformation.receivingAddress.address
                : order.receiptInformation.receivingRestaurant.name}
            </p>
          </div>
        </div>
        <div className={style.gallery}>
          <div className={style.galleryContent}>
            {order.dishes.map((dish, i) => (
              <FEImageComponent
                className={style.galleryImage}
                image={{ imageId: dish.base.imageId || '' }}
                // hard key
                key={dish.base.id + dish.product.id}
                altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
              />
            ))}
            {order.constructors.map((constructor, i) => (
              <FEImageComponent
                className={style.galleryImage}
                image={{ imageId: constructor.base.imageId || '' }}
                // hard key
                key={constructor.base.id + constructor.product.id}
                altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
              />
            ))}
          </div>
        </div>
        <div className={style.submit}>
          <div className={style.submitContainer}>
            <div className={style.submitContent}>
              <FEButton onClick={() => navigate(orderLink, { replace: true })}>
                {t('orders.detailsButtonText')}
              </FEButton>
              <FEButton
                onClick={() => onRepeatOrder({ order })}
                type='secondary'
              >
                {t('orders.repeatOrderButtonText')}
              </FEButton>
            </div>
          </div>
        </div>
      </div>
      <OrderDetails
        onClose={() => navigate(ordersLink, { replace: true })}
        currency={currency}
        order={order}
        onRepeatOrder={onRepeatOrder}
      />
    </>
  );
};

export default Order;
