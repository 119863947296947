import React from 'react';
import { MOBILE_WIDTH } from 'const';
import { FEBottomSheet, FEButton } from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import { Modal } from './components';
import style from './style.module.css';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const Confirmation = ({
  open,
  onClose,
  value,
  onDeclineConfirmation,
}: {
  open: boolean;
  onClose: () => void;
  value: string | null;
  onDeclineConfirmation: () => void;
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  return isDesktop ? (
    <Modal {...{ onClose, open, value, onDeclineConfirmation }} />
  ) : (
    <FEBottomSheet
      {...{ open, onClose }}
      render={({ onClose }) => (
        <div className={style.root}>
          <div className={style.data}>
            <p className='title'>{t('cartography.confirmation.label')}</p>
            <p className={clsx('text-2', style.address)}>{value}</p>
          </div>
          <div className={style.submit}>
            <FEButton onClick={onClose}>Все верно</FEButton>
            <FEButton
              onClick={() => {
                onDeclineConfirmation();
                onClose();
              }}
              type='secondary'
            >
              {t('common.change')}
            </FEButton>
          </div>
        </div>
      )}
    />
  );
};

export default Confirmation;
