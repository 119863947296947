import { URL } from 'const';
import Cookies from 'js-cookie';

export type ErrorDomain = 'AUTH';

export type ErrorDetails =
  | 'INVALID_EMAIL'
  | 'INVALID_PASSWORD'
  | 'INVALID_DATA'
  | 'ACCOUNT_IS_BLOCKED'
  | 'NO_CONNECTION';

export type ErrorEntityResponse<
  Details extends ErrorDetails = ErrorDetails,
  Domain extends ErrorDomain = ErrorDomain,
> = {
  presentationData: {
    title: string;
    message: string;
  };
  domain: Domain;
  details: Details;
};

export type SignInOptions = {
  loginPropName: string;
  passwordPropName: string;
};

export type IncorrectLoginError = ErrorEntityResponse<'INVALID_EMAIL'>;

export type TokenType = string;

export type SignInResponse = {
  result: {
    accessToken: TokenType;
    refreshToken: TokenType;
  };
};

export type SignOutResponse = {};

export type RefreshTokenResponse = SignInResponse;

export type BasicResponse<T> = {
  data: T;
};

export type RefreshServiceState = {
  lastResponse:
    | BasicResponse<RefreshTokenResponse>
    | BasicResponse<{ errors: ErrorEntityResponse[] }>
    | null;
  lastUpdated: Date;
  refreshPromise: Promise<BasicResponse<RefreshTokenResponse>> | null;
};
const getSeconds = (date: Date) => Math.floor(Number(date) / 1000);

export interface IApiClient {
  post: (url: string, payload?: any) => Promise<BasicResponse<any>>;
  get: (url: string) => Promise<BasicResponse<any>>;
}
export class AuthClient {
  //Сделано через класс, потому что если вдруг понадобится стейт refreshService, то мы его сможем получить
  protected signInUrl: string;
  protected signOutUrl: string;
  protected refreshUrl: string;
  protected defaultSignInOption: Partial<SignInOptions> = {};
  refreshServiceState: RefreshServiceState = {
    lastResponse: null,
    lastUpdated: new Date(0, 0),
    refreshPromise: null,
  };

  protected api: IApiClient;

  constructor(
    api: IApiClient,
    endpoints: { signIn: string; signOut: string; refresh: string },
    defaultSignInOption: Partial<SignInOptions> = {},
  ) {
    this.signInUrl = endpoints.signIn;
    this.signOutUrl = endpoints.signOut;
    this.refreshUrl = endpoints.refresh;
    this.defaultSignInOption = defaultSignInOption;
    this.api = api;
  }

  static clearCookies() {
    Cookies.remove('accessToken', {
      path: '/',
      domain: process.env.NODE_ENV === 'production' ? `.${URL}` : undefined,
    });
    Cookies.remove('refreshToken', {
      path: '/',
      domain: process.env.NODE_ENV === 'production' ? `.${URL}` : undefined,
    });
  }

  static get isAuth() {
    return Boolean(Cookies.get('accessToken'));
  }

  static setCookies(accessToken: string, refreshToken: string) {
    Cookies.set('accessToken', accessToken, {
      path: '/',
      domain: process.env.NODE_ENV === 'production' ? `.${URL}` : undefined,
    });
    Cookies.set('refreshToken', refreshToken, {
      path: '/',
      domain: process.env.NODE_ENV === 'production' ? `.${URL}` : undefined,
    });
  }

  static get tokens() {
    return {
      refreshToken: Cookies.get('refreshToken'),
      accessToken: Cookies.get('accessToken'),
    };
  }

  resetRefreshServiceState() {
    this.refreshServiceState = {
      lastResponse: null,
      lastUpdated: new Date(0, 0),
      refreshPromise: null,
    };
  }

  async signIn(
    login: string,
    password: string,
    options: Partial<SignInOptions> = {},
  ): Promise<BasicResponse<SignInResponse>> {
    try {
      const fetchDataOptions = {
        loginPropName: 'email',
        passwordPropName: 'password',
        ...this.defaultSignInOption,
        ...options,
      };

      const response = await this.api.post(this.signInUrl, {
        [fetchDataOptions.loginPropName]: login,
        [fetchDataOptions.passwordPropName]: password,
      });
      Cookies.set('accessToken', response.data.result.accessToken, {
        path: '/',
        domain: process.env.NODE_ENV === 'production' ? `.${URL}` : undefined,
      });
      Cookies.set('refreshToken', response.data.result.refreshToken, {
        path: '/',
        domain: process.env.NODE_ENV === 'production' ? `.${URL}` : undefined,
      });

      return response;
    } catch (error) {
      AuthClient.clearCookies();
      throw error;
    }
  }

  async signOut(): Promise<BasicResponse<SignOutResponse>> {
    try {
      const response = await this.api.post(this.signOutUrl);
      AuthClient.clearCookies();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async refresh(): Promise<BasicResponse<RefreshTokenResponse>> {
    const refreshToken = Cookies.get('refreshToken');
    if (!this.refreshServiceState.refreshPromise) {
      if (
        !this.refreshServiceState.lastResponse ||
        !(
          this.refreshServiceState.lastResponse?.data &&
          'errors' in this.refreshServiceState.lastResponse.data
        )
      ) {
        if (
          Math.abs(
            getSeconds(new Date()) -
              getSeconds(this.refreshServiceState.lastUpdated),
          ) > 60
        ) {
          try {
            this.refreshServiceState.refreshPromise = this.api.post(
              this.refreshUrl,
              { refreshToken },
            );
            const response = await this.refreshServiceState.refreshPromise;
            Cookies.set('accessToken', response.data.result.accessToken, {
              path: '/',
              domain:
                process.env.NODE_ENV === 'production' ? `.${URL}` : undefined,
            });
            Cookies.set('refreshToken', response.data.result.refreshToken, {
              path: '/',
              domain:
                process.env.NODE_ENV === 'production' ? `.${URL}` : undefined,
            });
            this.refreshServiceState = {
              lastResponse: response,
              lastUpdated: new Date(),
              refreshPromise: null,
            };

            return response;
          } catch (error: any) {
            AuthClient.clearCookies();
            this.refreshServiceState = {
              lastResponse: error,
              lastUpdated: new Date(),
              refreshPromise: null,
            };

            throw error;
          }
        } else {
          return this.refreshServiceState
            .lastResponse as BasicResponse<RefreshTokenResponse>;
        }
      } else {
        AuthClient.clearCookies();
        throw this.refreshServiceState.lastResponse;
      }
    } else return this.refreshServiceState.refreshPromise;
  }
}
