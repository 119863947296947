import { useTranslation } from 'react-i18next';
import { ConstructorSelectorGroup } from '../../../../../../model';
import style from './style.module.css';
import clsx from 'clsx';
import { CurrencySymbol } from 'models';

const SelectorGroupInfo = ({
  selectorGroup,
  onClickChange,
  currency,
}: {
  selectorGroup: ConstructorSelectorGroup;
  onClickChange: () => void;
  currency: CurrencySymbol;
}) => {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.header}>
            <p className={clsx('subhead', style.title)}>
              {selectorGroup.info.name}
            </p>
            <button
              onClick={onClickChange}
              className={clsx('subhead', style.changeButton)}
            >
              {selectorGroup.modifiers.length
                ? t('common.change')
                : t('catalog.constructor.modal.addGroupText')}
            </button>
          </div>
          {selectorGroup.modifiers.map((modifier, i) => (
            <div className={style.data} key={modifier.id}>
              <p className='subhead'>{modifier.info.name}</p>
              <p className='subhead'>{`${modifier.price} ${currency}`}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectorGroupInfo;
