import FeatSDKV1, {
  GetDeliveryZoneByGeolocationRequestV1,
  GetDeliveryZoneByGeolocationResponseV1,
} from 'api/version1';
import FeatSDKV2, {
  GetOrdersCheckCityByIdResponseV2,
  OrdersCheckCityByIdRequestV2,
} from 'api/version2';
import { createEffect } from 'effector';
import { Location } from 'models';
import { isInSchedule } from 'components';
import { Restaurant } from './entities';
import { BaseErrorResponse } from 'api/base';

export const getCityDeliveryZoneFx = createEffect<
  { location: Location; cityId: string },
  GetDeliveryZoneByGeolocationResponseV1.Response,
  BaseErrorResponse.ErrorResponse
>(({ location, cityId }: { location: Location; cityId: string }) => {
  return FeatSDKV1.deliveryZones.getDeliveryZoneByGeolocation({
    cityId,
    geolocation: location,
  });
});

export const fetchAddressesByRequestFx = createEffect(
  FeatSDKV1.addresses.getAddressBySearchRequest,
);

export const fetchRestaurantsFx = createEffect(
  FeatSDKV1.restaurants.getRestaurantsByCityId,
);

export const fetchDeliveryZoneByGeolocationFx = createEffect(
  FeatSDKV1.deliveryZones.getDeliveryZoneByGeolocation,
);

export const getRestaurantDepsFx = createEffect(
  async ({ restaurant }: { restaurant: Restaurant }) => {
    const timeIntervals = await FeatSDKV2.restaurants.getTimeIntervals(
      restaurant.id,
    );
    const stopLists = await FeatSDKV1.restaurants.getRestaurantStopList({
      id: restaurant.id,
    });

    let timeTo;
    let urgent;

    const schedule = {
      openAt: restaurant.openAt,
      closeAt: restaurant.closeAt,
    };

    if (
      isInSchedule(
        {
          timeInterval: timeIntervals.timeVariants[0],
          schedule,
        },
        true,
      )
    ) {
      timeTo = null;
      urgent = true;
    } else {
      timeTo = timeIntervals.timeVariants[0];
      urgent = null;
    }

    return {
      timeIntervals,
      stopLists,
      timeTo,
      urgent,
    };
  },
);

export const initGeolocationFx = createEffect(async () => {
  let userGeolocation: Location | null = null;

  const getCoordinates = () => {
    return new Promise<GeolocationPosition>(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  try {
    const {
      coords: { latitude, longitude },
    } = await getCoordinates();
    userGeolocation = { latitude, longitude };
  } catch (error) {
    userGeolocation = null;
  }

  return userGeolocation;
});

export const getDeliveryZoneFx = createEffect(
  async ({ cityId, geolocation }: GetDeliveryZoneByGeolocationRequestV1) => {
    const deliveryZone =
      await FeatSDKV1.deliveryZones.getDeliveryZoneByGeolocation({
        cityId,
        geolocation,
      });
    const timeIntervals = await FeatSDKV2.deliveryZone.getTimeIntervals(
      deliveryZone.id,
    );
    const stopLists = await FeatSDKV1.deliveryZones.getDeliveryZoneStopList({
      id: deliveryZone.id,
    });
    const catalogId = deliveryZone.catalogId;
    const zoneCityId = deliveryZone.cityId;
    const paymentTypes = deliveryZone.availablePaymentTypes;

    let timeTo;
    let urgent;

    const schedule = {
      openAt: deliveryZone.openAt,
      closeAt: deliveryZone.closeAt,
    };

    if (
      isInSchedule(
        {
          timeInterval: timeIntervals.timeVariants[0],
          schedule,
        },
        true,
      )
    ) {
      timeTo = null;
      urgent = true;
    } else {
      timeTo = timeIntervals.timeVariants[0];
      urgent = null;
    }

    return {
      deliveryZone,
      timeIntervals,
      stopLists,
      catalogId,
      zoneCityId,
      paymentTypes,
      paymentType:
        paymentTypes.filter(
          (paymentType) => paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
        ).length === 1
          ? paymentTypes.filter(
              (paymentType) =>
                paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
            )[0]
          : null,
      timeTo,
      urgent,
    };
  },
);

export const fetchCitiesFx = createEffect(FeatSDKV1.cities.getCities);

export const fetchDeliveryZonesAndMapLayerZones = createEffect(
  FeatSDKV1.deliveryZones.getDeliveryZoneAndMapLayerZoneByCityId,
);

export const fetchCountriesFx = createEffect(FeatSDKV1.countries.getCountries);

export const checkCityFx = createEffect<
  OrdersCheckCityByIdRequestV2,
  GetOrdersCheckCityByIdResponseV2,
  BaseErrorResponse.ErrorResponse
>(FeatSDKV2.orders.getOrdersCheckCity);
