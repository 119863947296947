import { useEvent } from 'hooks';
import React from 'react';
import {
  FEImageComponent,
  FEMultilineField,
  FESearchPlaceholder,
} from 'ui-kit/components';
import { FECountryPickerProps } from './props';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';
import { usePrepareLink } from 'hooks/router';
import { GET_ENUMS, GET_PARAMS } from 'const';
import { useNavigate } from 'react-router';

const Layoyt = ({
  onClose,
  counties,
  currentCountry,
  isPendingCounties,
  onChangeCountry,
  onCloseModal,
}: FECountryPickerProps & { onCloseModal: () => void }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>('');
  const [countriesList, setCountriesList] = React.useState<typeof counties>([]);
  const deferredList = React.useDeferredValue(countriesList);

  const navigate = useNavigate();

  const cityLayoutAfterCountryLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
    },
    pushToQuery: {
      [GET_PARAMS.layout]: 'city',
      separate: 'false',
    },
  });

  React.useEffect(() => setCountriesList(counties), [counties]);

  React.useLayoutEffect(() => {
    if (currentCountry) {
      setValue(currentCountry.name);
    }
  }, [currentCountry]);

  const changeQueryHandler = useEvent(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = e.target.value;
      setValue(val);
      if (val) {
        const newCountriesList = counties.filter((country) =>
          country.name.toLowerCase().includes(val.toLowerCase()),
        );
        setCountriesList(newCountriesList);
      } else setCountriesList(counties);
    },
  );

  return (
    <div className={style.root}>
      <div className={style.input}>
        <FEMultilineField
          disabled={isPendingCounties}
          hasSelectionEffect={false}
          value={value}
          onChange={changeQueryHandler}
          placeholder={t('cartography.country.picker.fieldPlaceholder')}
          onClear={() => {
            setValue('');
            setCountriesList(counties);
          }}
        />
      </div>
      <div className={style.list}>
        {deferredList.length === 0 && !!value ? (
          <div className={style.placeholderContainer}>
            <FESearchPlaceholder
              title={t('searchPlaceholderComponent.title')}
              message={t('cartography.country.picker.searchPlaceholder')}
            />
          </div>
        ) : (
          <div className={style.listContent}>
            {deferredList.map((country) => (
              <div
                className={style.listItem}
                key={country.id}
                onClick={() => {
                  if (currentCountry !== country) {
                    onChangeCountry(country);
                    onCloseModal();
                  } else {
                    onCloseModal();
                    navigate(cityLayoutAfterCountryLink);
                  }
                }}
              >
                <FEImageComponent
                  className={style.flag}
                  spinnerSize='small'
                  image={{
                    fetchFromThirdPartyOrigin: true,
                    imageId: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code.iso}.svg`,
                  }}
                />
                <p>{country.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Layoyt;
