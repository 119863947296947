import React from 'react';
import { FESpinner, FESearchPlaceholder } from 'components';
import { AddressInList } from '../props';
import style from '../styles/list.styles.module.css';
import ListItem from './ListItem';
import { useTranslation } from 'react-i18next';

const List = ({
  open,
  isPending,
  data,
  onClickAddress,
  onScroll,
  showPlaceholder,
}: {
  open: boolean;
  isPending: boolean;
  data: AddressInList[];
  onScroll: () => void;
  onClickAddress: (address: AddressInList) => void;
  showPlaceholder: boolean;
}) => {
  const { t } = useTranslation();

  if (!open) return null;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      className={style.list}
    >
      <div
        className={style.line}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
      {showPlaceholder && isPending === false && (
        <div className={style.placeholder}>
          <FESearchPlaceholder
            showIcon={false}
            title={t('searchPlaceholderComponent.title')}
            message={t('cartography.address.form.searchPlaceholder')}
          />
        </div>
      )}
      {isPending && (
        <div className={style.fetching}>
          <FESpinner size='large' />
        </div>
      )}
      <div className={style.listContent} onScroll={onScroll}>
        {data.map((address, index) => (
          <ListItem
            key={index}
            address={address}
            onClickAddress={onClickAddress}
          />
        ))}
      </div>
    </div>
  );
};

export default List;
