import React from 'react';
import FEOverlay from '../../FEOverlay';
import FENotificationCardProps from '../props';
import style from '../style.module.css';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { FEButton } from '../..';
import { GET_IMAGE } from '../model';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

const Layout = ({
  onClose,
  closeOnOverlayClick = true,
  title,
  description,
  icon,
  primaryButtonProps,
  secondaryButtonProps,
}: FENotificationCardProps) => {
  const [animationIn, setAnimationIn] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => setAnimationIn(true), []);

  function handleClose() {
    if (onClose) {
      onClose();
      setAnimationIn(false);
    }
  }
  return (
    <>
      <div className={style.dialog} role='dialog'>
        <FEOverlay
          onClick={() => closeOnOverlayClick && handleClose()}
          animationIn={animationIn}
        />
        <CSSTransition
          in={animationIn}
          timeout={200}
          mountOnEnter
          unmountOnExit
          classNames={containerAnimation}
          nodeRef={containerRef}
        >
          <div
            className={clsx(style.wrapper)}
            onClick={(e) => {
              closeOnOverlayClick && handleClose();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div
              className={style.container}
              ref={containerRef}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className={style.card}>
                <div className={style.image}>
                  <img className='icon-24' src={GET_IMAGE[icon]} alt='' />
                </div>
                <div className={style.content}>
                  <h3 className={clsx('title', style.title)}>{title}</h3>
                  <p className={clsx('text-2', style.description)}>
                    {description}
                  </p>
                </div>
                <div className={style.submit}>
                  <FEButton
                    children={primaryButtonProps.text}
                    onClick={() => {
                      primaryButtonProps.onClick();
                      primaryButtonProps.closeOnClick && handleClose();
                    }}
                  />
                  {secondaryButtonProps && (
                    <FEButton
                      type='secondary'
                      children={secondaryButtonProps.text}
                      onClick={() => {
                        secondaryButtonProps.onClick();
                        secondaryButtonProps.closeOnClick && handleClose();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
      injec
    </>
  );
};

export default Layout;
