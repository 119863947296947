import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetGiftRepurchasesResponseV1 } from '.';

const giftRepurchasesV1 = {
  getGiftRepurchases: ({ cityId }: { cityId: string }, signal?: AbortSignal) =>
    new Promise<GetGiftRepurchasesResponseV1.GiftRepurchase[]>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/api/v1/gift-repurchases?cityId=${cityId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetGiftRepurchasesResponseV1.GiftRepurchase[]>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetGiftRepurchasesResponseV1.GiftRepurchase[]>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
  notSecureGetGiftRepurchases: (
    { cityId }: { cityId: string },
    signal?: AbortSignal,
  ) =>
    new Promise<GetGiftRepurchasesResponseV1.GiftRepurchase[]>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/gift-repurchases?cityId=${cityId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetGiftRepurchasesResponseV1.GiftRepurchase[]>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetGiftRepurchasesResponseV1.GiftRepurchase[]>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
  getGiftRepurchaseById: ({ id }: { id: string }, signal?: AbortSignal) =>
    new Promise<GetGiftRepurchasesResponseV1.GiftRepurchase>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/api/v1/gift-repurchases/${id}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetGiftRepurchasesResponseV1.GiftRepurchase>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetGiftRepurchasesResponseV1.GiftRepurchase>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
  notSecureGetGiftRepurchasesById: (
    { id }: { id: string },
    signal?: AbortSignal,
  ) =>
    new Promise<GetGiftRepurchasesResponseV1.GiftRepurchase>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/gift-repurchases/${id}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetGiftRepurchasesResponseV1.GiftRepurchase>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetGiftRepurchasesResponseV1.GiftRepurchase>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default giftRepurchasesV1;
