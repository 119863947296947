import axios from 'axios';
import { AuthClient } from '../authClient';
import { nanoid } from 'nanoid';
import { RepositoryInstanceConfig } from 'api/base';
import { FEHeadersCookiesUtil, FELocalStorageUtil } from 'utils';
import moment from 'moment';
import { Language } from 'models';
import md5 from 'md5';
import { INITIAL_LANGUAGE } from 'const';

export type AcceptLanguage = 'ru-RU' | 'en-AE';
export interface Headers {
  'X-Device-Type'?: string;
  'X-Auth-Token'?: string;
  'X-Device-Token'?: string;
  'X-Date'?: string;
  'X-Version'?: string;
  'X-Customer'?: string;
  'X-City-Id'?: string;
  'Accept-Language'?: AcceptLanguage;
}

const getAcceptLanguage: Record<Language, AcceptLanguage> = {
  ru: 'ru-RU',
  en: 'en-AE',
};

export const getConfirmHeadersValues = () => {
  const code = FEHeadersCookiesUtil.getCode;
  const date =
    moment()
      .locale('en')
      .utc(true)
      .utcOffset(new Date().getTimezoneOffset())
      .format('ddd, D MMM YYYY HH:mm:ss') + ' GMT';

  return {
    code,
    date,
  };
};

const getHeaders = () => {
  const headers: Headers = {
    'X-Device-Type': 'WEB',
  };
  const token = AuthClient.tokens.accessToken;
  if (token) {
    headers['X-Auth-Token'] = token;
  }
  //TODO: вот тут плохо используется localStorage
  if (localStorage.getItem('deviceToken')) {
    headers['X-Device-Token'] = localStorage.getItem('deviceToken') as string;
  } else {
    const deviceToken = nanoid();
    headers['X-Device-Token'] = deviceToken;
  }
  return { common: { ...headers } };
};

export const axiosRepository = ({
  baseUrl,
  timeout,
  tokenRefresh,
  authErrors = {
    reasonKey: 'reason',
    tokenExpired: ['ACCESS_TOKEN_EXPIRED'],
    permissionInsufficient: ['CREDENTIAL_PERMISSION_INSUFFICIENT'],
    needToLogin: [
      'CREDENTIAL_BLOCKED',
      'ACCESS_TOKEN_INACTIVATED',
      'ACCESS_TOKEN_NOT_EXIST',
      'ACCESS_TOKEN_REFRESHED',
      'ACCESS_TOKEN_MODIFIED',
      'ACCESS_TOKEN_MALFORMED',
    ],
  },
}: RepositoryInstanceConfig) => {
  const headers = getHeaders();
  const repository = axios.create({
    // @ts-ignore
    headers,
    baseURL: baseUrl,
    timeout: timeout,
  });
  repository.defaults.timeout = 30000;
  repository.interceptors.request.use(
    (request) => {
      const lng = FEHeadersCookiesUtil.getLanguage || INITIAL_LANGUAGE;
      if (lng) {
        // @ts-ignore
        request.headers.common['Accept-Language'] = getAcceptLanguage[lng];
      }
      const { city } = FELocalStorageUtil.getCartography;
      if (city) {
        // @ts-ignore
        request.headers.common['X-City-Id'] = city.id;
      }
      const { code, date } = getConfirmHeadersValues();

      const phone = FEHeadersCookiesUtil.getPhone;
      if (phone) {
        // @ts-ignore
        request.headers.common['X-Customer'] = phone;
      }

      // @ts-ignore
      request.headers.common['X-Date'] = date;
      if (code) {
        // @ts-ignore
        request.headers.common['X-Version'] = md5(code + date);
      }
      const token = AuthClient.tokens.accessToken;
      if (token) {
        // @ts-ignore
        request.headers.common['X-Auth-Token'] = token;
      }
      return request;
    },
    (error) => error,
  );

  repository.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const reason =
        error.response?.data?.errors[0]?.[authErrors.reasonKey || 'reason'];
      if (authErrors.tokenExpired.includes(reason)) {
        let response;
        try {
          response = (await tokenRefresh()).data.result;
          if ('accessToken' in response === false) throw response;
        } catch (err) {
          AuthClient.clearCookies();
          return Promise.reject();
        }
        const config = error.config;
        config.headers['X-Auth-Token'] = response.accessToken;
        return await repository.request(config);
      } else if (authErrors.permissionInsufficient.includes(reason)) {
        return Promise.reject();
      } else if (authErrors.needToLogin.includes(reason)) {
        AuthClient.clearCookies();
        return Promise.reject();
      }

      return Promise.reject(error);
    },
  );
  return repository;
};
