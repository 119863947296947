import { GetOrdersCheckCityByIdResponseV2 } from 'api/version2';
import clsx from 'clsx';
import { useMount } from 'hooks';
import { useEffect, useState } from 'react';
import { FEBottomSheet, FEButton, FEDialog } from 'ui-kit/components';
import { useMediaQuery } from 'ui-kit/hooks';
import { IMG_MODAL_SORRY } from 'ui-kit/images';
import style from './style.module.css';

const Content = ({
  data,
  onClose,
}: Omit<Props, 'data'> & { data: GetOrdersCheckCityByIdResponseV2 }) => {
  const { title, body, buttonText } = data.alert;
  return (
    <div className={style.informationBanner}>
      <img src={IMG_MODAL_SORRY} alt='' className={style.icon} />
      <h1 className={clsx('title', style.title)}>{title}</h1>
      <p className={clsx('text-2', style.body)}>{body}</p>
      <div className={style.submit}>
        <FEButton onClick={onClose}>{buttonText}</FEButton>
      </div>
    </div>
  );
};

export const CityInformationBanner = ({
  data: dataFromProps,
  onClose,
}: Props) => {
  const isDesktop = useMediaQuery(`(min-width: 500px)`);
  const { mounted } = useMount({ isOpened: !!dataFromProps });
  const [data, setData] = useState<GetOrdersCheckCityByIdResponseV2 | null>(
    null,
  );

  useEffect(() => {
    if (mounted) setData(dataFromProps!);
    else setData(null);
  }, [mounted, dataFromProps]);

  if (!data) return null;

  return isDesktop ? (
    <FEDialog
      open={true}
      onClose={onClose}
      render={({ onClose }) => <Content onClose={onClose} data={data} />}
    />
  ) : (
    <FEBottomSheet
      open={true}
      onClose={onClose}
      render={({ onClose }) => <Content onClose={onClose} data={data} />}
    />
  );
};

type Props = {
  data: GetOrdersCheckCityByIdResponseV2 | null;
  onClose: () => void;
};
