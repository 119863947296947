import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetCatalogProductsRequestV2 } from '.';
import { GetCatalogProductsResponseV2 } from '.';

const catalogRepositoryV2 = {
  getCatalogProducts: (
    { request, catalogId, cityId }: GetCatalogProductsRequestV2,
    signal?: AbortSignal,
  ) =>
    new Promise<GetCatalogProductsResponseV2.Response>(
      async (resolve, reject) => {
        try {
          let url: string;
          if (request) {
            const { query, limit, offset, sortName, sortDirection } = request;
            url =
              `${BASE_URL}/not-secure/api/v2/catalogs/${catalogId}/products?cityId=${cityId}` +
              `${query ? `&query=${query}` : ''}` +
              `${limit ? `&limit=${limit}` : ''}` +
              `${offset ? `&offset=${offset}` : ''}` +
              `${query ? `&query=${query}` : ''}` +
              `${sortDirection ? `&sortDirection=${sortDirection}` : ''}` +
              `${sortName ? `&sortName=${sortName}` : ''}`;
          } else
            url = `${BASE_URL}/not-secure/api/v2/catalogs/${catalogId}/products?cityId=${cityId}`;

          let result: AxiosResponse<
            BaseAPIResponse<GetCatalogProductsResponseV2.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetCatalogProductsResponseV2.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default catalogRepositoryV2;
