import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import {
  GetAddressByGeolocationRequestV1,
  GetAddressBySearchRequestV1,
} from '.';
import {
  GetAddressByGeolocationResponseV1,
  GetAddressBySearchResponseV1,
} from '.';

const addressesRepositoryV1 = {
  getAddressBySearchRequest: (
    { cityId, query }: GetAddressBySearchRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetAddressBySearchResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/address?cityId=${cityId}&query=${query}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetAddressBySearchResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetAddressBySearchResponseV1.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getAddressByGeolocation: (
    { latitude, longitude }: GetAddressByGeolocationRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetAddressByGeolocationResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/places/address/geolocate?latitude=${latitude}&longitude=${longitude}`;
        let result: AxiosResponse<
          BaseAPIResponse<GetAddressByGeolocationResponseV1>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetAddressByGeolocationResponseV1>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default addressesRepositoryV1;
