import { useMount } from 'hooks';
import { Language } from 'models';
import { Layout } from './layout';
import { Portal } from './portal';

export const LanguagePicker = ({
  open,
  ...rest
}: {
  open: boolean;
  onClose: () => void;
  onChangeLanguage: (language: Language) => void;
  currentLanguage: Language;
}) => {
  const { mounted } = useMount({ isOpened: open });

  if (!mounted) return null;

  return (
    <Portal>
      <Layout {...rest} />
    </Portal>
  );
};
