import clsx from 'clsx';
import { ConstructorCurrentAddonGroup } from 'pages/catalog/components/constructorCard/model';
import { FEQuantity } from 'components';
import style from './style.module.css';
import { CurrencySymbol } from 'models';
import {useEffect, useRef, useState} from "react";

const AddonGroupInfo = ({
  addonGroup,
  onClickChange,
  onChangeModifierQuantity,
  currency,
  currentSum
}: {
  currency: CurrencySymbol;
  addonGroup: ConstructorCurrentAddonGroup;
  onClickChange: () => void;
  currentSum: number
  onChangeModifierQuantity: ({
    index,
    modifierId,
    quantity,
  }: {
    index: number;
    modifierId: string;
    quantity: number;
  }) => void;
}) => {
  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.header}>
            <p className={clsx('subhead', style.title)}>
              {addonGroup.info.name}
            </p>
            <button
              onClick={onClickChange}
              className={clsx('subhead', style.changeButton)}
            >
              {addonGroup.modifiers.length ? 'Изменить' : 'Добавить'}
            </button>
          </div>
          {addonGroup.modifiers.map((modifier, i) => (
            <div className={style.data} key={modifier.id}>
              <p className={clsx('subhead', style.name)}>
                {modifier.info.name}
              </p>
              <div className={style.quantityContainer}>
                <FEQuantity
                  value={modifier.quantity}
                  min={0}
                  max={modifier.max}
                  disabledMax={currentSum === addonGroup.max}
                  onChange={(v) =>
                    onChangeModifierQuantity({
                      index: i,
                      modifierId: modifier.id,
                      quantity: v,
                    })
                  }
                />
              </div>
              <p className='subhead'>{`${
                modifier.price * modifier.quantity
              } ${currency}`}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddonGroupInfo;
