import React from 'react';
import { FEButton } from 'components';
import style from './style.module.css';
import _ from 'lodash';
import { Modifier } from '..';
import {
  DishAddonGroupModifier,
  DishCurrentAddonGroupModifier,
} from '../../../../../../../../models';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const BottomSheetContent = ({
  name,
  onClose,
  onSubmit,
  baseModifiers,
  currentModifiers,
  max,
  currency,
  isReachedGroupMax = false
}: {
  name: string;
  onClose: () => void;
  onSubmit: (modifiers: DishCurrentAddonGroupModifier[]) => void;
  baseModifiers: DishAddonGroupModifier[];
  currentModifiers: DishCurrentAddonGroupModifier[];
  currency: CurrencySymbol;
  max: number;
  isReachedGroupMax?: boolean
}) => {
  const { t } = useTranslation();
  const [newModifiers, setNewModifiers] = React.useState<
    DishCurrentAddonGroupModifier[]
  >([]);

  const modifiers = React.useMemo(() => {
    const currentModifiersObject = Object.fromEntries(
      currentModifiers.map((currentModifier) => [
        currentModifier.id,
        currentModifier,
      ]),
    );

    return baseModifiers.map((modifier) => {
      const currentModifier: DishCurrentAddonGroupModifier | undefined =
        currentModifiersObject[modifier.id] || undefined;

      if (currentModifier) {
        return {
          ...modifier,
          quantity: currentModifier.quantity,
        };
      }
      return {
        ...modifier,
        quantity: 0,
      };
    });
  }, [currentModifiers, baseModifiers]);

  React.useEffect(() => {
    setNewModifiers(modifiers);
  }, [modifiers]);

  const isSubmitDisabled = React.useMemo(() => {
    const currentModifiersObject = Object.fromEntries(
      currentModifiers.map((currentModifier) => [
        currentModifier.id,
        currentModifier,
      ]),
    );

    return _.isEqual(
      baseModifiers.map((modifier) => {
        const currentModifier: DishCurrentAddonGroupModifier | undefined =
          currentModifiersObject[modifier.id] || undefined;

        if (currentModifier) {
          return {
            ...modifier,
            quantity: currentModifier.quantity,
          };
        }
        return {
          ...modifier,
          quantity: 0,
        };
      }),
      newModifiers,
    );
  }, [baseModifiers, currentModifiers, newModifiers]);

  const hasActiveModifiers = React.useMemo(
    () => newModifiers.filter((modifier) => modifier.quantity > 0).length !== 0,
    [newModifiers],
  );

  const price = React.useMemo(() => {
    if (newModifiers.filter((modifier) => modifier.quantity > 0).length === 0) {
      return 0;
    } else {
      return newModifiers
        .filter((modifier) => modifier.quantity > 0)
        .reduce(
          (accumulator, modifier) =>
            accumulator + modifier.price * modifier.quantity,
          0,
        );
    }
  }, [newModifiers]);

  const handleChangeQuantity = (id: string, quantity: number) => {
    const newCurrentModifiers = newModifiers.map((modifier) => {
      if (modifier.id === id) {
        modifier.quantity = quantity;
      }
      return modifier;
    });
    setNewModifiers(newCurrentModifiers);
  };

  return (
    <div className={style.root}>
      <div className={style.header}>
        <button className='button-arrowBack' onClick={onClose} />
        <p className='title'>{name}</p>
      </div>
      <div className={style.container}>
        <div className={style.content}>
          {newModifiers.map((modifier, i) => (
            <Modifier
              currency={currency}
              key={modifier.id}
              max={modifier.max}
              isReachedGroupMax={isReachedGroupMax}
              disabled={
                modifier.quantity === 0 && currentModifiers.length === max
              }
              quantity={modifier.quantity}
              name={modifier.info.name}
              id={modifier.id}
              onChangeQuantity={handleChangeQuantity}
              price={modifier.price}
              imageId={modifier.info.imageId}
            />
          ))}
        </div>
      </div>
      <div className={style.submit}>
        <FEButton
          disabled={isSubmitDisabled}
          onClick={() => {
            onSubmit(newModifiers);
            onClose();
          }}
        >
          {isSubmitDisabled || !hasActiveModifiers ? (
            t('common.next')
          ) : (
            <div className={style.submitButtonContent}>
              <p className='headline'>{t('catalog.dish.modal.addGroupText')}</p>
              <p className='headline'>{`+ ${price} ${currency}`}</p>
            </div>
          )}
        </FEButton>
      </div>
    </div>
  );
};

export default BottomSheetContent;
