import React from 'react';
import { useEvent, useMergedRef } from 'hooks';
import style from './style.module.css';
import clsx from 'clsx';
import FETextFieldProps from './props';

export default React.forwardRef<HTMLInputElement, FETextFieldProps>(
  (
    {
      value,
      onChange,
      label,
      hasError = false,
      cleareble = false,
      disabled = false,
      onClear,
      maxLength,
      type = 'text',
      min,
      max,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = React.useState<boolean>(false);
    const innerRef = React.useRef<HTMLInputElement>(null);
    const mergedRef = useMergedRef(ref, innerRef);

    const toggleFocusHandler = useEvent(() => setIsFocused(!isFocused));

    return (
      <div
        className={clsx(style.root, hasError && style.root__error)}
        onClick={(e) => {
          if (innerRef.current && !disabled) innerRef.current.focus();
          // e.preventDefault();
          // e.stopPropagation();
        }}
      >
        <div className={style.container}>
          <div className={style.textField}>
            <input
              min={min}
              max={max}
              disabled={disabled}
              maxLength={maxLength}
              onFocus={toggleFocusHandler}
              onBlur={toggleFocusHandler}
              value={value ?? ''}
              onChange={onChange}
              id='FETextField'
              type={type}
              ref={mergedRef}
              className={clsx(
                'text-1',
                style.textField__input,
                disabled && style.textField__disabled,
              )}
            />
            {label && (
              <label
                className={clsx(
                  style.floatingLabel,
                  'text-1',
                  (isFocused || Boolean(value)) && style.floatingLabel__focused,
                  hasError && style.floatingLabel__error,
                  disabled && style.floatingLabel__disabled,
                )}
                htmlFor='FESearchField'
              >
                {label}
              </label>
            )}
          </div>
        </div>
        {value && cleareble && !disabled && (
          <i
            className={style.clearIcon}
            onClick={(e) => {
              onClear && onClear();
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
      </div>
    );
  },
);

export type { FETextFieldProps };
