import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetBonusAccountTransactionsRequestV1 } from '.';
import {
  GetBonusAccountTransactionsResponseV1,
  GetBonusAccountsResponseV1,
} from '.';

const bonusAccountsRepositoryV1 = {
  getBonusAccounts: () =>
    new Promise<GetBonusAccountsResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/api/v1/bonus-accounts`;
          let result: AxiosResponse<
            BaseAPIResponse<GetBonusAccountsResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetBonusAccountsResponseV1.ListResponse>
          >(url);
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getBonusAccountTransactions: (
    { id, request }: GetBonusAccountTransactionsRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetBonusAccountTransactionsResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url: string;
          if (request) {
            const { query, limit, offset, sortName, sortDirection } = request;
            url =
              `${BASE_URL}/api/v1/bonus-accounts/${id}/transactions?` +
              `${query ? `query=${query}` : ''}` +
              `${limit ? `&limit=${limit}` : ''}` +
              `${offset ? `&offset=${offset}` : ''}` +
              `${query ? `&query=${query}` : ''}` +
              `${sortDirection ? `&sortDirection=${sortDirection}` : ''}` +
              `${sortName ? `&sortName=${sortName}` : ''}`;
          } else url = `${BASE_URL}/api/v1/bonus-accounts/${id}/transactions`;

          let result: AxiosResponse<
            BaseAPIResponse<GetBonusAccountTransactionsResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetBonusAccountTransactionsResponseV1.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default bonusAccountsRepositoryV1;
