import { GetDeliveryZoneStopListResponseV1 } from 'api/version1';
import { createStore } from 'effector';
import { CatalogCategory, CatalogProducts } from './entities';

export const $catalogProducts = createStore<CatalogProducts | null>(null);

export const $catalogCategories = createStore<CatalogCategory[]>([]);

export const $catalogId = createStore<string | null>(null);

export const $stopLists = createStore<GetDeliveryZoneStopListResponseV1 | null>(
  null,
);
