import React from 'react';
import { FEBottomSheet, FEDialog } from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import { Content } from './components';
import { DishCardModalProps } from './props';
import {
  // DishAddonGroup,
  DishBase,
  DishCurrentAddonGroup,
  DishSelectorGroup,
  DishSwitchGroup,
} from '../../models';
import { GET_MEASURE } from 'const';
import { useUnit } from 'effector-react';
import { addDishToCartFx, DishToCartModel } from 'domains/cart';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';
import { $catalogCategories } from 'domains/catalog';

const DishCardModal = ({
  changeShowLoader,
  open,
  onClose,
  dish,
  stopLists,
  validationPayload,
  currency,
}: DishCardModalProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${1024}px)`);

  const [isImageSuccess, setIsImageSuccess] = React.useState<boolean>(true);

  const [base, setBase] = React.useState<DishBase>();
  const [switchGroups, setSwitchGroups] = React.useState<DishSwitchGroup[]>([]);
  const [addonGroups, setAddonGroups] = React.useState<DishCurrentAddonGroup[]>(
    [],
  );
  const [selectorGroups, setSelectorGroups] = React.useState<
    DishSelectorGroup[]
  >([]);
  const [quantity, setQuantity] = React.useState<number>(1);

  const { nutritionFacts, description, bases, baseTitle, name, defaultBaseId } =
    dish;

  const filteredBases = React.useMemo(
    () =>
      stopLists
        ? bases.filter((base) => !Boolean(stopLists.bases.includes(base.id)))
        : bases,
    [bases, stopLists],
  );
  React.useEffect(() => {
    const defaultBase = filteredBases.find(
      (filteredBase) => filteredBase.id === defaultBaseId,
    );
    if (defaultBase) setBase(defaultBase);
    else setBase(filteredBases[0]);
  }, [defaultBaseId, filteredBases]);

  const categories = useUnit($catalogCategories);

  const filteredBaseSwitchGroups = React.useMemo(() => {
    if (base) {
      if (stopLists)
        return base.switchGroups
          .filter(
            (switchGroup) =>
              !Boolean(stopLists.modifierGroups.includes(switchGroup.id)),
          )
          .map((switchGroup) => ({
            ...switchGroup,
            modifiers: switchGroup.modifiers.filter(
              (modifier) => !Boolean(stopLists.modifiers.includes(modifier.id)),
            ),
          }));
      return base.switchGroups;
    } else return [];
  }, [base, stopLists]);

  React.useEffect(
    () =>
      setSwitchGroups(
        filteredBaseSwitchGroups.map((filteredSwitchGroup) => ({
          ...filteredSwitchGroup,
          modifiers: filteredSwitchGroup.modifiers.find(
            (modifier) => modifier.id === filteredSwitchGroup.defaultModifierId,
          )
            ? filteredSwitchGroup.modifiers.filter(
                (modifier) =>
                  modifier.id === filteredSwitchGroup.defaultModifierId,
              )
            : [filteredSwitchGroup.modifiers[0]],
        })),
      ),
    [filteredBaseSwitchGroups],
  );
  const filteredBaseSelectorGroups = React.useMemo(() => {
    if (base) {
      if (stopLists)
        return base.selectorGroups
          .filter(
            (selectorGroup) =>
              !Boolean(stopLists.modifierGroups.includes(selectorGroup.id)),
          )
          .map((selectorGroup) => ({
            ...selectorGroup,
            modifiers: selectorGroup.modifiers.filter(
              (modifier) => !Boolean(stopLists.modifiers.includes(modifier.id)),
            ),
          }));
      return base.selectorGroups;
    } else return [];
  }, [base, stopLists]);

  React.useEffect(
    () =>
      setSelectorGroups(
        filteredBaseSelectorGroups.map((filteredSelectorGroup) => ({
          ...filteredSelectorGroup,
          modifiers: filteredSelectorGroup.modifiers.filter(
            (modifier) => modifier.byDefault,
          ),
        })),
      ),
    [filteredBaseSelectorGroups],
  );

  const onkeydown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  React.useEffect(() => {
    if (open) {
      window.addEventListener('keydown', onkeydown);
    }
    return () => {
      window.removeEventListener('keydown', onkeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const filteredBaseAddonGroups = React.useMemo(() => {
    if (base) {
      if (stopLists)
        return base.addonGroups
          .filter(
            (addonGroup) =>
              !Boolean(stopLists.modifierGroups.includes(addonGroup.id)),
          )
          .map((addonGroup) => ({
            ...addonGroup,
            modifiers: addonGroup.modifiers.filter(
              (modifier) => !Boolean(stopLists.modifiers.includes(modifier.id)),
            ),
          }));
      return base.addonGroups;
    } else return [];
  }, [base, stopLists]);

  React.useEffect(
    () =>
      setAddonGroups(
        filteredBaseAddonGroups.map((filteredAddonGroup) => ({
          ...filteredAddonGroup,
          modifiers: [],
        })),
      ),
    [filteredBaseAddonGroups],
  );

  const baseSize = React.useMemo(() => {
    if (base) {
      setIsImageSuccess(true);
      const quantity = base.quantity;
      const size = base.size;

      if (quantity && size)
        return `${quantity} ${t(GET_MEASURE['PIECE'])} / ${size.value} ${t(
          GET_MEASURE[size.measure],
        )}`;

      if (quantity) return `${quantity} ${t(GET_MEASURE['PIECE'])}`;

      if (size) return `${size.value} ${t(GET_MEASURE[size.measure])}`;
    }
  }, [base, t]);

  React.useEffect(() => {
    const { defaultBaseId, bases } = dish;
    if (stopLists) {
      const filteredBases = bases.filter(
        (base) => !Boolean(stopLists.bases.includes(base.id)),
      );
      const defaultBase = filteredBases.find(
        (base) => base.id === defaultBaseId,
      );
      if (defaultBase) setBase(defaultBase);
      else {
        setBase(filteredBases[0]);
      }
    } else {
      const defaultBase = bases.find((base) => base.id === defaultBaseId)!;
      setBase(defaultBase);
    }
  }, [dish, stopLists]);

  const hasNutritionFacts = React.useMemo(() => {
    return Boolean(nutritionFacts);
  }, [nutritionFacts]);

  const price = React.useMemo(() => {
    if (base) {
      const switchPrice = switchGroups.reduce(
        (accumulator, group) => accumulator + group.modifiers[0]?.price || 0,
        0,
      );
      const selectorPrice = selectorGroups.reduce(
        (accumulator, group) =>
          accumulator +
          group.modifiers.reduce(
            (accumulator, modifier) => accumulator + modifier.price,
            0,
          ),
        0,
      );
      const addonPrice = addonGroups.reduce(
        (accumulator, group) =>
          accumulator +
          group.modifiers.reduce(
            (accumulator, modifier) =>
              accumulator + modifier.price * modifier.quantity,
            0,
          ),
        0,
      );
      const price =
        (base.price + switchPrice + selectorPrice + addonPrice) * quantity;
      return price;
    } else return 0;
  }, [base, addonGroups, selectorGroups, switchGroups, quantity]);

  const onAddDishToCart = useUnit(addDishToCartFx);

  const handleAddDishToCart = () => {
    changeShowLoader(true);
    const dishToCart: DishToCartModel = {
      categoryId: dish.categoryId,
      price: base?.price ?? 0,
      name: dish.name,
      quantity: quantity,
      productId: dish.id,
      base: {
        id: base!.id,
        addonGroups: addonGroups
          .filter((addonGroup) => Boolean(addonGroup.modifiers.length))
          .map((addonGroup) => ({
            id: addonGroup.id,
            modifiers: addonGroup.modifiers.map((modifier) => ({
              id: modifier.id,
              quantity: modifier.quantity,
            })),
          })),
        selectorGroups: selectorGroups.map((selectorGroup) => ({
          id: selectorGroup.id,
          modifiers: selectorGroup.modifiers.map((modifier) => ({
            id: modifier.id,
          })),
        })),
        switchGroups: switchGroups.map((switchGroup) => ({
          id: switchGroup.id,
          modifiers: switchGroup.modifiers.map((modifier) => ({
            id: modifier.id,
          })),
        })),
      },
    };

    onAddDishToCart({ dish: dishToCart, validationPayload, categories }).then(
      () => changeShowLoader(false),
      () => changeShowLoader(false),
    );
  };

  return isDesktop ? (
    <FEDialog
      {...{ open, onClose }}
      render={({ onClose }) => (
        <Content
          {...{
            currency,
            onClose,
            isImageSuccess,
            setIsImageSuccess,
            base,
            bases: filteredBases,
            description,
            baseSize,
            setSwitchGroups,
            baseTitle,
            switchGroups,
            baseSwitchGroups: filteredBaseSwitchGroups,
            baseAddonGroups: filteredBaseAddonGroups,
            addonGroups,
            setAddonGroups,
            selectorGroups,
            setSelectorGroups,
            setBase,
            name,
            hasNutritionFacts,
            nutritionFacts,
            price,
            baseSelectorGroups: filteredBaseSelectorGroups,
            quantity,
            setQuantity,
            handleAddDishToCart,
          }}
        />
      )}
    />
  ) : (
    <FEBottomSheet
      {...{ open, onClose }}
      render={({ onClose }) => (
        <Content
          {...{
            currency,
            onClose,
            isImageSuccess,
            setIsImageSuccess,
            base,
            bases: filteredBases,
            addonGroups,
            description,
            baseSize,
            setSwitchGroups,
            baseTitle,
            switchGroups,
            baseSwitchGroups: filteredBaseSwitchGroups,
            selectorGroups,
            setSelectorGroups,
            baseAddonGroups: filteredBaseAddonGroups,
            setAddonGroups,
            setBase,
            name,
            hasNutritionFacts,
            nutritionFacts,
            price,
            baseSelectorGroups: filteredBaseSelectorGroups,
            quantity,
            setQuantity,
            handleAddDishToCart,
          }}
        />
      )}
    />
  );
};

export default DishCardModal;
