import React from 'react';
import FEOverlay from '../../FEOverlay';
import FEBottomSheetProps from '../props';
import style from '../style.module.css';
import { CSSTransition } from 'react-transition-group';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

const Layout = ({
  onClose,
  closeOnOverlayClick = true,
  render,
}: FEBottomSheetProps) => {
  const [animationIn, setAnimationIn] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => setAnimationIn(true), []);

  function handleClose() {
    if (onClose) {
      onClose();
      setAnimationIn(false);
    }
  }

  function handleCloseModal() {
    setAnimationIn(false);
  }

  return (
    <div className={style.bottomSheet} role='dialog'>
      <FEOverlay
        onClick={() => closeOnOverlayClick && handleClose()}
        animationIn={animationIn}
      />

      <CSSTransition
        in={animationIn}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={containerAnimation}
        nodeRef={containerRef}
      >
        <div className={style.container} ref={containerRef}>
          {render({ onClose: handleClose, onCloseModal: handleCloseModal })}
        </div>
      </CSSTransition>
    </div>
  );
};

export default Layout;
