import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FEHeadersCookiesUtil, FERequestErrorsHandler } from 'utils';
import {
  DeleteCustomerRequestV1,
  UpdateCustomerRequestV1,
  UpdateCustomerAvatarRequestV1,
} from '.';

import { GetCustomerResponseV1 } from '.';

import md5 from 'md5';

const customerRepositoryV1 = {
  getCustomer: (signal?: AbortSignal) =>
    new Promise<GetCustomerResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v1/customer/me`;
        let result: AxiosResponse<BaseAPIResponse<GetCustomerResponseV1>> =
          await BaseAPIRepository.get<BaseAPIResponse<GetCustomerResponseV1>>(
            url,
            { signal },
          );
        resolve(result.data.result);
        FEHeadersCookiesUtil.setPhone(md5(result.data.result.phone));
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  updateCustomer: (request: UpdateCustomerRequestV1, signal?: AbortSignal) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v1/customer/me`;
        let result: AxiosResponse<BaseAPIResponse<string>> =
          await BaseAPIRepository.post<BaseAPIResponse<string>>(url, request, {
            signal,
          });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  updateAvatar: (
    request: UpdateCustomerAvatarRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v1/customer/avatar`;
        let result: AxiosResponse<BaseAPIResponse<string>> =
          await BaseAPIRepository.post<BaseAPIResponse<string>>(url, request, {
            signal,
          });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  deleteCustomer: (request: DeleteCustomerRequestV1, signal?: AbortSignal) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v1/customer/delete`;
        let result: AxiosResponse<BaseAPIResponse<string>> =
          await BaseAPIRepository.post<BaseAPIResponse<string>>(url, request, {
            signal,
          });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default customerRepositoryV1;
