import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FEHeadersCookiesUtil, FERequestErrorsHandler } from 'utils';
import { AuthClient } from 'api/base';
import {
  AuthConfirmRequestV1,
  AuthRefreshRequestV1,
  AuthStartRequestV1,
} from '.';
import { AuthConfirmResponseV1, AuthRefreshResponseV1 } from '.';

const authRepositoryV1 = {
  start: (request: AuthStartRequestV1, signal?: AbortSignal) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/auth/start`;
        let result: AxiosResponse<BaseAPIResponse<string>> =
          await BaseAPIRepository.post<BaseAPIResponse<string>>(url, request, {
            signal,
          });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  refresh: (request: AuthRefreshRequestV1, signal?: AbortSignal) =>
    new Promise<AuthRefreshResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/auth/refresh`;
        let result: AxiosResponse<BaseAPIResponse<AuthRefreshResponseV1>> =
          await BaseAPIRepository.post<BaseAPIResponse<AuthRefreshResponseV1>>(
            url,
            request,
            { signal },
          );

        AuthClient.setCookies(
          result.data.result.accessToken,
          result.data.result.refreshToken,
        );
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  confirm: (
    { mask, ...request }: AuthConfirmRequestV1 & { mask: string },
    signal?: AbortSignal,
  ) =>
    new Promise<AuthConfirmResponseV1>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/auth/confirm`;
        let result: AxiosResponse<BaseAPIResponse<AuthConfirmResponseV1>> =
          await BaseAPIRepository.post<BaseAPIResponse<AuthConfirmResponseV1>>(
            url,
            request,
            { signal },
          );

        AuthClient.setCookies(
          result.data.result.accessToken,
          result.data.result.refreshToken,
        );
        FEHeadersCookiesUtil.setPhoneMask(mask);
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default authRepositoryV1;
