import { ConstructorInCartModel, DishInCartModel } from 'domains/cart';
import React from 'react';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import { ProductProps } from './props';
import style from './style.module.css';
import clsx from 'clsx';
import { FEImageComponent, FEQuantity } from 'components';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const Product = ({
  product: productFromProps,
  type,
  onChangeQuantity,
  onDelete,
  isValidating,
  currency,
}: ProductProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const {
    problem,
    product: { price, quantity, product, base },
  } = productFromProps;

  const productDescription = React.useMemo(() => {
    if (type === 'CONSTRUCTOR') {
      const {
        product: {
          base: { name, selectorGroups, addonGroups },
        },
      } = productFromProps as ConstructorInCartModel;

      return `${name}${
        selectorGroups.length !== 0 &&
        selectorGroups.some(
          (selectorGroup) => selectorGroup.modifiers.length !== 0,
        )
          ? `, ${selectorGroups
              .map((selectorGroup) =>
                selectorGroup.modifiers
                  .map((modifier) => modifier.info.name)
                  .join(', '),
              )
              .join(', ')}`
          : ''
      } ${
        addonGroups.length !== 0 &&
        addonGroups.some((addonGroup) => addonGroup.modifiers.length !== 0)
          ? `, ${addonGroups
              .map((addonGroup) =>
                addonGroup.modifiers
                  .map(
                    (modifier) =>
                      modifier.info.name + ` × ${modifier.quantity}`,
                  )
                  .join(', '),
              )
              .join(', ')}`
          : ''
      }`;
    } else {
      const {
        product: {
          base: { name, switchGroups, selectorGroups, addonGroups },
        },
      } = productFromProps as DishInCartModel;

      return `${name}${
        switchGroups.length !== 0
          ? `, ${switchGroups
              .map((switchGroup) =>
                switchGroup.modifiers
                  .map((modifier) => modifier.info.name)
                  .join(', '),
              )
              .join(', ')}`
          : ''
      } ${
        selectorGroups.length !== 0 &&
        selectorGroups.some(
          (selectorGroup) => selectorGroup.modifiers.length !== 0,
        )
          ? `, ${selectorGroups
              .map((selectorGroup) =>
                selectorGroup.modifiers
                  .map((modifier) => modifier.info.name)
                  .join(', '),
              )
              .join(', ')}`
          : ''
      } ${
        addonGroups.length !== 0 &&
        addonGroups.some((addonGroup) => addonGroup.modifiers.length !== 0)
          ? `, ${addonGroups
              .map((addonGroup) =>
                addonGroup.modifiers
                  .map(
                    (modifier) =>
                      modifier.info.name + ` × ${modifier.quantity}`,
                  )
                  .join(', '),
              )
              .join(', ')}`
          : ''
      }`;
    }
  }, [productFromProps, type]);

  return (
    <div className={style.root}>
      <div className={style.container}>
        <FEImageComponent
          className={style.image}
          image={{ imageId: base.imageId }}
          altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
        />
        <div className={style.data}>
          <p className='subhead'>{product.name}</p>
          <p className={clsx('caption-1')}>{productDescription}</p>
          {Boolean(problem) === false ? (
            price && (
              <p className='subhead'>
                {`${Math.trunc(price.total)} ${currency}`}{' '}
                {Boolean(price.discount) && (
                  <s className={clsx('caption-1', style.fullPrice)}>{`${
                    price.price * quantity
                  } ${currency}`}</s>
                )}
              </p>
            )
          ) : (
            <p className={clsx('subhead', style.unavaliableMessage)}>
              {t('catalog.cart.unavailableProductLabel')}
            </p>
          )}
        </div>
        <div className={style.event}>
          {Boolean(problem) ? (
            <button
              className={clsx('subhead', style.eventButton)}
              onClick={() => onDelete(productFromProps, type)}
            >
              {t('common.delete')}
            </button>
          ) : (
            <FEQuantity
              onChange={(val) =>
                val > 0
                  ? onChangeQuantity(val)
                  : onDelete(productFromProps, type)
              }
              disabled={isValidating}
              value={quantity}
              min={0}
              max={100}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
