import { PromotionModel } from 'domains/promotion';
import { FEImageComponent } from 'components';
import { IMG_PLACEHOLDER_PERCENT, IMG_IC_24_TIME_DELIVERY } from 'images';
import style from './style.module.css';
import clsx from 'clsx';
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const Promotion = memo(
  ({
    promotion,
    onClick,
  }: {
    promotion: PromotionModel;
    onClick: (promotionId: string) => void;
  }) => {
    const { t } = useTranslation();
    const { name, description, imageId, endAt } = promotion;

    return (
      <>
        <div className={style.root} onClick={() => onClick(promotion.id)}>
          <div className={style.container}>
            <div className={style.content}>
              {/* TODO: модифицировать плейсхолдер */}
              <FEImageComponent
                processingComponent={
                  <img
                    alt=''
                    className={style.processingImage}
                    src={IMG_PLACEHOLDER_PERCENT}
                  />
                }
                altImage={IMG_PLACEHOLDER_PERCENT}
                loaderContainerClassName={style.loader}
                className={style.image}
                image={{ imageId }}
                spinnerSize='medium'
              />
              <div className={style.data}>
                <p className='subhead'>{name}</p>
                <p className={clsx('caption-1', style.description)}>
                  {description}
                </p>
                {!!endAt && (
                  <div className={style.endAt}>
                    <img src={IMG_IC_24_TIME_DELIVERY} alt='' />

                    <p className='caption-1'>
                      {t('promotions.promotionDateTo', {
                        to: moment(endAt).format('DD.MM.YYYY HH:mm'),
                      })}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default Promotion;
