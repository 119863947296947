import React from 'react';
import { IMG_MODAL_SORRY } from 'images';
import style from './style.module.css';
import clsx from 'clsx';
import { FEButton } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Page404 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={style.root}>
      <div className={style.container}>
        <img src={IMG_MODAL_SORRY} alt='sorry' className={style.icon} />
        <h1 className={clsx('title', style.title)}>{t('404.title')}</h1>
        <p className={clsx('text-2', style.message)}>{t('404.message')}</p>
        <div className={style.buttonsContainer}>
          <FEButton
            onClick={() => {
              navigate(-1);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            {t('404.primaryButtonText')}
          </FEButton>
          <FEButton
            onClick={() => {
              navigate('/');
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            type='secondary'
          >
            {t('404.secondaryButtonText')}
          </FEButton>
        </div>
      </div>
    </div>
  );
};

export default Page404;
