import React from 'react';
import { IMG_PLACEHOLDER_SEARCH } from 'images';
import clsx from 'clsx';
import FESearchPlaceholderProps from './props';
import style from './style.module.css';

const FESearchPlaceholder = ({
  title = 'Поиск не дал результатов',
  message,
  showIcon = true,
}: FESearchPlaceholderProps) => {
  return (
    <div className={style.root}>
      {showIcon && (
        <img className={style.root} src={IMG_PLACEHOLDER_SEARCH} alt='' />
      )}
      <div className={style.data}>
        <p className={clsx('subhead', style.title)}>{title}</p>
        <p className={clsx('text-2', style.message)}>{message}</p>
      </div>
    </div>
  );
};

export default FESearchPlaceholder;
export type { FESearchPlaceholderProps };
