import clsx from 'clsx';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { FEButton } from '../..';
import { swiperDefaults } from '../model';
import style from '../style.module.css';
import { useTranslation } from 'react-i18next';
import { Mousewheel } from 'swiper';

const Picker = ({
  days,
  timeIntervals,
  initialDayIndex,
  initialTimeIntervalIndex,
  changeDay,
  changeTimeInterval,
  submit,
  onClose,
}: {
  days: string[];
  timeIntervals: string[];
  initialDayIndex: number;
  initialTimeIntervalIndex: number;
  changeDay: (index: number) => void;
  changeTimeInterval: (index: number) => void;
  submit: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [swiperInstance, setSwiperInstance] = React.useState<SwiperClass>();
  const [dayIndex, setDayIndex] = React.useState<number>(initialDayIndex);
  const [timeIntervalIndex, setTimeIntevalIndex] = React.useState<number>(
    initialTimeIntervalIndex,
  );

  return (
    <div className={style.root}>
      <div className={style.picker}>
        <div className={style.days}>
          <Swiper
            {...swiperDefaults}
            mousewheel={true}
            modules={[Mousewheel]}
            initialSlide={initialDayIndex}
            onSlideChange={(e) => {
              swiperInstance && swiperInstance.slideTo(0, 1);
              changeDay(e.activeIndex);
              setDayIndex(e.activeIndex);
            }}
          >
            {days.map((day, i) => (
              <SwiperSlide key={i}>
                <div className={style.item}>
                  <p
                    className={clsx('text-1', i !== dayIndex && style.inactive)}
                  >
                    {day}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={style.timeIntervals}>
          <Swiper
            {...swiperDefaults}
            onAfterInit={setSwiperInstance}
            initialSlide={initialTimeIntervalIndex}
            onSlideChange={(e) => {
              changeTimeInterval(e.activeIndex);
              setTimeIntevalIndex(e.activeIndex);
            }}
          >
            {timeIntervals.map((timeInterval, i) => (
              <SwiperSlide key={i}>
                <div className={style.item}>
                  <p
                    className={clsx(
                      'text-1',
                      i !== timeIntervalIndex && style.inactive,
                    )}
                  >
                    {timeInterval}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={style.submit}>
        <FEButton
          onClick={() => {
            submit();
            onClose();
          }}
        >
          {t('common.apply')}
        </FEButton>
      </div>
    </div>
  );
};

export default Picker;
