import ordersRepositoryV2 from './orders';
import dishesRepositoryV2 from './dishes';
import productsRepositoryV2 from './products';
import catalogRepositoryV2 from './catalog';
import deliveryZoneRepositoryV2 from './deliveryZone';
import restaurantsRepositoryV2 from './restaurants';
import promotionsRepositoryV2 from './promotions';

export * from './orders';
export * from './dishes';
export * from './products';
export * from './catalog';
export * from './deliveryZone';
export * from './restaurants';
export * from './promotions';

const FeatSDKV2 = {
  orders: ordersRepositoryV2,
  dishes: dishesRepositoryV2,
  products: productsRepositoryV2,
  catalog: catalogRepositoryV2,
  deliveryZone: deliveryZoneRepositoryV2,
  restaurants: restaurantsRepositoryV2,
  promotions: promotionsRepositoryV2,
};

export default FeatSDKV2;
