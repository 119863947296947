import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import {
  OrdersValidateRequestV2,
  GetAllOrdersRequestV2,
  GetOrderByIdRequestV2,
  OrdersCheckCityByIdRequestV2,
  OrdersCreateRequestV2,
  OrdersAvaliablePaymentTypesRequestV2,
  GetOrderLongPollRequestV2,
} from '.';

import {
  OrdersAvaliablePaymentTypesResponseV2,
  OrdersValidateResponseV2,
  OrdersCreateResponseV2,
  GetAllOrdersResponseV2,
  GetOrderByIdResponseV2,
  GetOrdersCheckCityByIdResponseV2,
  GetOrderLongPollResponseV2,
} from '.';

const ordersRepositoryV2 = {
  getAllOrders: (
    { query, limit, offset, sortName, sortDirection }: GetAllOrdersRequestV2,
    signal?: AbortSignal,
  ) =>
    new Promise<GetAllOrdersResponseV2.ListResponse>(
      async (resolve, reject) => {
        try {
          let url =
            `${BASE_URL}/api/v2/orders?` +
            `${query ? `query=${query}` : ''}` +
            `${limit ? `&limit=${limit}` : ''}` +
            `${offset ? `&offset=${offset}` : ''}` +
            `${query ? `&query=${query}` : ''}` +
            `${sortDirection ? `&sortDirection=${sortDirection}` : ''}` +
            `${sortName ? `&sortName=${sortName}` : ''}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetAllOrdersResponseV2.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetAllOrdersResponseV2.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getOrderById: ({ id }: GetOrderByIdRequestV2, signal?: AbortSignal) =>
    new Promise<GetOrderByIdResponseV2.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v2/orders/${id}`;
        let result: AxiosResponse<
          BaseAPIResponse<GetOrderByIdResponseV2.Response>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetOrderByIdResponseV2.Response>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  getOrderLongPoll: (
    { id, timeout }: GetOrderLongPollRequestV2,
    signal?: AbortSignal,
  ) =>
    new Promise<GetOrderLongPollResponseV2.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v2/orders/long-polling/${id}/change?timeout=${timeout}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetOrderLongPollResponseV2.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetOrderLongPollResponseV2.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getOrdersCheckCity: (
    { id }: OrdersCheckCityByIdRequestV2,
    signal?: AbortSignal,
  ) =>
    new Promise<GetOrdersCheckCityByIdResponseV2>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v2/orders/check-city/${id}`;
        let result: AxiosResponse<
          BaseAPIResponse<GetOrdersCheckCityByIdResponseV2>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetOrdersCheckCityByIdResponseV2>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  ordersValidate: (
    request: OrdersValidateRequestV2.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersValidateResponseV2.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v2/orders/validate`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersValidateResponseV2.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersValidateResponseV2.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  ordersCreate: (
    request: OrdersCreateRequestV2.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersCreateResponseV2.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v2/orders/create`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersCreateResponseV2.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersCreateResponseV2.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  ordersAvaliablePaymentTypes: (
    request: OrdersAvaliablePaymentTypesRequestV2.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersAvaliablePaymentTypesResponseV2>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/api/v2/orders/available-payment-types`;
          let result: AxiosResponse<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV2>
          > = await BaseAPIRepository.post<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV2>
          >(url, request, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  notSecureOrdersValidate: (
    request: OrdersValidateRequestV2.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersValidateResponseV2.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v2/orders/validate`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersValidateResponseV2.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersValidateResponseV2.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  notSecureOrdersCreate: (
    request: OrdersCreateRequestV2.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersCreateResponseV2.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v2/orders/create`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersCreateResponseV2.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersCreateResponseV2.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  notSecureOrdersAvaliablePaymentTypes: (
    request: OrdersAvaliablePaymentTypesRequestV2.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersAvaliablePaymentTypesResponseV2>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v2/orders/available-payment-types`;
          let result: AxiosResponse<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV2>
          > = await BaseAPIRepository.post<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV2>
          >(url, request, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default ordersRepositoryV2;
