import React from 'react';
import FEDeliveryTimePickerProps, { Schedule } from '../props';
import { SwiperProps } from 'swiper/react';
import moment from 'moment';
import 'moment/locale/ru';
import { FEDateUtils } from 'utils';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Language } from 'models';

export const swiperDefaults: Partial<SwiperProps> = {
  slidesPerView: 5,
  freeMode: true,
  direction: 'vertical',
  slideToClickedSlide: true,
  centeredSlides: true,
  initialSlide: 0,
};

export const isInSchedule = (
  {
    timeInterval,
    schedule,
  }: {
    timeInterval: string;
    schedule: Schedule;
  },
  utc?: boolean,
) => {
  const { closeAt, openAt } = {
    closeAt: moment().format('YYYY-MM-DD') + ` ${schedule.closeAt}`,
    openAt: moment().format('YYYY-MM-DD') + ` ${schedule.openAt}`,
  };

  const momentTimeInterval = moment(timeInterval);

  return (
    moment(
      (utc
        ? FEDateUtils.addUtcOffset(momentTimeInterval)
        : momentTimeInterval
      ).format('YYYY-MM-DD HH:mm'),
    ).isBetween(openAt, closeAt) &&
    moment(moment().format('YYYY-MM-DD HH:mm')).isBetween(openAt, closeAt)
  );
};

const formatDay = (day: string, language: Language) => {
  if (
    moment(moment(day).format('YYYY-MM-DD')).isSame(
      moment().format('YYYY-MM-DD'),
    )
  )
    return i18next.t('cart.main.deliveryTimePicker.stringValue.today');
  return moment(day).locale(language).format('ddd DD MMM');
};

const formatTimeInterval = (
  timeInterval: string,
  timeIntervals: string[],
  timeError: number,
  schedule: Schedule | null,
) => {
  if (
    timeInterval === timeIntervals[0] &&
    (schedule ? isInSchedule({ timeInterval, schedule }) : true)
  )
    return i18next.t('cart.main.deliveryTimePicker.stringValue.makeItQuick');
  if (timeError === 0) return moment(timeInterval).format('HH:mm');

  const first = moment(timeInterval)
    .subtract(timeError, 'minutes')
    .format('HH:mm');
  const second = moment(timeInterval).add(timeError, 'minutes').format('HH:mm');
  return `${first} - ${second}`;
};

const formatTimeIntervalToDate = (timeInterval: string) =>
  moment(timeInterval).format('YYYY-MM-DD');

export const useTimePicker = ({
  timeTo: timeToFromProps,
  urgent: urgentFromProps,
  timeIntervals: timeIntervalsFromProps,
  onChange,
  open,
  deliveryTimeError,
  schedule,
  language = 'ru',
}: FEDeliveryTimePickerProps & { open: boolean }) => {
  const { t } = useTranslation();
  const [displayedValue, setDisplayedValue] = React.useState<string>(
    t('cart.main.deliveryTimePicker.placeholder'),
  );
  const [days, setDays] = React.useState<string[]>([]);
  const [timeIntervals, setTimeIntervals] = React.useState<string[]>([]);
  const [initialDayIndex, setInitialDayIndex] = React.useState<number>(0);
  const [initialTimeIntervalIndex, setInitialTimeIntervalIndex] =
    React.useState<number>(0);
  const [currentDayIndex, setCurrentDayIndex] = React.useState<number>();
  const [currentTimeIntervalIndex, setCurrentTimeIntervalIndex] =
    React.useState<number>();
  const [timeByDays, setTimeByDays] = React.useState<{
    [key: string]: string[];
  }>();

  const changeDay = (index: number) => {
    if (timeByDays) {
      const daysArray = Object.keys(timeByDays);
      setCurrentDayIndex(index);
      setTimeIntervals(
        timeByDays[daysArray[index]].map((timeInterval) =>
          formatTimeInterval(
            timeInterval,
            timeIntervalsFromProps,
            deliveryTimeError,
            schedule,
          ),
        ),
      );
    }
  };
  const changeTimeInterval = (index: number) => {
    setCurrentTimeIntervalIndex(index);
  };

  React.useLayoutEffect(() => {
    if (timeIntervalsFromProps.length) {
      let timeByDays: { [key: string]: string[] } = {};
      const days = new Set(
        timeIntervalsFromProps.map(formatTimeIntervalToDate),
      );
      for (let key of Array.from(days)) {
        timeByDays[key] = timeIntervalsFromProps.filter(
          (timeInterval) => formatTimeIntervalToDate(timeInterval) === key,
        );
      }
      const daysArray = Array.from(days);
      setTimeByDays(timeByDays);
      setDays(daysArray.map((day) => formatDay(day, language)));
      if (timeToFromProps && currentDayIndex) {
        setTimeIntervals(
          timeByDays[daysArray[currentDayIndex]].map((timeInterval) =>
            formatTimeInterval(
              timeInterval,
              timeIntervalsFromProps,
              deliveryTimeError,
              schedule,
            ),
          ),
        );
      } else {
        setTimeIntervals(
          timeByDays[daysArray[0]].map((timeInterval) =>
            formatTimeInterval(
              timeInterval,
              timeIntervalsFromProps,
              deliveryTimeError,
              schedule,
            ),
          ),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeIntervalsFromProps, timeToFromProps, open, language]);

  React.useLayoutEffect(() => {
    if (timeByDays) {
      if (timeToFromProps) {
        const daysArray = Object.keys(timeByDays);
        const timeToDate = formatTimeIntervalToDate(timeToFromProps);
        const dayIndex = daysArray.findIndex((day) => day === timeToDate);
        const timeIntervalIndex = timeByDays[
          formatTimeIntervalToDate(timeToFromProps)
        ].findIndex((timeInterval) => timeInterval === timeToFromProps);

        setInitialDayIndex(dayIndex);
        setInitialTimeIntervalIndex(timeIntervalIndex);
        setCurrentDayIndex(dayIndex);
        setCurrentTimeIntervalIndex(timeIntervalIndex);
        const displayedValue = `${formatDay(
          daysArray[dayIndex],
          language,
        )}, ${formatTimeInterval(
          timeByDays[formatTimeIntervalToDate(timeToFromProps)][
            timeIntervalIndex
          ],
          timeIntervalsFromProps,
          deliveryTimeError,
          schedule,
        )}`;
        setDisplayedValue(displayedValue);
      } else {
        const firstTimeInterval = timeIntervalsFromProps[0];
        setInitialDayIndex(0);
        setInitialTimeIntervalIndex(0);
        setCurrentDayIndex(0);
        setCurrentTimeIntervalIndex(0);
        const displayedValue = `${formatDay(
          firstTimeInterval,
          language,
        )}, ${formatTimeInterval(
          firstTimeInterval,
          timeIntervalsFromProps,
          deliveryTimeError,
          schedule,
        )}`;
        setDisplayedValue(displayedValue);
        // onChange({ timeTo: null, urgent: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeByDays, open, language]);

  const submit = () => {
    if (
      timeByDays &&
      currentDayIndex !== undefined &&
      currentTimeIntervalIndex !== undefined
    ) {
      const day = Object.keys(timeByDays)[currentDayIndex];
      const timeInterval = timeByDays[day][currentTimeIntervalIndex];
      const displayedValue = `${formatDay(
        timeInterval,
        language,
      )}, ${formatTimeInterval(
        timeInterval,
        timeIntervalsFromProps,
        deliveryTimeError,
        schedule,
      )}`;
      setDisplayedValue(displayedValue);
      onChange({
        timeTo:
          currentDayIndex === 0 &&
          currentTimeIntervalIndex === 0 &&
          (schedule ? isInSchedule({ timeInterval, schedule }) : true)
            ? null
            : timeInterval,
        urgent:
          currentDayIndex === 0 &&
          currentTimeIntervalIndex === 0 &&
          (schedule ? isInSchedule({ timeInterval, schedule }) : true)
            ? true
            : null,
      });
    }
  };

  return {
    displayedValue,

    days,
    timeIntervals,
    initialDayIndex,
    initialTimeIntervalIndex,

    changeDay,
    changeTimeInterval,

    submit,
  };
};
