import React from 'react';
import ReactDOM from 'react-dom';
import FEPortalProps from './props';

const FEPortal = ({ children }: FEPortalProps) => {
  const [container] = React.useState(() => document.createElement('div'));

  React.useEffect(() => {
    const visible = document.body.style.overflow !== 'hidden';
    document.body.appendChild(container);
    if (visible) document.body.style.overflow = 'hidden';
    return () => {
      document.body.removeChild(container);
      if (visible) document.body.style.overflow = 'visible';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(children, container);
};

export default FEPortal;
export type { FEPortalProps };
