import React from 'react';
import { Order as OrderModel } from 'domains/profile';
import { useGetParameter } from 'hooks/router';
import { GET_PARAMS, MOBILE_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import { FEBottomSheet, FEDialog } from 'components';
import { Content } from './components';
import { CurrencySymbol } from 'models';

const OrderDetails = ({
  order,
  onRepeatOrder,
  currency,
  onClose,
}: {
  order: OrderModel;
  onRepeatOrder: ({ order }: { order: OrderModel }) => void;
  currency: CurrencySymbol;
  onClose: () => void;
}) => {
  const queryOrderId = useGetParameter(GET_PARAMS.orderId);
  const [orderId, setOrderId] = React.useState(queryOrderId);

  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  const isOpened = Boolean(orderId === order.id);

  React.useEffect(() => {
    if (queryOrderId) {
      setOrderId(queryOrderId);
    } else setOrderId(null);
  }, [queryOrderId]);

  return isDesktop ? (
    <FEDialog
      showCloseButton={false}
      onClose={onClose}
      open={isOpened}
      render={({ onClose }) => (
        <Content
          currency={currency}
          onClose={onClose}
          order={order}
          onRepeatOrder={onRepeatOrder}
        />
      )}
    />
  ) : (
    <FEBottomSheet
      onClose={onClose}
      open={isOpened}
      render={({ onClose }) => (
        <Content
          currency={currency}
          onClose={onClose}
          order={order}
          onRepeatOrder={onRepeatOrder}
        />
      )}
    />
  );
};

export default OrderDetails;
