import React from 'react';
import { FEImageComponent, FEMiniCard } from 'components';
import FEModifierCardProps from './props';
import { IMG_PLACEHOLDER_CARD_PICTURE_MOBILE } from 'images';
import style from './style.module.css';
import clsx from 'clsx';
import { GET_MEASURE } from 'const';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const FEModifierCard = ({
  active,
  onClick,
  imageId,
  name,
  description,
  price,
  disabled,
  size,
  currency,
}: FEModifierCardProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const [showNutritionFacts, setShowNutritionFacts] =
    React.useState<boolean>(false);

  const { visibleDescription, hiddenDescription } = React.useMemo(() => {
    if (description) {
      if (description.length >= 30) {
        return {
          visibleDescription:
            description.slice(0, 27).lastIndexOf(' ') !== -1
              ? description.slice(
                  0,
                  description.slice(0, 27).lastIndexOf(' '),
                ) + '...'
              : description.slice(0, 27) + '...',
          hiddenDescription:
            description.slice(0, 27).lastIndexOf(' ') !== -1
              ? '+ ' +
                description.slice(description.slice(0, 27).lastIndexOf(' '))
              : '+ ' + description.slice(27),
        };
      } else {
        return {
          visibleDescription: description,
          hiddenDescription: undefined,
        };
      }
    } else {
      return {
        visibleDescription: undefined,
        hiddenDescription: undefined,
      };
    }
  }, [description]);

  return (
    <FEMiniCard {...{ active, onClick, disabled }}>
      <div className={style.root}>
        {hiddenDescription !== undefined && (
          <button
            className={clsx('button-icon-24', style.nutritionFactsButton)}
            onClick={(e) => {
              setShowNutritionFacts(!showNutritionFacts);
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
        <FEImageComponent
          className={style.image}
          image={{ imageId }}
          altImage={IMG_PLACEHOLDER_CARD_PICTURE_MOBILE}
        />
        <div className={style.dataContainer}>
          <p className={clsx('subhead', style.name)}>{name}</p>
          {visibleDescription && (
            <p className={clsx('caption-1', style.description)}>
              {visibleDescription}
            </p>
          )}
          {size && (
            <p className={clsx('caption-1', style.size)}>{`${size.value} ${t(
              GET_MEASURE[size.measure],
            )}`}</p>
          )}
          {price !== undefined &&
            (typeof price === 'string' ? (
              <p className='subhead'>{price}</p>
            ) : (
              <p className='subhead'>{`${price} ${currency}`}</p>
            ))}
        </div>
        {showNutritionFacts && (
          <div className={style.nutritionFacts__container}>
            <div className={style.nutritionFacts__content}>
              <p className={clsx('headline', style.nutritionFacts__title)}>
                {t('catalog.composition')}
              </p>
              <p className={clsx('text-2', style.nutritionFacts)}>
                {hiddenDescription}
              </p>
            </div>
          </div>
        )}
      </div>
    </FEMiniCard>
  );
};

export default FEModifierCard;
export type { FEModifierCardProps };
