import clsx from 'clsx';
import FEOpeningBarProps from './props';
import style from './style.module.css';

const FEOpeningBar = ({
  label,
  value,
  image,
  chevronDirection = 'down',
  onClick,
  hasError = false,
  valueTextType = 'primary',
}: FEOpeningBarProps) => {
  return (
    <div
      className={clsx(style.root, hasError && style.error)}
      onClick={(e) => {
        onClick();
      }}
    >
      <div className={clsx(style.container, hasError && style.containerError)}>
        {image && (
          <img src={image} alt='' className={clsx('icon-24', style.icon)} />
        )}
        <div className={style.data}>
          <p
            className={clsx(
              'caption-1',
              style.label,
              hasError && style.labelError,
            )}
          >
            {label}
          </p>
          <p
            className={clsx(
              'text-1',
              style.value,
              valueTextType === 'secondary' && style.value__secondary,
            )}
          >
            {value}
          </p>
        </div>
        <button
          className={clsx(
            chevronDirection === 'down' && 'button-chevronDown',
            chevronDirection === 'right' && 'button-chevronRight',
            style.exposeButton,
          )}
        />
      </div>
    </div>
  );
};

export default FEOpeningBar;
export type { FEOpeningBarProps };
