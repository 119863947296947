import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import {
  GetAllOrdersRequestV1,
  GetOrderByIdRequestV1,
  OrdersAvaliablePaymentTypesRequestV1,
  OrdersCreateRequestV1,
  OrdersValidateRequestV1,
} from '.';

import {
  GetAllOrdersResponseV1,
  GetOrderByIdResponseV1,
  OrdersCreateResponseV1,
  OrdersValidateResponseV1,
  OrdersAvaliablePaymentTypesResponseV1,
} from '.';

const ordersRepositoryV1 = {
  notSecureValidate: (
    request: OrdersValidateRequestV1.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersValidateResponseV1.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/orders/validate`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersValidateResponseV1.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersValidateResponseV1.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  notSecureCreate: (
    request: OrdersCreateRequestV1.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersCreateResponseV1.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/orders/create`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersCreateResponseV1.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersCreateResponseV1.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  notSecureAvaliablePaymentTypes: (
    request: OrdersAvaliablePaymentTypesRequestV1.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersAvaliablePaymentTypesResponseV1>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/orders/create`;
          let result: AxiosResponse<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV1>
          > = await BaseAPIRepository.post<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV1>
          >(url, request, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  validate: (request: OrdersValidateRequestV1.Request, signal?: AbortSignal) =>
    new Promise<OrdersValidateResponseV1.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v1/orders/validate`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersValidateResponseV1.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersValidateResponseV1.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  create: (request: OrdersCreateRequestV1.Request, signal?: AbortSignal) =>
    new Promise<OrdersCreateResponseV1.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/api/v1/orders/create`;
        let result: AxiosResponse<
          BaseAPIResponse<OrdersCreateResponseV1.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<OrdersCreateResponseV1.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  avaliablePaymentTypes: (
    request: OrdersAvaliablePaymentTypesRequestV1.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<OrdersAvaliablePaymentTypesResponseV1>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/api/v1/orders/create`;
          let result: AxiosResponse<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV1>
          > = await BaseAPIRepository.post<
            BaseAPIResponse<OrdersAvaliablePaymentTypesResponseV1>
          >(url, request, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getAllOrders: (
    { query, limit, offset, sortName, sortDirection }: GetAllOrdersRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetAllOrdersResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url =
            `${BASE_URL}/api/v1/orders?` +
            `${query ? `query=${query}` : ''}` +
            `${limit ? `&limit=${limit}` : ''}` +
            `${offset ? `&offset=${offset}` : ''}` +
            `${query ? `&query=${query}` : ''}` +
            `${sortDirection ? `&sortDirection=${sortDirection}` : ''}` +
            `${sortName ? `&sortName=${sortName}` : ''}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetAllOrdersResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetAllOrdersResponseV1.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getOrderById: ({ id }: GetOrderByIdRequestV1, signal?: AbortSignal) =>
    new Promise<GetOrderByIdResponseV1.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/orders/${id}`;
        let result: AxiosResponse<
          BaseAPIResponse<GetOrderByIdResponseV1.Response>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetOrderByIdResponseV1.Response>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default ordersRepositoryV1;
