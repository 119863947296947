import clsx from 'clsx';
import { CurrencySymbol } from 'models';
import { ConstructorBase } from '../../../../../../model';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';

const BaseInfo = ({
  base,
  title,
  onClickChange,
  currency,
}: {
  base?: ConstructorBase;
  title: string;
  onClickChange: () => void;
  currency: CurrencySymbol;
}) => {
  const { t } = useTranslation();
  if (base === undefined) return null;

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.header}>
            <p className={clsx('subhead', style.title)}>{title}</p>
            <button
              onClick={onClickChange}
              className={clsx('subhead', style.changeButton)}
            >
              {t('common.change')}
            </button>
          </div>
          <div className={style.data}>
            <p className='subhead'>{base.name}</p>
            <p className='subhead'>{`${base.price} ${currency}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseInfo;
