import React from 'react';
import FECityPickerProps from './props';
import { useMediaQuery } from 'ui-kit/hooks';
import { MOBILE_WIDTH } from 'const';
import { FETitledBottomSheet, FETitledDialog } from 'components';
import { List } from './components';
import { useTranslation } from 'react-i18next';

const FECityPicker = ({
  currentCity,
  data,
  onPickCity,
  onClose,
  isPendingData,
  closable = true,
  onGoBack,
  open,
  separate,
}: FECityPickerProps & { separate: boolean | null; onGoBack: () => void }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  return isDesktop ? (
    <FETitledDialog
      onClose={onClose}
      onArrowBackClick={(separate === false && onGoBack) || undefined}
      showCloseButton={closable}
      closeOnOverlayClick={closable}
      title={t('cartography.city.picker.title')}
      open={open}
      render={({ onClose }) => (
        <List
          {...{
            currentCity,
            data,
            onPickCity,
            onClose,
            isPendingData,
            closable,
            open,
          }}
        />
      )}
    />
  ) : (
    <FETitledBottomSheet
      onClose={onClose}
      onArrowBackClick={(separate === false && onGoBack) || undefined}
      showCloseButton={closable}
      closeOnOverlayClick={closable}
      title={t('cartography.city.picker.title')}
      open={open}
      render={({ onClose }) => (
        <List
          {...{
            currentCity,
            data,
            onPickCity,
            isPendingData,
            onClose,
            closable,
            open,
          }}
        />
      )}
    />
  );
};

export default FECityPicker;
export type { FECityPickerProps };
