import { initCatalogFx } from 'domains/application';
import { sample } from 'effector';
import { getPromotionsByCatalogIdFx } from './effects';
import { $promotions } from './stores';

sample({
  clock: initCatalogFx.doneData,
  fn: ({ catalogId }) => ({ catalogId }),
  target: getPromotionsByCatalogIdFx,
});

sample({
  clock: getPromotionsByCatalogIdFx.doneData,
  fn: (promotions) => promotions.items,
  target: $promotions,
});
sample({
  clock: getPromotionsByCatalogIdFx.failData,
  fn: () => [],
  target: $promotions,
});
