import clsx from 'clsx';
import React from 'react';
import { AddressInList } from '../props';
import style from '../styles/listItem.styles.module.css';

const ListItem = ({
  address,
  onClickAddress,
}: {
  address: AddressInList;
  onClickAddress: (address: AddressInList) => void;
}) => {
  return (
    <div
      className={style.root}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClickAddress(address);
      }}
    >
      <p className='text-1'>{address.address}</p>
      <p className={clsx('text-2', style.details)}>{address.details}</p>
    </div>
  );
};

export default ListItem;
