import { Order } from 'domains/profile';
import { createEvent } from 'effector';
import { PromotionProduct } from './entities';

export const setTime = createEvent<{
  timeTo: string | null;
  urgent: boolean | null;
}>();

export const changeDishQuantity = createEvent<{
  index: number;
  quantity: number;
}>();
export const changeConstructorQuantity = createEvent<{
  index: number;
  quantity: number;
}>();
export const changeCutleryQuantity = createEvent<{
  index: number;
  quantity: number;
}>();

export const deleteDish = createEvent<{ index: number }>();
export const deleteConstructor = createEvent<{ index: number }>();
export const deleteCutlery = createEvent<{ index: number }>();

export const changeDishQuantityFromCatalog = createEvent<{
  productId: string;
  quantity: number;
}>();

export const hideValidationError = createEvent<{ index: number }>();

export const validate = createEvent();

export const showCreatedOrderModal = createEvent<{
  icon: 'checkmark' | 'error';
  title: string;
  description: string;
  button: {
    text: string;
    onClick: () => void;
  };
  orderId: string | null;
  type: 'ERROR' | 'SUCCESS';
  orderNumber: string | null;
  price: string | null;
  items:
    | {
        Product: {
          productID: string;
          category: '1';
          price: string;
          priceCurrency: 'RUB' | 'AED';
        };
        orderQuantity: number;
        additionalType: 'sale';
      }[]
    | null;
}>();
export const closeCreatedOrderModal = createEvent();

export const repeatOrder = createEvent<{ order: Order }>();

export const clearCartAfterOrder = createEvent();

export const changePromotionProduct = createEvent<{
  promotionProduct: PromotionProduct | null;
}>();

export const clearCart = createEvent();
