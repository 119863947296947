import React from 'react';
import clsx from 'clsx';
import FEMultilineFieldProps from './props';
import style from './style.module.css';
import { useEvent, useMergedRef } from 'hooks';

export default React.forwardRef<HTMLTextAreaElement, FEMultilineFieldProps>(
  (
    {
      value,
      className,
      onChange,
      onClear,
      minRows = 1,
      maxRows = 3,
      hasSelectionEffect = true,
      cleareble = true,
      maxLength,
      label,
      hasError = false,
      disabled = false,
      placeholder,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = React.useState<boolean>(false);
    const innerRef = React.useRef<HTMLTextAreaElement>(null);
    const firstRenderRef = React.useRef<boolean>(false);
    const mergedRef = useMergedRef(ref, innerRef);
    const [rows, setRows] = React.useState<number>(0);

    const toggleFocusHandler = useEvent(() => setIsFocused(!isFocused));

    React.useLayoutEffect(() => {
      if (firstRenderRef.current) {
        const input = innerRef.current;
        const textareaLineHeight = 24;
        if (input) {
          const currentRows = ~~(input.scrollHeight / textareaLineHeight);
          setRows(currentRows);
        }
      } else setRows(minRows);
      firstRenderRef.current = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, firstRenderRef.current]);

    const handleChange = useEvent(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e);
        const textareaLineHeight = 24;
        const previousRows = e.target.rows;
        e.target.rows = minRows;
        const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
        if (currentRows === previousRows) {
          e.target.rows = currentRows;
        }
        if (currentRows >= maxRows) {
          e.target.rows = maxRows;
          e.target.scrollTop = e.target.scrollHeight;
        }
        setRows(currentRows < maxRows ? currentRows : maxRows);
      },
    );

    return (
      <>
        <div
          className={clsx(
            style.root,
            className,
            isFocused && hasSelectionEffect && style.root__focused,
            hasError && style.root__error,
          )}
          onClick={(e) => {
            if (innerRef.current && !disabled) innerRef.current.focus();
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className={style.container}>
            <div
              className={clsx(
                style.searchField,
                label === undefined && style.searchField__noLabel,
              )}
            >
              <textarea
                placeholder={placeholder}
                disabled={disabled}
                rows={rows}
                maxLength={maxLength}
                onFocus={toggleFocusHandler}
                onBlur={toggleFocusHandler}
                className={clsx(
                  'text-1',
                  style.searchField__input,
                  disabled && style.searchField__disabled,
                )}
                ref={mergedRef}
                id='FESearchField'
                onChange={handleChange}
                value={value ?? ''}
              />
              {label && (
                <label
                  className={clsx(
                    style.floatingLabel,
                    'text-1',
                    (isFocused || Boolean(value)) &&
                      style.floatingLabel__focused,
                    hasError && style.floatingLabel__error,
                    disabled && style.floatingLabel__disabled,
                  )}
                  htmlFor='FESearchField'
                >
                  {label}
                </label>
              )}
            </div>
            {value && cleareble && !disabled && (
              <i
                className={style.clearIcon}
                onClick={(e) => {
                  onClear && onClear();
                  setRows(minRows);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  },
);
export type { FEMultilineFieldProps };
