import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetStoryByIdRequestV1 } from '.';
import { GetStoryByIdResponseV1 } from '.';

const storiesRepositoryV1 = {
  getStoryByid: ({ id }: GetStoryByIdRequestV1, signal?: AbortSignal) =>
    new Promise<GetStoryByIdResponseV1.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/stories/${id}`;
        let result: AxiosResponse<
          BaseAPIResponse<GetStoryByIdResponseV1.Response>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetStoryByIdResponseV1.Response>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default storiesRepositoryV1;
