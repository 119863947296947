import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetConstructorByIdRequestV3 } from '.';
import { GetConstructorByIdResponseV3 } from '.';

const constructorsRepositoryV3 = {
  getConstructorById: (
    { id, catalogId }: GetConstructorByIdRequestV3,
    signal?: AbortSignal,
  ) =>
    new Promise<GetConstructorByIdResponseV3.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v3/constructors/${id}?catalogId=${catalogId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetConstructorByIdResponseV3.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetConstructorByIdResponseV3.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default constructorsRepositoryV3;
