import { City, DeliveryZone } from 'domains/cartography';
import { CatalogCategory, CatalogProducts } from 'domains/catalog';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { CurrencySymbol } from 'models';
import { useMatch } from 'react-router';

export const MetaContainer = ({
  currentCity,
  deliveryZone,
  currency,
  categories,
  products,
}: Props) => {
  const match = useMatch('/:name');

  const { currentCategory, currentProductName } = useMemo(() => {
    if (match) {
      const pathName = match.params.name;

      const currentCategoryFromUrl = categories.find(
        (category) => category.linkValue === pathName,
      );

      if (currentCategoryFromUrl) {
        return {
          currentCategory: currentCategoryFromUrl,
          currentProductName: null,
        };
      } else {
        if (products) {
          const currentProductFromUrl = [
            ...products.dishes,
            ...products.constructors,
          ].find((product) => {
            const productPathname =
              // product.linkValue.counter === 1
              // ?
              product.linkValue.value;
            // : `${product.linkValue.value}${product.linkValue.counter}`
            return productPathname === pathName;
          });

          if (currentProductFromUrl) {
            const currentCategoryFromProduct = categories.find(
              (category) => category.id === currentProductFromUrl.categoryId,
            )!;
            return {
              currentCategory: currentCategoryFromProduct,
              currentProductName: currentProductFromUrl.name,
            };
          }
        }
      }
    }
    return {
      currentCategory: null,
      currentProductName: null,
    };
  }, [categories, products, match]);

  const minFreeDeliveryOrderPrice = useMemo(() => {
    if (
      deliveryZone &&
      deliveryZone.conditions.deliveryPriceConditions.length
    ) {
      const freeDeliveryCondition =
        deliveryZone.conditions.deliveryPriceConditions.find(
          (condition) => condition.deliveryPrice === 0,
        );

      if (freeDeliveryCondition) {
        return `${freeDeliveryCondition.orderTotalMinimum} ${currency}`;
      }
    }
    return null;
  }, [deliveryZone, currency]);

  const receivingDelay = useMemo(
    () => (deliveryZone ? deliveryZone.orderReceivingDelay : null),
    [deliveryZone],
  );

  if (currentCity && currentCategory && currentProductName) {
    return (
      <Helmet>
        <title>{`${currentProductName} заказать в ${currentCity.name} | Доставка ${currentCategory.name} на дом от СушиВесла`}</title>
        <meta
          name='description'
          content={`Закажи ${currentProductName} 🍱 на дом или в офис в ${
            currentCity.name
          }. ${
            receivingDelay ? `Быстрая доставка за ${receivingDelay} мин.` : ' '
          }${
            minFreeDeliveryOrderPrice
              ? `Бесплатная доставка от ${minFreeDeliveryOrderPrice}.`
              : ' '
          }Сделай заказ на сушивесла.рф или в мобильном приложении.`}
        />
      </Helmet>
    );
  }

  if (currentCity && currentCategory) {
    return (
      <Helmet>
        <title>{`${currentCategory.name} в ${currentCity.name} | Заказать с доставкой в СушиВесла`}</title>
        <meta
          name='description'
          content={`Доставка ${currentCategory.name} 🍣 на дом или в офис в ${
            currentCity.name
          }. ${
            receivingDelay ? `Быстрая доставка за ${receivingDelay} мин.` : ' '
          }${
            minFreeDeliveryOrderPrice
              ? `Бесплатная доставка от ${minFreeDeliveryOrderPrice}.`
              : ' '
          }Сделай заказ на сушивесла.рф или в мобильном приложении.`}
        />
      </Helmet>
    );
  }

  if (currentCity) {
    return (
      <Helmet>
        <title>{`Доставка суши и роллов в ${currentCity.name} | Заказать суши, роллы и воки от СушиВесла`}</title>
        <meta
          name='description'
          content={`Заказать суши, роллы и воки в ${
            currentCity.name
          } на дом или в офис. ${
            receivingDelay ? `Быстрая доставка за ${receivingDelay} мин.` : ' '
          }${
            minFreeDeliveryOrderPrice
              ? `Бесплатная доставка от ${minFreeDeliveryOrderPrice}.`
              : ' '
          }Акции и скидки. Программа лояльности СушиВесла.`}
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{`Доставка суши и роллы | Заказать суши, роллы и воки от СушиВесла`}</title>
      <meta
        name='description'
        content={`Доставка суши и роллы | Заказать суши, роллы и воки от СушиВесла.`}
      />
    </Helmet>
  );
};

type Props = {
  currentCity: City | null;
  categories: CatalogCategory[];
  products: CatalogProducts | null;
  deliveryZone: DeliveryZone | null;
  currency: CurrencySymbol;
};
