import { OrdersValidateResponseV4 } from 'api/version4';
import { createStore } from 'effector';
import { createForm } from 'effector-forms';
import { createGate } from 'effector-react';
import { PaymentType } from 'models';
import { pending } from 'patronum';
import { FEValidationUtils } from 'utils';
import {
  createOrderFx,
  validateFx,
  setPromocodeFx,
  addDishToCartFx,
  addConstructorToCartFx,
  repeatOrderFx,
} from './effects';
import {
  ConstructorInCartModel,
  DishInCartModel,
  CutleryInCartModel,
  PromotionProduct,
  OrderingForm,
  LastOrder,
  ValidationError,
} from './entities';

export const $dishes = createStore<DishInCartModel[]>([]);
export const $constructors = createStore<ConstructorInCartModel[]>([]);

export const $paymentTypes = createStore<PaymentType[]>([]);
export const $avaliableOrderPaymentTypes = createStore<PaymentType[]>([]);

export const $timeTo = createStore<string | null>(null);
export const $urgent = createStore<boolean | null>(true);

export const $cutleries = createStore<CutleryInCartModel[]>([]);

export const $promotionProduct = createStore<PromotionProduct | null>(null);
export const $promotion =
  createStore<OrdersValidateResponseV4.Promotion | null>(null);

export const $promoCode = createStore<string | null>(null);

export const $warning = createStore<string | null>(null);
export const $longDeliveryWarning = createStore<string | null>(null);

export const $problems = createStore<OrdersValidateResponseV4.Problem[]>([]);

export const $price = createStore<OrdersValidateResponseV4.OrderPrice | null>(
  null,
);

export const $validationErrors = createStore<ValidationError[]>([]);
export const $hasCriticalCartError = createStore<boolean>(false);

export const $useCustomerCutleries = createStore<boolean>(false);

export const $isOrderRepeating = pending({ effects: [repeatOrderFx] });

export const $isValidating = pending({
  effects: [
    validateFx,
    createOrderFx,
    setPromocodeFx,
    addDishToCartFx,
    addConstructorToCartFx,
  ],
});

export const $createdOrderNotificationStore = createStore<{
  show: boolean;
  icon: null | 'checkmark' | 'error';
  title: null | string;
  description: null | string;
  button: null | {
    text: string;
    onClick: () => void;
  };
  orderId: string | null;
  type: 'ERROR' | 'SUCCESS';
  orderNumber: string | null;
  price: string | null;
  items:
    | {
        Product: {
          productID: string;
          category: '1';
          price: string;
          priceCurrency: 'RUB' | 'AED';
        };
        orderQuantity: number;
        additionalType: 'sale';
      }[]
    | null;
}>({
  show: false,
  icon: null,
  title: null,
  description: null,
  button: null,
  orderId: null,
  type: 'SUCCESS',
  orderNumber: null,
  price: null,
  items: null,
});

export const $lastOrder = createStore<LastOrder | null>(null);

export const orderingForm = createForm<OrderingForm>({
  fields: {
    name: {
      init: null,
      rules: [FEValidationUtils.required()],
    },
    phone: {
      init: null,
      rules: [FEValidationUtils.required()],
    },
    comment: {
      init: null,
    },
    paymentType: {
      init: null,
    },
    bonusAmount: {
      init: null,
    },
    changeFrom: {
      init: null,
    },
  },
  validateOn: ['submit'],
});

export const cartGate = createGate();
