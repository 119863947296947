import React from 'react';
import { applicationGate } from 'domains/application';
import { useGate, useUnit } from 'effector-react';
import { FEHeader } from 'ui-kit/layouts';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import {
  $type,
  $address,
  $restaurant,
  $showAddressConfirmation,
  closeAddressConfirmation,
  $city,
  $country,
  $cityInformationBanner,
  closeCityInformationBanner,
} from 'domains/cartography';
import { $user, $bonusAccount, $isPendingInitUser } from 'domains/profile';
import { useLinks, usePrepareLink } from 'hooks/router';
import { Language as LanguageModel, OrderType } from 'models';
import style from './style.module.css';
import { $catalogCategories, $catalogProducts } from 'domains/catalog';
import { Location } from 'react-router-dom';
import PromotionsPage from 'pages/promotions';
import {
  CONTACTS_PATHNAME,
  DELIVERY_AND_PAYMENTS_PATHNAME,
  GET_ENUMS,
  GET_PARAMS,
  LEGAL_INFORMATION_PATHNAME,
  PROMOTIONS_PATHNAME,
  RESTAURANTS_PATHNAME,
} from 'const';
import { $features } from 'domains/features';
import LegalInformationPage from 'pages/legalInformation';
import { Country, Language, CityInformationBanner } from './components';
import Footer from '../footer';
import { $language, changeLanguage } from 'domains/language';
import { useTranslation } from 'react-i18next';
import { CookiesLayot } from '../cookiesLayot';
import { FELocalStorageUtil } from 'utils';
import { Helmet } from 'react-helmet';

const ApplicationLayout = ({
  children,
}: {
  children: ({ location }: { location: Location }) => React.ReactNode;
}) => {
  const { i18n } = useTranslation();
  useGate(applicationGate);

  const navigate = useNavigate();
  const location = useLocation();

  const [type, address, restaurant, city, country] = useUnit([
    $type,
    $address,
    $restaurant,
    $city,
    $country,
  ]);
  const [user, bonusAccount, isPendingUserInit, features] = useUnit([
    $user,
    $bonusAccount,
    $isPendingInitUser,
    $features,
  ]);

  const [showAddressConfirmation, onCloseConfrimation] = useUnit([
    $showAddressConfirmation,
    closeAddressConfirmation,
  ]);

  const [products, categories] = useUnit([
    $catalogProducts,
    $catalogCategories,
  ]);

  const [language, onChangeLanguage] = useUnit([$language, changeLanguage]);

  const handleChangeLanguage = (lng: LanguageModel) => {
    onChangeLanguage(lng);
    i18n.changeLanguage(lng);
  };

  const { bonusLink } = useLinks();

  const match = useMatch('/:name');

  const showPromotionsPage = React.useMemo(
    () => location.pathname.split('/').includes(PROMOTIONS_PATHNAME),
    [location],
  );
  const showLegalInformationPage = React.useMemo(
    () => location.pathname.split('/').includes(LEGAL_INFORMATION_PATHNAME),
    [location],
  );

  const [cityInformationBanner, handleCloseCityInformationBanner] = useUnit([
    $cityInformationBanner,
    closeCityInformationBanner,
  ]);

  const cartographyLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
    },
    keepOldQuery: true,
  });

  const countryLayoutLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
    },
    pushToQuery: {
      [GET_PARAMS.layout]: 'country',
    },
    keepOldQuery: true,
  });

  React.useEffect(() => {
    if (match) {
      const pathName = match.params.name;
      if (products && categories) {
        const foundProduct = [
          ...products.constructors,
          ...products.dishes,
        ].find((product) => {
          const productPathName =
            // product.linkValue.counter === 1
            // ?
            product.linkValue.value;
          // : `${product.linkValue.value}${product.linkValue.counter}`
          return productPathName === pathName;
        });

        const foundCategory = categories.find(
          (category) => category.linkValue === pathName,
        );

        if (
          !foundProduct &&
          !foundCategory &&
          pathName !== DELIVERY_AND_PAYMENTS_PATHNAME &&
          pathName !== RESTAURANTS_PATHNAME &&
          pathName !== CONTACTS_PATHNAME &&
          pathName !== LEGAL_INFORMATION_PATHNAME &&
          pathName !== PROMOTIONS_PATHNAME
        )
          navigate('404', { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, categories, match]);

  return (
    <>
      <Helmet>
        <title>СушиВесла - Главная</title>
        <meta
          name='title'
          content='Доставка суши и роллов на дом - заказать суши и роллы в СушиВесла'
        />
        <meta
          name='description'
          content='Доставка суши и роллов на дом и в офис. 🍣 Оформите заказ вкусных роллов, суши и воков на сайте Сушивесла. У нас быстрая доставка и выгодные цены.'
        />
        <meta
          name='Keywords'
          content='Сушивесла - удобный сайт для доставки суши!'
        />
        <meta
          property='og:title'
          content='Доставка суши и роллов на дом - заказать суши и роллы в СушиВесла'
        />
        <meta
          property='og:description'
          content='Доставка суши и роллов на дом и в офис. 🍣 Оформите заказ вкусных роллов, суши и воков на сайте Сушивесла. У нас быстрая доставка и выгодные цены.'
        />
        <meta
          name='twitter:title'
          content='Доставка суши и роллов на дом - заказать суши и роллы в СушиВесла'
        />
        <meta
          name='twitter:description'
          content='Доставка суши и роллов на дом и в офис. 🍣 Оформите заказ вкусных роллов, суши и воков на сайте Сушивесла. У нас быстрая доставка и выгодные цены.'
        />
      </Helmet>
      <div className={style.languageLayout}>
        <div className={style.languageLayoutContainer}>
          <Language
            language={language}
            onChangeLanguage={handleChangeLanguage}
            className={style.languageButton}
          />
          <Country
            city={city?.name || null}
            country={country?.name || null}
            onClick={() => navigate(countryLayoutLink)}
          />
        </div>
      </div>
      <FEHeader
        features={features}
        user={user}
        isPendingUser={isPendingUserInit}
        bonusAccount={bonusAccount}
        address={address?.address || null}
        restaurant={restaurant?.name || null}
        orderType={type as Exclude<OrderType, 'IN_PLACE'>}
        onAddressBarClick={() => navigate(cartographyLink)}
        onBonusBarClick={() => navigate(bonusLink)}
        onLogoClick={() => {
          navigate('/');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        onCloseConfirmation={() => onCloseConfrimation()}
        onDeclineConfirmation={() => navigate(cartographyLink)}
        isOpenConfirmation={showAddressConfirmation}
      />
      {children({ location })}
      <Footer />
      <PromotionsPage onClose={() => navigate(-1)} open={showPromotionsPage} />
      <LegalInformationPage
        onClose={() => navigate(-1)}
        open={showLegalInformationPage}
      />
      <CookiesLayot
        onClose={() => FELocalStorageUtil.setShowCookies(false)}
        show={FELocalStorageUtil.showCookies}
      />
      <CityInformationBanner
        onClose={handleCloseCityInformationBanner}
        data={cityInformationBanner}
      />
    </>
  );
};

export default ApplicationLayout;
