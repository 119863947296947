import { MOBILE_WIDTH } from 'const';
import { GiftRepurschase } from 'domains/giftRepurschases';
import { FEBottomSheet, FEDialog } from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import Content from './content';
import { CurrencySymbol } from 'models';

const GiftRepurchaseModal = ({
  open,
  onClose,

  ...rest
}: {
  open: boolean;
  onClose: () => void;
  giftRepurchase: GiftRepurschase;
  currency: CurrencySymbol;
  products: {
    [k: string]: {
      productId: string;
      productName: string;
      categoryId: string;
      categoryName: string;
    };
  };
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  return isDesktop ? (
    <FEDialog
      {...{ open, onClose }}
      render={() => <Content {...{ ...rest }} />}
    />
  ) : (
    <FEBottomSheet
      {...{ open, onClose }}
      render={() => <Content {...{ ...rest }} />}
    />
  );
};

export default GiftRepurchaseModal;
