import React from 'react';
import { FEOpeningBar } from '..';
import FEBirthdayPickerProps from './props';
import Container from './components/Container';
import { useBirthdayPicker } from './model';
import { useTranslation } from 'react-i18next';

const FEBirthdayPicker = (props: FEBirthdayPickerProps) => {
  const { t } = useTranslation();
  const { hasError = false, moderated = false, disabled = false } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  const {
    years,
    months,
    days,
    initialYearIndex,
    initialMonthIndex,
    initialDayIndex,
    changeYear,
    changeMonth,
    changeDay,
    submit,
    displayedValue,
  } = useBirthdayPicker(props);
    console.log(disabled, moderated)
  return (
    <>
      <FEOpeningBar
        chevronDirection='right'
        onClick={() => {
            if (!disabled && !moderated) {
                setOpen(true);
            }
        }}
        label={t('editProfile.form.birthday.label')}
        valueTextType={moderated ? 'secondary' : 'primary'}
        value={displayedValue}
        hasError={hasError}
      />
      <Container
        open={open}
        onClose={() => setOpen(false)}
        title={t('editProfile.form.birthday.label')}
        {...{
          years,
          months,
          days,
          initialYearIndex,
          initialMonthIndex,
          initialDayIndex,
          changeYear,
          changeMonth,
          changeDay,
          submit,
        }}
      />
    </>
  );
};

export default FEBirthdayPicker;
export type { FEBirthdayPickerProps };
