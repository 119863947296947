import { $showStartPage } from 'domains/application';
import { closeStartPage } from 'domains/application';
import { $city } from 'domains/cartography';
import { useUnit } from 'effector-react';
import { cityFrom } from 'lvovich';
import { FENotificationCard } from 'components';
import { useNavigate } from 'react-router-dom';
import { usePrepareLink } from 'hooks/router';
import { GET_ENUMS, GET_PARAMS } from 'const';
import { useTranslation } from 'react-i18next';

const StartPage = () => {
  const { t } = useTranslation();
  const [showStartPage, onClosePage] = useUnit([
    $showStartPage,
    closeStartPage,
  ]);
  const [currentCity] = useUnit([$city]);

  const navigate = useNavigate();

  const countryLayoutLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
    },
    pushToQuery: {
      [GET_PARAMS.layout]: 'country',
    },
    to: '/',
  });
  const cartographyLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
    },
    to: '/',
  });

  return (
    <>
      <FENotificationCard
        open={showStartPage}
        icon='map'
        title={t('startPage.title')}
        description={t('startPage.description', {
          city: cityFrom(currentCity?.name || ''),
        })}
        closeOnOverlayClick={false}
        onClose={() => {}}
        primaryButtonProps={{
          text: t('startPage.primaryButtonText'),
          closeOnClick: true,
          onClick: () => {
            onClosePage();
            navigate(cartographyLink, { replace: true });
          },
        }}
        secondaryButtonProps={{
          text: t('startPage.secondaryButtonText'),
          closeOnClick: true,
          onClick: () => {
            onClosePage();
            navigate(countryLayoutLink, { replace: true });
          },
        }}
      />
    </>
  );
};

export default StartPage;
