import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { PostFeedbackRequestV1 } from '.';
import { GetFeedbackReasonsResponseV1 } from '.';

const feedbackRepositoryV1 = {
  postFeedback: (
    request: PostFeedbackRequestV1.Request,
    signal?: AbortSignal,
  ) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/feedback-messages`;
        let result: AxiosResponse<BaseAPIResponse<string>> =
          await BaseAPIRepository.post<BaseAPIResponse<string>>(url, request, {
            signal,
          });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  getFeedbackReasons: (signal?: AbortSignal) =>
    new Promise<GetFeedbackReasonsResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/feedback-messages/reasons`;
          let result: AxiosResponse<
            BaseAPIResponse<GetFeedbackReasonsResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetFeedbackReasonsResponseV1.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default feedbackRepositoryV1;
