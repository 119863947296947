import clsx from 'clsx';
import { ANIMATION_DELAY } from 'const';
import { $sitesMap } from 'domains/sitesMap';
import { useUnit } from 'effector-react';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import style from './style.module.css';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

export const Layout = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  const [animationIn, setAnimationIn] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => setAnimationIn(true), []);

  const sitesMap = useUnit($sitesMap);

  function handleClose() {
    if (onClose) {
      onClose();
      setAnimationIn(false);
    }
  }
  return (
    <CSSTransition
      in={animationIn}
      timeout={ANIMATION_DELAY}
      mountOnEnter
      unmountOnExit
      classNames={containerAnimation}
      nodeRef={containerRef}
    >
      <div className={style.container} ref={containerRef}>
        <p className={clsx('caption-1', style.text)}>
          {t('cookies.text1')}{' '}
          <a
            href={sitesMap?.cookies || ''}
            target='_blank'
            rel='noreferrer'
            className={clsx('caption-2', style.link)}
          >
            cookie
          </a>{' '}
          {t('cookies.text2')}
        </p>
        <button
          onClick={handleClose}
          className={clsx('caption-2', style.button)}
        >
          {t('cookies.buttonText')}
        </button>
      </div>
    </CSSTransition>
  );
};
