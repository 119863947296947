import React from 'react';
import { ZoneBottomSheetProps } from '../..';
import style from './style.module.css';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

const Layout = ({
  deliveryZoneAndMapLayerZone,
  currency,
}: ZoneBottomSheetProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const [animationIn, setAnimationIn] = React.useState<boolean>(false);
  // const [showDivider, setShowDivider] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  // const firstRenderRef = React.useRef(true);
  React.useEffect(() => setAnimationIn(true), []);

  // React.useEffect(() => {
  //   const current = containerRef.current;
  //   if (current) {
  //     setShowDivider(
  //       current.offsetHeight !== 0 &&
  //         current.scrollHeight - (current.scrollTop + current.offsetHeight) > 0,
  //     );
  //     const handleScroll = (e: Event) => {
  //       const target = e.target as HTMLDivElement;
  //       setShowDivider(
  //         target.scrollHeight - (target.scrollTop + target.offsetHeight) > 0,
  //       );
  //     };

  //     current.addEventListener('scroll', handleScroll);
  //     return () => current.removeEventListener('scroll', handleScroll);
  //   }
  //   firstRenderRef.current = false;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [firstRenderRef.current]);

  const { conditions } = React.useMemo(() => {
    const deliveryPriceConditions =
      deliveryZoneAndMapLayerZone?.deliveryZone.conditions
        .deliveryPriceConditions;
    const conditions = deliveryPriceConditions?.map((condition, i) => {
      const nextMinDeliveryPrice =
        i + 1 === deliveryPriceConditions.length
          ? null
          : deliveryPriceConditions[i + 1].orderTotalMinimum;
      const deliveryPrice = condition.deliveryPrice;

      return {
        label:
          nextMinDeliveryPrice === null
            ? t('deliveryAndPayments.conditions.deliveryPrice.orderFrom', {
                value: condition.orderTotalMinimum,
                currency,
              })
            : t('deliveryAndPayments.conditions.deliveryPrice.orderTo', {
                value: nextMinDeliveryPrice,
                currency,
              }),
        price:
          deliveryPrice === 0
            ? t('deliveryAndPayments.conditions.deliveryPrice.free')
            : `${deliveryPrice} ${currency}`,
      };
    });

    return {
      conditions,
    };
  }, [deliveryZoneAndMapLayerZone, currency, t]);

  if (!deliveryZoneAndMapLayerZone) return null;

  return (
    <div
      className={clsx(
        style.bottomSheet,
        // showDivider && style.bottomSheet__divider,
      )}
      role='dialog'
    >
      <CSSTransition
        in={animationIn}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={containerAnimation}
        nodeRef={containerRef}
      >
        <div className={style.wrapper} ref={containerRef}>
          <div className={style.header}>
            <p className='headline'>
              {t('deliveryAndPayments.conditions.label')}
            </p>
            <div
              className={style.colorTitle}
              style={{
                backgroundColor:
                  deliveryZoneAndMapLayerZone?.mapLayerZone.color,
                border: `2px solid ${deliveryZoneAndMapLayerZone?.mapLayerZone.color}`,
              }}
            />
          </div>
          <div className={style.infoItem}>
            <div className={style.infoItemContainer}>
              <div className={style.infoItemContent}>
                <p className={clsx('headline', style.infoItemLabel)}>
                  {t('deliveryAndPayments.conditions.time.label')}
                </p>
                <p className='text-1'>
                  {t('deliveryAndPayments.conditions.time.value', {
                    from: deliveryZoneAndMapLayerZone?.deliveryZone.openAt,
                    to: deliveryZoneAndMapLayerZone?.deliveryZone.closeAt,
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className={style.infoItem}>
            <div className={style.infoItemContainer}>
              <div className={style.infoItemContent}>
                <p className={clsx('headline', style.infoItemLabel)}>
                  {t('deliveryAndPayments.conditions.deliveryPrice.label')}
                </p>
                <div className={style.deliveryPriceList}>
                  {conditions!.map((condition, i) => (
                    <div key={i} className={style.deliveryPriceItem}>
                      <div className={style.deliveryPriceItemContainer}>
                        <div className={style.deliveryPriceItemContent}>
                          <p className='text-1'>{condition.label}</p>
                          <p className='text-1'>{condition.price}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={style.infoItem}>
            <div className={style.infoItemContainer}>
              <div className={style.infoItemContent}>
                <p className={clsx('headline', style.infoItemLabel)}>
                  {t('deliveryAndPayments.conditions.deliveryTime.label')}
                </p>
                <p className='text-1'>
                  {t('deliveryAndPayments.conditions.deliveryTime.value', {
                    delay:
                      deliveryZoneAndMapLayerZone.deliveryZone
                        .orderReceivingDelay,
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Layout;
