import { initCatalogFx, initCodeFx } from './effects';
import { sample } from 'effector';
import { applicationGate, $showStartPage } from './stores';
import { spread } from 'patronum';
import { closeStartPage } from './events';
import {
  $address,
  $allCitiesList,
  $citiesList,
  $city,
  $countriesList,
  $country,
  $currency,
  $deliveryZone,
  $deliveryZonesAndMapLayerZones,
  $noRestaurantsInCityError,
  $restaurant,
  $restaurantsList,
  $showAddressConfirmation,
  $timeIntervals,
  $type,
  checkCityFx,
  initGeolocationFx,
} from 'domains/cartography';
import {
  $catalogCategories,
  $catalogId,
  $catalogProducts,
  $stopLists,
} from 'domains/catalog';
import {
  $avaliableOrderPaymentTypes,
  $constructors,
  $dishes,
  $paymentTypes,
  $timeTo,
  $urgent,
  orderingForm,
} from 'domains/cart';
import { $features } from 'domains/features';
import { initUserFx } from 'domains/profile';
import moment from 'moment';
import md5 from 'md5';
import { $sitesMap } from 'domains/sitesMap';
import { FEHeadersCookiesUtil } from 'utils';

$showStartPage.on(closeStartPage, () => false);

sample({
  clock: applicationGate.open,
  fn: () => {
    FEHeadersCookiesUtil.clearCode();

    return md5(
      moment()
        .utc(true)
        .utcOffset(new Date().getTimezoneOffset())
        .locale('en')
        .format('YYYY-MM-DD-HH'),
    );
  },
  target: [initCodeFx, initGeolocationFx],
});
sample({
  clock: initCodeFx.doneData,
  fn: () => {},
  target: [initCatalogFx, initUserFx],
});

$showStartPage.on(
  initCatalogFx.doneData,
  (_, res) => !res.useLocalStorageData && res.showAddressConfirmation,
);

$showAddressConfirmation.on(
  initCatalogFx.doneData,
  (_, res) => res.useLocalStorageData && res.showAddressConfirmation,
);

sample({
  clock: initCatalogFx.doneData,
  fn: ({ restaurants }) => restaurants.length === 0,
  target: $noRestaurantsInCityError,
});

spread({
  // @ts-expect-error
  source: sample({
    clock: initCatalogFx.doneData,
    fn: ({
      sitesMap,
      cities,
      currency,
      restaurants,
      currentCity,
      currentAddress,
      currentRestaurant,
      catalogId,
      catalogProducts,
      catalogCategories,
      deliveryZone,
      timeIntervals,
      stopLists,
      paymentTypes,
      paymentType,
      type,
      urgent,
      deliveryZonesAndMapLayerZones,
      cartData,
      timeTo,
      features,
      countries,
      currentCountry,
      allCities,
    }) => {
      const { dishes, constructors } = cartData || {
        dishes: [],
        constructors: [],
      };

      return {
        sitesMap,
        currency,
        features,
        cities,
        restaurants,
        currentCity,
        currentAddress,
        currentRestaurant,
        catalogId,
        catalogProducts,
        catalogCategories,
        deliveryZone,
        timeIntervals,
        stopLists,
        paymentTypes,
        avaliableOrderPaymentTypes: paymentTypes,
        paymentType,
        type,
        urgent,
        timeTo,
        deliveryZonesAndMapLayerZones,
        dishes,
        constructors,
        countries,
        currentCountry,
        allCities,
        cityId: { id: currentCity.id },
      };
    },
  }),
  targets: {
    sitesMap: $sitesMap,
    currency: $currency,
    features: $features,
    cities: $citiesList,
    restaurants: $restaurantsList,
    currentCity: $city,
    currentAddress: $address,
    currentRestaurant: $restaurant,
    catalogId: $catalogId,
    catalogProducts: $catalogProducts,
    catalogCategories: $catalogCategories,
    deliveryZone: $deliveryZone,
    timeIntervals: $timeIntervals,
    stopLists: $stopLists,
    paymentTypes: $paymentTypes,
    avaliableOrderPaymentTypes: $avaliableOrderPaymentTypes,
    paymentType: orderingForm.fields.paymentType.$value,
    type: $type,
    urgent: $urgent,
    timeTo: $timeTo,
    deliveryZonesAndMapLayerZones: $deliveryZonesAndMapLayerZones,
    dishes: $dishes,
    constructors: $constructors,
    countries: $countriesList,
    currentCountry: $country,
    allCities: $allCitiesList,
    cityId: checkCityFx,
  },
});
