import clsx from 'clsx';
import React from 'react';
import FESpinnerProps from './props';
import style from './style.module.css';

const FESpinner = ({ size = 'medium', color = 'primary' }: FESpinnerProps) => (
  <div
    className={clsx(
      style.container,
      size === 'large' && style.containerLarge,
      size === 'medium' && style.containerMedium,
      size === 'small' && style.containerSmall,
    )}
  >
    <div
      className={clsx(
        style.root,
        style[color],
        size === 'large' && style.large,
        size === 'medium' && style.medium,
        size === 'small' && style.small,
        size === 'large' && color === 'primary' && style.largePrimary,
        size === 'medium' && color === 'primary' && style.mediumPrimary,
        size === 'small' && color === 'primary' && style.smallPrimary,
        size === 'large' && color === 'secondary' && style.largeSecondary,
        size === 'medium' && color === 'secondary' && style.mediumSecondary,
        size === 'small' && color === 'secondary' && style.smallSecondary,
      )}
    />
  </div>
);

export default FESpinner;
export type { FESpinnerProps };
