import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetDishByIdRequestV1 } from '.';
import { GetDishByIdResponseV1 } from '.';

const dishesRepositoryV1 = {
  getDishById: (
    { id, catalogId }: GetDishByIdRequestV1,
    signal?: AbortSignal,
  ) =>
    new Promise<GetDishByIdResponseV1.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/dishes/${id}?catalogId=${catalogId}`;
        let result: AxiosResponse<
          BaseAPIResponse<GetDishByIdResponseV1.Response>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetDishByIdResponseV1.Response>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default dishesRepositoryV1;
