import React from 'react';
import { FECheckBox, FEImageComponent, FERadioButton } from 'components';
import { ModifierProps } from './props';
import style from './style.module.css';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import clsx from 'clsx';
import { CurrencySymbol } from 'models';

const Modifier = ({
  active,
  name,
  id,
  onToggle,
  price,
  imageId,
  disabled,
  currency,
  type,
}: ModifierProps & { currency: CurrencySymbol }) => {
  return (
    <div
      className={style.root}
      onClick={() => !disabled && onToggle(id, !active)}
    >
      <div className={clsx(style.content, disabled && style.content__disabled)}>
        <FEImageComponent
          className={style.image}
          image={{ imageId: imageId || '' }}
          altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
        />
        <div className={style.data}>
          <p className='subhead'>{name}</p>
          <p className='subhead'>{`${price} ${currency}`}</p>
        </div>
        <div className={style.active}>
          {type === 'checkbox' ? (
            <FECheckBox checked={active} />
          ) : (
            <FERadioButton active={active} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Modifier;
