import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'theme/base.css';
import 'theme/theme.css';
import 'theme/normalize.css';
import 'theme/colors.css';
import 'images/images.css';

import 'domains/init';

import '../app/styles/index.css'

import {
  CatalogPage,
  DeliveryAndPaymentsPage,
  ContactsPage,
  Page404,
  StartPage,
  RestaurantsPage,
} from 'pages';
import { GetParameterModals } from './routes';
import { ApplicationLoaderLayout } from 'layouts';
import { ApplicationLayout } from 'src/layouts';
import {
  CONTACTS_PATHNAME,
  DELIVERY_AND_PAYMENTS_PATHNAME,
  RESTAURANTS_PATHNAME,
} from 'const';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <ApplicationLayout>
          {() => (
            <>
              <Routes>
                <Route path='/404' element={<Page404 />} />
                <Route
                  path={`/${DELIVERY_AND_PAYMENTS_PATHNAME}/*`}
                  element={<DeliveryAndPaymentsPage />}
                />
                <Route
                  path={`/${CONTACTS_PATHNAME}/*`}
                  element={<ContactsPage />}
                />
                <Route
                  path={`/${RESTAURANTS_PATHNAME}/*`}
                  element={<RestaurantsPage />}
                />
                <Route path='/*' element={<CatalogPage />}></Route>
              </Routes>
              <GetParameterModals />
              <StartPage />
              <ApplicationLoaderLayout />
            </>
          )}
        </ApplicationLayout>
      </BrowserRouter>
    </>
  );
};

export default App;
