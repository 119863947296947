import React from 'react';
import {
  FEBanner,
  FEButton,
  FEMultilineField,
  FESearchPlaceholder,
} from 'components';
import { List } from './components';
import style from './style.module.css';
import { useEvent } from 'hooks';
import { RestaurantLayoutProps } from './props';
import { useUnit } from 'effector-react';
import {
  $hasRestaurantsError,
  $noRestaurantsInCityError,
  fetchRestaurantsFx,
  submitRestaurant,
} from 'domains/cartography';
import { $restaurant, $restaurantsList } from 'domains/cartography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const RestaurantLayout = ({
  userGeolocation,
  onCloseModal,
  currentCity,
  changeType,
  onClose,
  fromCart,
}: RestaurantLayoutProps) => {
  const { t } = useTranslation();
  const [
    currentRestaurant,
    data,
    hasError,
    noRestaurantsInCityError,
    fetchRestaurants,
  ] = useUnit([
    $restaurant,
    $restaurantsList,
    $hasRestaurantsError,
    $noRestaurantsInCityError,
    fetchRestaurantsFx,
  ]);
  const navigate = useNavigate();

  const [searchFieldHeight, setSearchFieldHeight] = React.useState<number>(52);
  const searchFieldRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (searchFieldRef.current) {
      const { height } = searchFieldRef.current.getBoundingClientRect();
      setSearchFieldHeight(height);
    }
  }, []);

  const onPickRestaurant = useUnit(submitRestaurant);

  const [value, setValue] = React.useState<string>('');
  const [restaurantsList, setRestaurantsList] = React.useState<typeof data>([]);
  const deferredList = React.useDeferredValue<typeof data>(restaurantsList);

  React.useEffect(() => setRestaurantsList(data), [data]);

  React.useLayoutEffect(() => {
    if (currentRestaurant) {
      setValue(currentRestaurant.name);
    }
  }, [currentRestaurant]);

  const changeQueryHandler = useEvent(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = e.target.value;
      setValue(val);
      if (val) {
        const newRestaurantsList = data.filter(
          (restaurant) =>
            restaurant.name.toLowerCase().includes(val.toLowerCase()) ||
            restaurant.address.address
              .toLowerCase()
              .includes(val.toLowerCase()),
        );
        setRestaurantsList(newRestaurantsList);
      } else setRestaurantsList(data);
    },
  );
  return (
    <>
      {!noRestaurantsInCityError && (
        <div className={style.searchField} ref={searchFieldRef}>
          <FEMultilineField
            placeholder={t('cartography.restaurant.form.fieldPlaceholder')}
            hasSelectionEffect={false}
            value={value}
            onChange={changeQueryHandler}
            onClear={() => {
              setValue('');
              setRestaurantsList(data);
            }}
          />
        </div>
      )}
      {hasError ? (
        <>
          <div className={style.errorConntainer}>
            <FEBanner
              text={t('cartography.restaurant.form.loadErrorPlaceholder')}
            />
            <div className={style.reload}>
              <FEButton
                onClick={() => fetchRestaurants({ cityId: currentCity!.id })}
              >
                {t('common.refresh')}
              </FEButton>
            </div>
          </div>
        </>
      ) : noRestaurantsInCityError ? (
        <div className={style.placeholderContainer}>
          <FESearchPlaceholder
            title={t('searchPlaceholderComponent.title')}
            message={t('cartography.restaurant.form.noRestaurantsPlaceholder')}
          />
        </div>
      ) : (
        <List
          fromCart={fromCart}
          restaurantsList={deferredList}
          userGeolocation={userGeolocation}
          searchFieldHeight={searchFieldHeight}
          onChangeRestaurant={(restaurant) => {
            currentRestaurant !== restaurant &&
              onPickRestaurant({ currentRestaurant: restaurant });
            setRestaurantsList(data);
            setValue(restaurant.name);
            onCloseModal();
            if (fromCart) navigate(-2);
            else navigate('/');
          }}
        />
      )}
    </>
  );
};

export default RestaurantLayout;
