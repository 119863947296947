import { GET_ENUMS } from 'const';
import { useGetModalState } from 'hooks/router';
import {
  CartographyPage,
  AuthPage,
  ProfilePage,
  CartPage,
  OrdersPage,
  BonusPage,
} from 'pages';

const modals = {
  [GET_ENUMS.page.cartography]: CartographyPage,
  [GET_ENUMS.page.auth]: AuthPage,
  [GET_ENUMS.page.profile]: ProfilePage,
  [GET_ENUMS.page.cart]: CartPage,
  [GET_ENUMS.page.orders]: OrdersPage,
  [GET_ENUMS.page.bonus]: BonusPage,
};

export const GetParameterModals = () => {
  const { mountedModals, modals: popups } = useGetModalState();

  return (
    <>
      {mountedModals.map((mountedPopup) => {
        const Component = modals[mountedPopup];

        if (!Component) {
          return null;
        }

        return (
          <Component key={mountedPopup} open={popups.includes(mountedPopup)} />
        );
      })}
    </>
  );
};
