import { forwardRef, memo, useId } from 'react';
import style from './style.module.css';

export const FECheckBox = memo(
  forwardRef<HTMLInputElement, FECheckBoxProps>(
    ({ checked, onChange, disabled = false }, ref) => {
      const id = useId();

      const checboxId = `FECheckbox-${id}`;
      return (
        <div
          className={style.checkbox}
          is-disabled={disabled ? 'true' : 'false'}
        >
          <input
            ref={ref}
            id={checboxId}
            className={style.input}
            type='checkbox'
            checked={checked}
            readOnly
            disabled={disabled}
            onChange={(e) => onChange && onChange(e.target.checked)}
          />
          <label
            is-disabled={disabled ? 'true' : 'false'}
            is-checked={checked ? 'true' : 'false'}
            htmlFor={checboxId}
            onClick={(e) => e.stopPropagation()}
            className={style.label}
          />
        </div>
      );
    },
  ),
);

export type FECheckBoxProps = {
  checked: boolean;
  onChange?: (v: boolean) => void;
  disabled?: boolean;
};
