import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetFeaturesInfoByPlaceIdResponseV1 } from '.';

const featuresRepositoryV1 = {
  getFeaturesByPlaceId: (
    { placeId }: { placeId: string },
    signal?: AbortSignal,
  ) =>
    new Promise<GetFeaturesInfoByPlaceIdResponseV1.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/features-info/${placeId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetFeaturesInfoByPlaceIdResponseV1.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetFeaturesInfoByPlaceIdResponseV1.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default featuresRepositoryV1;
