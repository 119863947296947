import clsx from 'clsx';
import { useEvent } from 'hooks';
import React from 'react';
import { useClickOutside, useMediaQuery } from 'ui-kit/hooks';
import { useTranslation } from 'react-i18next';
import List from './components/List';
import FEAddressSearchFieldProps, { AddressInList } from './props';
import style from './styles/field.styles.module.css';

const minRows = 1;
const maxRows = 3;

const FEAddressSearchField = ({
  value,
  onChange,
  onClear,
  isPending,
  data,
  onChangeAddress,
  hasError = false,
  showPlaceholder,
  disabled,
}: FEAddressSearchFieldProps) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const firstRenderRef = React.useRef<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const [rows, setRows] = React.useState<number>(0);
  const isDesktop = useMediaQuery('(min-width: 500px)');

  // const toggleFocusHandler = useEvent(() => {
  //   setIsFocused(!isFocused);
  //   setIsOpen(!isOpen);
  // });

  const onClickAddress = useEvent((address: AddressInList) => {
    if (address.final) {
      onChangeAddress(address);
      onChange({ address: address.address, final: address.final });
      setIsFocused(false);
      setIsOpen(false);
    } else {
      onChange({ address: address.address + ' ', final: address.final });
      textAreaRef.current?.focus();
    }
  });

  useClickOutside(ref, () => {
    setIsOpen(false);
    setIsFocused(false);
  });

  React.useLayoutEffect(() => {
    if (firstRenderRef.current) {
      const input = textAreaRef.current;
      const textareaLineHeight = 24;
      if (input) {
        const currentRows = ~~(input.scrollHeight / textareaLineHeight);
        setRows(currentRows);
      }
    } else {
      setRows(minRows);
    }
    firstRenderRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, firstRenderRef.current]);

  const handleChange = useEvent((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange({ address: e.target.value, final: false });
    const textareaLineHeight = 24;
    const previousRows = e.target.rows;
    e.target.rows = minRows;
    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }
    setRows(currentRows < maxRows ? currentRows : maxRows);
    setIsOpen(Boolean(e.target.value));
  });

  return (
    <>
      <div
        ref={ref}
        className={clsx(
          style.root,
          isFocused && style.root__focused,
          isOpen && style.root__open,
          hasError && !isFocused && !isOpen && style.root__error,
        )}
        is-disabled={disabled ? 'true' : 'false'}
        onClick={(e) => {
          setIsFocused(true);
          if (textAreaRef.current) textAreaRef.current.focus();
        }}
      >
        <div className={style.container}>
          <div className={clsx(style.searchField)}>
            <textarea
              onKeyDown={(e) => {
                const key = e.key;
                if (key === 'Enter') {
                  e.preventDefault();
                }
              }}
              disabled={disabled}
              placeholder={t('cartography.address.form.fieldPlaceholder')}
              rows={rows}
              // onFocus={toggleFocusHandler}
              // onBlur={toggleFocusHandler}
              className={clsx('text-1', style.searchField__input)}
              ref={textAreaRef}
              id='FESearchField'
              onChange={handleChange}
              value={value ?? ''}
            />
          </div>
          {value && (
            <i
              className={style.clearIcon}
              onClick={(e) => {
                onClear && onClear();
                setRows(minRows);
                e.preventDefault();
                e.stopPropagation();
                setIsOpen(false);
              }}
            />
          )}
        </div>
        <List
          onScroll={() =>
            !isDesktop && isFocused && textAreaRef.current?.blur()
          }
          showPlaceholder={showPlaceholder}
          onClickAddress={onClickAddress}
          data={data}
          isPending={isPending}
          open={isOpen}
        />
      </div>
    </>
  );
};

export default FEAddressSearchField;
export type { FEAddressSearchFieldProps };
