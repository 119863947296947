// import { MOBILE_WIDTH } from 'const';
import React from 'react';
// import { useMediaQuery } from 'ui-kit/hooks';
import { BonusPickerProps } from './props';
import style from './style.module.css';
import { IMG_IC_24_BONUS } from 'images';
import { FESlider, FETextField } from 'ui-kit/components';
import { useTranslation } from 'react-i18next';

const BonusPicker = ({
  amount,
  balance,
  onChange,
  bonusName,
  disabled = false,
  orderTotal,
}: BonusPickerProps) => {
  const { t } = useTranslation();
  // const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);
  // const [open, setOpen] = React.useState<boolean>(false);

  return (
    <div className={style.root}>
      <p className='title'>{bonusName}</p>
      <div className={style.content}>
        <div className={style.title}>
          <p className='text-1'>
            {t('cart.ordering.components.bonusPicker.balance', { balance })}
          </p>
          <img src={IMG_IC_24_BONUS} className='icon-24' alt='' />
        </div>
        <FETextField
          label={t('cart.ordering.components.bonusPicker.useBonusesLabel')}
          min={0}
          max={Math.min(balance, orderTotal)}
          type='number'
          value={Boolean(amount) ? (amount || 0).toString() : ''}
          onChange={(e) => {
            if (!disabled) {
              const value = Number(e.target.value);
              if (value > Math.min(balance, orderTotal))
                return onChange(Math.min(balance, orderTotal));
              if (value < 0) return onChange(0);
              return onChange(value);
            }
          }}
        />
        <div className={style.slider}>
          <FESlider
            disabled={disabled}
            value={amount || 0}
            min={0}
            max={Math.min(balance, orderTotal)}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BonusPicker;
