import clsx from 'clsx';
import style from './style.module.css';

const Block = ({
  onClick,
  value,
  icon,
  className,
}: {
  onClick: () => void;
  value: string;
  icon: string;
  className?: string;
}) => {
  return (
    <div onClick={onClick} className={clsx(style.root, className)}>
      <img className={style.icon} src={icon} alt='' />
      <p className={clsx('caption-2', style.value)}>{value}</p>
    </div>
  );
};

export default Block;
