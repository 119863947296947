import FeatSDKV1 from 'api/version1';
import { createEffect } from 'effector';
import { AuthClient } from 'api/base';

export const getGiftRepurchasesFx = createEffect(
  async (params: { cityId: string }) =>
    AuthClient.isAuth
      ? FeatSDKV1.giftRepurchases.getGiftRepurchases(params)
      : FeatSDKV1.giftRepurchases.notSecureGetGiftRepurchases(params),
);
