import clsx from 'clsx';
import { $legalsDocuments, $pendingLegals } from 'domains/legals';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { FESpinner } from 'ui-kit/components';
import style from './style.module.css';

const linkOptions = {
  target: '_blank',
  rel: 'noreferrer',
};

const Content = () => {
  const { t } = useTranslation();
  const [legalsDocuments, pending] = useUnit([
    $legalsDocuments,
    $pendingLegals,
  ]);

  return (
    <>
      <p className={clsx('text-2', style.message)}>
        {t('legalInformation.message')}
      </p>
      <div className={style.root}>
        {legalsDocuments.map(({ name, link }) => (
          <a
            className={clsx('headline', style.link)}
            key={link}
            {...linkOptions}
            href={link}
          >
            {name}
          </a>
        ))}
        {pending && (
          <div className={style.pendingContainer}>
            <FESpinner />
          </div>
        )}
      </div>
    </>
  );
};

export default Content;
