import { initCatalogFx } from 'domains/application';
import { sample } from 'effector';
import i18next from 'i18next';
import { spread } from 'patronum';
import { FEHeadersCookiesUtil } from 'utils';
import { initLanguageFx } from './effects';
import { changeLanguage } from './events';
import { $language } from './stores';
import { initUserFx } from 'domains/profile';

//@ts-expect-error
spread({
  source: sample({
    clock: changeLanguage,
    fn: (lng) => {
      FEHeadersCookiesUtil.setLanguage(lng);
      return { lng, catalog: false };
    },
  }),
  targets: {
    lng: $language,
    catalog: initCatalogFx,
    initUserFx,
  },
});

sample({
  clock: initLanguageFx.doneData,
  source: $language,
  fn: (sourceLanguage, payloadLanguage) => {
    if (payloadLanguage !== sourceLanguage) {
      FEHeadersCookiesUtil.setLanguage(payloadLanguage);
      i18next.changeLanguage(payloadLanguage);
    }

    return payloadLanguage;
  },
  target: $language,
});
