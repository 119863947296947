import { useMount } from 'hooks';
import React from 'react';
import FEPortal from '../FEPortal';
import Layout from './components/Layout';
import FEDialogProps from './props';

const FEDialog = (props: FEDialogProps) => {
  const { mounted } = useMount({ isOpened: props.open });

  if (!mounted) return null;

  return (
    <FEPortal>
      <Layout {...props} />
    </FEPortal>
  );
};

export default FEDialog;
export type { FEDialogProps };
