import React from 'react';
import FESwitchProps from './props';
import style from './style.module.css';

const FESwitch = ({ value, onChange }: FESwitchProps) => {
  return (
    <div className={style.root}>
      <div className={style.container}>
        <input
          className={style.input}
          type='checkbox'
          checked={value}
          readOnly
        />
        <span className={style.switch} onClick={() => onChange(!value)} />
      </div>
    </div>
  );
};

export default FESwitch;
export type { FESwitchProps };
