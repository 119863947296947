import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { ProductsSearchRequestV3 } from '.';
import { ProductsSearchResponseV3 } from '.';

const productsRepositoryV3 = {
  search: (request: ProductsSearchRequestV3, signal?: AbortSignal) =>
    new Promise<ProductsSearchResponseV3.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v3/products/search`;
        let result: AxiosResponse<
          BaseAPIResponse<ProductsSearchResponseV3.Response>
        > = await BaseAPIRepository.post<
          BaseAPIResponse<ProductsSearchResponseV3.Response>
        >(url, request, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default productsRepositoryV3;
