import { Rule } from 'effector-forms';

class FEValidationUtils {
  static required = () => ({
    name: 'required',
    validator: (value: any) => Boolean(value),
    errorText: 'Обязательное поле',
  });

  static email = (): Rule<string | null> => ({
    name: 'email',
    validator: (value) => {
      if (value === null) return false;
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      );
    },
    errorText: 'Неверный формат почты',
  });

  static true = (): Rule<boolean | null> => ({
    name: 'true',
    validator: (value) => {
      if (value === null) return false;
      return value;
    },
  });
}

export default FEValidationUtils;
