import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import {
  GetPromotionsByCatalogIdRequestV2,
  GetPromotionByIdRequestV2,
} from '.';
import {
  GetPromotionsByCatalogIdResponseV2,
  GetPromotionByIdResponseV2,
} from '.';

const promotionsRepositoryV2 = {
  getPromotionsByCatalogId: (
    { catalogId }: GetPromotionsByCatalogIdRequestV2,
    signal?: AbortSignal,
  ) =>
    new Promise<GetPromotionsByCatalogIdResponseV2.ListResponse>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v2/promotions?catalogId=${catalogId}`;
          let result: AxiosResponse<
            BaseAPIResponse<GetPromotionsByCatalogIdResponseV2.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetPromotionsByCatalogIdResponseV2.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getPromotionById: ({ id }: GetPromotionByIdRequestV2, signal?: AbortSignal) =>
    new Promise<GetPromotionByIdResponseV2>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v2/promotions/${id}`;
        let result: AxiosResponse<BaseAPIResponse<GetPromotionByIdResponseV2>> =
          await BaseAPIRepository.get<
            BaseAPIResponse<GetPromotionByIdResponseV2>
          >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default promotionsRepositoryV2;
