import { INITIAL_LANGUAGE } from 'const';
import { createEffect } from 'effector';
import { Language } from 'models';
import { FEHeadersCookiesUtil } from 'utils';

export const initLanguageFx = createEffect(() => {
  let language: Language;

  if (FEHeadersCookiesUtil.getLanguage) {
    language = FEHeadersCookiesUtil.getLanguage;
  } else {
    language = INITIAL_LANGUAGE;
  }

  return language;
});
