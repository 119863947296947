import {
  $applicationStarting,
  $isPendingCatalogDeps,
} from 'domains/application';
import { useUnit } from 'effector-react';
import { useMount } from 'hooks';
import React from 'react';
import { Layout } from './components';

const ApplicationLoaderLayout = () => {
  const loadersArr = useUnit([$applicationStarting, $isPendingCatalogDeps]);
  const someIsPending = loadersArr.some((loader) => loader);

  const { mounted } = useMount({ isOpened: someIsPending });

  if (!mounted) return null;

  return <Layout open={someIsPending} />;
};

export default ApplicationLoaderLayout;
