import { GiftRepurschase } from 'domains/giftRepurschases';
import { FEImageComponent, FEModifierCard } from 'components';
import { IMG_PLACEHOLDER_PERCENT } from 'images';
import style from './style.module.css';
import clsx from 'clsx';
import ProgressBar from '../progressBar';
import { useNavigate } from 'react-router-dom';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';

const Content = ({
  giftRepurchase,
  products: catalogProducts,
  currency,
}: {
  giftRepurchase: GiftRepurschase;
  currency: CurrencySymbol;
  products: {
    [k: string]: {
      productId: string;
      productName: string;
      categoryId: string;
      categoryName: string;
    };
  };
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { name, description, detailImageId, maxLevel, currentLevel, products } =
    giftRepurchase;

  return (
    <div className={style.root}>
      <FEImageComponent
        processingComponent={
          <img
            alt=''
            className={style.processingImage}
            src={IMG_PLACEHOLDER_PERCENT}
          />
        }
        loaderContainerClassName={style.loader}
        className={style.image}
        image={{ imageId: detailImageId }}
        spinnerSize='medium'
      />
      <p className={clsx('title', style.name)}>{name}</p>
      <p className={clsx('text-2', style.description)}>{description}</p>
      <div className={style.progressContainer}>
        <ProgressBar
          type='secondary'
          max={maxLevel}
          current={currentLevel || 0}
        />
        <p className={clsx('text-2', style.progressCaption)}>
          {t('banners.giftRepurchase.progressCaption', {
            current: currentLevel || 0,
            max: maxLevel,
          })}
        </p>
      </div>
      <div className={style.giftsContainer}>
        <p className='headline'>{t('banners.giftRepurchase.giftsLabel')}</p>
        <div className={style.giftsList}>
          {products.map((product, i) => (
            <div key={product.id} className={style.giftCard}>
              <FEModifierCard
                {...{
                  currency,
                  active: false,
                  description: product.description,
                  onClick: () =>
                    catalogProducts[product.id].productName
                      ? navigate(`/${catalogProducts[product.id].productName}`)
                      : null,
                  imageId: product.imageId,
                  name: product.name,
                  disabled: false,
                  size: product.size,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Content;
