import React from 'react';
import { FEMultilineField, FESearchPlaceholder, FESpinner } from 'components';
import FECityPickerProps from '../../props';
import style from './style.module.css';
import { useEvent } from 'hooks';
import { useTranslation } from 'react-i18next';

const List = ({
  currentCity,
  data,
  onPickCity,
  isPendingData,
  onClose,
}: FECityPickerProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>('');
  const [citiesList, setCitiesList] = React.useState<typeof data>([]);
  const deferredList = React.useDeferredValue(citiesList);

  React.useEffect(() => setCitiesList(data), [data]);

  React.useLayoutEffect(() => {
    if (currentCity) {
      setValue(currentCity.name);
    }
  }, [currentCity]);

  const changeQueryHandler = useEvent(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = e.target.value;
      setValue(val);
      if (val) {
        const newCitiesList = data.filter((city) =>
          city.name.toLowerCase().includes(val.toLowerCase()),
        );
        setCitiesList(newCitiesList);
      } else setCitiesList(data);
    },
  );

  return (
    <div className={style.root}>
      <div className={style.search}>
        <FEMultilineField
          disabled={isPendingData}
          hasSelectionEffect={false}
          value={value}
          onChange={changeQueryHandler}
          placeholder={t('cartography.city.picker.fieldPlaceholder')}
          onClear={() => {
            setValue('');
            setCitiesList(data);
          }}
        />
      </div>
      <div className={style.listContainer}>
        {isPendingData && (
          <div className={style.fetchingContainer}>
            <FESpinner size='large' />
          </div>
        )}
        {deferredList.length === 0 && isPendingData === false ? (
          <div className={style.placeholderContainer}>
            <FESearchPlaceholder
              title={t('searchPlaceholderComponent.title')}
              message={t('cartography.city.picker.searchPlaceholder')}
            />
          </div>
        ) : (
          <div className={style.list}>
            {deferredList
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((city) => (
                <p
                  className={style.listItem}
                  key={city.id}
                  onClick={() => {
                    currentCity !== city
                      ? onPickCity(city)
                      : onClose && onClose();
                    setCitiesList(data);
                    setValue(city.name);
                  }}
                >
                  {city.name}
                </p>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default List;
