import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { PostNewImageRequestV1 } from '.';
import { PostNewImageResponseV1 } from '.';

const imagesRepositoryV1 = {
  postImage: (request: PostNewImageRequestV1, signal?: AbortSignal) =>
    new Promise<PostNewImageResponseV1>(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append('file', request.file);
        let url = `${BASE_URL}/api/v1/images`;
        let result: AxiosResponse<BaseAPIResponse<PostNewImageResponseV1>> =
          await BaseAPIRepository.post<BaseAPIResponse<PostNewImageResponseV1>>(
            url,
            formData,
            { signal },
          );
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),

  getImage: (id: string): string =>
    `${BASE_URL}/not-secure/api/v1/images/${id}`,
};

export default imagesRepositoryV1;
