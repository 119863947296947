import { ANIMATION_DELAY } from 'const';
import React from 'react';
const useMount = ({ isOpened }: { isOpened: boolean }) => {
  const [mounted, setMounted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isOpened && !mounted) {
      setMounted(true);
    } else if (!isOpened && mounted) {
      setTimeout(() => {
        setMounted(false);
      }, ANIMATION_DELAY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return { mounted };
};

export default useMount;
