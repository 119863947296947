import { GET_ENUMS, GET_PARAMS } from 'const';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'ui-kit/hooks';
import { FEBottomSheet, FEDialog, FENotificationCard } from 'components';
import {
  $constructors,
  $dishes,
  $cutleries,
  $isValidating,
  $price,
  $problems,
  $promoCode,
  $promotion,
  $promotionProduct,
  $timeTo,
  $urgent,
  $warning,
  orderingForm,
  cartGate,
  $createdOrderNotificationStore,
  closeCreatedOrderModal,
  changePromotionProduct,
  clearCartAfterOrder,
  $hasCriticalCartError,
  $validationErrors,
  hideValidationError,
  validate,
  $longDeliveryWarning,
  CreateOrderFxModel,
  createOrderFx,
  $avaliableOrderPaymentTypes,
  clearCart,
} from 'domains/cart';
import {
  $type,
  $timeIntervals,
  $deliveryZone,
  $address,
  $restaurant,
  $currency,
  $country,
  $countriesList,
  CountryCodeModel,
  $city,
} from 'domains/cartography';
import {
  setTime,
  deleteConstructor,
  deleteCutlery,
  deleteDish,
  changeConstructorQuantity,
  changeCutleryQuantity,
  changeDishQuantity,
  setPromocodeFx,
} from 'domains/cart';
import { $bonusAccount, $user } from 'domains/profile';
import { useGate, useUnit } from 'effector-react';
import { AdmitadScript, Content } from './components';
import { useField } from 'effector-forms';
import { OrderType, PaymentType } from 'models';
import { usePrepareLink } from 'hooks/router';
import {
  mapConstructorsFromCartToValidate,
  mapDishesFromCartToValidate,
} from 'domains/cart/model';
import { $features } from 'domains/features';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { $catalogCategories, CatalogCategory } from 'domains/catalog';

const CartPage = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const [stage, setStage] = React.useState<'CART' | 'ORDERING'>('CART');
  const isDesktop = useMediaQuery(`(min-width: ${600}px)`);
  const navigate = useNavigate();

  useGate(cartGate);

  const title = React.useMemo(
    () => (stage === 'CART' ? t('cart.cartTitle') : t('cart.orderingTitle')),
    [stage, t],
  );

  const currency = useUnit($currency);

  const [
    dishes,
    onChangeDishQuantity,
    onDeleteDish,
    constructors,
    onChangeConstructorQuantity,
    onDeleteConstructor,
    cutleries,
    onChangeCutleryQuantity,
    onDeleteCutlery,
    categories,
  ] = useUnit([
    $dishes,
    changeDishQuantity,
    deleteDish,
    $constructors,
    changeConstructorQuantity,
    deleteConstructor,
    $cutleries,
    changeCutleryQuantity,
    deleteCutlery,
    $catalogCategories,
  ]);

  const [
    orderModalState,
    closeOrderModal,
    onClearCartAfterOrder,
    onCreateOrder,
  ] = useUnit([
    $createdOrderNotificationStore,
    closeCreatedOrderModal,
    clearCartAfterOrder,
    createOrderFx,
  ]);

  const paymentTypes = useUnit($avaliableOrderPaymentTypes);
  const user = useUnit($user);
  const bonusAccount = useUnit($bonusAccount);
  const features = useUnit($features);

  const [timeTo, urgent, onChangeTime] = useUnit([$timeTo, $urgent, setTime]);

  const [promoCode] = useUnit([$promoCode]);
  const onChagePromocode = useUnit(setPromocodeFx);

  const [country, countries] = useUnit([$country, $countriesList]);

  const [countryCode, setCurrentCountryCode] =
    useState<CountryCodeModel | null>(null);

  useEffect(() => {
    setCurrentCountryCode(country?.code || null);
  }, [country]);

  const [promotion, promotionProduct, onChangePromotionProduct] = useUnit([
    $promotion,
    $promotionProduct,
    changePromotionProduct,
  ]);

  const [type, timeIntervals, deliveryZone, address, restaurant, city] =
    useUnit([
      $type,
      $timeIntervals,
      $deliveryZone,
      $address,
      $restaurant,
      $city,
    ]);

  const [
    hasCriticalCartError,
    validationErrors,
    onHideValidationError,
    onValidate,
    onClearCart,
  ] = useUnit([
    $hasCriticalCartError,
    $validationErrors,
    hideValidationError,
    validate,
    clearCart,
  ]);

  const customer = useUnit($user);

  const isValidating = useUnit($isValidating);

  const [price, warning, longDeliveryWarning, problems] = useUnit([
    $price,
    $warning,
    $longDeliveryWarning,
    $problems,
  ]);

  const { value: phone, onChange: onChangePhone } = useField(
    orderingForm.fields.phone,
  );
  const { value: name, onChange: onChangeName } = useField(
    orderingForm.fields.name,
  );
  const { value: comment, onChange: onChangeComment } = useField(
    orderingForm.fields.comment,
  );
  const { value: paymentType, onChange: onChangePaymentType } = useField(
    orderingForm.fields.paymentType,
  );
  const { value: bonusAmount, onChange: onChangeBonusAmount } = useField(
    orderingForm.fields.bonusAmount,
  );
  const { value: changeFrom, onChange: onChangeChangeFrom } = useField(
    orderingForm.fields.changeFrom,
  );

  const createOrderData: CreateOrderFxModel & {
    categories: CatalogCategory[];
  } = {
    categories,
    dishes: mapDishesFromCartToValidate(dishes),
    constructors: mapConstructorsFromCartToValidate(constructors),
    isLogin: customer !== null,
    name: name || '',
    phone: phone || '',
    timeTo,
    urgent,
    cutleries: cutleries.map((cutlery) => ({
      orderItemId: cutlery.orderItemId,
      complectationId: cutlery.complectation.complectation.id,
      quantity: cutlery.quantity,
    })),
    promoCode,
    type,
    receivingAddress: address,
    receivingRestaurantId: restaurant ? restaurant.id : null,
    promotionProduct: promotionProduct
      ? promotionProduct.product.product.id
      : null,
    customerCount: 1,
    paymentType: paymentType!,
    bonusAmount,
    comment,
    changeFrom,
  };

  const emptyCartDescription = React.useMemo(() => {
    if (type === 'COURIER')
      return `${t('cart.emptyCart.description')}
    ${t('cart.emptyCart.caption', {
      deliveryPrice: deliveryZone?.conditions?.deliveryPriceConditions
        ? Math.min(
            ...deliveryZone.conditions.deliveryPriceConditions.map(
              (condition) => condition.orderTotalMinimum,
            ),
          )
        : 0,
      currency,
    })}`;
    return t('cart.emptyCart.description');
  }, [type, deliveryZone, t, currency]);

  const cartIsEmpty = React.useMemo(
    () => dishes.length === 0 && constructors.length === 0,
    [dishes, constructors],
  );

  const [orderType, setOrderType] =
    React.useState<Exclude<OrderType, 'IN_PLACE'>>('COURIER');

  React.useEffect(() => {
    setOrderType(type as Exclude<OrderType, 'IN_PLACE'>);
  }, [type]);

  const orderLink = usePrepareLink({
    pushToQuery: {
      [GET_PARAMS.orderId]: orderModalState.orderId || '',
    },
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.orders,
    },
  });

  const cartographyLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
    },
    pushToQuery: {
      [GET_PARAMS.page]: GET_ENUMS.page.cart,
    },
    keepOldQuery: true,
  });

  return (
    <>
      {city && (
        <Helmet>
          <title>{`Корзина | СушиВесла ${city.name}`}</title>
          <meta
            name='description'
            content={`Оформить заказ суши, роллов и воков на дом или в офис. СушиВесла в ${city.name}.`}
          />
        </Helmet>
      )}
      {cartIsEmpty ? (
        <FENotificationCard
          icon='shoppingCart'
          title={t('cart.emptyCart.title')}
          open={cartIsEmpty}
          onClose={() => navigate(-1)}
          description={emptyCartDescription}
          primaryButtonProps={{
            text: t('cart.emptyCart.buttonText'),
            closeOnClick: true,
            onClick: () => {},
          }}
        />
      ) : isDesktop ? (
        <FEDialog
          open={open}
          onClose={() => navigate(-1)}
          render={({ onClose }) => (
            <Content
              {...{
                categories,
                changeCountryCode: setCurrentCountryCode,
                countries: countries.map((country) => country.code),
                country: countryCode,
                onClearCart,
                currency,
                features,
                createOrderData,
                isDesktop,
                title,
                onArrowBackClick: () => setStage('CART'),
                hasCriticalCartError,
                cartographyLink,
                onClose,
                dishes,
                constructors,
                cutleries,
                price,
                warning,
                longDeliveryWarning,
                isValidating,
                timeTo,
                urgent,
                promoCode,
                onChangePromoCode: (promoCode) =>
                  onChagePromocode({
                    promoCode,
                    validationPayload: {
                      deliveryZoneId: deliveryZone?.id || null,
                      request: {
                        dishes,
                        cutleries: cutleries.map((cutlery) => ({
                          orderItemId: cutlery.orderItemId,
                          complectationId:
                            cutlery.complectation.complectation.id,
                          quantity: cutlery.quantity,
                        })),
                        constructors,
                        promotionProduct:
                          promotionProduct?.product.product.id || null,
                        urgent,
                        timeTo,
                        type,
                        receivingAddress: address,
                        receivingRestaurantId: restaurant
                          ? restaurant.id
                          : null,
                        customerCount: 1,
                        customer: customer
                          ? {
                              name: customer?.fullName?.firstName || '',
                              phone: customer?.phone || '',
                            }
                          : null,
                      },
                    },
                  }),
                onChangeTime,
                stage,
                promotion,
                promotionProduct,
                problems,
                deliveryZone,
                type: orderType,
                timeIntervals,
                address,
                restaurant,
                onChangeStage: (stage) => setStage(stage),
                name,
                onChangeName,
                phone,
                onChangePhone,
                comment,
                onChangeComment,
                paymentType,
                onChangePaymentType,
                bonusAmount,
                onChangeBonusAmount,
                changeFrom,
                onChangeChangeFrom,
                onChangeConstructorQuantity,
                onChangeCutleryQuantity,
                onChangeDishQuantity,
                onDeleteConstructor,
                onDeleteCutlery,
                onDeleteDish,
                paymentTypes: paymentTypes as Exclude<
                  PaymentType,
                  'BONUS' | 'SBERPAY'
                >[],
                user,
                bonusAccount,
                onCreateOrder,
                navigateFunction: navigate,
                onChangePromotionProduct,
              }}
            />
          )}
        />
      ) : (
        <FEBottomSheet
          open={open}
          onClose={() => navigate(-1)}
          render={({ onClose }) => (
            <Content
              {...{
                categories,
                onClearCart,
                changeCountryCode: setCurrentCountryCode,
                countries: countries.map((country) => country.code),
                country: countryCode,
                currency,
                features,
                createOrderData,
                isDesktop,
                title,
                onArrowBackClick: () => setStage('CART'),
                deliveryZone,
                hasCriticalCartError,
                cartographyLink,
                onClose,
                dishes,
                constructors,
                cutleries,
                price,
                warning,
                longDeliveryWarning,
                isValidating,
                timeTo,
                urgent,
                promoCode,
                onChangePromoCode: (promoCode) =>
                  onChagePromocode({
                    promoCode,
                    validationPayload: {
                      deliveryZoneId: deliveryZone?.id || null,
                      request: {
                        dishes,
                        cutleries: cutleries.map((cutlery) => ({
                          orderItemId: cutlery.orderItemId,
                          complectationId:
                            cutlery.complectation.complectation.id,
                          quantity: cutlery.quantity,
                        })),
                        constructors,
                        promotionProduct:
                          promotionProduct?.product.product.id || null,
                        urgent,
                        timeTo,
                        type,
                        receivingAddress: address,
                        receivingRestaurantId: restaurant
                          ? restaurant.id
                          : null,
                        customerCount: 1,
                        customer: customer
                          ? {
                              name: customer?.fullName?.firstName || '',
                              phone: customer?.phone || '',
                            }
                          : null,
                      },
                    },
                  }),
                onChangeTime,
                stage,
                promotion,
                promotionProduct,
                problems,
                type: orderType,
                timeIntervals,
                address,
                restaurant,
                onChangeStage: (stage) => setStage(stage),
                name,
                onChangeName,
                phone,
                onChangePhone,
                comment,
                onChangeComment,
                paymentType,
                onChangePaymentType,
                bonusAmount,
                onChangeBonusAmount,
                changeFrom,
                onChangeChangeFrom,
                onChangeConstructorQuantity,
                onChangeCutleryQuantity,
                onChangeDishQuantity,
                onDeleteConstructor,
                onDeleteCutlery,
                onDeleteDish,
                paymentTypes: paymentTypes as Exclude<
                  PaymentType,
                  'BONUS' | 'SBERPAY'
                >[],
                user,
                bonusAccount,
                onCreateOrder,
                navigateFunction: navigate,
                onChangePromotionProduct,
              }}
            />
          )}
        />
      )}
      {validationErrors.map((error, i) => (
        <FENotificationCard
          open={true}
          key={i}
          title={error.title}
          description={error.message}
          icon={'sorry'}
          onClose={() => {
            onHideValidationError({ index: i });
            switch (error.actionType) {
              case 'cartography':
                navigate(cartographyLink);
                break;
              case 'catalog':
                navigate('/', { replace: true });
                break;
              case 'hide':
                break;
              case 'revalidate':
                onValidate();
                break;
            }
          }}
          primaryButtonProps={{
            closeOnClick: true,
            text: t(error.buttonText),
            onClick: () => {},
          }}
        />
      ))}
      <FENotificationCard
        open={orderModalState.show}
        icon={orderModalState.icon || 'checkmark'}
        title={orderModalState.title || ''}
        description={orderModalState.description || ''}
        onClose={() => {
          closeOrderModal();
          navigate('/');
          onClearCartAfterOrder();
        }}
        primaryButtonProps={{
          closeOnClick: true,
          text: orderModalState.button?.text || '',
          onClick: () => {
            orderModalState.type === 'SUCCESS' && onClearCartAfterOrder();
            navigate(orderLink, { replace: true });
          },
        }}
      />
      {orderModalState.show && orderModalState.type === 'SUCCESS' && (
        <AdmitadScript
          price={orderModalState.price}
          items={orderModalState.items}
          orderNumber={orderModalState.orderNumber}
        />
      )}
    </>
  );
};

export default CartPage;
