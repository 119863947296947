import { CurrencySymbol } from 'models';
import { IMG_PLACEHOLDER_DELIVERY } from 'ui-kit/images';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';

const Delivery = ({
  price,
  currency,
}: {
  price: number;
  currency: CurrencySymbol;
}) => {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <div className={style.container}>
        <img className={style.image} src={IMG_PLACEHOLDER_DELIVERY} alt='' />
        <div className={style.data}>
          <p className='subhead'>{t('cart.main.delivery.label')}</p>
          <p className='subhead'>{`${Math.trunc(price)} ${currency}`}</p>
        </div>
      </div>
    </div>
  );
};

export default Delivery;
