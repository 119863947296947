import React from 'react';
import FEOverlay from '../../FEOverlay';
import FEDialogProps from '../props';
import style from '../style.module.css';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { ANIMATION_DELAY } from 'const';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

const Layout = ({
  onClose,
  closeOnOverlayClick = true,
  showCloseButton = true,
  render,
}: FEDialogProps) => {
  const [animationIn, setAnimationIn] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => setAnimationIn(true), []);

  function handleClose() {
    if (onClose) {
      onClose();
      setAnimationIn(false);
    }
  }

  function handleCloseModal() {
    setAnimationIn(false);
  }

  return (
    <div className={style.dialog} role='dialog'>
      <FEOverlay
        onClick={() => closeOnOverlayClick && handleClose()}
        animationIn={animationIn}
      />
      <CSSTransition
        in={animationIn}
        timeout={ANIMATION_DELAY}
        mountOnEnter
        unmountOnExit
        classNames={containerAnimation}
        nodeRef={containerRef}
      >
        {/* <div
          className={clsx(
            style.wrapper,
            showCloseButton && style.extendedWrapperPadding,
          )}
          // onClick={(e) => {
          //   closeOnOverlayClick && handleClose();
          //   e.preventDefault();
          //   e.stopPropagation();
          // }}
        > */}
        <div className={style.container} ref={containerRef}>
          {showCloseButton && (
            <button
              tabIndex={0}
              className={clsx('closeButton-desktop', style.closeButton)}
              onClick={(e) => {
                handleClose();
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
          <>
            {render({ onClose: handleClose, onCloseModal: handleCloseModal })}
          </>
          {/* </div> */}
        </div>
      </CSSTransition>
    </div>
  );
};

export default Layout;
