import React from 'react';
import style from './style.module.css';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';
import FeatSDKV1 from 'api/version1';
import FESpinner from '../FESpinner';
import { onImageError } from 'utils';

const FEObserveredVideo = ({
  imageClassName,
  videoClassName,
  containerClassName,
  loaderContainerClassName,
  videoId,
  imageId,
  altImage,
  onError,
  spinnerSize = 'small',
}: {
  videoClassName?: string;
  imageClassName?: string;
  containerClassName?: string;
  loaderContainerClassName?: string;
  videoId: string;
  imageId: string;
  altImage: string;
  onError?: () => void;
  spinnerSize?: 'small' | 'large' | 'medium';
}) => {
  const [url, setUrl] = React.useState<string>();
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [isPending, setisPending] = React.useState<boolean>(true);
  const [isInView, setIsInView] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const { ref, inView } = useInView();
  const src = (videoId: string) => FeatSDKV1.videos.getVideo(videoId);
  const imageSrc = (imageId: string) => FeatSDKV1.images.getImage(imageId);

  React.useEffect(() => {
    if (inView === true) setIsInView(true);
  }, [inView]);

  React.useEffect(() => {
    if (isInView) {
      setisPending(true);
      new Promise((resolve, reject) =>
        fetch(src(videoId)).then((response) => {
          if (response.ok)
            return response
              .blob()
              .then((video) => resolve(URL.createObjectURL(video)));
          return reject();
        }),
      )
        .then((urls) => {
          setUrl(urls as string);
          setisPending(false);
        })
        .catch(() => {
          onError && onError();
          setUrl('');
          setHasError(true);
          new Promise((resolve, reject) =>
            fetch(imageSrc(imageId)).then((response) => {
              if (response.ok)
                return response
                  .blob()
                  .then((image) => resolve(URL.createObjectURL(image)));
              return reject();
            }),
          )
            .then((urls) => {
              setImageUrl(urls as string);
              setisPending(false);
            })
            .catch(() => {
              onError && onError();
              setisPending(false);
              setImageUrl('');
            });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId, isInView, imageId]);

  return (
    <div ref={ref} className={clsx(style.root, containerClassName)}>
      <div
        className={clsx(
          style.loaderContainer,
          isPending === false && style.loaderContainer__loaded,
          loaderContainerClassName,
        )}
      >
        <FESpinner size={spinnerSize} />
      </div>

      {hasError === false ? (
        <video
          className={clsx(style.image, videoClassName)}
          src={url}
          autoPlay={true}
          controls={false}
          loop={true}
          muted={true}
          playsInline={true}
          onContextMenu={(e) => e.preventDefault()}
          onError={() => {
            setHasError(true);
          }}
        />
      ) : (
        <img
          className={clsx(style.image, imageClassName)}
          src={imageUrl}
          onContextMenu={(e) => e.preventDefault()}
          onError={(e) => onImageError(e, altImage)}
          alt=''
        />
      )}
    </div>
  );
};

export default FEObserveredVideo;
