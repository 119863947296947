import { PromotionModel } from 'domains/promotion';
import style from './style.module.css';
import { FESpinner } from 'ui-kit/components';
import { Promotion, PromotionModal } from './components';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';
// import { PROMOTIONS_PATHNAME } from 'const';

const Content = ({
  promotions,
  isPending,
  onClose,
}: {
  promotions: PromotionModel[];
  isPending: boolean;
  onClose: () => void;
}) => {
  const location = useLocation();

  const match = useMatch(
    `${location.pathname.split('/').slice(0, -1).join('/')}/:promotionId`,
  );
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (promotionId: string) => navigate(`${location.pathname}/${promotionId}`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGoBack = useCallback(() => navigate(-1), []);

  return (
    <>
      <div className={style.promotionsList}>
        {promotions.map((promotion) => (
          <React.Fragment key={promotion.id}>
            <Promotion promotion={promotion} onClick={handleNavigate} />
            <PromotionModal
              onClose={handleGoBack}
              open={match?.params.promotionId === promotion.id}
              promotion={promotion}
            />
          </React.Fragment>
        ))}
      </div>
      {isPending && (
        <div className={style.fetchingContainer}>
          <FESpinner size='large' />
        </div>
      )}
    </>
  );
};

export default Content;
