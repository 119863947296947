import React from 'react';
import { MOBILE_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import { DeliveryPriceConditionsProps } from './props';
import { FEButton, FETitledBottomSheet, FETitledDialog } from 'components';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';

const DeliveryPriceConditions = ({
  open,
  onClose,
  conditions,
}: DeliveryPriceConditionsProps) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  const Content = React.useMemo(
    () =>
      ({ onClose }: { onClose: () => void }) => {
        return (
          <div className={style.root}>
            <div className={style.conditions}>
              {conditions?.map((condition, i) => (
                <div key={i} className={style.condition}>
                  <p className='text-1'>{condition.label}</p>
                  <p className='text-1'>{condition.price}</p>
                </div>
              ))}
            </div>
            <div className={style.submit}>
              <FEButton type='secondary' onClick={onClose}>
                {t('common.ok')}
              </FEButton>
            </div>
          </div>
        );
      },
    [conditions, t],
  );

  return isDesktop ? (
    <FETitledDialog
      showCloseButton={false}
      onClose={onClose}
      open={open}
      title={t('cartography.address.deliveryPriceConditions.title')}
      render={({ onClose }) => <Content onClose={onClose} />}
    />
  ) : (
    <FETitledBottomSheet
      showCloseButton={false}
      onClose={onClose}
      open={open}
      title={t('cartography.address.deliveryPriceConditions.title')}
      render={({ onClose }) => <Content onClose={onClose} />}
    />
  );
};

export default DeliveryPriceConditions;
