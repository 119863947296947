import { GetBonusAccountTransactionsResponseV1 } from 'api/version1';
import clsx from 'clsx';
import React from 'react';
import style from './style.module.css';
import { IMG_PLACEHOLDER_BONUS } from 'images';
import { Transaction } from './components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { $language } from 'domains/language';

const Content = ({
  transactions,
  showInfo,
  onChangeShowInfo,
}: {
  showInfo: boolean;
  onChangeShowInfo: (v: boolean) => void;
  transactions: GetBonusAccountTransactionsResponseV1.ResponseItem[];
}) => {
  const { t } = useTranslation();
  const language = useUnit($language);
  const validDate = (date: string) =>
    moment(date).locale(language).format('LL');

  const dates = React.useMemo(() => {
    function unique(arr: string[]) {
      let result: string[] = [];
      for (let str of arr) {
        if (!result.includes(str)) {
          result.push(str);
        }
      }
      return result;
    }
    return unique(
      transactions
        .filter((transaction) => transaction.type !== 'STATUS_CHANGED')
        .map((transaction) => validDate(transaction.createdAt)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  return (
    <>
      {showInfo === false && (
        <button
          className={clsx('button-icon-24', style.infoButton)}
          onClick={() => onChangeShowInfo(true)}
        />
      )}
      {showInfo ? (
        <div className={style.info}>
          <div className={style.infoContainer}>
            <img
              className={style.infoIcon}
              src={IMG_PLACEHOLDER_BONUS}
              alt=''
            />
            <div className={style.infoContent}>
              <p className={clsx('title', style.infoTitle)}>
                {t('bonus.page.info.title')}
              </p>
              <p className={clsx('text-2', style.infoMessage)}>
                {t('bonus.page.info.message.1')}
                <br />
                {t('bonus.page.info.message.2')}
                <br />
                <br />
                {t('bonus.page.info.message.3')}
                <br />
                {t('bonus.page.info.message.4')}
                <br />
                {t('bonus.page.info.message.5')}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={style.transactions}>
          {!!dates.length ? (
            dates.map((date, i) => (
              <div className={style.dateTransactionContainer} key={i}>
                <p className={clsx('caption-1', style.dateTransactionDate)}>
                  {date}
                </p>
                <div className={style.dateTransactions}>
                  {transactions
                    .filter(
                      (transaction) => transaction.type !== 'STATUS_CHANGED',
                    )
                    .filter(
                      (transaction) =>
                        validDate(transaction.createdAt) === date,
                    )
                    .map((transaction) => (
                      <Transaction
                        key={transaction.orderId}
                        transaction={transaction}
                      />
                    ))}
                </div>
              </div>
            ))
          ) : (
            <div className={style.noTransactionsContainer}>
              <p className={clsx('text-2', style.noTransactionsMessage)}>
                {t('bonus.page.noTransactionsLabel')}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Content;
