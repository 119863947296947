import React from 'react';

const useCountDown = (countFrom: number, onTickedCallback: () => void) => {
  const [count, setCount] = React.useState<number>(countFrom);
  const resetCounter = () => setCount(countFrom);

  React.useEffect(() => {
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    if (count === 0) onTickedCallback();
    return () => clearInterval(timer as NodeJS.Timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return {
    count,
    resetCounter,
  };
};

export default useCountDown;
