import React from 'react';
import { useClickOutside, useMediaQuery } from 'ui-kit/hooks';
import { IMG_IC_24_PROFILE } from 'images';
import style from './style.module.css';
import clsx from 'clsx';
import { MenuContainer } from './components';
import { MOBILE_MENU_WIDTH } from 'const';
import { MenuProps } from './props';
import { FESpinner } from 'ui-kit/components';

const Menu = ({ bonusAccount, user, isPendingUser, features }: MenuProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_MENU_WIDTH}px)`);
  const ref = React.useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setOpen(false));

  return (
    <div className={style.root} ref={ref}>
      {isDesktop ? (
        <button
          className={style.openButton}
          onClick={(e) => {
            if (!isPendingUser) {
              e.stopPropagation();
              e.preventDefault();
              setOpen(!open);
            }
          }}
        >
          {isPendingUser ? (
            <FESpinner size='small' />
          ) : (
            <img src={IMG_IC_24_PROFILE} alt='' />
          )}
        </button>
      ) : (
        <>
          {open ? (
            <button
              className={clsx(style.closeButton, 'button-icon-24')}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpen(false);
              }}
            />
          ) : !isPendingUser ? (
            <button
              className={clsx(style.openButton__mobile, 'button-icon-24')}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpen(true);
              }}
            />
          ) : (
            <FESpinner size='small' />
          )}
        </>
      )}
      <MenuContainer
        features={features}
        isPendingUser={isPendingUser}
        open={open}
        user={user}
        bonusAccount={bonusAccount}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default Menu;
