import React from 'react';
import FEPaymentTypePickerProps from './props';
import { PaymentTypeInList } from 'models';
import clsx from 'clsx';
import { useClickOutside, useMediaQuery } from 'ui-kit/hooks';
import style from './styles/picker.style.module.css';
import {
  GET_COURIER_PAYMENT_TYPE,
  GET_PAYMENT_TYPE,
  GET_PAYMENT_TYPE_IMAGE,
  MOBILE_WIDTH,
} from 'const';
import List from './components/List';
import { useTranslation } from 'react-i18next';

const FEPaymentTypePicker = <T = PaymentTypeInList,>({
  value,
  paymentTypes,
  onChange,
  hasError = false,
  orderType,
  disabled = false,
}: FEPaymentTypePickerProps<T>) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  useClickOutside(ref, () => isDesktop && setOpen(false));

  React.useEffect(() => {
    if (paymentTypes.length === 1) {
      onChange(paymentTypes[0]);
    }
  }, [onChange, paymentTypes]);

  return (
    <div
      ref={ref}
      className={clsx(
        style.root,
        hasError && style.root__error,
        open && Boolean(paymentTypes.length) && style.root__open,
      )}
      onClick={(e) => {
        if (!disabled) {
          paymentTypes.length > 1 && setOpen(!open);
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className={clsx(style.container, disabled && style.disabled)}>
        {value && (
          <img
            src={GET_PAYMENT_TYPE_IMAGE[value as PaymentTypeInList]}
            alt=''
            className={clsx('icon-24', style.image)}
          />
        )}
        <div className={style.data}>
          <p
            className={clsx(
              'text-1',
              style.floatingLabel,
              value && style.floatingLabel__active,
              hasError && style.floatingLabel__error,
            )}
          >
            {value
              ? t('cart.ordering.components.paymentTypes.label1')
              : t('cart.ordering.components.paymentTypes.label2')}
          </p>
          {value && (
            <p className={clsx('text-1', style.value)}>
              {orderType === 'COURIER'
                ? t(GET_COURIER_PAYMENT_TYPE[value as PaymentTypeInList])
                : t(GET_PAYMENT_TYPE[value as PaymentTypeInList])}
            </p>
          )}
        </div>
        <button className={clsx('button-chevronDown', style.exposeButton)} />
      </div>
      <List<T>
        onClose={() => setOpen(false)}
        {...{
          disabled,
          orderType,
          open: open && paymentTypes.length > 1,
          onChange,
          paymentTypes,
          isDesktop,
          value,
        }}
      />
    </div>
  );
};

export default FEPaymentTypePicker;

export type { FEPaymentTypePickerProps };
