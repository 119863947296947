import { getBannersByCatalogIdFx } from 'domains/banners';
import {
  getDeliveryZoneFx,
  changeRestaurant,
  changeCity,
} from 'domains/cartography';
import { sample } from 'effector';
import { spread } from 'patronum';
import { fetchCatalogFx, fetchCatalogByCityFx } from './effects';
import { $catalogCategories, $catalogId, $catalogProducts } from './stores';

spread({
  //@ts-expect-error
  source: sample({
    clock: fetchCatalogFx.done,
    fn: ({ params, result }) => ({
      catalogProducts: result.catalogProducts,
      catalogCategories: result.catalogCategories.items,
      catalogId: params.catalogId,
    }),
  }),
  targets: {
    catalogProducts: $catalogProducts,
    catalogCategories: $catalogCategories,
    catalogId: $catalogId,
  },
});

sample({
  clock: fetchCatalogFx.doneData,
  fn: ({ catalogId }) => ({ catalogId }),
  target: getBannersByCatalogIdFx,
});

spread({
  //@ts-expect-error
  source: sample({
    clock: fetchCatalogFx.failData,
    fn: () => ({
      catalogProducts: null,
      catalogCategories: [],
      catalogId: null,
    }),
  }),
  targets: {
    catalogProducts: $catalogProducts,
    catalogCategories: $catalogCategories,
    catalogId: $catalogId,
  },
});
//@ts-expect-error
spread({
  source: sample({
    clock: fetchCatalogByCityFx.doneData,
    fn: (payload) => ({
      catalogProducts: payload.catalogProducts,
      catalogCategories: payload.catalogCategories.items,
    }),
  }),
  targets: {
    catalogProducts: $catalogProducts,
    catalogCategories: $catalogCategories,
  },
});

sample({
  clock: fetchCatalogByCityFx.doneData,
  fn: ({ catalogId }) => ({ catalogId }),
  target: getBannersByCatalogIdFx,
});

spread({
  //@ts-expect-error
  source: sample({
    clock: fetchCatalogByCityFx.failData,
    fn: () => ({
      catalogProducts: null,
      catalogCategories: [],
    }),
  }),
  targets: {
    catalogProducts: $catalogProducts,
    catalogCategories: $catalogCategories,
  },
});

sample({
  clock: changeRestaurant,
  filter: (restaurant) => Boolean(restaurant),
  fn: (restaurant) => ({
    catalogId: restaurant!.catalogId,
    cityId: restaurant!.cityId,
  }),
  target: fetchCatalogFx,
});

sample({
  clock: getDeliveryZoneFx.doneData,
  fn: (payload) => ({
    catalogId: payload.catalogId,
    cityId: payload.zoneCityId,
  }),
  target: fetchCatalogFx,
});

sample({
  clock: changeCity,
  fn: (city) => city!,
  target: fetchCatalogByCityFx,
});
