import clsx from 'clsx';
import React from 'react';
import FERadioProps from './props';
import style from './style.module.css';

const FERadio = <T extends string>({
  label,
  values,
  onChange,
  disabled = false,
}: FERadioProps<T>) => {
  const keys = React.useMemo(() => Object.keys(values) as T[], [values]);

  return (
    <div className={style.root}>
      {label && <p className={clsx(style.label, 'caption-1')}>{label}</p>}
      <div className={style.inputs}>
        {keys.map((key, index) => (
          <label
            onClick={(e) => {
              if (!disabled) {
                onChange(key);
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            key={key}
            htmlFor={`radio-${key}`}
            className={style.inputContainer}
          >
            <input
              checked={values[key].checked}
              onChange={(e) => {
                if (!disabled) onChange(e.target.value as T);
              }}
              name={label}
              type='radio'
              id={`radio-${key}`}
              value={key}
              className={style.input}
            />
            <span className={style.radio}>
              <span className={style.dot} />
            </span>
            <p className='text-1'>{values[key].label}</p>
          </label>
        ))}
      </div>
    </div>
  );
};

export default FERadio;
export type { FERadioProps };
