import clsx from 'clsx';
import React from 'react';
import { useImages } from 'ui-kit/hooks/useImages';
import { onImageError } from 'utils';
import FESpinner from '../FESpinner';
import style from './style.module.css';

const FEImageComponent = ({
  className,
  loaderContainerClassName,
  image: { imageId, needToFetch = true, fetchFromThirdPartyOrigin = false },
  altImage,
  onError,
  spinnerSize = 'small',
  onClick,
  processingComponent,
  alt,
}: {
  alt?: string;
  className?: string;
  loaderContainerClassName?: string;
  image: {
    imageId: string;
    needToFetch?: boolean;
    fetchFromThirdPartyOrigin?: boolean;
  };
  altImage?: string;
  onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  spinnerSize?: 'small' | 'large' | 'medium';
  onClick?: () => void;
  processingComponent?: React.ReactNode;
}) => {
  const { url, isPending } = useImages({
    imageId,
    needToFetch,
    fetchFromThirdPartyOrigin,
  });

  return (
    <div className={clsx(style.root, className)} onClick={onClick}>
      <div
        className={clsx(
          style.loaderContainer,
          isPending === false && style.loaderContainer__loaded,
          loaderContainerClassName,
        )}
      >
        {processingComponent ? (
          processingComponent
        ) : (
          <FESpinner size={spinnerSize} />
        )}
      </div>

      <img
        className={clsx(style.image, className)}
        src={url}
        onContextMenu={(e) => e.preventDefault()}
        onError={(e) => {
          altImage && onImageError(e, altImage);
          onError && onError(e);
        }}
        alt={alt}
      />
    </div>
  );
};

export default FEImageComponent;
