import { useMount } from 'hooks';
import React from 'react';
import style from './style.module.css';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DELAY, PROMOTIONS_PATHNAME } from 'const';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuProps } from '../../props';
import { IMG_IC_24_CHEVRON_RIGHT, IMG_IC_24_BONUS } from 'images';
import { useLinks } from 'hooks/router';
import { useUnit } from 'effector-react';
import { logOutUser } from 'domains/profile';
import { IMG_PLACEHOLDER_AVATAR } from 'images';
import clsx from 'clsx';
import { FEImageComponent } from 'ui-kit/components';
import { useTranslation } from 'react-i18next';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

const MenuContainer = ({
  open,
  onClose,
  user,
  bonusAccount,
  features,
}: {
  open: boolean;
  onClose: () => void;
} & MenuProps) => {
  const { t } = useTranslation();
  const { mounted } = useMount({ isOpened: open });
  const containerRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { authLink, profileLink, ordersLink, bonusLink } = useLinks();

  const logOut = useUnit(logOutUser);

  if (!mounted) return null;

  console.log(user)

  return (
    <CSSTransition
      in={open}
      timeout={ANIMATION_DELAY}
      mountOnEnter
      unmountOnExit
      classNames={containerAnimation}
      nodeRef={containerRef}
    >
      <div
        ref={containerRef}
        className={style.root}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {user && (
          <div
            className={style.user}
            onClick={() => {
              navigate(profileLink);
              onClose();
            }}
          >
            <div className={style.userContent}>
              <FEImageComponent
                className={style.userAvatar}
                image={{ imageId: user.avatarId || '', needToFetch: true }}
                altImage={IMG_PLACEHOLDER_AVATAR}
              />
              <div className={style.userData}>
                <div className={style.userName}>
                  {user.fullName?.firstName || user.fullName?.lastName ? (
                    <>
                      <p className='headline'>{user.fullName.firstName}</p>
                      <p className='headline'>{user.fullName.lastName}</p>
                    </>
                  ) : (
                    <>
                      <p className='headline'>{user.phone}</p>
                    </>
                  )}
                </div>
                <p className={clsx('text-2', style.link)}>
                  {t('menu.editProfile')}
                </p>
              </div>
            </div>
          </div>
        )}
        <div>
          {user !== null ? (
            <>
              <div
                className={style.menuItem}
                onClick={() => {
                  navigate(ordersLink);
                  onClose();
                }}
              >
                <p className='text-1'>{t('menu.orders')}</p>
                <img src={IMG_IC_24_CHEVRON_RIGHT} alt='' />
              </div>
              {bonusAccount !== null &&
                features.includes('LOYALTY_PROGRAM') && (
                  <div
                    className={style.menuItem}
                    onClick={() => {
                      navigate(bonusLink);
                      onClose();
                    }}
                  >
                    <p className='text-1'>{t('menu.bonus')}</p>
                    <div className={style.balance}>
                      <div className={style.balanceAmount}>
                        <img src={IMG_IC_24_BONUS} alt='' />
                        <p>{bonusAccount.balance}</p>
                      </div>
                      <img src={IMG_IC_24_CHEVRON_RIGHT} alt='' />
                    </div>
                  </div>
                )}
              <div
                className={style.menuItem}
                onClick={() => {
                  navigate(`${location.pathname}/${PROMOTIONS_PATHNAME}`);
                  onClose();
                }}
              >
                <p className='text-1'>{t('menu.promotions')}</p>
                <img src={IMG_IC_24_CHEVRON_RIGHT} alt='' />
              </div>
              <div
                className={style.exit}
                onClick={() => {
                  logOut();
                  onClose();
                }}
              >
                <p className='text-1'>{t('menu.logOut')}</p>
              </div>
            </>
          ) : (
            <>
              <div
                className={style.menuItem}
                onClick={() => {
                  navigate(authLink);
                  onClose();
                }}
              >
                <p className='text-1'>{t('menu.logIn')}</p>
                <img src={IMG_IC_24_CHEVRON_RIGHT} alt='' />
              </div>
              <div
                className={style.menuItem}
                onClick={() => {
                  navigate(`${location.pathname}/${PROMOTIONS_PATHNAME}`);
                  onClose();
                }}
              >
                <p className='text-1'>{t('menu.promotions')}</p>
                <img src={IMG_IC_24_CHEVRON_RIGHT} alt='' />
              </div>
            </>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default MenuContainer;
