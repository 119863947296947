import { DeliveryZoneAndMapLayerZone } from 'domains/cartography';
import { useMount } from 'hooks';
import { CurrencySymbol } from 'models';
import React from 'react';
import { Layout } from './components';

export type ZoneBottomSheetProps = {
  deliveryZoneAndMapLayerZone: DeliveryZoneAndMapLayerZone | null;
  open: boolean;
  onClose: () => void;
  currency: CurrencySymbol;
};

const ZoneBottomSheet = (props: ZoneBottomSheetProps) => {
  const { mounted } = useMount({ isOpened: props.open });

  if (!mounted) return null;

  return <Layout {...props} />;
};

export default ZoneBottomSheet;
