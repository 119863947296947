import { useMount } from 'hooks';
import React from 'react';
import { Layout } from './layout';
import Portal from './portal';

export const CookiesLayot = ({ show, onClose }: Props) => {
  const { mounted } = useMount({ isOpened: show });
  if (!mounted) return null;

  return (
    <Portal>
      <Layout onClose={onClose} />
    </Portal>
  );
};

type Props = {
  show: boolean;
  onClose: () => void;
};
