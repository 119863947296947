import clsx from 'clsx';
import { MOBILE_WIDTH } from 'const';
import React from 'react';
import { FEButton, FETitledBottomSheet, FETitledDialog } from 'components';
import InputMask from 'react-input-mask';
import { useMediaQuery } from 'ui-kit/hooks';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';

const normalizeMask = (mask: string) =>
  mask
    .replace(/[[\]]/g, '')
    .split('')
    .map((char) => {
      if (/[1-9]/.test(char)) return `\\${char}`;
      return char;
    })
    .join('')
    .replace(/[0]/g, '9');

const PhoneField = ({
  phone,
  mask,
  disabled,
}: {
  phone: string | null;
  mask: string | undefined;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  const Content = ({ onClose }: { onClose: () => void }) => {
    return (
      <div className={style.dialog}>
        <p className={style.message}>{t('editProfile.form.phone.message')}</p>
        <div className={style.submit}>
          <FEButton onClick={onClose}>{t('common.apply')}</FEButton>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={style.root} onClick={() => !disabled && setOpen(true)}>
        <div className={style.container}>
          <div className={style.content}>
            <p className={clsx('caption-1', style.floatingLabel)}>Телефон</p>
            {phone && mask && (
              <InputMask
                mask={normalizeMask(mask)}
                value={phone}
                alwaysShowMask={true}
                maskPlaceholder={''}
              >
                <input
                  className={clsx('text-1', style.input)}
                  value={phone}
                  type='tel'
                  name=''
                  id=''
                />
              </InputMask>
            )}
          </div>
          <button
            className={clsx('button-chevronRight', style.chevronButton)}
          />
        </div>
      </div>

      {isDesktop ? (
        <FETitledDialog
          title={t('editProfile.form.phone.title')}
          open={open}
          onClose={() => setOpen(false)}
          render={Content}
        />
      ) : (
        <FETitledBottomSheet
          title={t('editProfile.form.phone.title')}
          open={open}
          onClose={() => setOpen(false)}
          render={Content}
        />
      )}
    </>
  );
};

export default PhoneField;
