import { createStore } from 'effector';
import { pending } from 'patronum';
import { getPromotionsByCatalogIdFx } from './effects';
import { PromotionModel } from './entities';

export const $promotions = createStore<PromotionModel[]>([]);

export const $isPendingPromotions = pending({
  effects: [getPromotionsByCatalogIdFx],
});
