import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetCountriesCodesResponseV1, GetCountriesResponseV1 } from '.';

const countriesRepositoryV1 = {
  getCountries: (signal?: AbortSignal) =>
    new Promise<GetCountriesResponseV1.ListResponse>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/countries`;
          let result: AxiosResponse<
            BaseAPIResponse<GetCountriesResponseV1.ListResponse>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetCountriesResponseV1.ListResponse>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),

  getCountriesCodes: (signal?: AbortSignal) =>
    new Promise<GetCountriesCodesResponseV1.Response>(
      async (resolve, reject) => {
        try {
          let url = `${BASE_URL}/not-secure/api/v1/places/countries/codes`;
          let result: AxiosResponse<
            BaseAPIResponse<GetCountriesCodesResponseV1.Response>
          > = await BaseAPIRepository.get<
            BaseAPIResponse<GetCountriesCodesResponseV1.Response>
          >(url, { signal });
          resolve(result.data.result);
        } catch (error: any) {
          FERequestErrorsHandler(error, reject);
        }
      },
    ),
};

export default countriesRepositoryV1;
