import { MOBILE_WIDTH } from 'const';
import React from 'react';
import { useMediaQuery } from 'ui-kit/hooks';
import { FETitledBottomSheet, FETitledDialog } from '../..';
import Picker from './Picker';

const Container = ({
  open,
  title,
  years,
  months,
  days,
  initialYearIndex,
  initialMonthIndex,
  initialDayIndex,
  changeYear,
  changeMonth,
  changeDay,
  submit,
  onClose,
}: {
  open: boolean;
  title: string;
  onClose: () => void;
  years: string[];
  months: string[];
  days: string[];
  initialYearIndex: number;
  initialMonthIndex: number;
  initialDayIndex: number;
  changeYear: (index: number) => void;
  changeMonth: (index: number) => void;
  changeDay: (index: number) => void;
  submit: () => void;
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  return isDesktop ? (
    <FETitledDialog
      title={title}
      open={open}
      onClose={onClose}
      render={({ onClose }) => (
        <Picker
          {...{
            submit,
            onClose,
            years,
            months,
            days,
            initialYearIndex,
            initialMonthIndex,
            initialDayIndex,
            changeYear,
            changeMonth,
            changeDay,
          }}
        />
      )}
    />
  ) : (
    <FETitledBottomSheet
      open={open}
      title={title}
      onClose={onClose}
      render={({ onClose }) => (
        <Picker
          {...{
            submit,
            onClose,
            years,
            months,
            days,
            initialYearIndex,
            initialMonthIndex,
            initialDayIndex,
            changeYear,
            changeMonth,
            changeDay,
          }}
        />
      )}
    />
  );
};

export default Container;
