import catalogRepositoryV1 from './catalog';
import ordersRepositoryV1 from './orders';
import addressesRepositoryV1 from './addresses';
import customerRepositoryV1 from './customer';
import authRepositoryV1 from './auth';
import citiesRepositoryV1 from './cities';
import dishesRepositoryV1 from './dishes';
import restaurantsRepositoryV1 from './restaurants';
import deliveryZonesRepositoryV1 from './deliveryZones';
import storiesRepositoryV1 from './stories';
import imagesRepositoryV1 from './images';
import videosRepositoryV1 from './videos';
import transactionsRepositoryV1 from './transactions';
import bonusAccountsRepositoryV1 from './bonusAccounts';
import feedbackRepositoryV1 from './feedback';
import notificationsRepositoryV1 from './notifications';
import promotionsRepositoryV1 from './promotions';
import bannersRepositoryV1 from './banners';
import codeRepositoryV1 from './code';
import featuresRepositoryV1 from './features';
import giftRepurchasesV1 from './giftRepurchases';
import countriesRepositoryV1 from './countries';
import legalsDocumentsRepositoryV1 from './legals';
import sitesMapRepositoryV1 from './sitesMap';

export * from './catalog';
export * from './orders';
export * from './addresses';
export * from './customer';
export * from './auth';
export * from './cities';
export * from './dishes';
export * from './restaurants';
export * from './deliveryZones';
export * from './stories';
export * from './images';
export * from './transactions';
export * from './bonusAccounts';
export * from './feedback';
export * from './notifications';
export * from './promotions';
export * from './banners';
export * from './features';
export * from './giftRepurchases';
export * from './countries';
export * from './legals';
export * from './sitesMap';

const FeatSDKV1 = {
  catalog: catalogRepositoryV1,
  orders: ordersRepositoryV1,
  addresses: addressesRepositoryV1,
  customer: customerRepositoryV1,
  auth: authRepositoryV1,
  cities: citiesRepositoryV1,
  dishes: dishesRepositoryV1,
  restaurants: restaurantsRepositoryV1,
  deliveryZones: deliveryZonesRepositoryV1,
  stories: storiesRepositoryV1,
  images: imagesRepositoryV1,
  videos: videosRepositoryV1,
  transactions: transactionsRepositoryV1,
  bonusAccounts: bonusAccountsRepositoryV1,
  feedback: feedbackRepositoryV1,
  notifications: notificationsRepositoryV1,
  promotions: promotionsRepositoryV1,
  banners: bannersRepositoryV1,
  code: codeRepositoryV1,
  features: featuresRepositoryV1,
  giftRepurchases: giftRepurchasesV1,
  countries: countriesRepositoryV1,
  legals: legalsDocumentsRepositoryV1,
  sitesMap: sitesMapRepositoryV1,
};

export default FeatSDKV1;
