import { OrdersValidateResponseV4 } from 'api/version4';
import _ from 'lodash';
import {
  ConstructorInCartModel,
  ConstructorToCartModel,
  ConstructorToValidateModel,
  DishInCartModel,
  DishToCartModel,
  DishToValidateModel,
  ProblemsToShowModel,
} from './entities';

export const mapDishesFromCartToValidate = (
  dishes: DishInCartModel[],
): DishToValidateModel[] =>
  dishes.map((dish) => ({
    orderItemId: dish.orderItemId,
    productId: dish.product.product.id,
    quantity: dish.product.quantity,
    base: {
      id: dish.product.base.id,
      addonGroups: dish.product.base.addonGroups.map((addonGroup) => ({
        id: addonGroup.id,
        modifiers: addonGroup.modifiers.map((modifier) => ({
          id: modifier.id,
          quantity: modifier.quantity,
        })),
      })),
      selectorGroups: dish.product.base.selectorGroups.map((selectorGroup) => ({
        id: selectorGroup.id,
        modifiers: selectorGroup.modifiers.map((modifier) => ({
          id: modifier.id,
        })),
      })),
      switchGroups: dish.product.base.switchGroups.map((switchGroup) => ({
        id: switchGroup.id,
        modifiers: switchGroup.modifiers.map((modifier) => ({
          id: modifier.id,
        })),
      })),
    },
  }));

export const mapConstructorsFromCartToValidate = (
  constructors: ConstructorInCartModel[],
): ConstructorToValidateModel[] =>
  constructors.map((constructor) => ({
    orderItemId: constructor.orderItemId,
    productId: constructor.product.product.id,
    quantity: constructor.product.quantity,
    base: {
      id: constructor.product.base.id,
      addonGroups: constructor.product.base.addonGroups.map((addonGroup) => ({
        id: addonGroup.id,
        modifiers: addonGroup.modifiers.map((modifier) => ({
          id: modifier.id,
          quantity: modifier.quantity,
        })),
      })),
      selectorGroups: constructor.product.base.selectorGroups.map(
        (selectorGroup) => ({
          id: selectorGroup.id,
          modifiers: selectorGroup.modifiers.map((modifier) => ({
            id: modifier.id,
          })),
        }),
      ),
    },
  }));

export const hasSameDish = (
  dishFromCatalog: DishToCartModel,
  dishesFromCart: DishInCartModel[],
): { hasDish: boolean; index: number | undefined } => {
  if (dishesFromCart.length === 0) return { hasDish: false, index: undefined };
  else {
    const sameIdDish = dishesFromCart.find(
      (dishInCart) =>
        dishInCart.product.product.id === dishFromCatalog.productId,
    );
    const sameIdDishIndex = dishesFromCart.findIndex(
      (dishInCart) =>
        dishInCart.product.product.id === dishFromCatalog.productId,
    );

    if (sameIdDish === undefined) return { hasDish: false, index: undefined };
    else {
      const sameIdDishBase = sameIdDish.product.base;

      if (
        sameIdDishBase.id === dishFromCatalog.base.id &&
        _.isEqual(
          dishFromCatalog.base.addonGroups,
          sameIdDishBase.addonGroups.map((addonGroup) => ({
            id: addonGroup.id,
            modifiers: addonGroup.modifiers.map((modifier) => ({
              id: modifier.id,
              quantity: modifier.quantity,
            })),
          })),
        ) &&
        _.isEqual(
          dishFromCatalog.base.selectorGroups,
          sameIdDishBase.selectorGroups.map((selectorGroup) => ({
            id: selectorGroup.id,
            modifiers: selectorGroup.modifiers.map((modifier) => ({
              id: modifier.id,
            })),
          })),
        ) &&
        _.isEqual(
          dishFromCatalog.base.switchGroups,
          sameIdDishBase.switchGroups.map((switchGroup) => ({
            id: switchGroup.id,
            modifiers: switchGroup.modifiers.map((modifier) => ({
              id: modifier.id,
            })),
          })),
        )
      ) {
        return { hasDish: true, index: sameIdDishIndex };
      }
      return { hasDish: false, index: undefined };
    }
  }
};

export const hasSameConstructor = (
  constructorFromCatalog: ConstructorToCartModel,
  constructorsFromCart: ConstructorInCartModel[],
): { hasConstructor: boolean; index: number | undefined } => {
  if (constructorsFromCart.length === 0)
    return { hasConstructor: false, index: undefined };
  else {
    const sameIdConstructor = constructorsFromCart.find(
      (constructorInCart) =>
        constructorInCart.product.product.id ===
        constructorFromCatalog.productId,
    );
    const sameIdConstructorIndex = constructorsFromCart.findIndex(
      (constructorInCart) =>
        constructorInCart.product.product.id ===
        constructorFromCatalog.productId,
    );

    if (sameIdConstructor === undefined)
      return { hasConstructor: false, index: undefined };
    else {
      const sameIdConstructorBase = sameIdConstructor.product.base;

      if (
        sameIdConstructorBase.id === constructorFromCatalog.base.id &&
        _.isEqual(
          constructorFromCatalog.base.addonGroups,
          sameIdConstructorBase.addonGroups.map((addonGroup) => ({
            id: addonGroup.id,
            modifiers: addonGroup.modifiers.map((modifier) => ({
              id: modifier.id,
              quantity: modifier.quantity,
            })),
          })),
        ) &&
        _.isEqual(
          constructorFromCatalog.base.selectorGroups,
          sameIdConstructorBase.selectorGroups.map((selectorGroup) => ({
            id: selectorGroup.id,
            modifiers: selectorGroup.modifiers.map((modifier) => ({
              id: modifier.id,
            })),
          })),
        )
      ) {
        return { hasConstructor: true, index: sameIdConstructorIndex };
      }
      return { hasConstructor: false, index: undefined };
    }
  }
};

export const getProblemActionType: Record<
  ProblemsToShowModel,
  'revalidate' | 'cartography' | 'catalog' | 'hide'
> = {
  ZONE_INVALID: 'cartography',
  WRONG_ORDER_TYPE: 'hide',
  CITY_STOP: 'cartography',
  DELIVERY_TIME_INVALID: 'hide',
  GO_TO_PLACE_TIME_INVALID: 'hide',
  INTERNAL_ERROR: 'catalog',
  INVALID_PROMOTION_PRODUCT: 'hide',
  TO_DATETIME_NOT_PROVIDED: 'hide',
  SUM_ORDER_INVALID: 'hide',
  RESTAURANT_INVALID: 'cartography',
  MIN_ORDER_PRICE_INVALID: 'hide',
};

export const getButtonText: Record<
  'revalidate' | 'cartography' | 'catalog' | 'hide',
  string
> = {
  cartography: 'cart.validationErrors.errorsButtonsText.cartography',
  revalidate: 'cart.validationErrors.errorsButtonsText.revalidate',
  catalog: 'cart.validationErrors.errorsButtonsText.catalog',
  hide: 'cart.validationErrors.errorsButtonsText.hide',
};

export const filterBlockingProblems = (
  problems: OrdersValidateResponseV4.Problem[],
) =>
  problems.filter((problem) => {
    const reason = problem.reason;
    return (
      reason !== 'DELIVERY_CAN_BE_CHEAPER' &&
      reason !== 'DELIVERY_CAN_BE_LONGER' &&
      reason !== 'NO_CUSTOMER' &&
      reason !== 'DELIVERY_CAN_BE_FREE'
    );
  });
