import React from 'react';
import style from './style.module.css';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DELAY } from 'const';

const overlayAnimation = {
  enter: style.enter,
  enterActive: style.enterActive,
  exit: style.exit,
  exitActive: style.exitActive,
};

const FEOverlay = ({
  onClick,
  animationIn,
}: {
  onClick?: () => void;
  animationIn: boolean;
}) => {
  const overlayRef = React.useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={animationIn}
      timeout={ANIMATION_DELAY}
      mountOnEnter
      unmountOnExit
      nodeRef={overlayRef}
      classNames={overlayAnimation}
    >
      <div
        className={style.overlay}
        ref={overlayRef}
        onClick={(e) => {
          onClick && onClick();
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </CSSTransition>
  );
};

export default FEOverlay;
