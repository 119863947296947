import { changeCity } from 'domains/cartography';
import { sample } from 'effector';
import { getSitesMapByCountryIdFx } from './effects';
import { $sitesMap } from './stores';

sample({
  clock: changeCity,
  filter: (city) => !!city,
  fn: (city) => ({ countryId: city!.countryId }),
  target: getSitesMapByCountryIdFx,
});

sample({
  clock: getSitesMapByCountryIdFx.doneData,
  target: $sitesMap,
});

sample({
  clock: getSitesMapByCountryIdFx.failData,
  fn: () => null,
  target: $sitesMap,
});
