import { Marker as MarkerComponent, MarkerProps } from '@react-google-maps/api';
import {
  IMG_IC_48_MARKER_RESTAURANT_SELECTED,
  IMG_IC_48_MARKER_RESTAURANT_DEFAULT,
} from 'images';

type Props = {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  position: google.maps.LatLng | google.maps.LatLngLiteral;
} & Pick<MarkerProps, 'clusterer'>;

const Marker = ({ selected, ...props }: Props & { selected?: boolean }) => {
  return (
    <MarkerComponent
      icon={
        selected === undefined || selected === true
          ? IMG_IC_48_MARKER_RESTAURANT_SELECTED
          : IMG_IC_48_MARKER_RESTAURANT_DEFAULT
      }
      {...props}
    />
  );
};

export default Marker;
