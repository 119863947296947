import { IMG_PLACEHOLDER_CARD_PICTURE_DESKTOP } from 'images';
import { ConstructorCardProps } from './props';
import { useMount } from 'hooks';
// import cyrillicToTranslit from 'cyrillic-to-translit-js';
import { usePrepareLink } from 'hooks/router';
import { useMatch, useNavigate, useLocation } from 'react-router-dom';
import { Constructor } from './components';
import style from './style.module.css';
import { addConstructorToCartFx } from 'domains/cart';
import { useUnit } from 'effector-react';
import { FEImageComponent } from 'ui-kit/components';
import { useMediaQuery } from 'ui-kit/hooks';
import { MOBILE_MENU_WIDTH } from 'const';
import clsx from 'clsx';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { $catalogCategories } from 'domains/catalog';

const ConstructorCard = ({
  product,
  validationPayload,
  stopLists,
  className,
  currency,
  available,
}: Omit<ConstructorCardProps, 'onAddConstructorToCart'> & {
  currency: CurrencySymbol;
  available: boolean;
}) => {
  const [hasImageError, setHasImageError] = useState(false);
  const { t } = useTranslation();
  const match = useMatch('/:name');
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_MENU_WIDTH}px)`);
  const { imageId, linkValue, imageSiteId } = product;

  const onAddConstructorToCart = useUnit(addConstructorToCartFx);
  const categories = useUnit($catalogCategories);

  const productPathname =
    // linkValue.counter === 1
    // ?
    linkValue.value;
  // : `${linkValue.value}${linkValue.counter}`
  const productLink = usePrepareLink({
    to: `/${productPathname}`,
  });

  const { mounted } = useMount({
    isOpened: match?.params.name === productPathname,
  });

  return (
    <>
      <div
        className={clsx(style.root, className)}
        onClick={() => available && navigate(productLink)}
      >
        <FEImageComponent
          onError={() => setHasImageError(true)}
          className={clsx(
            style.image,
            !available && style.image__unavailable,
            hasImageError && style.imageError,
          )}
          image={{
            imageId: isDesktop ? imageSiteId || imageId || '' : imageId || '',
          }}
          altImage={IMG_PLACEHOLDER_CARD_PICTURE_DESKTOP}
        />
        {!available && (
          <div className={style.unavailableContainer}>
            <p className={'caption-2'}>
              {t('catalog.constructor.card.unavailableLabel')}
            </p>
          </div>
        )}
      </div>
      {mounted && (
        <Constructor
          {...{
            categories,
            currency,
            open: match?.params.name === productPathname,
            onClose: () => {
              location.key === 'default'
                ? navigate('/', { replace: true })
                : navigate(-1);
            },
            product,
            validationPayload,
            stopLists,
            onAddConstructorToCart,
          }}
        />
      )}
    </>
  );
};

export default ConstructorCard;
