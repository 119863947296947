import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FEHeadersCookiesUtil, FERequestErrorsHandler } from 'utils';

const codeRepositoryV1 = {
  getCode: (code: string) => {
    return new Promise<{ version: string }>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/version/${code}`;
        let result: AxiosResponse<BaseAPIResponse<{ version: string }>> =
          await BaseAPIRepository.get(url);

        FEHeadersCookiesUtil.setCode(result.data.result.version);
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    });
  },
};

export default codeRepositoryV1;
