import { BASE_URL } from 'const';
import { AuthClient } from '../authClient';
import { axiosRepository } from '../axiosRepository';

const BaseAPIRepository = axiosRepository({
  baseUrl: BASE_URL,
  tokenRefresh: () => AuthAPI.refresh(),
  authErrors: {
    reasonKey: 'reason',
    tokenExpired: ['ACCESS_TOKEN_EXPIRED', 'ACCESS_TOKEN_MALFORMED'],
    permissionInsufficient: ['CREDENTIAL_PERMISSION_INSUFFICIENT'],
    needToLogin: [
      'CREDENTIAL_BLOCKED',
      'ACCESS_TOKEN_INACTIVATED',
      'ACCESS_TOKEN_NOT_EXIST',
      'ACCESS_TOKEN_REFRESHED',
      'ACCESS_TOKEN_MODIFIED',
      'ACCESS_TOKEN_MALFORMED',
    ],
  },
});

export const AuthAPI = new AuthClient(
  BaseAPIRepository,
  {
    signIn: `${BASE_URL}/not-secure/api/v1/auth/login/email`,
    signOut: `${BASE_URL}/not-secure/api/v1/auth/logout`,
    refresh: `${BASE_URL}/not-secure/api/v1/auth/refresh`,
  },
  {
    loginPropName: 'email',
  },
);

export default BaseAPIRepository;
