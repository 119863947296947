import { MOBILE_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import {
  FEScrollableTitledBottomSheet,
  FEScrollableTitledDialog,
} from 'components';
import style from './style.module.css';
import { Content } from './components';
import { useTranslation } from 'react-i18next';

const LegalInformationPage = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  return isDesktop ? (
    <FEScrollableTitledDialog
      title={t('legalInformation.title')}
      containerClassName={style.container}
      open={open}
      onClose={onClose}
      render={() => <Content />}
    />
  ) : (
    <FEScrollableTitledBottomSheet
      title={t('legalInformation.title')}
      containerClassName={style.container}
      open={open}
      onClose={onClose}
      render={() => <Content />}
    />
  );
};

export default LegalInformationPage;
