import React from 'react';
import { IMG_MODAL_MAP } from 'images';
import style from './style.module.css';
import clsx from 'clsx';

const FEMapPlaceholder = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(style.root, className)}>
      <div className={style.container}>
        <img className={style.placeholder} src={IMG_MODAL_MAP} alt={''} />
      </div>
    </div>
  );
};

export default FEMapPlaceholder;
