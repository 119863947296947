import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetDishByIdRequestV2 } from '.';
import { GetDishByIdResponseV2 } from '.';

const dishesRepositoryV2 = {
  getDishById: (
    { id, catalogId }: GetDishByIdRequestV2,
    signal?: AbortSignal,
  ) =>
    new Promise<GetDishByIdResponseV2.Response>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v2/dishes/${id}?catalogId=${catalogId}`;
        let result: AxiosResponse<
          BaseAPIResponse<GetDishByIdResponseV2.Response>
        > = await BaseAPIRepository.get<
          BaseAPIResponse<GetDishByIdResponseV2.Response>
        >(url, { signal });
        resolve(result.data.result);
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    }),
};

export default dishesRepositoryV2;
