import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import style from './style.module.css';
import { FEImageComponent } from 'components';
import { CurrencySymbol } from 'models';
import clsx from 'clsx';

const ModifierCard = ({
  name,
  imageId,
  quantity,
  price,
  currency,
}: {
  name: string;
  imageId?: string;
  quantity: number;
  price: number;
  currency: CurrencySymbol;
}) => {
  return (
    <div className={style.root}>
      <div className={style.imageContainer}>
        <FEImageComponent
          className={style.image}
          image={{ imageId: imageId || '' }}
          altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
        />
        {quantity > 1 && (
          <div className={style.quantity}>
            <p className='caption-2'>{quantity}</p>
          </div>
        )}
      </div>
      <div className={style.data}>
        <p className={clsx('caption-1', style.name)}>{name}</p>
        <p className='caption-2'>{`+ ${price} ${currency}`}</p>
      </div>
    </div>
  );
};

export default ModifierCard;
