import clsx from 'clsx';
import React from 'react';
import FEAvatarFieldProps from './props';
import { IMG_PLACEHOLDER_AVATAR } from 'images';
import style from './style.module.css';
import FEImageComponent from '../FEImageComponent';

export default React.forwardRef<HTMLInputElement, FEAvatarFieldProps>(
  ({ avatarId, onChange, disabled = false }, ref) => {
    const [image, setImage] = React.useState<string>(avatarId || '');
    const [needToFetch, setNeedToFetch] = React.useState<boolean>(true);

    React.useLayoutEffect(() => {
      setImage(avatarId || '');
    }, [avatarId]);

    const changeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      onChange(e.target.files![0]);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.readyState === 2) {
          setImage(fileReader.result as string);
          setNeedToFetch(false);
        }
      };
      fileReader.readAsDataURL(e.target.files![0]);
    };

    return (
      <div className={style.root}>
        <label htmlFor='FEAvatarLoader' onClick={(e) => e.stopPropagation()}>
          <FEImageComponent
            className={style.avatar}
            altImage={IMG_PLACEHOLDER_AVATAR}
            image={{ imageId: image || '', needToFetch }}
            spinnerSize='medium'
          />
        </label>
        <input
          id='FEAvatarLoader'
          ref={ref}
          onClick={(e) => e.stopPropagation()}
          type='file'
          className={clsx(style.input, 'FEAvatarLoader')}
          onChange={!disabled ? changeFileHandler : undefined}
          multiple={false}
          accept='image/*'
        />
      </div>
    );
  },
);

export type { FEAvatarFieldProps };
