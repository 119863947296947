export const abortWrapper = <Params, ReturnType>(
  fn: (params: Params, abortSignal: AbortSignal) => ReturnType,
) => {
  let abortController: AbortController;

  return (params: Params) => {
    abortController && abortController.abort();
    abortController = new AbortController();
    return fn(params, abortController.signal);
  };
};
